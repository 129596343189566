import React, { useState,useEffect } from "react";
import { TopBar } from "../../../Components/Sidebar/Sidebar";
import { getnotification } from "../../../Services/Admin/Api";
import { userId, userOrgId, userRole } from "../../../Components/Assets/userData";
import { io } from "socket.io-client";
import { baseUrl } from "../../../Services/EndPoint";
import moment from "moment";
import Loader from "../../../Components/Assets/Loader";
import { readOrgAdminNotification } from "../../../Services/Admin/Api";
import { useNavigate } from "react-router-dom";
const socket = io(baseUrl);


export default function NotificationOrganizationAdmin() {
  let [notificationData, setNotificationData] = useState([])
  const [loader,setLoader] = useState(true)
  
  socket.on("check", () => {
    getNotificationData() 
  });
  const getNotificationData = async () => {
    await getnotification(userId(), { organization_id: userOrgId() }).then(async(res) => {
      console.log(res.data.data)
      let dataReverse = [...res.data.data.result];
      setNotificationData(dataReverse)
      setLoader(false)
      let ok = dataReverse.map((e) => e.data).flat(Infinity);
      let ids = ok
        .filter((e) => !e.view_by.includes(userId()))
        .map((e) => e._id);
        if(window.location.pathname.includes("notificationOrgAdmin")){

          await readDataNotificationOrg(ids);
        }
    }).catch((err)=>{
      console.log(err);
      setLoader(false)
    })
  }

  const readDataNotificationOrg = async (d) => {
    let payload = {
      notificationData: d,
    };
    if(d.length ===0) return
    await readOrgAdminNotification(userId(), payload)
      .then((res) => {
        console.log(res.data.data);
        // setNotificationData("")
      })
      .catch((err) => {
        console.log(err);
      });
  };

let navigate=useNavigate()

  function navigateToNotification(data) {
    console.log(data);

    if (data?.routes) {
      let routeData = data.routes.find((e) => e.role === userRole());
      console.log(routeData);
      if (routeData?.route) {
        navigate(routeData.route);
      }
    }
  }
  function getDatainTime(date) {
    let today = moment().format("YYYY-MM-DD");
    let Yesterday = new Date(today);
    Yesterday.setDate(Yesterday.getDate() - 1);
    let current = new Date(date);
    if (date === "Today") {
      return date;
    }
    if (+Yesterday === +current) {
      return "Yesterday";
    } else {
      return moment(current).format("DD MMMM, YYYY");
    }
  }
  useEffect(() => {
    getNotificationData()
  }, [])

  return (
    <>
      <TopBar />
      <div className="mainContainer">
        <div className="card p-md-4 p-sm-3 p-2">
          <div className="d-flex align-items-center justify-content-between ">
            <div className="d-flex align-items-center">
              <h1>Notifications</h1>
              {/* <span className="notification-number ms-4">3</span>
              <i className="pi pi-refresh ms-4 refreshColor"></i> */}
            </div>
          </div>
          {notificationData.map((res) => {
            return (
              <>
              <h5 className="mt-3">{getDatainTime(res._id)}</h5>
                {res?.data?.map((res2)=>{
              return(
                <>
                <div className="notificationList mt-3 p-3"  onClick={() => navigateToNotification(res2)}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex  align-items-center">
                    {/* <div>
                      <img
                        src="/images/eProfile.png"
                        alt=""
                        style={{ width: "60px" }}
                      />
                    </div> */}
                    <div className="">
                      {console.log(res2)}
                      <span className="textStyleMain">{res2?.orgAdmin_message?.message_one}</span>
                      <p className="textStyle">{res2?.orgAdmin_message?.message_two} </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <span className=" me-2"></span>
                    <span>{moment(res2.created_at).fromNow()}</span>

                  </div>
                </div>
              </div>
                </>
              )
             })}</>
           
              
            )
          })}
        </div>
      </div>
      <Loader open={loader} />

    </>
  );
}
