import React, { useEffect, useRef, useState } from 'react';
import style from "./recording.module.css";
import { Carousel } from 'primereact/carousel';
import { BackButton } from '../../Components/Assets/BackButton';
import moment from 'moment';

export const ViewScreenShots = ({ currentVideoData, selectedKpi, startTime, partData, endTime, interval, setIsScreenShotPage, sessionData }) => {


    let [allIntervals, setAllIntervals] = useState([])
    let screenVideoRef = useRef([])
    let frontVideoRef = useRef([])
    let backVideoRef = useRef([])
    let [mainVideoUrl, setMainVideoUrl] = useState(currentVideoData.back)
    let mainVideoRef = useRef(null)

    function setVideoIntervals() {
        if (endTime && startTime) {
            let diff = endTime - startTime
            let intervals = diff / interval
            let num = startTime
            let arr = [];
            let dd = [...Array(interval)]
            dd.forEach((r) => {
                arr.push(num)
                num += intervals
            })
            setAllIntervals(arr)
        }
    }


    const seekToTime = (videoIndex, timeInSeconds) => {
        if (frontVideoRef.current[videoIndex]) {
            frontVideoRef.current[videoIndex].currentTime = timeInSeconds;
        }
        if (backVideoRef.current[videoIndex]) {
            backVideoRef.current[videoIndex].currentTime = timeInSeconds;
        }
        if (screenVideoRef.current[videoIndex]) {
            screenVideoRef.current[videoIndex].currentTime = timeInSeconds;
        }
    };

    function ff() {
        allIntervals.forEach((res, ind) => {
            seekToTime(ind, res)
        })
    }
    useEffect(() => {
        if (allIntervals.length !== 0) {
            // setTimeout(() => {
            ff()
            // }, 00);
        }
    }, [allIntervals])

    function videoClick(data, vid) {
        console.log(data)
        setMainVideoUrl(currentVideoData[vid])
        setTimeout(() => {
            mainVideoRef.current.currentTime = data.val
        }, 0);
    }



    useEffect(() => {
        setVideoIntervals()
        mainVideoRef.current.currentTime = startTime
    }, [startTime])


    let responsiveOptions = [
        {
            breakpoint: '1800px',
            numVisible: 6,
            numScroll: 6
        },
        {
            breakpoint: '1600px',
            numVisible: 5,
            numScroll: 5
        },
        {
            breakpoint: '1400px',
            numVisible: 4,
            numScroll: 10
        },
        {
            breakpoint: '1200px',
            numVisible: 3,
            numScroll: 3
        },
        {
            breakpoint: '1000px',
            numVisible: 2,
            numScroll: 2
        },
        {
            breakpoint: '575px',
            numVisible: 1,
            numScroll: 1
        }
    ]

    function screenShotsTemplate(ind) {

        return (

            <div className='px-4'>
                <div onClick={() => videoClick(ind, "front")}>
                    <video
                        src={currentVideoData?.front}
                        style={{ width: "100%", height: "100px" }}
                        ref={el => (frontVideoRef.current[ind.ind] = el)}
                    ></video>
                </div>
                <div onClick={() => videoClick(ind, "back")}>
                    <video
                        src={currentVideoData?.back}
                        style={{ width: "100%", height: "100px" }}
                        ref={el => (backVideoRef.current[ind.ind] = el)}
                    ></video>
                </div>
                <div onClick={() => videoClick(ind, "screen")}>
                    <video
                        src={currentVideoData?.screen}
                        style={{ width: "100%", height: "100px" }}
                        ref={el => (screenVideoRef.current[ind.ind] = el)}
                    ></video>
                </div>
            </div>
        );
    };


    return (
        <div className={`mainContainer ${style.viewScreenShots_main_con}`}>
            <div className="card p-md-4 p-sm-3 p-2">
                <div onClick={() => setIsScreenShotPage(false)}>
                    <BackButton />
                </div>

                <div className='d-flex gap-4 my-3'>
                    <div><b>Evaluator Name:-</b> {sessionData?.userName?.[0]}</div>
                    <div><b>Session Duration:-</b> {partData?.duration && Math.floor(partData?.duration)}</div>
                    <div><b>Part Start Time:-</b> {partData?.part_start_time && moment(partData?.part_start_time).format("MM:SS")}</div>
                    <div><b>Part End Time:-</b> {partData?.part_end_time && moment(partData?.part_end_time).format("MM:SS") || "---"}</div>
                    <div><b>Key Points of Interest:-</b> {selectedKpi}</div>

                </div>

                <div className='d-flex justify-content-center'>
                    <div className={style.screenshot_main_image}>
                        <video
                            style={{ width: "100%", height: "100%" }}
                            ref={mainVideoRef}
                            src={mainVideoUrl}
                        >
                        </video>

                    </div>
                </div>

                <div className="d-flex mt-3">
                    <div className={`${style.camera_names_con} text-nowrap`}>
                        <div>Front Camers</div>
                        <div>Back Camers</div>
                        <div>Screen</div>
                    </div>
                    <div className='w-100'>
                        <Carousel
                            numVisible={8} numScroll={2}
                            responsiveOptions={responsiveOptions}
                            showIndicators={false}
                            value={allIntervals.map((e, i) => { return ({ val: e, ind: i }) })}
                            itemTemplate={screenShotsTemplate} />
                    </div>

                </div>



            </div>
        </div>
    );
};
