import React, { useEffect, useState } from 'react'
import "./style.css"
import { Link } from 'react-router-dom';
import { getAllOrganization, getExamByOrgId } from "../../Services/Admin/Api";
import { Select } from '../../Components/Inputs/Select';
import { formValidation } from '../../utils/formValidation';
import { Calendar } from 'primereact/calendar';
import { userOrgId, userRole } from '../../Components/Assets/userData';

export const QuestionPaperForm = ({ data, mode, onSubmit, setUpdatedFields }) => {
    let [allOranization, setAllOrganization] = useState([])
    let [allExams, setAllExams] = useState([])
    let [formData, setFormData] = useState({ ...data })
console.log(formData,"dasdadasdasasdasdasdasdasdasd")
    let [formValidations, setFormValidations] = useState({
        paperName: false,
        paperCode: false,
        orgId: false,
        paperDate: false,
        examId: false,
    })

    useEffect(() => {
        const getAllOrg = async () => {
            try {
                let res = await getAllOrganization()
                setAllOrganization(res.data.data)
                if(userRole()==="orgAdmin"){
console.log(res.data.data.filter(e=>e._id===userOrgId()))
                    setFormData({ ...formData, orgId: userOrgId(),  organization_name:res.data.data.filter(e=>e._id===userOrgId())[0]?.orgName})
                }
            }
            catch (err) {
                console.log(err)
            }
        }
        getAllOrg()
    }, [])


    useEffect(() => {
        const getExams = async () => {
            try {
                let res = await getExamByOrgId(formData.orgId)
                console.log(res.data.data)
                setAllExams(res.data.data)
            }
            catch (err) {
                console.log(err)
            }
        }
        if (formData.orgId) {
            getExams()
        }
    }, [formData.orgId])


    function formSubmit(e) {
        e.preventDefault()
        let fields = ["paperName", "paperCode", "orgId", "paperDate", "examId"]
        let res = formValidation(formData, fields)
        setFormValidations(res.keys)

        if (res.isFormValid) {
            onSubmit(formData) 
        }
        else {
            console.log("invalid form")
        }
    }


    return (
        <div className='mainContainer create_question_con'>
            <div className='card p-md-4 p-sm-3 p-2'>
                <h1>{mode} Paper</h1>

                <form onSubmit={formSubmit}>
                    <div className="row m-0">


                        <div className="col-lg-6 col-12 mt-5 p-0 pe-lg-2">
                            <label className='HelveticaMedium'>Paper's Full Name</label>
                            <div className="mt-2">
                                <input
                                    value={formData.paperName}
                                    className={`form-control ${formValidations.paperName ? "invalid_input_field" : ""}`}
                                    onChange={(e) => {
                                        setFormData({ ...formData, paperName: e.target.value })
                                        setUpdatedFields && setUpdatedFields(pre => ({ ...pre, paperName: e.target.value }))
                                    }}
                                    placeholder='Question Paper Name'
                                />
                            </div>
                        </div>

                        <div className="col-lg-6 col-12 mt-5 p-0 ps-lg-2">
                            <label className='HelveticaMedium'>Paper Code</label>
                            <div className="mt-2">
                                <input
                                    disabled={mode === "Update"}
                                    className={`form-control ${formValidations.paperDate ? "invalid_input_field" : ""}`}
                                    onChange={(e) => {
                                        setFormData({ ...formData, paperCode: e.target.value })
                                        setUpdatedFields && setUpdatedFields(pre => ({ ...pre, paperCode: e.target.value }))

                                    }}
                                    value={formData.paperCode}
                                    placeholder='Paper Code' />
                            </div>

                        </div>
                       
                        {userRole() === "admin" &&(
                            <>
                            <div className="col-lg-6 col-12 p-0 mt-4 pe-lg-2">
                            <label className='HelveticaMedium'>Organization</label>
                            <div className="mt-2">

                                <Select
                                    error={formValidations.orgId}
                                    value={allOranization.find(e => e._id == formData.orgId)}
                                    onChange={(e) => {
                                        setFormData({ ...formData, orgId: e.value._id, examId: null , organization_name:e.value.orgName})
                                        setUpdatedFields && setUpdatedFields(pre => ({ ...pre, orgId: e.value._id }))
                                    }
                                    }
                                    options={allOranization}
                                    optionLabel="orgName"
                                    placeholder="Select Organization" className="w-100 "
                                />
                            </div>
                        </div>
                            </>
                        )}
 {userRole() === "admin" ?
                        
<div className="col-lg-6 col-12 mt-4 p-0 ps-lg-2">
                            <label className='HelveticaMedium'>Exam</label>
                            <div className="mt-2">
                                <Select
                                    error={formValidations.examId}
                                    value={allExams.find(e => e._id == formData.examId)}
                                    onChange={(e) => {
                                        setFormData({ ...formData, examId: e.value._id })
                                        setUpdatedFields && setUpdatedFields(pre => ({ ...pre, examId: e.value._id }))
                                    }}
                                    options={allExams}
                                    optionLabel="examName"
                                    placeholder="Select Exam"
                                    className="w-100"
                                />
                            </div>
                        </div>:<div className="col-lg-6 col-12 mt-4 p-0 pe-lg-2">
                            <label className='HelveticaMedium'>Exam</label>
                            <div className="mt-2">
                                <Select
                                    error={formValidations.examId}
                                    value={allExams.find(e => e._id == formData.examId)}
                                    onChange={(e) => {
                                        setFormData({ ...formData, examId: e.value._id })
                                        setUpdatedFields && setUpdatedFields(pre => ({ ...pre, examId: e.value._id }))
                                    }}
                                    options={allExams}
                                    optionLabel="examName"
                                    placeholder="Select Exam"
                                    className="w-100"
                                />
                            </div>
                        </div>}
                        {userRole() === "admin" ?

                        <div className="col-lg-6 col-12 mt-4 p-0 pe-lg-2">
                            <label className='HelveticaMedium'>Exam Year</label>

                            <div className="mt-2">
                                <input disabled className='form-control' placeholder='Exam Year' value={formData.examId ? allExams.find(e => e._id == formData.examId)?.examYear : ""} />
                            </div>

                        </div>: <div className="col-lg-6 col-12 mt-4 p-0 ps-lg-2">
                            <label className='HelveticaMedium'>Exam Year</label>

                            <div className="mt-2">
                                <input disabled className='form-control' placeholder='Exam Year' value={formData.examId ? allExams.find(e => e._id == formData.examId)?.examYear : ""} />
                            </div>

                        </div>}


                       
{userRole() === "admin" ?
 <div className="col-lg-6 col-12 mt-4 p-0 ps-lg-2">

 <label className='HelveticaMedium'>Paper Date</label>

 <div className="mt-2  card d-flex justify-content-center">
     <Calendar 
         readOnlyInput={true}
         onChange={(e) => {
             setFormData({ ...formData, paperDate: e.target.value.toISOString() })
             setUpdatedFields && setUpdatedFields(pre => ({ ...pre, paperDate: e.target.value.toISOString() }))
         }}
         value={formData.paperDate ? new Date(formData.paperDate) : null}
         className={`${formValidations.paperDate ? "invalid_input_field" : ""} `}
         placeholder='Paper Date'
     />
 </div>
</div>:
 <div className="col-lg-6 col-12 mt-4 p-0 pe-lg-2">

 <label className='HelveticaMedium'>Paper Date</label>

 <div className="mt-2  card d-flex justify-content-center">
     <Calendar 
         readOnlyInput={true}
         onChange={(e) => {
             setFormData({ ...formData, paperDate: e.target.value.toISOString() })
             setUpdatedFields && setUpdatedFields(pre => ({ ...pre, paperDate: e.target.value.toISOString() }))
         }}
         value={formData.paperDate ? new Date(formData.paperDate) : null}
         className={`${formValidations.paperDate ? "invalid_input_field" : ""}`}
         placeholder='Paper Date'
     />
 </div>
</div>
}
                       
                    </div>
                    <div className='d-flex justify-content-end mt-4'>
                        <Link to={"/question"}>
                            <button type='button' className='btn btn-Secondary me-3'>Cancel</button>
                        </Link>
                        <button type='submit' disabled={!Object.keys(formData).every(e => formData[e]?.trim()&& formData[e]?.trim().length !== 0)} className='btn btn-Primary'>{mode} Paper</button>
                    </div>
                </form>

            </div>
        </div>
    )
}
