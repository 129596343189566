import React, { useEffect, useState } from "react";
import TableContainer from "../../../Components/Assets/TableContainer";
import { getorgAdminOverviewLstestdata,getPaperWithEvaluationOrgOverview,getDeclaredExamDataOrgAdmin } from "../../../Services/Admin/Api";
import { userOrgId } from "../../../Components/Assets/userData";
import moment from "moment";
import Loader from "../../../Components/Assets/Loader";
import { TopBar } from "../../../Components/Sidebar/Sidebar";




export default function OverviewOrganization() {
  const [loading, setLoading] = useState(true);
  const [data1, setData1] = useState([]);
  const [data, setData] = useState([]);
  const [dataDeclaredOrg,setDataDeclaredOrg] = useState([])


  const orgAdminOverviewGetData =async()=>{
    await getorgAdminOverviewLstestdata(userOrgId()).then((res)=>{
      console.log(res.data.data);
      setData1(res.data.data)
      setLoading(false)
    }).catch((err)=>{
      console.log(err)
      setLoading(false)

    })
  }
  const getDataPaperWithEvaluator = async ()=>{
    await getPaperWithEvaluationOrgOverview(userOrgId()).then((res)=>{
      console.log(res)
      setData(res.data.data)
      setLoading(false)

    }).catch((err)=>{
      console.log(err)
      setLoading(false)

    })
  }

  const getDeclaredDataOrg = async ()=>{
    await getDeclaredExamDataOrgAdmin(userOrgId()).then((res)=>{
      console.log(res)
      setLoading(false)
      setDataDeclaredOrg(res.data.data.slice(0,3))
    }).catch((err)=>{
      console.log(err)
      setLoading(false)
    })
  }
  useEffect(()=>{
    orgAdminOverviewGetData()
    getDataPaperWithEvaluator()
    getDeclaredDataOrg()
  },[])


  return (
    <>
    <TopBar></TopBar>
      <div className="mainContainer">
        <div className="card p-md-4 p-sm-3 p-2">
          <h1>Papers with Difference in Evaluation {data.length} </h1>
          <div className="py-4">
            <TableContainer>
              <table className="">
                <thead>
                  <th>S.No.</th>
                  <th>Folder Name</th>
                  <th>Paper Name</th>
                  <th>Language</th>
                  <th>Exam Name</th>
                  <th>No. of Sheets</th>
                  <th></th>
                </thead>
                <tbody>
                  {data.map((res, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{res.folder_name}</td>
                          <td>{res.paper_name}</td>
                          <td>{res.language}</td>
                          <td>{res.exam_name}</td>
                          <td>{res.no_of_sheet}</td>
                          <td>
                            <i
                              className="pi pi-ellipsis-v  pointer"
                              id="Popover1"
                            ></i>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </TableContainer>
          </div>
        </div>
        <div className="card p-md-4 p-sm-3 p-2 mt-4">

          <div className="row">
            <div className="col-md-6">
          <h1>Latest Saved Recordings </h1>

              <div className="py-4">
                {/* <TableContainer className=""> */}
                  <table className="border  w-100">
                    <thead>
                      <th>Evaluator</th>
                      <th>Paper</th>
                      <th>Date</th>
                      <th>Start Time</th>
                    </thead>
                    <tbody>
                      {data1.map((res1, index) => {
                        return (
                          <>
                            <tr key={index}>
                              <td>{res1.evaluatorName}</td>
                              <td>{res1.paperName}</td>
                              <td>{moment(res1.start_time).format("DD/MM/YYYY")}</td>
                              <td>{moment(res1.start_time).format("h:mm:ss a")}</td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                {/* </TableContainer> */}
              </div>
            </div>
            <div className="col-md-6">
          <h1>Latest Declared Results </h1>

              <div className="py-4">
                {/* <TableContainer> */}
                  <table className="border  w-100">
                    <thead>
                      <th>Exam Name</th>
                      <th>Exam Date</th>
                      <th>Result Date</th>
                    </thead>
                    <tbody>
                      {dataDeclaredOrg.map((resdata, index) => {
                        return (
                          <>
                            <tr key={index}>
                              <td>{resdata.examName}</td>
                              <td>{moment(resdata.examYear).format("YYYY")}</td>
                              <td>{moment(resdata.declaredDate).format("DD/MM/YYYY")}</td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                {/* </TableContainer> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loader open={loading} />

    </>
  );
}
