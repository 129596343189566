import React, { useEffect, useState } from "react";
import "./style.css";
import { TopBar } from "../../Components/Sidebar/Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import {
  getOrrganizationById,
  updateOrganizeById,
  updateExamById,
  updatePaperById,
  getExamAndPaperId,
  addExam,
  addPaper,
  deletePaperBy,
  deleteExamById
} from "../../Services/Admin/Api";
import Loader from "../../Components/Assets/Loader";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Calendar } from "primereact/calendar";
import { Label } from "reactstrap";
import uniqid from "uniqid";
import { userId, userInfo } from "../../Components/Assets/userData";
import { addExamNotification, addPaperNotification } from "../../Components/Assets/socket";

export default function EditOrganization() {
  const navigate = useNavigate();
  const [editOrganizationName, setEditOrganizationName] = useState();
  const [editExamAndPaperData, setEditExamAndPaperData] = useState([]);
  const [updateOrgName, setUpdateOrgNAme] = useState();
  const [updateAbbreFullName, setUpdateAbbreFullName] = useState();
  const [loading, setLoading] = useState(false);
  const [uniqUserID, setUniqUserID] = useState(uniqid());
  
  const param = useParams();
  let Editexams = {
    examName: "",
    examYear: "",
    examCode: "",
    QuestionData: [
      {
        paperName: "",
        paperDate: "",
        paperCode: "",
      },
    ],
  };
  const [editExam, setEditExam] = useState([
    {
      examName: "",
      examYear: "",
      examCode: "",
      QuestionData: [
        {
          paperName: "",
          paperDate: "",
          paperCode: "",
        },
      ],
    },
  ]);
  console.log(editExam);
  const newExam = () => {
    let examArr = [...editExam];
    examArr.push(Editexams);
    console.log(examArr);
    setEditExam(examArr);
  };
  const Newpapers = (index) => {
    console.log(index)
    let arr2 = [...editExam];

    arr2[index].QuestionData.push({
      paperName: "",
      paperDate: "",
      paperCode: "",
    });

    setEditExam(arr2);
  };
  const EditOrganizationById = async () => {
    setLoading(true);
    await getOrrganizationById(param.id)
      .then((res) => {
        setEditOrganizationName(res.data.data);
        setUpdateAbbreFullName(res.data.data.orgFullName);
        setUpdateOrgNAme(res.data.data.orgName);
        setLoading(false);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getPaperAndExam = async () => {
    setLoading(true);
    await getExamAndPaperId(param.id)
      .then((res) => {
        setLoading(false);
        console.log(res);
        setEditExamAndPaperData(res.data.data);
        setEditExam(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const organizeUpdatedById = async (e) => {
    e.preventDefault();
    setLoading(true);

    console.log("param.id:", param.id);
    console.log("updateOrgName:", updateOrgName);
    console.log("updateAbbreFullName:", updateAbbreFullName);

    try {
      let orgPayload = {
        orgName: updateOrgName.trim(),
        orgFullName: updateAbbreFullName.trim(),
        orgId:updateOrgName + "-" + uniqUserID
      };
      const res = await updateOrganizeById(param.id, orgPayload);

      for (let i = 0; i < editExam.length; i++) {
        console.log(i, "list1");
        console.log(editExam[i]._id);
        let uExamPayload = {
          examName: editExam[i].examName.trim(),
          examYear: editExam[i].examYear.trim(),
        };
        let res2;
        if (editExam[i]._id) {
          res2 = await updateExamById(editExam[i]._id, uExamPayload);
        } else {
          let addExamPayload = {
            examName: editExam[i].examName.trim(),
            examCode: editExam[i].examCode.trim(),
            orgId: param.id,
            examYear: editExam[i].examYear,
            organization_name:updateOrgName.trim(),
            orgAdminName:userInfo().name,
            orgAdminNameId:userId(),
          };
          res2 = await addExam(addExamPayload)
          addExamNotification()
        }
        for (let j = 0; j < editExam[i].QuestionData.length; j++) {
          console.log(j, "list1");
          console.log(editExam[i]?.QuestionData[j]?._id);
          let editPaperPayload = {
            paperName: editExam[i].QuestionData[j].paperName.trim(),
            paperDate: editExam[i].QuestionData[j].paperDate,
          };
          let res6;
          if (editExam[i]?.QuestionData[j]?._id) {
            console.log(editExam[i]?.QuestionData[j]?._id);
            res6 = await updatePaperById(
              editExam[i]?.QuestionData[j]?._id,
              editPaperPayload
            );
          } else {
            console.log(res2);
            let addPaperPayload = {
              paperName: editExam[i].QuestionData[j].paperName.trim(),
              paperCode: editExam[i].QuestionData[j].paperCode.trim(),
              orgId: param.id,
              paperDate: editExam[i].QuestionData[j].paperDate,
              examId: res2.data.data._id ? res2.data.data._id : editExam[i]._id,
              organization_name:updateOrgName.trim(),
              orgAdminName:userInfo().name,
              orgAdminNameId:userId(),
            };
          console.log(addPaperPayload)

            res6 = await addPaper(addPaperPayload)
            addPaperNotification()
            console.log(res6);
          }
        }
      }

      console.log(res);
      navigate("/organization");
    } catch (err) {
      console.error("Error updating organization:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    EditOrganizationById();
    getPaperAndExam();
  }, []);

  function addd(val, ind) {
    let arr = [...editExam];
    arr[ind].examName = val;
    setEditExam(arr);
  }

  function addYear(val, ind) {
    let arr = [...editExam];
    arr[ind].examYear = val;
    setEditExam(arr);
  }
  function addEcode(val, ind) {
    let arr = [...editExam];
    arr[ind].examCode = val;
    setEditExam(arr);
  }

  const paperDeleteById = async(id)=>{
    await deletePaperBy(id)
    .then((res9)=>{
      console.log(res9)
    })

  }
  const ExamDeleteById =async(id)=>{
    await deleteExamById(id)
    .then((res)=>{
      console.log(res)
    })
  }
  return (
    <>
      <TopBar></TopBar>
      <form onSubmit={(e) => {e.preventDefault();organizeUpdatedById(e, param.id)}}>
        <div className="mainContainer">
          <div className="card p-md-4 p-sm-3 p-2">
            <h1>Edit Organizational Details</h1>

            <div className="row mt-5">
              <div className="col-md-12">
                <label className="HelveticaMedium">
                  Organization’s Full Name
                </label>
                <input
                  className="form-control"
                  placeholder=""
                  defaultValue={editOrganizationName?.orgFullName}
                  onChange={(e) => {
                    if (e.target.value === " ") {
                      e.target.value = "";
                    } else {
                      setUpdateAbbreFullName(e.target.value);
                    }
                  }}
                  required
                />
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-6">
                <label className="HelveticaMedium">
                  Organization’s Abbreviation
                </label>
                <input
                  className="form-control"
                  placeholder=""
                  defaultValue={editOrganizationName?.orgName}
                  required
                  onChange={(e) => {
                    if (e.target.value === " ") {
                      e.target.value = "";
                    } else {
                      setUpdateOrgNAme(e.target.value);
                    }
                  }}
                />
              </div>
              <div className="col-md-6">
                <label className="HelveticaMedium">Organization Code</label>
                <input
                  className="form-control"
                  disabled
                  defaultValue={updateOrgName?updateOrgName + "-" + uniqUserID:editOrganizationName?.orgId}
                />
              </div>
            </div>
            {editExam.map((res, index6) => {
              return (
                <>
                  <Accordion className="mt-3" 
                          activeIndex={0}
                          >
                    <AccordionTab
                      header={
                        <div className="d-flex justify-content-between align-items-center text-align-center ">
                          <div className="HeadingStyle">Exam {index6 + 1}</div>
                          <span className=" align-items-center">
                            {editExam.length !== 1 && (
                              <>
                                <span
                                  className="deletStyle "
                                  onClick={(e) => {
                                    console.log("delete button lick");
                                    let arr = editExam.filter(
                                      (item, index1) => index1 !== index6
                                    );
                                    console.log(arr);
                                    setEditExam(arr);
                                    if(res._id){

                                      ExamDeleteById(res?._id);
                                    }

                                  }}
                                >
                                  <i className="pi pi-trash me-2" /> Delete
                                </span>
                              </>
                            )}
                          </span>
                        </div>
                      }
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <Label className="HelveticaMedium">
                            Exam’s Full Name
                          </Label>
                          <input
                            className="form-control"
                            value={res?.examName}
                            onChange={(e) => {
                              if (e.target.value === " ") {
                                e.target.value = "";
                              } else {
                                addd(e.target.value, index6);
                              }
                            }}
                            required
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-6">
                          <Label className="HelveticaMedium">Exam’s Year</Label>
                          <input
                            className="form-control"
                            value={res?.examYear}
                            onChange={(e) => {
                              addYear(e.target.value, index6);
                            }}
                            type="number"
                            required
                          />
                        </div>
                        <div className="col-md-6">
                          <Label className="HelveticaMedium">Exam Code</Label>
                          <input
                            className="form-control"
                          value={res?.examCode}
                            onChange={(e) => {
                              if (e.target.value === " ") {
                                e.target.value = "";
                              } else {
                                addEcode(e.target.value, index6);
                              }
                            }}
                            required
                            // disabled
                          />
                        </div>
                      </div>
                      {res?.QuestionData?.map((res2, index7) => {
                        return (
                          <>
                            <div className="mt-4">
                              <div className="d-flex justify-content-between">
                                <span className="subHeading">
                                  Paper {index7 + 1}
                                </span>
                                {res?.QuestionData?.length !== 1 && (
                                  <>
                                    <span
                                      onClick={(e) => {
                                        
                                        // let arr = res?.QuestionData?.filter(
                                        //   (item, index3) => index3 !== index7
                                        //   );
                                        //   console.log(arr)
                                        let editparrr = [...editExam];
                                        
                                       let arr =  editparrr[index6].QuestionData.filter((rr,ii)=> rr.paperName !== res2.paperName)
                                       editparrr[index6].QuestionData = arr
                                        console.log(editparrr[index6].QuestionData)
                                        setEditExam(editparrr);
                                        if(res._id){
                                          console.log(res._id)
                                          paperDeleteById(res2._id);
                                        }
                                      }}
                                      className="deletStyle"
                                    >
                                      <i className="pi pi-trash me-2" />
                                      Delete
                                    </span>
                                  </>
                                )}
                              </div>
                              {console.log(
                                editExamAndPaperData[0]?.QuestionData[0]
                              )}
                              <div className="row mt-3">
                                <div className="col-md-6">
                                  <Label className="HelveticaMedium">
                                    Paper’s Full Name
                                  </Label>
                                  <input
                                    className="form-control"
                                    value={res2?.paperName}
                                    onChange={(e) => {
                                      if (e.target.value === " ") {
                                        e.target.value = "";
                                      } else {
                                        setEditExam((pre) => {
                                          pre[index6].QuestionData[
                                            index7
                                          ].paperName = e.target.value;
                                          console.log(pre);
                                          return [...pre];
                                        });
                                      }
                                    }}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="row mt-3">
                                <div className="col-md-6">
                                  <Label className="HelveticaMedium">
                                    Paper Date
                                  </Label>
                                  <Calendar
                                    value={new Date(res2?.paperDate)}
                                    onChange={(e) => {
                                      setEditExam((pre) => {
                                        pre[index6].QuestionData[
                                          index7
                                        ].paperDate = e.target.value;
                                        console.log(pre);
                                        return [...pre];
                                      });
                                    }}
                                    required
                                  />
                                </div>
                                <div className="col-md-6">
                                  <Label className="HelveticaMedium">
                                    Paper Code
                                  </Label>
                                  <input
                                    value={res2?.paperCode}
                                    className="form-control"
                                    onChange={(e) => {
                                      if (e.target.value === " ") {
                                        e.target.value = "";
                                      } else {
                                        setEditExam((pre) => {
                                          pre[index6].QuestionData[
                                            index7
                                          ].paperCode = e.target.value;
                                          console.log(pre);
                                          return [...pre];
                                        });
                                      }
                                    }}
                                    required
                                    
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                      <div className="d-flex  mt-5">
                        <span
                          className="addOrganize "
                          onClick={() => Newpapers(index6)}
                        >
                          <i className="pi pi-plus me-2" />
                          Add More Paper
                        </span>
                      </div>
                    </AccordionTab>
                  </Accordion>
                </>
              );
            })}
            <div className="d-flex  mt-5">
              <span
                className="addOrganize "
                onClick={(e) => {
                  newExam(e);
                }}
              >
                <i className="pi pi-plus me-2" />
                Add More Exam
              </span>
            </div>
            <div className="d-flex justify-content-end mt-5">
              <div className="d-flex">
                <button
                  className="btn btn-Secondary me-3"
                  onClick={() => navigate("/organization")}
                  type="button"
                >
                  Cancel
                </button>
                <button className="btn btn-primary" type="submit">
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Loader open={loading} />
    </>
  );
}
