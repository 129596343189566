import React, { useEffect, useState } from 'react'
import { TopBar } from '../../../Components/Sidebar/Sidebar'
import { BackButton } from '../../../Components/Assets/BackButton'
import { useNavigate, useParams,useLocation } from 'react-router-dom'
import style from './style.module.css'
import PopOver from '../../../Components/Assets/PopOver'
import { PopoverItem } from '../../../Components/Assets/PopoverItem'
import TableContainer from '../../../Components/Assets/TableContainer'
import { getAssignQuestionListByQuestionId, getEvaluatorAllSheetsData } from '../../../Services/Admin/EvaluatorApi'
import moment from 'moment'
import { userId } from '../../../Components/Assets/userData'
import { loader } from '../../../utils/loader'
import { ViewPdfDialog } from './ViewPdfDialog'
import { CustomPagination } from '../../../Components/Assets/CustomPagination'
import { formatDate } from '../../../utils/commonUtils'
import { Dialog } from 'primereact/dialog'
import { evaluationStart } from '../../../Services/Admin/Api'

export default function AssignPaperView() {

    const navigate = useNavigate()
    const { id } = useParams()
    let paperId = new URLSearchParams(window.location.search).get("paperId")
    let language = new URLSearchParams(window.location.search).get("language")
    let [page, setPage] = useState(1)
    let per_page_data = 4;
    let [totalData, setTotalData] = useState(0)

    let [tableActiveType, setTableActiveType] = useState("all")
    let [questionData, setQuestionData] = useState({})


    const [examData, setExamData] = useState([])
    let [sheetData, setSheetData] = useState([])


    // const evaluatorStart = async()=>{
    //     loader.start()
    //     await evaluationStart (userId(),{
    //         startTime:new Date(),
    //         paperName: examData?.examData[0]?.examName
    //     }).then((res)=>{
    //         // console.log(res)
    //         loader.stop()
    //          setVisible(false);
    //     }).catch((err)=>{
    //         console.log(err)
    //         loader.stop()
    //     })
    // }

  

    async function getAllAssignQuestions() {
        try {
            let allData = await getAssignQuestionListByQuestionId(id)
            console.log(allData.data.data[0])
            let [examD] = allData.data.data
            setQuestionData(examD)
            console.log(examD)
            setExamData(examD)
        } catch (err) {
            console.log(err)
        }
    }

    function changeTableType(type) {
        setTableActiveType(type)
    }

    useEffect(() => {
        getAllAssignQuestions()
    }, [])


    async function getTableData() {
        try {
            let userID = await userId()
            loader.start()
            let data = {
                language: language,
                evaluator_id: userID,
                check: tableActiveType,
                current_page: page,
                page_size: per_page_data 
            }
            let res = await getEvaluatorAllSheetsData(paperId, data)
            loader.stop()
            console.log(res.data.data,"data data dtata")
            if (res?.data?.data) {
                setTotalData(res?.data?.totalCount?.[0].count || 1)
                setSheetData(res.data.data)
            }
        }
        catch (err) {
            loader.stop()
            console.log(err)
        }
    }

    useEffect(() => {
        getTableData()
    }, [tableActiveType, page])

    let [visible, setVisible] = useState(false)

    return (
        <>
            <TopBar />
            <div className="mainContainer">
                <div className="card p-md-4 p-sm-3 p-2">
                    <BackButton link={'/assignedQuestions'} />
                    <div className='d-flex justify-content-between align-items-center pt-4'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div><img width='80px' height='80px' src='/images/questionPaper/questionTypeIcons4.svg' /></div>
                            <div className='ms-3'>
                                <h1 className='fs-4'>{examData.paperData !== undefined ? examData?.paperData[0]?.paperCode : ''}</h1>
                                <div className='sub_heading_gray'>{examData.paperData !== undefined ? examData?.paperData[0]?.paperName : ''}</div>
                            </div>
                        </div>
                        <div>
                            <button
                                className='btn btn-Primary'
                                disabled={!questionData?.paperId || !questionData?.language}
                                onClick={() => {
                                    setVisible(true)
                                }}
                            >
                                Start Evaluating
                            </button>
                            <Dialog
                                draggable={false}
                                header={"Start Evaluating"}
                                closable={false}
                                visible={visible}
                                style={{ width: "50vw" }}
                                onHide={() => setVisible(false)}
                            >
                                <div style={{ fontFamily: "Helvetica" }}>
                                    <div>Before commencing the evaluation process, please adhere to the following guidelines:</div>
                                    <br />
                                    <ol>
                                        <li>

                                            Strictly refrain from using any additional digital devices such as mobile phones or iPads during the evaluation process.
                                        </li>
                                        <li>

                                            Avoid engaging in conversations or interactions with any other individuals while evaluating the answer sheets.
                                        </li>
                                        <li>

                                            Ensure that you do not switch tabs or navigate away from the evaluation portal while assessing the answer sheets.
                                        </li>
                                        <li>

                                            Maintain confidentiality and do not disclose any information about the evaluation process or the contents of the answer sheets to unauthorized individuals.
                                        </li>
                                        <li>

                                            Evaluate each answer sheet objectively and impartially, without any bias or preconceived notions.
                                        </li>
                                        <li>

                                            Follow the prescribed evaluation criteria and grading rubric provided by the organization.
                                        </li>
                                        <li>

                                            Report any technical issues or discrepancies encountered during the evaluation promptly to the relevant support personnel.
                                        </li>
                                        <li>
                                            Double-check your assessments for accuracy and completeness before submitting them.
                                        </li>
                                        <li>

                                            Spending a minimum of 3 mins of each answer sheet is necessary
                                        </li>
                                    </ol>
                                    <div>
                                        Your commitment to these guidelines ensures the integrity and fairness of the evaluation process. Thank you for your cooperation.
                                    </div>

                                </div>
                                <div className="d-flex justify-content-end mt-3">
                                    <button
                                        className="btn btn-Secondary me-3"
                                        onClick={() => setVisible(false)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => {
                                            navigate(`/evaluation?paperId=${questionData?.paperId}&language=${questionData?.language}`,{state:{examName:examData?.examData[0]?.examName,paperName: examData?.paperData[0]?.paperName}})
                                            setVisible(false);
                                            // evaluatorStart()
                                        }}
                                    >
                                        Continue
                                    </button>
                                </div>
                            </Dialog>





                        </div>
                    </div>
                    <div className={`mt-5 pt-2 ${style.secondContainer} px-3`}>
                        <div className={`${style.tableC}`}>
                            <table className={style.sTableContainer}>
                                <tbody className={style.sTableContainer}>
                                    <tr>
                                        <th>Exam Name:</th>
                                        <td>{examData.examData !== undefined ? examData?.examData[0]?.examName : ''}</td>
                                    </tr>
                                    <tr>
                                        <th>Question Number:</th>
                                        <td>{examData?.quesNo}</td>
                                    </tr>
                                    <tr>
                                        <th>Language:</th>
                                        <td>{examData?.language}</td>
                                    </tr>
                                    <tr>
                                        <th>Exam Date:</th>
                                        <td>{examData.paperData !== undefined ? moment(examData?.paperData[0]?.paperDate).format("DD MMM, YYYY") : ''}</td>
                                    </tr>
                                    <tr>
                                        <th>Assigning Date:</th>
                                        <td>{moment(examData?.assignEvaluator2Date).format("DD MMM, YYYY")}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='d-flex justify-content-center align-items-center'>
                            <div className={`${style.btnContainer}`}>
                                <ViewPdfDialog title="Question Paper" pdfUrl={examData?.paperData?.[0]?.question_paper_link}>
                                    <button className='btn btn-Secondary'>View Question Paper</button>
                                </ViewPdfDialog>
                                <ViewPdfDialog title="Answer Key" pdfUrl={examData?.paperData?.[0]?.answerKey_link}>

                                    <button className='btn btn-Secondary'>View Answer Key</button>
                                </ViewPdfDialog>
                            </div>
                        </div>
                    </div>
                    <div className='mt-5 px-3'>
                        <div className='subHeading'>Evaluation Guidelines</div>
                        <div className={style.guidelineBox}>
                            <div dangerouslySetInnerHTML={{ __html: examData?.paperData?.[0]?.guildline || "<div>No Guidelines Available...</div>" }} />
                        </div>
                    </div>
                    <div className='mt-5 pt-4'>
                        <div className='subHeading'>Sheets ({sheetData.length})</div>
                        <div className='mt-3'>

                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex gap-3'>
                                    <div className={`${tableActiveType === "all" ? style.activeTab : ""} px-3 pointer pb-2`} onClick={() => tableActiveType !== "all" ? changeTableType("all") : null}>All Sheets</div>
                                    <div className={`${tableActiveType === "notSubmitted" ? style.activeTab : ""} px-3 pb-2 pointer`} onClick={() => tableActiveType !== "notSubmitted" ? changeTableType("notSubmitted") : null}>Not Submitted Sheets</div>
                                    <div className={`${tableActiveType === "submitted" ? style.activeTab : ""} px-3 pb-2 pointer`} onClick={() => tableActiveType !== "submitted" ? changeTableType("submitted") : null}>Evaluated Sheets</div>
                                </div>


                                <div className='d-flex gap-3'>
                                    <div className='d-flex'><div className={`${style.green} px-2`}>&nbsp;</div> <div className='ms-2'>Submitted</div></div>
                                    <div className='d-flex'><div className={`${style.yellow} px-2`}>&nbsp;</div> <div className='ms-2'>Draft</div></div>
                                    <div className='d-flex'><div className={`${style.blue} px-2`}>&nbsp;</div> <div className='ms-2'>Not&nbsp;Submitted</div></div>
                                </div>
                            </div>

                            <div className='mt-4'>
                                <TableContainer>
                                    <table className="left_align_table">
                                        <thead>
                                            <th>S. No.</th>
                                            <th>Sheet Barcode</th>
                                            <th>Sheet Code</th>
                                            <th>Marks</th>
                                            <th>Remarks</th>
                                            <th>Evaluated On</th>
                                            <th>&nbsp;</th>
                                        </thead>
                                        <tbody>
                                            {sheetData.map((res, index) => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            <div className={`${style.sBox}
                                                                     ${res?.evaluator_status?.status === 'submitted' ? style.green : ''} 
                                                                     ${res?.evaluator_status?.status === 'draft' ? style.yellow : ''} 
                                                                     ${res?.evaluator_status?.status === "notSubmitted" ? style.blue : ''}
                                                                     `}>
                                                                {((page - 1) * per_page_data) + index + 1}
                                                            </div>
                                                        </td>
                                                        <td><img src='/images/barcode.png' /></td>
                                                        <td>{res?.sheetCode}</td>
                                                        <td>{res?.evaluator_status?.marks || "--"}</td>
                                                        <td>{res?.evaluator_status?.remarks || "--"}</td>
                                                        <td>{res?.evaluator_status?.evaluated_date && formatDate(res?.evaluator_status?.evaluated_date) || "--"}</td>
                                                        <td><PopOver>
                                                            <PopoverItem text={"Edit"} icon={<i className='pi pi-pencil' />} />
                                                            <PopoverItem text={"Delete"} icon={<i className='pi pi-trash' />} />
                                                        </PopOver></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </TableContainer>
                                <CustomPagination pageChange={setPage} page={page} perPageData={per_page_data} totalRecords={totalData} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
