import axios from "axios";
import { baseUrl, auth, organize, orgAdmin, userProfile, questionPaper, exam, file, evaluator, question, answerSheet, recording, report, notification, overview, results } from "../EndPoint";
import CryptoJS from "crypto-js";
import { jwtDecode } from "jwt-decode";
import { socketLogOut } from "../../Components/Assets/socket";

const handleDecrypt = () => {
  if (sessionStorage.getItem("token")) {
    const bytes = CryptoJS.AES.decrypt(
      sessionStorage.getItem("token"),
      "<w9|2+-zV-8f(oJ"
    );
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    const decoded = jwtDecode(originalText);

    // console.log(decoded);
    return originalText;
  } else {
    return false
  }

};

export const logout = () => {
  sessionStorage.clear()
  socketLogOut()

}

export const jwtDecodes = async () => {
  const token = handleDecrypt()
  if (!token) {
    return false
  } else {

    const decoded = jwtDecode(token);

    // console.log(decoded);
    return decoded
  }
}
export const login = async (body) => {
  return await axios.post(`${baseUrl}${auth.authLogin}`, body);
};

export const resetPassword = async (body) => {
  return await axios.post(`${baseUrl}${auth.authResetPassword}`, body, {
    headers: { Authorization: `Bearer ${handleDecrypt()}` },
  });
};

export const emailVerified = async (body) => {
  return await axios.post(`${baseUrl}${auth.authemailVerification}`, body)
}

export const numberVerified = async (body) => {
  return await axios.post(`${baseUrl}${auth.authnumberCheck}`, body, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })

}

export const userFirstTimeDisable = async (body) => {
  return await axios.put(`${baseUrl}${auth.authDisable}`, body, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const addOrganizationItAdmin = async (body) => {
  return await axios.post(`${baseUrl}${organize.addOrganized}`, body, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const getOrrganizationById = async (id) => {
  return await axios.get(`${baseUrl}${organize.organizedById}${id}`, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const getAllOrganization = async (body) => {
  return await axios.get(`${baseUrl}${organize.getAllorganizationList}`, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const updateOrganizeById = async (id, body) => {
  return await axios.put(`${baseUrl}${organize.organizationUpdateById}${id}`, body, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const organizeDeleteById = async (id) => {
  return await axios.delete(`${baseUrl}${organize.organizationDeleteById}${id}`, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}
export const updateOrgTimer = async (body, id) => {
  return await axios.put(`${baseUrl}${organize.updateOrgTimer}${id}`, body, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const addAdminOrganization = async (body) => {
  return await axios.post(`${baseUrl}${orgAdmin.addAdmin}`, body, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const allAdminListing = async (body) => {
  return await axios.get(`${baseUrl}${orgAdmin.adminListing}`, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const adminListById = async (id) => {
  return await axios.get(`${baseUrl}${orgAdmin.adminListingById}${id}`, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const adminBygetId = async (id) => {
  return await axios.get(`${baseUrl}${orgAdmin.adminGetById}${id}`, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const deleteAdminById = async (id) => {
  return await axios.delete(`${baseUrl}${orgAdmin.adminDeleteById}${id}`, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const adminUpdateById = async (id, body) => {
  return await axios.put(`${baseUrl}${orgAdmin.adminUpdatedById}${id}`, body, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const disableEnableAdmin = async (id, body) => {
  return await axios.put(`${baseUrl}${orgAdmin.adminDisableEnable}${id}`, body, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const addUserProfile = async (body) => {
  return await axios.post(`${baseUrl}${userProfile.addUser}`, body, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const userProfileListGetById = async (id) => {
  return await axios.get(`${baseUrl}${userProfile.usersListingById}${id}`, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const userProfileGetById = async (id) => {
  return await axios.get(`${baseUrl}${userProfile.getUserById}${id}`, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const userProfileUdateById = async (id, body) => {
  return await axios.put(`${baseUrl}${userProfile.updateuserById}${id}`, body, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const userProfileDeleteById = async (id, body) => {
  return await axios.post(`${baseUrl}${userProfile.userDeleteById}${id}`, body, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const activeUserById = async (id, body) => {
  return await axios.put(`${baseUrl}${userProfile.userActiveById}${id}`, body, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const addPaper = async (body) => {
  return await axios.post(`${baseUrl}${questionPaper.addpaper}`, body, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}
export const updatePaperById = async (id, body) => {
  return await axios.put(`${baseUrl}${questionPaper.updatePaperById}${id}`, body, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const getExamByOrgId = async (id) => {
  return await axios.get(`${baseUrl}${exam.getExamByOrgId}${id}`, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}
export const addExam = async (body) => {
  return await axios.post(`${baseUrl}${exam.addexam}`, body, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}
export const getExamWithPaperList = async () => {
  return await axios.get(`${baseUrl}${exam.getExamWithPaperList}`, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}
export const getPaperById = async (id) => {
  return await axios.get(`${baseUrl}${questionPaper.getPaperById}${id}`, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}
export const deletePaperById = async (id) => {
  return await axios.delete(`${baseUrl}${questionPaper.deletePaperById}${id}`, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}
export const updatePaperView = async (id, data) => {
  return await axios.put(`${baseUrl}${questionPaper.updatePaperView}${id}`, data, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const getExamById = async (id) => {
  return await axios.get(`${baseUrl}${exam.getExamById}${id}`, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const updateExamById = async (id, body) => {
  return await axios.put(`${baseUrl}${exam.examUpdateById}${id}`, body, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}
export const getExamAndPaperId = async (id) => {
  return await axios.get(`${baseUrl}${exam.examandpaper}${id}`, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}
export const uploadFile = async (fileData) => {
  const formData = new FormData();
  formData.append("uploadDoc", fileData);
  formData.append("name", "firstFile");
  return await axios.post(`${baseUrl}${file.upload}`, formData, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}
export const uploadMultipleFiles = async (files,user_id,session,part) => {
  const formData = new FormData()
  for (var x = 0; x < files.length; x++) {
    formData.append("upload", files[x]);
  }
  return await axios.post(`${baseUrl}${file.uploadMultiple}user_id=${user_id}&session=${session}&part=${part}`, formData, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })


}

export const deleteFile = async (fileName) => {
  return await axios.delete(`${baseUrl}${file.delete}`, { fileName }, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}
export const getAllEvaluatorListing = async (orgId) => {
  return await axios.get(`${baseUrl}${evaluator.evaluatorListing}${orgId}`, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}
export const addQuestion = async (data) => {
  return await axios.post(`${baseUrl}${question.addQuestion}`, data, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const deleteQuestion = async (id) => {
  return await axios.delete(`${baseUrl}${question.deleteQuestion}${id}`, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const updateQuestion = async (id, data) => {
  return await axios.put(`${baseUrl}${question.updateQuestion}${id}`, data, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}
export const deletePaperBy = async (id) => {
  return await axios.delete(`${baseUrl}${questionPaper.deletePaper}${id}`, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}
export const deleteExamById = async (id) => {
  return await axios.delete(`${baseUrl}${exam.deleteExam}${id}`, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}
export const getPaperByExamId = async (examId) => {
  return await axios.get(`${baseUrl}${exam.getPaperByExamId}${examId}`, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}


export const getFolderName = async () => {
  return await axios.get(`${baseUrl}${answerSheet.getFolderName}`, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}
export const copyAnswerSheet = async (data) => {
  return await axios.post(`${baseUrl}${answerSheet.copyAnswerSheet}`, data, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}
export const getOurS3Data = async () => {
  return await axios.get(`${baseUrl}${answerSheet.getOurS3Data}`, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}
export const getFolderData = async (data) => {
  return await axios.post(`${baseUrl}${answerSheet.getFolderData}`, data, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}
export const deleteFolder = async (data) => {
  return await axios.post(`${baseUrl}${answerSheet.deleteFolder}`, data, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}
export const getAllRecordings = async (data) => {
  return await axios.get(`${baseUrl}${recording.getAllRecording}`, data, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const getAllEvaluatorList = async (data) => {
  return await axios.get(`${baseUrl}${recording.getEvaluatorList}`, data, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const addRecording = async (data) => {
  return await axios.post(`${baseUrl}${recording.addRecording}`, data, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}
export const addRecordingParts = async (id, data) => {
  return await axios.put(`${baseUrl}${recording.addRecordingParts}${id}`, data, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const getrecordingOfEvaluatorById = async (id) => {
  return await axios.get(`${baseUrl}${recording.getRecordingOfEvaluatorById}${id}`, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}
export const getLastSession = async (id) => {
  return await axios.get(`${baseUrl}${recording.getLastSession}${id}`, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const deleteRecording = async (id) => {
  return await axios.delete(`${baseUrl}${recording.deleteRecording}${id}`, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}
export const getRecordingById = async (id) => {
  return await axios.get(`${baseUrl}${recording.getRecordingById}${id}`, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}
export const getPartData = async (id, body) => {
  return await axios.post(`${baseUrl}${recording.getPartData}${id}`, body, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}
export const getAnswerSheetByPaperId = async (data, paperId) => {
  return await axios.post(`${baseUrl}${answerSheet.getAnswerSheetByPaperId}${paperId}`, data, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const approvedQuestion = async (id, data) => {
  return await axios.put(`${baseUrl}${question.questionApproved}${id}`, data, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const activeListEvaluator = async (id) => {
  return await axios.get(`${baseUrl}${evaluator.activeEvaluatorList}${id}`, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const hodAnswerSheetListById = async (body) => {
  return await axios.post(`${baseUrl}${answerSheet.hodAnswerSheetlist}`, body, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const hodAnswerSheetViewDetail = async (id, data) => {
  return await axios.post(`${baseUrl}${answerSheet.hodAnswerSheetDetailList}${id}`, data, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const evaluationStart = async (id, data) => {
  return await axios.put(`${baseUrl}${evaluator.startEvaluating}${id}`, data, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const evaluationEnd = async (id, body) => {
  return await axios.put(`${baseUrl}${evaluator.endEvaluating}${id}`, body, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const getEvaluatorsForThirdEvaluation = async (id, body) => {
  return await axios.post(`${baseUrl}${evaluator.getEvaluatorsForThirdEvaluation}${id}`, body, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}
export const checkAnswerSheetTotalMarks = async (id, body) => {
  return await axios.put(`${baseUrl}${answerSheet.checkAnswerSheetTotalMarks}${id}`, body, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}
export const assignThirdEvaluator = async (questionId, body) => {
  return await axios.put(`${baseUrl}${question.assignThirdEvaluator}${questionId}`, body, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const commandCenterReport = async (data) => {
  return await axios.post(`${baseUrl}${report.addReportCommandcenter}`, data, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const getAllReportlistByOrgId = async (orgId) => {
  return await axios.get(`${baseUrl}${report.getReportListingByOrgId}${orgId}`, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const getReportsById = async (id) => {
  return await axios.get(`${baseUrl}${report.getReportListById}${id}`, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const getnotification = async (id, body) => {
  return await axios.post(`${baseUrl}${notification.getorgNotification}${id}`, body, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const getEvaluationNotification = async (id, body) => {
  return await axios.post(`${baseUrl}${notification.getEvalNotifiaction}${id}`, body, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const getCommandCenterNotification = async (orgId, body) => {
  return await axios.post(`${baseUrl}${notification.getcommandcenterNotification}${orgId}`, body, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const getsecretaryNotification = async (orgId, body) => {
  return await axios.post(`${baseUrl}${notification.getSecreteryNotification}${orgId}`, body, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const getNotificationItAdminData = async (id) => {
  return await axios.post(`${baseUrl}${notification.getdatanotificationItAdmin}${id}`, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const getNotificationHodData = async(id,body) =>{
  return await axios.post(`${baseUrl}${notification.getHodNotification}${id}`,body, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const readOrgAdminNotification = async (id, body) => {
  return await axios.post(`${baseUrl}${notification.readOrgNotification}${id}`, body, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}
export const getRecordingSessionJson = async (id, body) => {
  return await axios.post(`${baseUrl}${recording.getRecordingSessionJson}${id}`, body, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const getorgAdminOverviewLstestdata = async (id) => {
  return await axios.get(`${baseUrl}${overview.orgAdminOverviewLatest}${id}`, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const getOverviewEvaluatorData = async (id) => {
  return await axios.get(`${baseUrl}${question.overViewEvaluatorById}${id}`, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const getPaperWithEvaluationOrgOverview = async (id) => {
  return await axios.get(`${baseUrl}${answerSheet.paperWithDifferenceOverViewOrg}${id}`, { headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const getPaperDataResultItAdmin = async()=>{
  return await axios.get(`${baseUrl}${results.examDataForResultItAdmin}`,{ headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const getPaperDataResultForOrgAdmin = async(id)=>{
  return await axios.get(`${baseUrl}${results.examResultOfOrgAdmn}${id}`,{ headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const getAnswerSheetPaperDataByExamId = async(id)=>{
  return await axios.get(`${baseUrl}${answerSheet.answerSheetPaperDataForDeclare}${id}`,{ headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const getAnswerSheetPaperDataOrg = async(body) =>{
  return await axios.post(`${baseUrl}${answerSheet.answerSheetPaperDataForOrg}`,body,{ headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const putConfirmEvaluator = async(id)=>{
  return await axios.put(`${baseUrl}${questionPaper.paperConfirm}${id}`,{},{ headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const getDeclaredExam = async(id)=>{
  return await axios.put(`${baseUrl}${exam.examDataDeclared}${id}`,{},{ headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const getDeclaredExamDataListAdmin = async()=>{
  return await axios.get(`${baseUrl}${exam.examDeclaredataList}`,{ headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const getDeclaredExamDataOrgAdmin = async(id)=>{
  return await axios.get(`${baseUrl}${exam.getExamDeclaredDateOfOrgAdmin}${id}`,{ headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const getViewDataListOfItAdmin = async(id)=>{
  return await axios.get(`${baseUrl}${results.ViewDeclareDataItAdmin}${id}`,{ headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}

export const getViewDataListOfOrgAdmn = async(body)=>{
  return await axios.post(`${baseUrl}${results.viewDeclaredDataOrgAdmin}`,body,{ headers: { Authorization: `Bearer ${handleDecrypt()}` } })
}
