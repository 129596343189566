import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { Select } from "../../Components/Inputs/Select";
import { PAPER_LANGUAGES } from "../../constant";
import { GoPlus } from "react-icons/go";
import { Editor } from "primereact/editor";
import {
  addQuestion,
  getAllEvaluatorListing,
  getExamById,
  getOrrganizationById,
  updateQuestion,
} from "../../Services/Admin/Api";
import { PopoverItem } from "../../Components/Assets/PopoverItem";
import { loader } from "../../utils/loader";
import { userId, userInfo } from "../../Components/Assets/userData";
import { addQuestionNotification } from "../../Components/Assets/socket";

export const AddUpdateQuestion = ({ mode, data, paperData, onSubmit }) => {
  let [dialog, setDialog] = useState(false);
  const [organizationName, setOrganizationName] = useState();
  console.log(paperData);

  let [questionData, setQuestionData] = useState(
    data
      ? data
      : {
          question: "",
          section: "",
          language: null,
          orgId: paperData.orgId,
          quesNo: "",
          examId: paperData.examId,
          paperId: paperData._id,
          assignEvaluator1: null,
          assignEvaluator2: null,
          assignEvaluator1Date: new Date().toISOString(),
          assignEvaluator2Date: new Date().toISOString(),
          maxMarks: "",
          organization_name: "",
          orgAdminName: userInfo().name,
          orgAdminNameId: userId(),
          paper_name: paperData.paperName,
          exam_name: "",
        }
  );
  let [updatedFields, setUpdatedFields] = useState({});

  let [allEvaluators, setAllEvaluators] = useState([]);

  async function assignEvaluator() {
    loader.start();
    try {
      let res;

      const org = await getOrrganizationById(paperData.orgId);
      const Exam = await getExamById(paperData.examId);
      if (mode === "Create") {
        let dataObject ={ exam_name: Exam.data.data.examName,organization_name: org.data.data.orgName,}
        let data = { ...questionData,...dataObject };

        if (data.section.trim() == "") {
          delete data.section;
        }
        res = await addQuestion(data);
        setQuestionData({
          question: "",
          section: "",
          language: null,
          orgId: paperData.orgId,
          quesNo: "",
          organization_name: org.data.data.orgName,
          examId: paperData.examId,
          paperId: paperData._id,
          assignEvaluator1: null,
          assignEvaluator2: null,
          assignEvaluator1Date: new Date().toISOString(),
          assignEvaluator2Date: new Date().toISOString(),
          maxMarks: "",
          exam_name: Exam.data.data.examName,
        });
        addQuestionNotification()
      } else {
        res = await updateQuestion(data._id, updatedFields);
      }

      onSubmit();
      loader.stop();
      setDialog(false);
      console.log(res);
    } catch (err) {
      loader.stop();
      console.log(err);
    }
  }

  useEffect(() => {
    if (questionData.orgId && dialog) {
      getAllEvaluators();
    }
  }, [questionData.orgId, dialog]);

  async function getAllEvaluators() {
    try {
      let allEvl = await getAllEvaluatorListing(questionData.orgId);
      setAllEvaluators(allEvl.data.data);
    } catch (err) {
      console.log(err);
    }
  }
  // const dateComparision = () => {
  //   let date = new Date();
  //   let validDate = new Date(paperData?.paperDate);

  //   if (date.getTime() < validDate.getTime()) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };
  const dateComparision = () => {
    let date = new Date();
    let validDate = new Date(paperData?.paperDate);
    console.log(date.getTime() > validDate.getTime(), "asdadsdasdasdasdsddasdasdasdasdasd");
    if (paperData.status) {
      if (date.getTime() > validDate.getTime()) {
        if (paperData.status === "Approved") {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  return (
    <>
      {mode === "Create" ? (
        <button
          onClick={() => setDialog(true)}
          className="pointer  mt-4 add_more_que"
          disabled={dateComparision()}
        >
          <GoPlus /> Add Questions
        </button>
      ) : (
        <PopoverItem
          text={"Edit"}
          icon={<i className="pi pi-pencil" />}
          onClick={() => setDialog(true)}
        />
      )}

      <Dialog
        draggable={false}
        closable={false}
        style={{ width: "70vw" }}
        visible={dialog}
        header={`${mode} Question`}
      >
        <section className="row my-3">
          <div className="col-3 HelveticaMedium">Section</div>
          <div className="col-3 HelveticaMedium">Question No.</div>
          <div className="col-3 HelveticaMedium">Language</div>
          <div className="col-3 HelveticaMedium">Max Marks.</div>

          <div className="col-3">
            <input
              className="form-control"
              value={questionData.section}
              onChange={(e) => {
                setQuestionData((pre) => ({ ...pre, section: e.target.value }));
                setUpdatedFields((pre) => ({
                  ...pre,
                  section: e.target.value,
                }));
              }}
              placeholder="Enter Section"
            />
          </div>

          <div className="col-3">
            <input
              className="form-control"
              value={questionData.quesNo}
              placeholder="Q.No"
              onChange={(e) => {
                setQuestionData((pre) => ({ ...pre, quesNo: e.target.value }));
                setUpdatedFields((pre) => ({ ...pre, quesNo: e.target.value }));
              }}
              type="text"
            />
          </div>

          <div className="col-3">
            <Select
              placeholder="Select Language"
              className="p-0"
              options={PAPER_LANGUAGES}
              value={questionData?.language}
              onChange={(e) => {
                setQuestionData((pre) => ({
                  ...pre,
                  language: e.target.value,
                }));
                setUpdatedFields((pre) => ({
                  ...pre,
                  language: e.target.value,
                }));
              }}
              name="language"
            />
          </div>
          <div className="col-3">
            <input
              className="form-control"
              value={questionData.maxMarks}
              onChange={(e) => {
                setQuestionData((pre) => ({
                  ...pre,
                  maxMarks: e.target.value,
                }));
                setUpdatedFields((pre) => ({
                  ...pre,
                  maxMarks: e.target.value,
                }));
              }}
              placeholder="Max. Marks"
              type="number"
            />
          </div>

          <div className="col-12 mt-3"></div>

          <div className="col-6 HelveticaMedium">Evaluator 1</div>
          <div className="col-6 HelveticaMedium mb-1">Evaluator 2</div>

          <div className="col-6">
            <Select
              optionLabel="name"
              placeholder="Evaluator 1"
              className="p-0"
              options={[
                ...allEvaluators.filter(
                  (evalu) =>
                    !paperData.questions.some(
                      (p) =>
                        p.assignEvaluator1 === evalu._id ||
                        p.assignEvaluator2 === evalu._id
                    ) ||
                    data?.assignEvaluator1 == evalu._id ||
                    data?.assignEvaluator2 == evalu._id
                ),
              ].filter((d) => d._id !== questionData.assignEvaluator2)}
              value={
                questionData.assignEvaluator1
                  ? allEvaluators.find(
                      (e) => e._id == questionData.assignEvaluator1
                    )
                  : null
              }
              onChange={(e) => {
                setQuestionData((pre) => ({
                  ...pre,
                  assignEvaluator1: e.target.value._id,
                }));
                setUpdatedFields((pre) => ({
                  ...pre,
                  assignEvaluator1: e.target.value._id,
                }));
              }}
            />
          </div>

          <div className="col-6">
            <Select
              optionLabel="name"
              placeholder="Evaluator 2"
              className="p-0"
              options={[
                ...allEvaluators.filter(
                  (evalu) =>
                    !paperData.questions.some(
                      (p) =>
                        p.assignEvaluator1 === evalu._id ||
                        p.assignEvaluator2 === evalu._id
                    ) ||
                    data?.assignEvaluator2 == evalu._id ||
                    data?.assignEvaluator1 == evalu._id
                ),
              ].filter((d) => d._id !== questionData.assignEvaluator1)}
              value={
                questionData.assignEvaluator2
                  ? allEvaluators.find(
                      (e) => e._id == questionData.assignEvaluator2
                    )
                  : null
              }
              onChange={(e) => {
                console.log([
                  ...paperData.questions
                    .map((e) => [e.assignEvaluator1, e.assignEvaluator2])
                    .flat(),
                  questionData.assignEvaluator1,
                ]);
                setQuestionData((pre) => ({
                  ...pre,
                  assignEvaluator2: e.target.value._id,
                }));
                setUpdatedFields((pre) => ({
                  ...pre,
                  assignEvaluator2: e.target.value._id,
                }));
              }}
            />
          </div>
        </section>

        <section>
          <div className="HelveticaMedium mb-1">Question</div>
          <Editor
            value={questionData?.question}
            onTextChange={(e) => {
              setQuestionData((pre) => ({ ...pre, question: e.htmlValue }));
              setUpdatedFields((pre) => ({ ...pre, question: e.htmlValue }));
            }}
            style={{ height: "250px" }}
          />
        </section>
        <div className="d-flex justify-content-end  mt-4 gap-3">
          <button
            className="btn btn-Secondary"
            onClick={() => setDialog(false)}
          >
            Cancel
          </button>
          <button
            className="btn btn-Primary"
            // disabled={
            //   !Object.keys(mode === "Create" ? questionData : updatedFields)
            //     .filter((e) => e !== "section")
            //     .every((e) =>
            //       mode === "Create"
            //         ? questionData[e]?.trim()
            //         : updatedFields[e]?.trim()
            //     )
            // }
            onClick={assignEvaluator}
          >
            {mode} Question
          </button>
        </div>
      </Dialog>
    </>
  );
};
