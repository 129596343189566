import { Dialog } from 'primereact/dialog'
import React, { useEffect, useState } from 'react'
import { Select } from '../../Components/Inputs/Select'
import { copyAnswerSheet, getAllOrganization, getExamByOrgId, getPaperByExamId } from '../../Services/Admin/Api'
import { PAPER_LANGUAGES } from '../../constant'
import { loader } from '../../utils/loader'
import { Label } from 'reactstrap'

export const ImportSheetDialog = ({ folderName, getOurS3DataAgain,importFolderData }) => {
    console.log(folderName)

    let [dialog, setDialog] = useState(false)
    let [allOrganizations, setAllOrganizations] = useState([])
    let [allExams, setAllExams] = useState([])
    let [allPapers, setAllPapers] = useState([])

    let [formData, setFormData] = useState({
        orgId: null,
        examId: null,
        paperId: "",
        language: ""
    })


    useEffect(() => {
        if (dialog) {
            getAllOrg()
        }
    }, [dialog])

    async function getAllOrg() {
        try {
            let res = await getAllOrganization()
            console.log(res.data.data)
            setAllOrganizations(res.data.data)
        }
        catch (err) {
            console.log(err)
        }
    }

    async function getExamByOrganization() {
        try {
            let res = await getExamByOrgId(formData.orgId)
            console.log(res.data.data)
            setAllExams(res.data.data)
        }
        catch (err) {
            console.log(err)
        }
    }

    async function getPaper() {
        try {
            let res = await getPaperByExamId(formData.examId)
            console.log(res.data.data)
            setAllPapers(res.data.data[0].Question_paper)
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if (formData.orgId) {
            getExamByOrganization()
        }
    }, [formData.orgId])

    useEffect(() => {
        if (formData.examId) {
            getPaper()
        }

    }, [formData.examId])


    async function formSubmit() {
        try {
            let data = {
                dataScraper: folderName,
                language: formData.language,
                question_paper_id: formData.paperId,
                orgId: formData.orgId,
                examId: formData.examId,
            }
            loader.start()
            await copyAnswerSheet(data)
            loader.stop()
            setFormData("")
            setDialog(false)
            getOurS3DataAgain()
        }
        catch (err) {
            loader.stop()
            alert("Some Error Occured !")
            console.log(err)
        }
    }

    const importDataFolder =() =>{
        let dataImport = importFolderData.filter((e)=>e.original_name === folderName)
        console.log(dataImport)
        if(dataImport.length !== 0){
            return true
        }else{
            return false
        }
    }

    return (
        <>
            <div>
                
                <button onClick={() => setDialog(true)} className={importDataFolder() === true ? "btn import_button1" : "btn import_button"} disabled={importDataFolder()}>
                    Import
                </button>
            </div>

            <Dialog
                visible={dialog}
                draggable={false}
                closable={false}
                header="Import and Link Folder"
                style={{ maxWidth: '1000px' }}
            >
                <div className='inport_sheet_con'>
                    <div className='text_content'>
                        Choose the fields below to link the imported folder to its corresponding Organization, Exam, Paper, and language. This ensures seamless integration between the imported data and its relevant categories, facilitating efficient organization and management of data.
                    </div>

                    <div className="row mt-5">
                        <div className="col-6">
                            <Label className="HelveticaMedium">Organization Name</Label>
                            <Select
                                options={allOrganizations}
                                value={formData.orgId ? allOrganizations.find(r => r._id == formData.orgId) : null}
                                optionLabel="orgName"
                                placeholder="Choose Organization's Name"
                                onChange={(e) => {
                                    setFormData(pre => ({ ...pre, orgId: e.target.value._id, examId: null, paperId: null }))
                                    setAllExams([])
                                    setAllPapers([])
                                }}

                            />
                        </div>
                        <div className="col-6">
                            <Label className="HelveticaMedium">Exam Name</Label>
                            <Select
                                options={allExams}
                                value={formData.examId ? allExams.find(r => r._id == formData.examId) : null}
                                optionLabel="examName"
                                onChange={(e) => {
                                    setFormData(pre => ({ ...pre, examId: e.target.value._id, paperId: null }))
                                    setAllPapers([])
                                }}

                                placeholder="Choose Organization's Name" />
                        </div>

                        <div className="col-6 mt-4">
                            <Label className="HelveticaMedium">Paper Name</Label>
                            <Select
                                options={allPapers}
                                optionLabel="paperName"
                                value={formData.paperId ? allPapers.find(r => r._id == formData.paperId) : null}
                                placeholder="Choose Organization's Name"
                                onChange={(e) => {
                                    setFormData(pre => ({ ...pre, paperId: e.target.value._id }))
                                }}
                            />
                        </div>
                        <div className="col-6 mt-4">
                            <Label className="HelveticaMedium">Paper Language</Label>
                            <Select
                                options={PAPER_LANGUAGES}
                                placeholder="Choose Organization's Name"
                                value={formData.language}
                                onChange={(e) => {
                                    setFormData(pre => ({ ...pre, language: e.target.value }))
                                }}

                            />
                        </div>
                    </div>

                    <div className='d-flex justify-content-end mt-5'>
                        <button className='btn btn-Secondary me-4' onClick={() => {setDialog(false);setFormData("")}}>Cancel</button>
                        <button className='btn btn-Primary'
                            disabled={!Object.values(formData).every(e => e)}
                            onClick={formSubmit}>Import</button>
                    </div>


                </div>
            </Dialog>


        </>
    )
}
