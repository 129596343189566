import { Dialog } from 'primereact/dialog'
import React, { useEffect, useRef, useState } from 'react'

export const ViewPdfDialog = ({ children, title, pdfUrl }) => {
    let [dialog, setDialog] = useState(false)
    return (
        <>
            <div onClick={() => setDialog(true)}>{children}</div>
            <Dialog
                visible={dialog}
                modal
                style={{ width: "60rem", height: "95vh" }}
                onHide={() => setDialog(false)}
                closable={false}
                draggable={false}
            >
                <div>
                    <h1>{title}</h1>
                    <div style={{ height: "70vh" }}>
                        {pdfUrl ?
                            <iframe
                                 className='w-100 h-100'
                                src={`${pdfUrl}#toolbar=0`}>
                            </iframe> : <div><br />No Pdf Available</div>}
                    </div>
                    <br />
                    <div className='d-flex justify-content-end'>
                        <button onClick={() => setDialog(false)} className='btn btn-Secondary'>Cancel</button>
                    </div>
                </div>
            </Dialog>

        </>
    )
}
