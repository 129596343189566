import React, { useEffect, useState } from 'react'
import { TabPanel, TabView } from "primereact/tabview";
import ResultDeclare from './ResultDeclare';
import { TopBar } from '../../Components/Sidebar/Sidebar';
import AwaitedResultTab from './AwaitedResultTab';

export default function ResultMainTab() {

    const [activeIndex,setActiveIndex] = useState(0)
    let  token = new URLSearchParams(window.location.search).get("activeTab");

  return (
    <>
    <TopBar></TopBar>
    <div className="mainContainer">
        <div className="card p-md-4 p-sm-3 p-2">
      
            {/* <TabView activeIndex={1}> */}
            <TabView activeIndex={token === null ? 0:+(token)}>
                <TabPanel header="Declare Results">
                    <ResultDeclare/>
                </TabPanel>
                <TabPanel header="Awaited Results">
                    <AwaitedResultTab/>
                </TabPanel>
            </TabView>
        </div>

    </div>
    </>
    
  )
}
