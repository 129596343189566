import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./style.css";

import { storeUserInfo } from "../../Components/Assets/userData";

export default function () {
  useEffect(() => {
    storeUserInfo();
  }, []);
  return (
    <>
      <form>
        <div className="main-container">
          <div className="left-contain">
            <div className="logoFixed">
              <span className="eLogo">E-Valuators App</span>
            </div>
          </div>
          <div className="right-contain">
            <div className="right-content-div ">
              <div className="d-flex justify-content-center ">
                <span className="logIn">Log In</span>
              </div>
              <div className="d-flex justify-content-center">
                <span className="HelveticaMedium mt-3">Biometric Setup</span>
              </div>
              <div className="d-flex justify-content-center">
                <span className="resetPasswordFirst mt-3">
                  Place your finger on the external fingerprint sensor three
                  times to set up your Biometric credentials for Logging in
                </span>
              </div>
              <div className="d-flex justify-content-center mt-4">
                <img src="/images/biometric.png" alt="" />
              </div>
              <div className="mt-4">
                <Link to="/resetPassword">
                  <button
                    className=" btn btn-primary w-100"
                    type="submit"
                    id="biometricsubmit"
                  >
                    Continue
                  </button>
                </Link>
              </div>
              <div className="d-flex justify-content-center mt-3">
                <Link to="/" className="textDecoration">
                  <span className="backTologin">Cancel</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
