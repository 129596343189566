import React, { useEffect, useRef, useState } from "react";
import { TopBar } from "../../Components/Sidebar/Sidebar";
import { userId, userUid } from "../../Components/Assets/userData";
import { getAllOrganization } from "../../Services/Admin/Api";

import ReactApexChart from "react-apexcharts";
export default function Overview() {
  const [itAdminOverView, setItAdminOverView] = useState([]);

  const overViewItadmin = async () => {
    await getAllOrganization()
      .then((res) => {
        console.log(res);
        let overview = res.data.data;
        let datas = overview.map((e) => {
          return {
            data: [
              {
                name: "Adminstrations",
                data: e.adminIds.length,
              },
              {
                name: "HOD",
                data: e.hodIds.length,
              },
              {
                name: "Secretary",
                data: e.secretaryIds.length,
              },
              {
                name: "Evaluator",
                data: e.evaluatorIds.length,
              },
              {
                name: "Command Center",
                data: e.command_centerIds.length,
              },
            ],
            orgName: e.orgName,
            orgId: e.orgId,
            examIds:e.examIds

          };
        });
        setItAdminOverView(datas);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    overViewItadmin();
  }, []);
  const donutData = [
    {
      name: "Adminstrations",
      data: 25,
    },
    {
      name: "HOD",
      data: 30,
    },
    {
      name: "Secretary",
      data: 15,
    },
    {
      name: "Evaluator",
      data: 10,
    },
    {
      name: "Command Center",
      data: 5,
    },
  ];

  const options = {
    chart: {
      type: "donut",
    },
    labels: [
      "Adminstrations",
      "HOD",
      "Secretary",
      "Evaluator",
      "Command Center",
    ],
    dataLabels: {
      enabled: false,
    },
    colors: ["#FFCE54", "#48CFAD", "#4FC1E9", "#AC92EC", "#EC87C0"],
    data: {
      enabled: true,
      formatter: function (val, opts) {
        console.log(opts.w.config.series);
        return opts.w.config?.series;
      },
    },
  };

  return (
    <>
      <TopBar></TopBar>

      <div className="mainContainer">
      <div className="card p-md-4 p-sm-3 p-2">
        <h1>OverView ({itAdminOverView.length})</h1>
        <div className="d-flex gap-10 flex-wrap ">
          {itAdminOverView.map((res, ind) => {
            return (
              <>
                <div className="cardStyle gap-10 me-3 mt-3">
                  <div className="d-flex justify-content-center">
                    <h1>{res?.orgName}</h1>
                  </div>
                  <div className="d-flex justify-content-center">
                    <p>{res?.orgId}</p>
                  </div>
                  <ReactApexChart
                    options={options}
                    series={res?.data?.map((item) => item.data)}
                    type="donut"
                    height={350}
                    width={400}
                  />
                  <div className="d-flex justify-content-center">
                    <h6>Number of Total Exams: {res.examIds.length}</h6>
                  </div>
                  <div className="d-flex justify-content-center">
                    <h6>Number of Total Users: {res.data.reduce((total, item) => total + item.data, 0)}</h6>
        
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
    </>
  
  );
}
