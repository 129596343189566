import React, { useState } from "react";
import "./style.css";
import { TopBar } from "../../../Components/Sidebar/Sidebar";
import TableContainer from "../../../Components/Assets/TableContainer";

export default function ScreenTimeTab() {
  const [cardData, setCardData] = useState([
    {
      dayTime: "2hrs 10min /day",
      screenTime: "Average Screen Time",
      img: "/images/avgTime.png",
    },
    {
      dayTime: "1hr 35min /day",
      screenTime: "Average Focus Time",
      img: "/images/fTime.png",
    },
    {
      dayTime: "10min /day",
      screenTime: "Average Unfocus Time",
      img: "/images/uTime.png",
    },
  ]);

  const [tableData, setTableData] = useState([
    {
      sd: "12 Feb, 2024",
      st: "03:00 p.m",
      et: "04:12 p.m",
      ts: "1 hr 12 mins",
      ft: "58 mins",
      ut: "14 mins",
    },
    {
      sd: "09 Feb, 2024",
      st: "12:40 p.m",
      et: "03:16 p.m",
      ts: "2 hrs 36 mins",
      ft: "2 hrs 18 mins",
      ut: "18 mins",
    },
    {
      sd: "08 Feb, 2024",
      st: "04:25 p.m",
      et: "06:22 p.m",
      ts: "1 hr 53 mins",
      ft: "1 hr 51 mins",
      ut: "02 mins",
    },
  ]);

  return (
    <>
      <TopBar />
      <div className="mainContainer">
        <div className="card p-md-4 p-sm-3 p-2">
          <h1 className="pt-4">Screen Time Summary</h1>
          <div className="d-flex py-2 gap-4">
            {cardData.map((res, index) => {
              return (
                <>
                  <div className="question_paper_card_con1 ">
                    <div className="d-flex justify-content-between">
                      <div className="col-10  gap-3">
                        <div className="questionPaperCard_heading1">
                          {res.dayTime}
                        </div>
                        <div className="questionPaperCard_text1 mt-2">
                          {res.screenTime}
                        </div>
                      </div>
                      <div>
                        <img src={res.img} />
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>

          <h1 className="py-5"> Screen Time Records</h1>

          <div className="">
            <TableContainer>
              <table className="left_align_table">
                <thead>
                  <th>Session Date</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                  <th>Total Screen Time</th>
                  <th>Focused Time</th>
                  <th>Unfocused Time</th>
                </thead>
                <tbody>
                    {tableData.map((res,index)=>{
                        return (
                            <tr key={index}>
                                <td>{res.sd}</td>
                                <td>{res.st}</td>
                                <td>{res.et}</td>
                                <td>{res.ts}</td>
                                <td>{res.ft}</td>
                                <td>{res.ut}</td>
                            </tr>
                        )
                    })}
                </tbody>
              </table>
            </TableContainer>
          </div>
        </div>
      </div>
    </>
  );
}
