import React, { useState } from 'react'
import { TopBar } from '../../../Components/Sidebar/Sidebar'
import { BackButton } from '../../../Components/Assets/BackButton'
import "./livefeed.css"
import { Select } from '../../../Components/Inputs/Select'
import { Slider } from 'primereact/slider'
import { RxSpeakerLoud } from "react-icons/rx";
import { RxSpeakerOff } from "react-icons/rx";

export const ViewLiveFeed = () => {

    let [voiceValue, setVoiceValue] = useState(0)

    let options = [
        "Using phone/other digital device while evaluating",
        "Trying switching tabs while evaluating",
        "Talking to somebody else in thr room while evaluating",
        "Left the system unattended while the evaluation was on",
        "Custom enter the reason..."
    ]

    return (
        <>

            <TopBar />
            <div className='mainContainer view_live_feed_container'>
                <div className='card p-md-4 p-sm-3 p-2'>
                    <div>
                        <BackButton link={"/commandCenter/liveFeed"} />
                    </div>
                    <br />
                    <h1>Mithali Shah</h1>
                    <br />

                    <div className='row m-0 p-3 border rounded'>
                        <div className="col-6 p-0">
                            <img src='/images/videoCallImg2.png' className='w-100' />
                        </div>
                        <div className="col-6 p-0">
                            <img src='/images/videoCallImg.png' className='w-100' />
                        </div>
                    </div>
                    <br />
                    <div className="row m-0 p-0 align-items-center evaluator_details_con">
                        <div className="col-5">
                            <div className="row">
                                <div className="col-6">Login Time:</div>
                                <div className="col-6">12:08 p.m. , 19 February 2024</div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-6">Total Screen Time:</div>
                                <div className="col-6">1 hr 15 mins</div>
                            </div>

                        </div>
                        <div className="col-5">
                            <div className="row">
                                <div className="col-6">Total Focused Time:</div>
                                <div className="col-6">10 mins</div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-6">Total Unfocused Time:</div>
                                <div className="col-6">1 hr 15 mins</div>
                            </div>

                        </div>
                        <div className="col-2">
                            <div className='d-flex align-items-center gap-4 fs-4'>
                                {voiceValue === 0 ?
                                    <RxSpeakerOff />
                                    :
                                    <RxSpeakerLoud />
                                }
                                <div className='w-100'>
                                    <Slider onChange={(e) => setVoiceValue(e.value)} value={voiceValue} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <br />
                    <div className='d-flex gap-4'>
                        <div className="w-100">
                            <Select options={options} placeholder="Choose Reporting Reason" />
                        </div>
                        <div>
                            <button className='btn btn-Secondary text-nowrap'>Report Evaluator</button>
                        </div>
                    </div>

                </div>

            </div>

        </>
    )
}
