import React, { useEffect, useState } from "react";
import TableContainer from "../../Components/Assets/TableContainer";
import { getPaperDataResultItAdmin } from "../../Services/Admin/Api";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { loader } from "../../utils/loader";

export default function AwaitedResultTab() {

    const [awitedData,setAwaitedData] = useState([])
  const [searchData, setSearchData] = useState([]);
  const handleSearchClick = (e) => {
    let data = [...searchData];
    let val = e.trim().toLowerCase();
    let searchName = data.filter((res) =>
      res?.examData?.examName?.toLowerCase().includes(val)
    );
    setAwaitedData(searchName);
  };
    const getawaitedData = async() =>{
        loader.start()
        await getPaperDataResultItAdmin().then((res)=>{
            console.log(res)
            setAwaitedData(res.data.data)
            setSearchData(res.data.data)
            loader.stop()
        }).catch((err)=>{
            console.log(err)
            loader.stop()
        })
    }

    useEffect(()=>{
        getawaitedData()
    },[])

    let navigate = useNavigate()
  return (
    <>
      <div className="py-3">
      <div className="d-flex justify-content-between align-items-center pt-4 pb-3">
            <h1>Exam Results</h1>
            <div className="searchInput w-25 me-3">
              <input
                className="form-control ps-5"
                placeholder="Search"
                onChange={(e) => {
                    handleSearchClick(e.target.value);
                }}
              ></input>
              <i className="pi pi-search searchIcon"></i>
            </div>
          </div>
        <TableContainer>
          <table className="left_align_table">
          <thead>
                <th>Exam Name</th>
                <th>No. of Paper</th>
                <th>Evaluated Papers</th>
                <th>No. of Students</th>
                <th>Exam Year</th>
                <th>Declare Result</th>
              </thead>
              <tbody>
                {awitedData.map((res,index)=>{
                    return (
                        <>
                        <tr key={index}>
                            <td className="pointer" onClick={()=>{navigate(`/resultmaintab/awaitedresulttab/detailedscreenparticular/${res.examData._id}`,{state:res?.examData.examName})}}>{res.examData.examName}</td>
                            <td>{res?.examData?.questionIds?.length}</td>
                            <td>{res.evaluatedSheet}</td>
                            <td>{res.number_of_students}</td>
                            <td>{moment(res.examData.examYear).format("YYYY")}</td>
                            <td>{<button  className="btn btn-primary" disabled={res.examData.declared === "undeclared"}>Declare</button>}</td>
                        </tr>
                        </>
                    )
                })}
              </tbody>
          </table>
        </TableContainer>
      </div>
    </>
  );
}
