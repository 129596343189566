import React, { useEffect, useRef, useState } from "react";
import { TopBar } from "../../Components/Sidebar/Sidebar";
import { Input, Label } from "reactstrap";
import { getAllOrganization, addUserProfile } from "../../Services/Admin/Api";
import uniqid from "uniqid";
import Loader from "../../Components/Assets/Loader";
import { userId ,userInfo, userOrgId, userRole} from "../../Components/Assets/userData";
import { useNavigate } from "react-router-dom";
import Infomation from "../../Components/Assets/Infomation";
import { Select } from "../../Components/Inputs/Select";
// import { io } from "socket.io-client";
// import { baseUrl } from "../../Services/EndPoint";
import { addNotification } from "../../Components/Assets/socket";

// const socket = io(baseUrl);

export default function AddUsers() {
  const [userList, setUserList] = useState([
    "hod",
    "evaluator",
    "secretary",
    "command center",
  ]);
  const [selectOrganization, setSelectOrganization] = useState([]);
  const [addUserNAme, setAddUserName] = useState("");
  const [adduserEmail, setAddUserEmail] = useState("");
  const [adduserContact, setAddUserContact] = useState("");
  const [selectOrganizations, setSelectOganizations] = useState();
  const [selectUser, setSelectUser] = useState("");
  const [uniqUserID, setUniqUserID] = useState(uniqid());
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [emailExixt, setEmailExit] = useState(false);
  const [emailError,setEmailError] = useState(false)
  const [orgName,setOrgName] = useState("")
  const [contactExist,setContactExist] = useState(false)

 

  

  const getSelectOrganization = async () => {
    await getAllOrganization()
      .then((res) => {
        console.log(res);
        setSelectOrganization(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmitAdminstration = async (e) => {
    e.preventDefault();
    setLoading(true);
    let userPayload = {
      email: adduserEmail.trim(),
      name: addUserNAme.trim(),
      contectNo: adduserContact.trim(),
      role: selectUser,
      uid: selectUser + "-" + uniqUserID,
      parentId: userId(),
      orgId:  userRole() === "admin" ? selectOrganizations:userOrgId(),
      organization_name:selectOrganization?.find(r=>r?._id == selectOrganizations)?.orgName,
      orgAdminName:userInfo().name,
      orgAdminNameId:userId(),

    };

    await addUserProfile(userPayload)
      .then((res) => {
        addNotification()
        console.log(res);
        setLoading(false);
        navigate("/users");
      })
      .catch((err) => {
        console.log(err?.response?.data?.errormessage?.includes("email"));
        if (err?.response?.data?.errormessage?.includes("email")) {
          setEmailExit(true);
          setLoading(false);
        } else {
          setEmailExit(false);
          setLoading(false);
        }
        if(err?.response.data?.errormessage?.includes("contectNo_1 dup key")|| err?.response.data?.errormessage?.includes("This Contect Number is already used")){
          setContactExist(true)
          setLoading(false)
        }else{
          setContactExist(false)
          setLoading(false)
        }
      });
  };
console.log(userRole())
  useEffect(() => {
    getSelectOrganization();
setSelectOganizations( userRole() === "admin" ? sessionStorage.getItem("org"):userOrgId())
  }, []);

  const addDisableFunction = () => {
    console.log(addUserNAme?.length);
    if (
      addUserNAme?.length === 0 ||
      adduserEmail?.length === 0 ||
      adduserContact?.length === 0 ||
      selectOrganizations?.length === 0 ||
      selectUser?.length === 0
    ) {
      return true;
    } else {
      if (adduserContact?.length !== 10) {
        return true;
      } else {
        if (!/\S+@\S+\.\S+/.test(adduserEmail)) {
          return true;
        } else {
          return false;
        }
      }
    }
  };
  return (
    <>
      <TopBar></TopBar>
     
        <div className="mainContainer">
          <div className="card p-md-4 p-sm-3 p-2">
          <form
        onSubmit={(e) => {
          e.preventDefault()
          handleSubmitAdminstration(e);
          // console.log("asdasdasdasd")
        }}
      >
            <h1>Add User</h1>
            <div>
              {emailExixt ? (
                <Infomation text="Email is already exists" type="warning" />
              ) : (
                ""
              )}
              {
                contactExist ? (
                  <Infomation text="Contact is already exists" type="warning" />
                ):(
                  ""
                )
              }
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <Label className="HelveticaMedium">Name</Label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter the User Name"
                  onChange={(e) => {
                    if (e.target.value === " ") {
                      e.target.value = "";
                    } else {
                      setAddUserName(e.target.value);
                    }
                  }}
                  required
                />
              </div>
              <div className="col-md-6">
                <Label className="HelveticaMedium">User Code</Label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="EVA-101-HOD-240106 "
                  value={selectUser + "-" + uniqUserID}
                  disabled
                  required
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-6">
                <Label className="HelveticaMedium">Contact Number</Label>
                <input
                  className="form-control"
                  type="number"
                  placeholder="Enter the User Contact Number"
                  onChange={(e) => {
                    setAddUserContact(e.target.value);
                    if (e.target.value.length === 10) {
                      setIsError(false);
                    } else {
                      setIsError(true);
                    }
                  }}
                  required
                />
                {isError ? (   
                  <Infomation
                    text="Please Enter 10 digit contact number"
                    type="warning"
                  />
            ) : (
              ""
            )}
              </div>
              <div className="col-md-6">
                <Label className="HelveticaMedium">Email Address</Label>
                <input
                  className="form-control"
                  // type="email"
                  placeholder="Enter the User Email Address"
                  onChange={(e) => {
                    if (e.target.value === " ") {
                      e.target.value = "";
                    } else {
                      setAddUserEmail(e.target.value);
                    }
                    if(!/\S+@\S+\.\S+/.test(e.target.value)){
                      setEmailError(true)
                    }else{
                      setEmailError(false)
                    }
                  }}
                  required
                />
                {emailError ?(       
                <Infomation   text="Invalid email format "
                    type="warning"/>
    
            ):(<></>)}
              </div>
            </div>
            
            <div className="row mt-4">
              {userRole() === "admin" &&(
                <>
                    <div className="col-md-6">
                <Label className="HelveticaMedium">Select Organization</Label>
                {/* <Input
                  type="select"
                  className="form-control"
                  value={sessionStorage.getItem("org")}
                  onChange={(e) => {
                    setSelectOganizations(e.target.value);
                    sessionStorage.setItem("org", e.target.value);
                  }}
                >
                  <option>Select Organization</option>
                  {selectOrganization.map((res, index) => (
                    <option key={index} value={res._id}>
                      {res.orgName}
                    </option>
                  ))}
                </Input> */}
                <Select 
                options={selectOrganization}
                optionLabel="orgName"
                placeholder="Select organization"
                value={selectOrganization.find(
                  (s) => s._id == sessionStorage.getItem("org")
                )}
                onChange={(e) => {
                  console.log("okokokokok");
    
                  console.log(e.value);
                  setSelectOganizations(e.value._id);
                  sessionStorage.setItem("org", e.value._id);
                  setOrgName(e.value.orgName)
                  console.log(e.value.orgName)
                }}
                />
              </div>
                </>
              )}
            
              <div className="col-md-6">
                <Label className="HelveticaMedium">Select User Role</Label>
                {/* <Input
                  type="select"
                  className="form-control"
                  onChange={(e) => {
                    setSelectUser(e.target.value);
                  }}
                >
                  <option>Select User Role</option>
                  {userList.map((res, index) => {
                    return (
                      <option key={index} value={res.toLocaleLowerCase()}>
                        {res}
                      </option>
                    );
                  })}
                </Input> */}
                <Select options={userList} placeholder="Select User Role" value={selectUser} onChange={(e)=>{
                    setSelectUser(e.target.value);
                }} className="textTransform"/>
              </div>
            </div>
            <div className="d-flex justify-content-end mt-4">
              <div className="d-flex">
                <button
                  className="btn btn-Secondary me-3"
                  type="button"
                  onClick={() => {
                    navigate("/users");
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={addDisableFunction()}
                >
                  Add User
                </button>
              </div>
            </div>
      </form>
          </div>
        </div>
      <Loader open={loading} />
    </>
  );
}
