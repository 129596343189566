import React, { Fragment, useEffect, useState } from "react";
import { TopBar } from "../../../Components/Sidebar/Sidebar";
import "./style.css";
import { getExamAndPaperId } from "../../../Services/Admin/Api";
import Loader from "../../../Components/Assets/Loader";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { userOrgId } from "../../../Components/Assets/userData";

export default function HodQuestion() {
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  let [orgData, setOrgData] = useState([]);
  const [searchData,setSearchData] = useState([])
  const navigate = useNavigate();

  const handleSearchClick = (e) =>{
    let data = [...searchData]
    let val = e.trim().toLowerCase();
    
    let searchName = data.filter((res) =>
      res?.examName?.toLowerCase().includes(val)
    );
    setOrgData(searchName);
  }

  const getAllDataById = async () =>{
    setLoading(true)
    await getExamAndPaperId(userOrgId())
    .then((res)=>{
      let dataReverse = [...res.data.data];
      let reverData = dataReverse.reverse();
      setAllData(reverData);
      setOrgData(reverData)
      setSearchData(reverData)
      setLoading(false)
    }).catch((err)=>{
      console.log(err)
    })
  }
useEffect(()=>{
  getAllDataById()
},[])
  return (
    <>
      <TopBar />
      <div className="mainContainer">
        <div className="card p-md-4 p-sm-3 p-2">
          <div className="d-flex justify-content-end">
            <div className="searchInput w-25 me-3">
              <input
                className="form-control ps-5"
                placeholder="Search"
                onChange={(e) => {
                  handleSearchClick(e.target.value);
                }}
              ></input>
              <i className="pi pi-search searchIcon"></i>
            </div>
          </div>
          {orgData.map((res)=>{
            return (
              <Fragment>
                <div className="mt-5">
                  <h1>{res.examName}</h1>
                </div>
                <div className="d-flex gap-5  my-5 flex-wrap">
                  {res.QuestionData.map((res1,ind)=>{
                    return(
                      <>
                          <div className="question_paper_card_con_secre pointer" onClick={()=>{navigate(`/hodquestiontab/paperdata/${res1._id}`)}}>
                          <div className="row">
                            <div className="col-10 d-flex gap-3">
                              <div>
                                <img
                                  src={`/images/questionPaper/questionTypeIcons${
                                    ind < 6 ? ind + 1 : 1
                                  }.svg`}
                                />
                              </div>
                              <div>
                                <div className="questionPaperCard_heading">
                                  {res1.paperCode}
                                </div>
                                <div className="questionPaperCard_text mt-2">
                                  {res1.paperName}
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-2">{res1.guildline?<img src="/images/info.png" />:""}</div> */}
                          </div>
                          <div className="questionPaperCard_text mt-3">
                          Paper Date: {moment(res1.paperDate).format(
                            "DD/MM/YYYY"
                          )}
                          </div>
                        </div>
                      </>
                    )
                  })}
                </div>
              </Fragment>
            )
          })}
        </div>
      </div>
      <Loader open={loading} />
    </>
  );
}
