import React, { useState } from "react";
import "./style.css";
import { TopBar } from "../../Components/Sidebar/Sidebar";
import { Editor } from "primereact/editor";
import { FaCheck } from "react-icons/fa6";
import { BackButton } from "../../Components/Assets/BackButton";
import DragAndDrop from "../../Components/Assets/DragAndDrop";
import DragAndDropquestion from "../../Components/Assets/DragAndDropquestion";
import DragAndDropAnswer from "../../Components/Assets/DragAndDropAnswer";
import {
  getPaperById,
  updatePaperView,
  uploadFile,
  getExamById,
} from "../../Services/Admin/Api";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { formatDate } from "../../utils/commonUtils";
import { loader } from "../../utils/loader";
import { AssignEvaluator } from "./AssignEvaluator";
import { Dialog } from "primereact/dialog";
import moment from "moment/moment";
import { userInfo } from "../../Components/Assets/userData";
import { addQuestionGuidelineNotification } from "../../Components/Assets/socket";
import Infomation from "../../Components/Assets/Infomation";

export const ViewQuestionPaper = () => {
  let date = moment();
  let location = useLocation();
  let exmaName = location?.state;
  console.log(exmaName);

  let params = useParams();
  const [modalOpen1, setModalOPen1] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [ModalOPen3, setModalOpen3] = useState(false);
  const [fileOpen, setFileOpen] = useState("");
  const [fileOpen1, seFileOpen1] = useState("");
  const [fileOpen2, setFileOpen2] = useState("");
  let [guildline, setGuidline] = useState(null);
  let [mode, setMode] = useState("add");
  let [questionDrop, setQuestionDrop] = useState("");
  let [answerDrop, setAnswerDrop] = useState("");

  let [paperData, setPaperData] = useState({});
  const [examName, setExamName] = useState();
  const [questionStatus, setQuestionStatus] = useState("");
  const [dataFill, setDataFill] = useState(false);

  let [files, setFiles] = useState({
    question_paper_format: null,
    question_paper: null,
    answer_key: null,
  });

  function dragAndDropChange(e, key) {
    setFiles((pre) => ({ ...pre, [key]: e }));
    console.log(e, key);
  }
  let navigate = useNavigate();
  async function formSubmit() {
    // if (!Object.values(files).every((e) => e) || guildline.trim() === "") {
    //   console.log(paperData);
// console.log(!files.question_paper_format,files.question_paper_format)
console.log(files)
console.log(files.question_paper_format,guildline)
    // if (paperData?.question_skeleton_Link  !== files.question_paper_format && paperData.guildline !==  guildline ) {
    if (files.question_paper_format == null || guildline == null) {
      // navigate("/question");
      // alert("All fields are required");
      setDataFill(true);
      return;
    }

    if (paperData.status === "Approved") {
      let keys = ["question_paper", "answer_key"];
      console.log(keys);

      let arr = [];

      let pushdata = keys.map(async (res) => {
        if (files[res]) {
          let url = uploadFile(files[res]);
          console.log(url, "asdasdadasd");
          return url;
        }
      });

      loader.start();
      Promise.all(pushdata).then(async (res) => {
        console.log(res);
        loader.stop();
        let obj = {
          question_paper_link: res[0]?.data?.fileLocation,
          question_paper_fileName: res[0]?.data?.fileName,
          answerKey_link: res[1]?.data?.fileLocation,
          answerKey_fileName: res[1]?.data?.fileName,
          guildline,
          orgAdminName: userInfo().name,
          orgId: paperData.orgId,
          exam_name: paperData.examName,
        };

        console.log(obj);
        await updatePaper(obj);
      });
    } else {
      let keys = ["question_paper_format"];
      console.log(keys);

      let arr = [];

      keys.forEach(async (res) => {
        if (files[res]) {
          let url = uploadFile(files[res]);
          arr.push(url);
        }
      });
      loader.start();
      Promise.all(arr).then(async (res) => {
        console.log(res);
        loader.stop();

        let obj = {
          question_skeleton_Link: res[0]?.data?.fileLocation,
          question_skeleton_fileName: res[0]?.data?.fileName,
          guildline,
          orgAdminName: userInfo().name,
          orgId: paperData.orgId,
          exam_name: paperData.examName,
        };
        if (guildline !== paperData.guildline) {
          obj = {
            ...obj,
            ...{ status: "Pending" },
          };
          console.log(obj, "Pending");
        }
        console.log(obj);
        await updatePaper(obj);
      });
    }
  }

  async function updatePaper(data) {
    console.log(data);
    try {
      let res = await updatePaperView(params.id, data);
      addQuestionGuidelineNotification();
      console.log(res);
      navigate("/question");
      loader.stop();
    } catch (err) {
      loader.stop();
      console.log(err);
    }
  }

  async function getPaper() {
    try {
      loader.start();
      let res = await getPaperById(params.id);
      let res1 = await getExamById(res.data.data.examId);
      console.log(res)
      console.log(res1)
      loader.stop();
      setGuidline(res.data.data.guildline)
      let obj = { ...res.data.data, ...{ examName: res1.data.data.examName } };
      setFiles(pre=>{
        return {
          ...pre,
          question_paper_format:res.data.data.question_skeleton_fileName
        }
      })
      setPaperData(obj);
      console.log(res.data.data);
      if (res.data.data.question_skeleton_fileName) {
        setMode("edit");
      }
    } catch (err) {
      loader.stop();
      console.log(err);
    }
  }

  useState(() => {
    getPaper();
  }, []);

  const dateComparision = () => {
    let date = new Date();
    let validDate = new Date(paperData?.paperDate);
    console.log(date.getTime() > validDate.getTime(), "asdadsdasdasdasdsddasdasdasdasdasd");
    if (paperData.status) {
      if (date.getTime() > validDate.getTime()) {
        if (paperData.status === "Approved") {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  };
  console.log(dateComparision());

  return (
    <>
      <Dialog
        style={{ width: "60rem" }}
        visible={modalOpen1}
        modal
        onHide={() => {
          setModalOPen1(false);
        }}
        closable={false}
        draggable={false}
      >
        {console.log(fileOpen)}
        <iframe
          src={fileOpen}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          style={{ width: "100%", height: "700px" }}
        ></iframe>
        <div>
          <button
            className="btn btn-Secondary"
            onClick={() => {
              setModalOPen1(false);
            }}
          >
            Cancel
          </button>
        </div>
      </Dialog>
      <Dialog
        style={{ width: "60rem" }}
        visible={modalOpen2}
        modal
        onHide={() => {
          setModalOpen2(false);
        }}
        closable={false}
        draggable={false}
      >
        <iframe
          src={fileOpen1}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          style={{ width: "100%", height: "700px" }}
        ></iframe>
        <div>
          <button
            className="btn btn-Secondary"
            onClick={() => {
              setModalOpen2(false);
            }}
          >
            Cancel
          </button>
        </div>
      </Dialog>
      <Dialog
        style={{ width: "60rem", height: "60rem" }}
        visible={ModalOPen3}
        modal
        onHide={() => {
          setModalOpen3(false);
        }}
        closable={false}
        draggable={false}
      >
        <iframe
          src={fileOpen2}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          style={{ width: "100%", height: "700px" }}
        ></iframe>
        <div>
          <button
            className="btn btn-Secondary"
            onClick={() => {
              setModalOpen3(false);
            }}
          >
            Cancel
          </button>
        </div>
      </Dialog>
      <TopBar />
      <div className="mainContainer">
        <BackButton link={"/question"} />
        <br />
        <div className="card p-md-4 p-sm-3 p-2 view_questionPaper_con">
          <section className="d-flex justify-content-between">
            <div className="d-flex align-items-center gap-3">
              <div>
                <img src="/images/questionPaper/questionTypeIcons4.svg" />
              </div>
              <div>
                <h1>{paperData?.paperCode}</h1>
                <div className="">{paperData?.paperName}</div>
              </div>
            </div>

            <div className="d-flex gap-3 align-items-center">
              <div>
                <div>
                  {paperData?.paperDate && formatDate(paperData?.paperDate)}
                </div>
                <div>Paper Date</div>
              </div>
            </div>
          </section>
          {dataFill ? (
            <>
              <Infomation
                text="Question Paper Format and Evaluation Guidelines are required"
                type="warning"
              />
            </>
          ) : (
            <></>
          )}
          <div className="mt-5">
            <div className="d-flex justify-content-between">
              <label className="HelveticaMedium">Question Paper Format</label>
            </div>
            <div className="mt-2">
              {mode === "add" || mode === "updateQuestion" ? (
                <>
                  {console.log(mode, "asdasdasdasdasdasdasdasdas")}

                  <DragAndDrop
                    acceptFiles={["application/pdf"]}
                    value={files?.question_paper_format}
                    onChange={(e) => {
                      dragAndDropChange(e, "question_paper_format");
                    }}
                  />
                </>
              ) : (
                <div className="border rounded-2 p-2 pointer d-flex justify-content-between">
                  <div
                    onClick={() => {
                      setModalOPen1(true);
                      setFileOpen(paperData?.question_skeleton_Link);
                      console.log(paperData);
                    }}
                  >
                    {paperData?.question_skeleton_fileName}
                  </div>
                  {paperData.status === "Unapproved" && (
                    <>
                      <span
                        className=""
                        onClick={() => {
                          console.log("qweewqweqweqweqwe");
                          setMode("updateQuestion");
                        }}
                      >
                        <i className="pi pi-pencil me-2 pointer" />
                      </span>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="mt-5">
            <div className="d-flex justify-content-between">
              <label className="HelveticaMedium">Evaluation Guidelines</label>
              <div className="d-flex">
                <span className="HelveticaMedium">Status:</span>
                {/* {paperData?.guildline ? (
                  paperData?.guildline_verify === true ? (
                    <span className="HelveticaMedium text-success ms-2 d-flex align-items-center">
                      <FaCheck /> &nbsp; Approved
                    </span>
                  ) : (
                    <span className="HelveticaMedium #F04438 ms-2 d-flex align-items-center">
                      <img src="/images/info.png" /> &nbsp;
                      <span className="unapprovedQuetion">Unapproved</span>
                    </span>
                  )
                ) : (
                  "__"
                )} */}

                {paperData.status === "Approved" && (
                  <>
                    <span className="HelveticaMedium text-success ms-2 d-flex align-items-center">
                      <FaCheck /> &nbsp; Approved
                    </span>
                  </>
                )}
                {paperData.status === "Unapproved" && (
                  <>
                    <span className="HelveticaMedium #F04438 ms-2 d-flex align-items-center">
                      <img src="/images/info.png" /> &nbsp;
                      <span className="unapprovedQuetion">Unapproved</span>
                    </span>
                  </>
                )}
                {/* {console.log(!paperData.status,"asdasd")} */}
                {paperData.status === "Pending" && (
                  <>
                    <span className="HelveticaMedium text-success ms-2 d-flex align-items-center">
                      Pending
                    </span>
                  </>
                )}

                {!paperData.status && (
                  <>
                    <span className="HelveticaMedium text-success ms-2 d-flex align-items-center">
                      None
                    </span>
                  </>
                )}
              </div>
            </div>
            {console.log(paperData)}
            <Editor
              value={paperData?.guildline}
              onTextChange={(e) => {
                setGuidline(e.htmlValue);
              }}
              className="mt-2"
              style={{ height: "200px" }}
              // disabled={true}
            />
          </div>

          <div className="row m-0">
            <div className="col-lg-6 col-12 mt-5">
              <div className="d-flex justify-content-between">
                <label className="HelveticaMedium">Question Paper</label>
              </div>

              <div className="mt-2">
                {console.log(paperData?.question_paper_fileName)}
                {mode === "add" || questionDrop ==="updateQuestionPaper" || !paperData?.question_paper_fileName ? (
                  <DragAndDrop
                    acceptFiles={["application/pdf"]}
                    value={files?.question_paper}
                    onChange={(e) => dragAndDropChange(e, "question_paper")}
                    disabled={dateComparision()}
                  />
                ) : (
                  <div className="border rounded-2 p-2 pointer d-flex justify-content-between">
                    <div
                      onClick={() => {
                        setModalOpen2(true);
                        seFileOpen1(paperData.question_paper_link);
                      }} 
                    >
                      {paperData?.question_paper_fileName}
                    </div>
                    {/* {!paperData?.question_paper_fileName ? (
                  <></>
                ) : ( */}
                    <span
                      onClick={() => {
                        setQuestionDrop("updateQuestionPaper")
                      }}
                    >
                      <i className="pi pi-pencil me-2 pointer" />
                    </span>
                    {/* )} */}
                  </div>
                )}
              </div>
            </div>

            <div className="col-lg-6 col-12 mt-5">
              <div className="d-flex justify-content-between">
                <label className="HelveticaMedium">Answer Key</label>
              </div>
              <div className="mt-2">
                {mode === "add" || answerDrop === "updateQuestionAnswerKey" || !paperData?.answerKey_fileName ? (
                  <DragAndDrop
                    acceptFiles={["application/pdf"]}
                    value={files?.answer_key}
                    onChange={(e) => dragAndDropChange(e, "answer_key")}
                    disabled={dateComparision()}
                  />
                ) : (
                  <div className="border rounded-2 p-2 pointer d-flex justify-content-between">
                    <div
                      onClick={() => {
                        setModalOpen3(true);
                        setFileOpen2(paperData.answerKey_link);
                      }}
                    >
                      {paperData?.answerKey_fileName}
                    </div>
                    {/* {!paperData?.answerKey_fileName ? (
                  <></>
                ) : ( */}
                    <span
                      onClick={() => {
                        setMode("updateQuestionAnswerKey");
                        setAnswerDrop("updateQuestionAnswerKey")
                      }}
                    >
                      <i className="pi pi-pencil me-2 pointer" />
                    </span>
                    {/* )} */}
                  </div>
                )}
              </div>
            </div>
          </div>

          {paperData?.paperName ? (
            <div className="mt-5">
              <AssignEvaluator onAddUpdate={getPaper} paperData={paperData} />
            </div>
          ) : null}

          <div className="d-flex justify-content-end mt-4">
            <Link to={"/question"}>
              <button className="btn btn-Secondary me-4">Cancel</button>
            </Link>
            <button className="btn btn-Primary" onClick={formSubmit}>
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
