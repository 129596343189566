import React from "react";
import { TopBar } from "../../../Components/Sidebar/Sidebar";

export default function SecretaryOverView() {
  return (
    <>
      <TopBar></TopBar>
      <div className="mainContainer"></div>
    </>
  );
}
