import React from 'react'
import "./style.css"
import PopOver from '../../Components/Assets/PopOver'
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../utils/commonUtils';
import { ConfirmationModal } from '../../Components/Assets/ConfirmationModal';
import { deletePaperById } from '../../Services/Admin/Api';
import { loader } from '../../utils/loader';
import { PopoverItem } from '../../Components/Assets/PopoverItem';


export const QuestionPaperCard = ({ paperName, paperCode, paperDate, _id, onDelete, ind,orgData }) => {
    console.log(orgData)
    let navigate = useNavigate()
    async function deleteOrg(id) {
        try {
            console.log(id)
            loader.start()
            let res = await deletePaperById(id)
            loader.stop()
            onDelete(true)
            console.log(res)
        }
        catch (err) {
            loader.stop()
            console.log(err)
        }
    }

    return (
        <div className='question_paper_card_con'>
            {/* <div className='d-flex justify-content-end mb-3'>
                <PopOver>
                    <div onClick={() => navigate(`edit/${_id}`)} className='d-flex align-items-center gap-2 pointer'>
                        <div className='pb-1'>
                            <MdEdit />
                        </div>
                        <div>
                            Edit
                        </div>
                    </div>
                    <hr className='my-1' />
                    <ConfirmationModal onSubmit={deleteOrg} data={_id} header={"Delete Paper"} text={"Are you sure you want to delete ?"}>
                        <div className='d-flex align-items-center gap-2 pointer'>
                            <div className='pb-1'>
                                <MdDelete />
                            </div>
                            <div>
                                Delete
                            </div>
                        </div>
                    </ConfirmationModal>
                    <hr className='my-1' />
                    <div onClick={() => navigate(`view/${_id}`)} className='d-flex align-items-center gap-2 pointer'>
                        <div className='pb-1'>
                            <MdOutlinePreview />
                        </div>
                        <div>
                            View
                        </div>
                    </div>
                    
                </PopOver>
            </div>

            <div className='d-flex align-items-center justify-content-between'>
                <div>
                    <div className="questionPaperCard_heading">{paperCode}</div>
                    <div className='questionPaperCard_text mt-1'>{paperName}</div>
                </div>
                <div>
                    <img src={`/images/questionPaper/questionTypeIcons${num}.svg`} />
                </div>
            </div>
            <div className='questionPaperCard_text mt-3'>{formatDate(paperDate)}</div> */}

            <div className="row">
                <div className="col-10 d-flex gap-3">
                    <div> <img src={`/images/questionPaper/questionTypeIcons${ind < 6 ? ind + 1 : 1}.svg`} /></div>
                    <div>
                        <div className='questionPaperCard_heading'>{paperCode}</div>
                        <div className='questionPaperCard_text mt-2'>{paperName}</div>
                    </div>
                </div>
                <div className="col-2">
                    <PopOver>
                        <PopoverItem text={"Edit"} icon={<i className='pi pi-pencil' />} onClick={() => navigate(`edit/${_id}`)} />
                        <ConfirmationModal onSubmit={deleteOrg} data={_id} header={"Delete Paper"} text={"Are you sure you want to delete ?"}>
                            <PopoverItem text={"Delete"} icon={<i className='pi pi-trash' />} />
                        </ConfirmationModal>
                        <PopoverItem text={"View"} icon={<i className='pi pi-eye' />} onClick={() => navigate(`view/${_id}`,{state:orgData})} />
                    </PopOver>
                </div>
            </div>
            <div className='questionPaperCard_text mt-3'>
                Paper Date: {formatDate(paperDate)}
            </div>

        </div>
    )
}
