import moment, { min } from "moment";

export const filterArrayWithSingleKey = (allData, key, string) => {
    return allData.filter(res => res[key].toLowerCase().trim().includes(string.toLowerCase().trim()))
};

export const filterArrayWithMultipleKey = (dataArray, keyArr, string) => {
    return dataArray.filter(res => {
        return keyArr.some(key =>
            res.hasOwnProperty(key) &&
            res[key].toString().trim().toLowerCase().includes(string.trim().toLowerCase())
        )
    })
}

export const formatDate = (date) => {
    const inputDate = moment(date);
    const formattedDate = inputDate.format('DD-MM-YYYY');
    return formattedDate
}

export function findDiffereceBetweenTwoNumber(...numbers) {
    let minNum = Math.min(...numbers)
    let maxNum = Math.max(...numbers)
    return maxNum - minNum;
}

export const findDifferencePercentageEvaluatorsMarks = (...numbers) => {
    let minNum = Math.min(...numbers)
    let maxNum = Math.max(...numbers)
    let difference = maxNum - minNum
    return ((difference * 100 / (minNum === 0 ? 1 : minNum)).toFixed(2))
}











