import React, { useEffect, useState } from "react";
import "./style.css";
import { Calendar } from "primereact/calendar";
import { TopBar } from "../../../Components/Sidebar/Sidebar";
import {
  getNotificationHodData,
  readOrgAdminNotification,
} from "../../../Services/Admin/Api";
import { userId, userOrgId, userRole } from "../../../Components/Assets/userData";
import { loader } from "../../../utils/loader";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import { baseUrl } from "../../../Services/EndPoint";
const socket = io(baseUrl);

export default function HodNotification() {
  let [notificationDataHod, setNotificationDataHod] = useState([]);

  socket.on("getNotificationForHOD", () => {
    getNotificationDataOfHod();
  });

  const getNotificationDataOfHod = async () => {
    loader.start();
    let payloadData = {
      user_id: userId(),
    };
    await getNotificationHodData(userOrgId(),payloadData).then(async (res) => {
      console.log(res.data.data);
      let dataReverse = [...res.data.data.result];
      loader.stop();
      setNotificationDataHod(dataReverse);
      let ok = dataReverse.map((e) => e.data).flat(Infinity);
      console.log(ok);

      let ids = ok
        .filter((e) => !e.view_by.includes(userId()))
        .map((e) => e._id);
      console.log(ids);
      if (window.location.pathname.includes("hodnotification")) {
        await readDataNotificationOrg(ids);
      }
    }).catch((err)=>{
      console.log(err)
      loader.stop()
    })
  };

  const readDataNotificationOrg = async (d) => {
    let payload = {
      notificationData: d,
    };
    if (d.length === 0) return;
    await readOrgAdminNotification(userId(), payload)
      .then((res) => {
        console.log(res.data.data);
        // setNotificationData("")
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let navigate = useNavigate();

  function navigateToNotification(data) {
    console.log(data);

    if (data?.routes) {
      let routeData = data.routes.find((e) => e.role === userRole());
      console.log(routeData);
      if (routeData?.route) {
        navigate(routeData.route);
      }
    }
  }

  useEffect(() => {
    getNotificationDataOfHod();
  }, []);
  function getDatainTime(date) {
    let today = moment().format("YYYY-MM-DD");
    let Yesterday = new Date(today);
    Yesterday.setDate(Yesterday.getDate() - 1);
    let current = new Date(date);
    if (date === "Today") {
      return date;
    }
    if (+Yesterday === +current) {
      return "Yesterday";
    } else {
      return moment(current).format("DD MMMM, YYYY");
    }
  }
  return (
    <>
      <TopBar></TopBar>
      <div className="mainContainer">
        <div className="card p-md-4 p-sm-3 p-2">
          <div className="d-flex align-items-center justify-content-between ">
            <div className="d-flex align-items-center">
              <h1>Notifications</h1>
            </div>
          </div>
          {notificationDataHod.map((res) => {
            return (
              <>
                <h5 className="mt-3">{getDatainTime(res._id)}</h5>
                {res?.data?.map((res2) => {
                  return (
                    <div
                      className="notificationList mt-3 p-3"
                      onClick={() => navigateToNotification(res2)}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex  align-items-center">
                          <div className="">
                            <span className="textStyleMain">
                              {res2?.hod_message?.message_one}
                            </span>
                            <p className="textStyle">
                              {res2?.hod_message?.message_two}{" "}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className=" me-2"></span>
                          <span>{moment(res2.created_at).fromNow()}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}
