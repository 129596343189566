import React, { useEffect, useRef, useState } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';

import { BsThreeDotsVertical } from "react-icons/bs";


export default function PopOver({ children, item }) {
    const op = useRef(null);

    useEffect(() => {
        let dd = document.querySelector(".mainContainer")
        if (dd) {
            dd.addEventListener("scroll", () => {
                if (op.current) {
                    op.current.hide()
                }
            })
        }
    }, [])


    return (
        <div className='w-100'>
            <button className='p-1 three_dot_btn' type='button' onClick={(e) => {
                op.current.show(e);
            }}>

                {item || <BsThreeDotsVertical />}
            </button>
            <OverlayPanel ref={op} appendTo={document.body} className='p-0'>
                {children}
            </OverlayPanel>
        </div>
    );
}
