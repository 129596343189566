import React, { useEffect, useState } from "react";
import "./answerSheet.css";
import { TopBar } from "../../Components/Sidebar/Sidebar";
import { ImportSheetDialog } from "./ImportSheetDialog";
import {
  deleteFolder,
  getFolderName,
  getOurS3Data,
  hodAnswerSheetListById,
} from "../../Services/Admin/Api";
import { loader } from "../../utils/loader";
import { useNavigate } from "react-router-dom";
import { ConfirmationModal } from "../../Components/Assets/ConfirmationModal";
import Paginator from "../../Components/Assets/Paginator";
import Paginator1 from "../../Components/Assets/Paginator1";

export const AnswerSheet = () => {
  let navigate = useNavigate();

  useEffect(() => {
    getFoldersName();
    getOurS3Folders();
  }, []);

  let [error, setError] = useState({
    error: false,
    errorMessage: "",
  });
  let [error2, setError2] = useState({
    error: false,
    errorMessage: "",
  });

  let [folders, setFolders] = useState([]);
  let [ourFolders, setOurFolders] = useState([]);

  let [allFoldersData, setAllFoldersData] = useState([]);
  let [allOurFolders, setAllOurFolders] = useState([]);
  const [dataListArray,setDataListArray] = useState([])
  const [dataListArray2,setDataListArray2] = useState([])
  const[flagData,setFlagData] = useState([])
  const [flagData1,setFlagData1] = useState([])

  async function getFoldersName() {
    try {
      loader.start();
      let res = await getFolderName();
      loader.stop();
      console.log(res.data.data);
      if (res?.data?.data?.folders) {
        setDataListArray(res.data.data.folders);
        setAllFoldersData(res.data.data.folders);
        setFlagData(res.data.data.folders)
        
      } else {
        setError({
          error: true,
          errorMessage: res.data.data,
        });
      }
    } catch (err) {
      loader.stop();
      console.log(err);
    }
  }

  async function getOurS3Folders() {
    try {
      let res = await hodAnswerSheetListById({ id: "" });
      console.log(res.data.data);
      // if (res?.data?.data?.folders) {
        setDataListArray2(res.data.data);
      setAllOurFolders(res.data.data);
      setFlagData1(res.data.data)
      // } else {
      //     setError2({
      //         error: true,
      //         errorMessage: res.data.data
      //     })
      // }
      // console.log(res.data.data)
    } catch (err) {
      console.log(err);
    }
  }

  async function deleteFolderByName(folderName) {
    try {
      loader.start();
      let res = await deleteFolder({ dataRecord: folderName });
      getOurS3Folders();
      loader.stop();
    } catch (err) {
      loader.stop();
      console.log(err);
    }
  }

  return (
    <>
      <TopBar />

      <div className="mainContainer answerSheet_main_con">
        <div className="card p-md-4 p-sm-3 p-2">
          <div className="d-flex gap-4">
            <div className="w-50 col_main_container ">
              <div className="addScrollbar">
                <div className="main_heading mb-3">
                  All Folders ({folders?.length})
                </div>
                <div>
                  <div className="searchInput">
                    <input
                      onChange={(e) => {
                        setFolders(
                          [...allFoldersData].filter((d) =>
                            d
                              .toLowerCase()
                              .includes(e.target.value.toLowerCase())
                          )
                        );
                        // setFlagData()
                      }}
                      className="form-control ps-5"
                      placeholder="Search"
                    />
                    <i className="pi pi-search searchIcon"></i>
                  </div>
                </div>
              </div>
              <br />
              {/* <div className="removeScrollBar"> */}
              {error.error ? (
                <div className="text-center text_1">{error.errorMessage}</div>
              ) : (
                <>
                  {folders?.map((res, key) => {
                    return (
                      <div
                        key={key}
                        className="table_row d-flex align-items-center justify-content-between p-4 "
                      >
                        <div>
                          <div className="text_1">{res}</div>
                        </div>
                        <ImportSheetDialog
                          getOurS3DataAgain={getOurS3Folders}
                          folderName={res}
                          importFolderData={dataListArray2}
                        />
                       
                      </div>
                    );
                  })}
                  <div className="p-4">

                   <Paginator itemsPerPage={5} items={dataListArray} setShowData={setFolders} hideButtons={false} flagg={flagData}/>
                  </div>
                </>
              )}
              {/* </div> */}
            </div>
            <div className="w-50 col_main_container">
            <div className="addScrollbar">
              <div className="main_heading mb-3">
                Added Folders ({ourFolders?.length})
              </div>
              <div className="searchInput me-4">
                <input
                  onChange={(e) => {
                    setOurFolders(
                      [...allOurFolders].filter((d) =>
                        d.original_name
                          .toLowerCase()
                          .includes(e.target.value.toLowerCase())
                      )
                    );
                  }}
                  className="form-control ps-5"
                  placeholder="Search"
                />
                <i className="pi pi-search searchIcon"></i>
              </div>
            </div>
              <br />

              {error2.error ? (
                <div className="text-center text_1">{error2.errorMessage}</div>
              ) : (
                <div className="mainAnswerSheetContainer">
                  {ourFolders?.map((res, key) => {
                    return (
                      <div
                        key={key}
                        className="table_row d-flex align-items-center justify-content-between"
                      >
                        <div
                          onClick={() =>
                            navigate(
                              `viewFolder/${
                                res.question_paper_id
                              }?folderName=${encodeURIComponent(
                                res.original_name
                              )}&orgId=${res.orgId}`
                            )
                          }
                          className="pointer w-100 "
                        >
                          <div className="folder_name">{res.original_name}</div>
                        </div>
                        <ConfirmationModal
                          data={res.original_name}
                          onSubmit={deleteFolderByName}
                          header={"Delete Folder"}
                          text={`Are you sure you want to delete Folder "${res.original_name}" ?`}
                        >
                          <button className="import_button">Delete</button>
                        </ConfirmationModal>
                      </div>
                    );
                  })}
                   <div className="p-4">

<Paginator1 itemsPerPage1={5} items1={dataListArray2} setShowData1={setOurFolders} hideButtons1={false} flagg1={flagData1}/>
</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
