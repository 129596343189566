import React, { useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";
import OrgresultDeclare from "./OrgresultDeclare";
import OrgAwaitedResult from "./OrgAwaitedResult";


export default function OrgAdminMainResultTab() {
  const [activeIndex,setActiveIndex] = useState(0)
  let  token = new URLSearchParams(window.location.search).get("activeTab");
  return (
    <>
      <div className="mainContainer">
        <div className="card p-md-4 p-sm-3 p-2">

     
            <TabView activeIndex={token === null ? 0:+(token)}>
                <TabPanel header="Declare Results">
                  <OrgresultDeclare/>
                </TabPanel>
                <TabPanel header="Awaited Results">
                  <OrgAwaitedResult/>
                </TabPanel>
            </TabView>
        </div>

      </div>

    </>
  );
}
