import React, { useEffect, useState } from 'react';
import { TopBar } from '../../Components/Sidebar/Sidebar';
import { BackButton } from '../../Components/Assets/BackButton';
import TableContainer from '../../Components/Assets/TableContainer';
import { useNavigate, useParams } from 'react-router-dom';
import { getRecordingById } from '../../Services/Admin/Api';
import { loader } from '../../utils/loader';
import moment from 'moment';

export const ViewSession = () => {


    let { id } = useParams()
    let [sessionData, setSessionData] = useState({})
    let [sessionPartsData, setSessionPartsData] = useState([])


    async function getRecordingData() {
        try {
            loader.start()
            let res = await getRecordingById(id)
            console.log(res.data.data)
            if (res.data.data.length > 0) {
                let { session_parts, ...rest } = res.data.data[0]
                console.log(rest)
                setSessionData(rest);
                setSessionPartsData(session_parts);
            }
        }
        catch (err) {
            console.log(err)
        }
        finally {
            loader.stop()
        }
    }


    useEffect(() => {
        getRecordingData()
    }, [])
    let navigate = useNavigate()

    return (
        <>
            <TopBar />
            <div className="mainContainer">
                <div className="card p-md-4 p-sm-3 p-2">
                   {sessionData?.evaluatorId && <BackButton link={`/recording/viewrecording/${sessionData?.evaluatorId}`} />}
                    <div className="d-flex gap-3 mt-3">
                        {/* <div>
                            <img src="/images/Avatar.png" />
                        </div> */}
                        <div className=" w-100 align-self-center text-start">
                            <h1>{sessionData?.userName || "--"}</h1>
                            <p>{sessionData?.paperName || "--"}</p>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <table className='table table-bordered text-start'>
                            <thead>
                                <tr>
                                    <td>Session Number</td>
                                    <td>{sessionData?.session || "--"}</td>
                                    <td>Session Date</td>
                                    <td>{sessionData?.start_time && moment(sessionData?.start_time).format("DD-MM-YYYY")}</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Paper Name</td>
                                    <td>{sessionData?.paperName || "--"}</td>
                                    <td>Session Duration</td>
                                    <td>{sessionData?.duration}</td>
                                </tr>
                                <tr>
                                    <td>Start Time</td>
                                    <td>{sessionData?.start_time && moment(sessionData?.start_time).format("HH:MM") || "--"}</td>
                                    <td>End Time</td>
                                    <td>{sessionData?.end_time && moment(sessionData?.end_time).format("HH:MM") || "--"}</td>
                                </tr>
                            </tbody>
                        </table>




                    </div>
                    <h1 className='mb-3 mt-4'>Session Recordings</h1>


                    <TableContainer>

                        <table>
                            <thead>
                                <tr>

                                    <th>Part</th>
                                    <th>Start Time</th>
                                    <th>End Time</th>
                                    <th>Part Duration</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>

                            <tbody>
                                {sessionPartsData?.map((res, key) => {
                                    return (
                                        <tr>
                                            <td
                                                onClick={() => {
                                                    navigate(`/recording/viewsession/part/${res._id}/${sessionData?._id}`)
                                                }}
                                            >{res.part_number}</td>
                                            <td>{res.part_start_time && moment(res.part_start_time).format("HH:MM") || "--"}</td>
                                            <td>{res.part_end_time && moment(res.part_end_time).format("HH:MM") || "--"}</td>
                                            <td>{res.duration || "--"}</td>
                                            <td><i className='bi bi-trash' /></td>
                                        </tr>

                                    )
                                })}
                            </tbody>
                        </table>
                    </TableContainer>

                </div>
            </div>
        </>
    )
}
