import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import 'primeicons/primeicons.css';
import "primereact/resources/primereact.min.css";
import Sidebar from "./Components/Sidebar/Sidebar";
import Router from "./Routers/Router";
import { Provider } from "react-redux"
import { store } from "./Store/Store";
import { MainLoader } from "./Components/Assets/Loader";
import 'react-circular-progressbar/dist/styles.css';

function App() {


  return (
    <>
      <Provider store={store} >
        <BrowserRouter>
          <MainLoader />
          <Router>
          </Router>
        </BrowserRouter>
      </Provider>
      {/* <h1>Hello</h1> */}
      {/* <Sidebar></Sidebar> */}
    </>
  );
}

export default App;
