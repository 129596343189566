import React, { Fragment, useEffect, useState } from "react";
import { TopBar } from "../../Components/Sidebar/Sidebar";
import { QuestionPaperCard } from "./QuestionPaperCard";
import { GrAdd } from "react-icons/gr";
import { Link } from "react-router-dom";
import "./style.css";
import {
  getAllOrganization,
  getExamWithPaperList,
  getExamAndPaperId,
} from "../../Services/Admin/Api";
import { loader } from "../../utils/loader";
import { filterArrayWithMultipleKey } from "../../utils/commonUtils";
import { Select } from "../../Components/Inputs/Select";
import { userOrgId, userRole } from "../../Components/Assets/userData";

export const Questions = () => {
  let [allData, setAllData] = useState([]);
  let [orgData, setOrgData] = useState([]);

  const getAllData = async (org) => {
    loader.start();
    if (userRole() === "admin") {
      try {
        let res = await getExamWithPaperList();
        loader.stop();
        let dataReverse = [...res.data.data];
        let reverData = dataReverse.reverse();
        setAllData(reverData);
        console.log(res.data.data);
        if (org) {
          if (selectedOrg._id) {
            console.log(org)
            let ress = filterArrayWithMultipleKey(
              reverData,
              ["orgId"],
              selectedOrg._id
            );
            console.log(ress)
            setOrgData(ress);
          }else {
            setOrgData(reverData);
          }
        } else {
          setOrgData(reverData);
        }
      } catch (err) {
        console.log(err);
        loader.stop();
      }
    } else {
      try {
        let res = await getExamAndPaperId(userOrgId());
        loader.stop();
        let dataReverse = [...res.data.data];
        let reverData = dataReverse.reverse();
        setAllData(reverData);
        console.log(res.data.data);
        setOrgData(reverData);
      } catch (err) {
        console.log(err);
      }
    }
  };
  useEffect(() => {
    getAllData();
  }, []);

  function search(e) {
    let res = filterArrayWithMultipleKey(allData, ["examName"], e.target.value);
    setOrgData(res);
  }

  let [allOrg, setAllOrg] = useState([]);

  let [selectedOrg, setSelectedOrg] = useState({});

  useEffect(() => {
    const getAllOrg = async () => {
      try {
        let res = await getAllOrganization();

        setAllOrg([
          {
            orgName: "All Organization",
            _id: null,
          },
          ...res.data.data,
        ]);
      } catch (err) {
        console.log(err);
      }
    };
    getAllOrg();
  }, []);

  useEffect(() => {
    if (selectedOrg._id) {
      let res = filterArrayWithMultipleKey(allData, ["orgId"], selectedOrg._id);
      setOrgData(res);
    }
  }, [selectedOrg]);

  return (
    <>
      <TopBar>
        {userRole() === "admin" && (
          <>
            <div className="d-flex justify-content-end">
              <div>
                <Select
                  style={{ width: "200px" }}
                  options={allOrg}
                  value={selectedOrg}
                  onChange={(e) => {
                    console.log(e.target.value);
                    if (e.target.value._id === null) {
                      setSelectedOrg({});
                      setOrgData([...allData]);
                    } else {
                      setSelectedOrg(e.target.value);
                    }
                  }}
                  optionLabel="orgName"
                  placeholder="All Organization"
                  className="p-0"
                />
              </div>
            </div>
          </>
        )}
      </TopBar>
      <div className="mainContainer">
        <br />
        <div className="card p-md-4 p-sm-3 p-2 question_main_con">
          <div className="d-flex justify-content-end mb-3">
            <div className="searchInput me-4">
              <input
                onChange={search}
                className="form-control ps-5"
                placeholder="Search with exam name"
              ></input>
              <i className="pi pi-search searchIcon"></i>
            </div>
            <Link to={"create"}>
              <button className="btn btn-Primary">
                <GrAdd />
                &nbsp;Add Paper
              </button>
            </Link>
          </div>
          <div className="questionListingMainContainer">
            {orgData.map((res, key) => {
              return (
                <Fragment key={key}>
                  <div className="main_heading">{res.examName}</div>
                  <div className="d-flex gap-5  my-5 flex-wrap">
                    {res.QuestionData.map((res2, key) => {
                      return (
                        <QuestionPaperCard
                          ind={key}
                          onDelete={getAllData}
                          {...res2}
                          orgData={orgData}
                        />
                      );
                    })}
                  </div>
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
