import React, { useEffect, useRef, useState } from "react";
import { TopBar } from "../../Components/Sidebar/Sidebar";
import Infomation from "../../Components/Assets/Infomation";
import { Input, Label } from "reactstrap";
import { useNavigate } from "react-router-dom";
import {
  addAdminOrganization,
  getAllOrganization,
} from "../../Services/Admin/Api";
import uniqid from "uniqid";
import { Dropdown } from "primereact/dropdown";
import Loader from "../../Components/Assets/Loader";
import { Select } from "../../Components/Inputs/Select";
export default function AddAdminstration() {
  const navigate = useNavigate();
  const [addAdmin, setAddAdmin] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [selectOrganization, setSelectOrganization] = useState([]);
  const [selectOrganizations, setSelectOganizations] = useState("");
  const [loading, setLoading] = useState(false);
  let adminUId = useRef("adm-" + uniqid());
  const [isError, setIsError] = useState(false);
  const [emailExixt, setEmailExit] = useState(false);
  const [uniqUserID, setUniqUserID] = useState(uniqid());
  const [emailError,setEmailError] = useState(false)
  const [contactExist,setContactExist] = useState(false)

  const handleSubmitAdminstration = async (e) => {
    e.preventDefault();
    setLoading(true);
    let adminPayload = {
      email: adminEmail.trim(),
      name: addAdmin.trim(),
      contectNo: contactNumber.trim(),
      role: "orgAdmin",
      uid: `${
        selectOrganization
          ? selectOrganization.filter((e) => e._id === selectOrganizations)[0]
              ?.orgName
          : ""
      }-${uniqUserID}`,
      orgId: selectOrganizations.trim(),
    };
    await addAdminOrganization(adminPayload)
      .then((res) => {
        // console.log(res);
        navigate("/adminstrations");
        setLoading(false);
      })
      .catch((err) => {
        // console.log(err.response.data.errormessage.includes("email"));
        // console.log(err?.response.data?.errormessage?.includes("email"));
        if (err?.response.data?.errormessage?.includes("email")) {
          setEmailExit(true);
          setLoading(false);
        } else {
          setEmailExit(false);
          setLoading(false);
        }
        if(err?.response.data?.errormessage?.includes("contectNo_1 dup key") || err?.response.data?.errormessage?.includes("This Contect Number is already used")){
          setContactExist(true)
          setLoading(false)
        }else{
          setContactExist(false)
          setLoading(false)
        }
      });
  };

  const getSelectOrganization = async () => {
    await getAllOrganization()
      .then((res) => {
        // console.log(res);
        setSelectOrganization(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getSelectOrganization();
setSelectOganizations(sessionStorage.getItem("org"))
  }, []);
  const adminDisable = () => {
    // console.log(contactNumber);
    // console.log(adminEmail);
    // console.log(selectOrganizations);

    if (
      addAdmin?.length === 0 ||
      contactNumber?.length === 0 ||
      adminEmail?.length === 0 ||
      selectOrganizations?.length === 0
    ) {
      return true;
    } else {
      if (contactNumber?.length !== 10) {
        return true;
      } else {
        if (!/\S+@\S+\.\S+/.test(adminEmail)) {
          console.log(!/\S+@\S+\.\S+/.test(adminEmail))
          return true;
        } else {
          return false;
        }
      }
    }
  };
  return (
    <>
      <TopBar></TopBar>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          handleSubmitAdminstration(e);
        }}
      >
        <div className="mainContainer">
          <div className="card p-md-4 p-sm-3 p-2">
            <h1>Add Organizational Admin</h1>
            <div>
              <Infomation text="Upon clicking the “Add Admin” button, an invitation email will be automatically dispatched to the specified email address. This email will contain system-generated credentials, enabling the user to seamlessly log in to the system."></Infomation>
            </div>
            <div>
              {emailExixt ? (
                <Infomation text="Email is already exists" type="warning" />
              ) : (
                ""
              )}
              {
                contactExist ? (
                  <Infomation text="Contact is already exists" type="warning" />
                ):(
                  ""
                )
              }
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <Label className="HelveticaMedium">Name</Label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter the Organizational Admin’s Name"
                  onChange={(e) => {
                    if (e.target.value === " ") {
                      e.target.value = "";
                    } else {
                      setAddAdmin(e.target.value);
                    }
                  }}
                  required
                />
              </div>
              <div className="col-md-6">
                <Label className="HelveticaMedium">Admin Code</Label>
                <input
                  className="form-control"
                  placeholder="EVA-101-OA-240103"
                  value={`${
                    selectOrganization
                      ? selectOrganization.filter(
                          (e) => e._id === selectOrganizations
                        )[0]?.orgName
                      : ""
                  }-${uniqUserID}`}
                  disabled
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-6">
                <Label className="HelveticaMedium">Contact Number</Label>
                <input
                  className="form-control"
                  placeholder="Enter the Organizational Admin’s Contact Number"
                  onChange={(e) => {
                    setContactNumber(e.target.value);
                    if (e.target.value.length === 10) {
                      setIsError(false);
                    } else {
                      setIsError(true);
                    }
                  }}
                  type="number"
                  required
                />
                {isError ? (
            
            // <div className="col-md-6">
              <Infomation
                text="Please Enter 10 digit contact number"
                type="warning"
              />
            // </div>
        ) : (
          ""
          )}
              </div>
              <div className="col-md-6">
                <Label className="HelveticaMedium">Email Address</Label>
                <input
                  className="form-control"
                  placeholder="Enter the Organizational Admin’s Email Address"
                  onChange={(e) => {
                    if (e.target.value === " ") {
                      e.target.value = "";
                    } else {
                      setAdminEmail(e.target.value);
                    }
                    if(!/\S+@\S+\.\S+/.test(e.target.value)){
                      setEmailError(true)
                    }else{
                      setEmailError(false)
                    }
                  }}
                  required
                />
                 {emailError ?(
              
              // <div className="col-md-6"></div>
              // <div className="col-md-6">
                <Infomation   text="Invalid email format "
                    type="warning"/>
    
            // </div>
            ):(<></>)}
              </div>
            </div>

          {/* <div className="row"> */}
            
           
       {/* </div> */}

            <div className="row mt-4">
              <div className="col-md-6">
                <Label className="HelveticaMedium">Select Organization</Label>
                {/* <Input
                  type="select"
                  className="form-control"
                  value={sessionStorage.getItem("org")}
                  onChange={(e) => {
                    setSelectOganizations(e.target.value);
                    sessionStorage.setItem("org", e.target.value);
                  }}
                >
                  <option>Select Organization</option>
                  {selectOrganization &&
                    selectOrganization.map((res, index) => (
                      <option key={index} value={res._id}>
                        {res.orgName}
                      </option>
                    ))}
                </Input> */}
                <Select options={selectOrganization}
                 optionLabel="orgName"
                 placeholder="Select organization"
                 value={selectOrganization.find(
                  (s) => s._id == sessionStorage.getItem("org")
                )}
                onChange={(e) => {
                  console.log("okokokokok");
    
                  console.log(e.value);
                  setSelectOganizations(e.value._id);
                  sessionStorage.setItem("org", e.value._id);
                }}
                />
              </div>
            </div>
            <div className="d-flex justify-content-end mt-5">
              <div className="d-flex">
                <button
                  className="btn btn-Secondary me-3"
                  onClick={() => navigate("/adminstrations")}
                  type="button"
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={adminDisable()}
                >
                  Add Admin
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Loader open={loading} />
    </>
  );
}
