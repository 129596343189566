import { jwtDecodes } from "../../Services/Admin/Api";
import { runSocket } from "./socket";
let userdata = {};
jwtDecodes().then((res) => {
  userdata = res;
  runSocket(res);
});

export const userId = () => {
  return userdata._id ? userdata._id : "";
};

export const userEmail = () => {
  return userdata.email ? userdata.email : "";
};

export const userRole = () => {
  return userdata.role ? userdata.role : "";
};

export const userOrgId = () => {
  return userdata.orgId ? userdata.orgId : "";
};

export const userUid = () => {
  return userdata.uid ? userdata.uid : "";
};
export const userInfo = () => {
  return userdata;
};
export const storeUserInfo = () => {
  jwtDecodes().then((res) => {
    userdata = res;
    runSocket(res);
  });
};
export const fetchIpAddress = async () => {
  try {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error("Error fetching IP address:", error);
  }
};
