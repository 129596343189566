import React, { useState } from "react";
import { Paginator } from 'primereact/paginator';

export const CustomPagination = ({ page, pageChange, perPageData,totalRecords }) => {

    const [first, setFirst] = useState([0, 0, 0]);
    const [rows, setRows] = useState([10, 10, 10]);


    const onPageChange = (e, index) => {
        setFirst(first.map((f, i) => (index === i ? e.first : f)));
        setRows(rows.map((r, i) => (index === i ? e.rows : r)));
    };

    const template3 = {
        layout: 'RowsPerPageDropdown PrevPageLink PageLinks NextPageLink CurrentPageReport',

        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: "100%", textAlign: 'center' }} className="mt-2">
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };



    return (
        <div>
            <Paginator template={template3} first={(page - 1) * perPageData} rows={perPageData} totalRecords={totalRecords} onPageChange={(e) => {
                pageChange(e.page + 1)
                onPageChange(e, 2)
            }} />
        </div>
    )
}
