import React, { useEffect, useState } from "react";
import { TopBar } from "../../../Components/Sidebar/Sidebar";
import TableContainer from "../../../Components/Assets/TableContainer";
import { getAllReportlistByOrgId } from "../../../Services/Admin/Api";
import { userOrgId } from "../../../Components/Assets/userData";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { loader } from "../../../utils/loader";

export default function Reports() {
  const [data, setData] = useState([]);
  const [formatData, setFormateData] = useState({});

  const [searchData, setSearchData] = useState([]);
  const [searchtext, setsearchtext] = useState("");
  const navigate = useNavigate();


  const handleSearchClick = (e) => {
    let data = [...searchData];
    let val = e.trim().toLowerCase();

    let searchName = data.filter((res) =>
      res?.userName?.toLowerCase().includes(val)
    );
    console.log(searchName);
    setData(searchName);
  };

  const getAllList = async () => {
    loader.start()
    await getAllReportlistByOrgId(userOrgId())
      .then((res) => {
        loader.stop()
        console.log(res);
        let dataReverse = [...res.data.data];
        let reverData = dataReverse.reverse();
        const organizedData = reverData.reduce((acc, curr) => {
          const date = extractDate(curr.reportTime);
          if (!acc[date]) {
              acc[date] = [];
          }
          acc[date].push(curr);
          return acc;
      }, {});
        setSearchData(reverData);
        setData(reverData);
        setFormateData(organizedData)
      })
      .catch((err) => {
        console.log(err);
        loader.stop()
      });
  };


  // const organizedData = {};

  function extractDate(reportTime) {
    const dateObj = new Date(reportTime);
    return moment(dateObj).format("DD  MMMM  YYYY");
}

// Organize data into an object with dates as keys
const organizedData = data.reduce((acc, curr) => {
    const date = extractDate(curr.reportTime);
    if (!acc[date]) {
        acc[date] = [];
    }
    acc[date].push(curr);
    return acc;
}, {});


  useEffect(() => {
    getAllList();
  }, []);
  return (
    <>
      <TopBar />
      <div className="mainContainer">
        <div className="card p-md-4 p-sm-3 p-2">
          <div className="d-flex justify-content-between">
            <h1>Reports</h1>
            <div className="searchInput w-25 me-3">
              <input
                className="form-control ps-5"
                placeholder="Search"
                onChange={(e) => {
                  handleSearchClick(e.target.value);
                }}
              ></input>
              <i className="pi pi-search searchIcon"></i>
            </div>
          </div>
          {Object.entries(formatData).map(([datas,value])=>(
<>
          <div className="mt-5">
            <p>{datas}</p>
          </div>
          {console.log(value)}
          <div className="py-2">
            <TableContainer>
              <table className="left_align_table">
                <thead>
                  <th>S.No</th>
                  <th>Evaluator Full Name</th>
                  <th>Paper Name</th>
                  <th>Reporting Reason</th>
                  <th>Report Time</th>
                </thead>
                <tbody>
                  {value.map((res, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td className="pointer" onClick={()=>{navigate(`/reports/detailReport/${res._id}`)}}>{res.userName}</td>
                      <td>{res.paperName}</td>
                      <td>{res.messages}</td>
                      <td>
                        {moment(res.reportTime).format("DD/MM/YYYY, h:mm:ss a")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </TableContainer>
          </div>
</>
          ))}
          {/* <div className="mt-5">
            <p>23 February 2024</p>
          </div>
          <div className="py-2">
            <TableContainer>
              <table className="left_align_table">
                <thead>
                  <th>S.No</th>
                  <th>Evaluator Full Name</th>
                  <th>Paper Name</th>
                  <th>Reporting Reason</th>
                  <th>Report Time</th>
                </thead>
                <tbody>
                  {data1.map((res, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{res.name}</td>
                      <td>{res.Pname}</td>
                      <td>{res.Rreason}</td>
                      <td>{res.rt}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </TableContainer>
          </div> */}
        </div>
      </div>
    </>
  );
}
