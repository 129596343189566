
import React, { useRef, useState } from 'react';
import "./style.css"
import { PiUploadSimpleLight } from 'react-icons/pi';
import { RiDeleteBin6Fill } from "react-icons/ri";
import Infomation from './Infomation';

export default function DragAndDrop({ value, onChange, acceptFiles, disabled }) {
    let inpRef = useRef(null)
  const [dataFill1, setDataFill1] = useState(false);


    let randomId = Math.random()

    let [inpvalue, setInpValue] = useState(null)

    const handleDrop = (event) => {
        event.preventDefault();
        // onChange && onChange(event.dataTransfer.files[0])
        onChangeSubmit(event.dataTransfer.files[0])
    };
    const handleDragOver = (event) => {
        event.preventDefault();
    };

    function onChangeSubmit(file) {

        if (acceptFiles.includes(file.type)) {
            onChange && onChange(file)
        } else {
            // alert(`Please upload ${acceptFiles.join(",")} file is required`)
            setDataFill1(true)

        }


    }

    return (
        <div className="card flex justify-content-center">
           

            {value?.name ?
                <div className='dragAndDrop_value_filled_con'>
                
                    {value.name}
                    <div className='delete_icon'
                        onClick={() => {
                            setInpValue(null)
                            onChange && onChange(null)
                        }}>
                         <i className="pi pi-pencil me-2 pointer" />
                    </div>
                </div>
                :
                <label
                    onDrop={() => {
                        if (!disabled) {
                            handleDrop()
                        }
                    }}
                    onDragOver={handleDragOver}
                    htmlFor={randomId}
                >
                    <div className={`main_dragAndDrop ${disabled ? "disable_draganddrop" : "enable_draganddrop"}`}>
                        <div className='upload_icon'><PiUploadSimpleLight /></div>
                        <div className='upload_text'>Click or drag pdf to this area to upload</div>
                    </div>
                </label>
            }
            <input id={randomId}
            //  value={inpvalue}
              disabled={disabled}
               onChange={(e) => {
                // onChange && onChange(e.target.files[0])
                onChangeSubmit(e.target.files[0])
                e.target.value = ""
            }} type='file' hidden />
 {dataFill1?(<>
            <div className='mb-2'>

            <Infomation type="warning" text="Please upload application/pdf file is required" />
            </div>
            </>):""}
        </div>

    )
}

