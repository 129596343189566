import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { loader } from '../../../utils/loader';
import { checkAnswerSheetTotalMarks } from '../../../Services/Admin/Api';
import { FaArrowLeft } from 'react-icons/fa6';
import TableContainer from '../../../Components/Assets/TableContainer';
import { findDiffereceBetweenTwoNumber, findDifferencePercentageEvaluatorsMarks } from '../../../utils/commonUtils';
import { AssignThirdEvaluator } from '../../Hod/AnswerSheet/AssignThirdEvaluator';

export default function OrgAdminViewSheet() {

    let { id } = useParams();
    let [answerData, setAnswerSheet] = useState([]);
    let paperId = new URLSearchParams(window.location.search).get("paperId");
    let folderName = new URLSearchParams(window.location.search).get(
      "folderName"
    );
    let [isNodata, setIsNodata] = useState(false);
    let [totalMarks, setTotalMarks] = useState(0);
  const [totalMarksData,setTotalMarksData] = useState([])

  
  
    let location = useLocation();
    let pdfName = location?.state;
    console.log(pdfName);
  
    let [paperData, setPaperData] = useState({
      examName: "",
      papeName: "",
      paperId: null,
    });
  
    useEffect(() => {
      getAnswerSheetData();
    }, []);
  
    async function getAnswerSheetData() {
      try {
        loader.start();
        setIsNodata(false);
        let body = {
          paper_id: paperId,
        };
        let res = await checkAnswerSheetTotalMarks(id, body);
        console.log(res.data.data);
        if (res.data.data.result.length == 0) {
          setIsNodata(true);
        }
        let data = res.data.data.result.sort(
          (e, b) => e?.evaluator_number - b?.evaluator_number
        )
        let resultData = data.map((e)=>{
          let avg = finalMarksFunction(e.evaluators,findDifferencePercentageEvaluatorsMarks(
            parseInt(e.evaluators[0].marks),
            parseInt(e.evaluators[1].marks),
  
          ));
          return{
            ...e,
            ...{finalMark:avg}
          }
        }) 
        setAnswerSheet(
          resultData 
        );
        setPaperData({
          paperName: res.data.data.paper[0].paperName,
          examName: res.data.data.paper[0].examName,
          paperId: res.data.data.paper[0]._id,
          orgId: res.data.data.paper[0].orgId,
        });
        loader.stop();
      } catch (err) {
        loader.stop();
        console.log(err);
      }
    }
  
    let navigate = useNavigate();
  
    function backUrl() {
      paperData.paperId &&
        navigate(
          `/orgAdminAnswerSheet/orgAdminAnswerfile/${
            paperData.paperId
          }?folderName=${encodeURIComponent(folderName)}&orgId=${paperData.orgId}`
        );
    }
  
    const finalMarksFunction = (result,mark_avg) => {
      console.log(result,"adsaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa")
    console.log("")
    
    if(result.length === 3){
        console.log("rrrrrrrrrrrrrrrrrrrrrrrrrrrrrr")
        if(result[2].status === "submitted"){
            let marks = result.map(e=>{return  e.marks})
            console.log(marks)
            let marksSorting = marks.sort().reverse()
            let average  = (marksSorting[0] + marksSorting[1])/2
            console.log(average)
            let totalMarkss =totalMarks + average
            setTotalMarksData((totalMarksData)=>[...totalMarksData,totalMarkss])
  
  
          setTotalMarks(totalMarks + average)
          console.log(totalMarks)
            return average
  
        }else{
          return "pending"
        }
      }else{
        if(mark_avg <= 12.5){
        let average  = (result[0].marks + result[1].marks)/2
        console.log(average,"asdasdasdasdasdadadasdasdasdas")
        let totalMarkss = totalMarks + average
        setTotalMarks(totalMarkss)
        setTotalMarksData((totalMarksData)=>[...totalMarksData,totalMarkss])
        
        return average
      }else{
    return '-'
      }
      }
  
    }
  
  return (
    <div>
          <div className="mainContainer">
        <div className="card p-md-4 p-sm-3 p-2">
          {/* <BackButton link={backUrl()} /> */}

          <div className="back_btn_con" onClick={backUrl}>
            <FaArrowLeft />
            &nbsp;Back
          </div>
          <br />
          <div>
            <h1>{folderName}</h1>
            <p>
              {paperData?.paperName} - {paperData?.examName}
            </p>
          </div>
          <div className="mt-3"></div>
          <div className="d-flex justify-content-between mt-4">
            <h1>{pdfName}</h1>
            <p>Total Marks:{totalMarksData.reduce((acc, curr) => acc + curr, 0) }</p>
          </div>

          <div className="py-5">
            {isNodata ? (
              <div>No Data Available</div>
            ) : (
              <TableContainer>
                <table className="left_align_table">
                  <thead>
                    <th>Ques:</th>
                    <th>Evaluator 1</th>
                    <th>Marks</th>
                    <th>Evaluator 2</th>
                    <th>Marks</th>
                    <th>Avg. Marks</th>
                    <th>Difference</th>
                    <th>Evaluator 3</th>
                    <th>Marks</th>
                    <th className="align-items-center ">
                      Final Marks<i className="pi pi-info-circle ms-2"></i>
                    </th>
                  </thead>
                  <tbody>
                    {answerData?.map((res, index) => (
                      <tr key={index}>
                        <td>{res?.question_number}</td>
                        <td>{res?.evaluators?.[0]?.name}</td>
                        <td>{res?.evaluators?.[0]?.marks}</td>
                        <td>{res?.evaluators?.[1]?.name}</td>
                        <td>{res?.evaluators?.[1]?.marks}</td>
                        <td>
                          {res.evaluators?.[0]?.marks &&
                            findDifferencePercentageEvaluatorsMarks(
                              parseInt(res.evaluators[0].marks),
                              parseInt(res.evaluators[1].marks)
                            )}{" "}
                          %
                        </td>
                        <td>
                          {findDiffereceBetweenTwoNumber(
                            res.evaluators[1].marks,
                            res.evaluators[0].marks
                          )}
                        </td>
                        <td>
                        {findDifferencePercentageEvaluatorsMarks(parseInt(res.evaluators[0].marks), parseInt(res.evaluators[1].marks)) > 12.5 ?
                            <>
                            {console.log(res?.evaluators?.[2]?.name)}
                              {res?.evaluators?.[2]?.name ||
                                <AssignThirdEvaluator answerSheetId={id} answerSheetData={res} paperData={paperData} test={getAnswerSheetData}/>}
                            </>
                            : <span>---</span>}
                        </td>
                        <td>
                          {res?.evaluators[2]?.status === "submitted" &&
                          res?.evaluators[2]?.marks
                            ? res?.evaluators[2]?.marks
                            : "--"}
                        </td>

                        <td>{res.finalMark}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </TableContainer>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
