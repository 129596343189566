
import React, { useEffect, useId, useRef, useState } from "react";
import style from "./style.module.css";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import { fullScreenPlugin } from "@react-pdf-viewer/full-screen";
import { Select } from "../../../Components/Inputs/Select";
import { MdEditDocument } from "react-icons/md";
import io from "socket.io-client";

import Peer from "simple-peer";
import {
  userId,
  userInfo,
  userOrgId,
  userRole,
} from "../../../Components/Assets/userData";
import { baseUrl } from "../../../Services/EndPoint";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addEvaluationRecording,
  getEvaluatorAllSheetsData,
  getQuestionByEvaluatorId,
  submitAnswerSheetResult,
  updateAnswerSheetDraftResult,
} from "../../../Services/Admin/EvaluatorApi";
import {
  getPaperById,
  uploadMultipleFiles,
  evaluationEnd,
  evaluationStart,
  getOrrganizationById,
} from "../../../Services/Admin/Api";
import { ViewPdfDialog } from "./ViewPdfDialog";
import { CustomPagination } from "../../../Components/Assets/CustomPagination";
import { loader } from "../../../utils/loader";
import styled from "styled-components";
import moment from "moment";
import { Dialog } from "primereact/dialog";
import Infomation from "../../../Components/Assets/Infomation";
import { startEvaluatorNotification, stopEvaluatingNotification } from "../../../Components/Assets/socket";
import { Timer } from "./Timer";
import { OverlayPanel } from "primereact/overlaypanel";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { GetUserMediaDetails } from "./GetUserMediaDetails";
const videoConstraints = {
  height: window.innerHeight / 2,
  width: window.innerWidth / 2,
};

const StyledVideo = styled.video`
  height: 100%;
  width: 100%;
`;
// const { desktopCapturer, ipcRenderer } = window.require("electron");

export default function EvaluationSheet() {
  const location = useLocation();
  let evalutionPaper = location?.state.examName;
  let PaperNameData = location?.state.paperName;

  console.log(evalutionPaper);
  let paperId = new URLSearchParams(window.location.search).get("paperId");
  let language = new URLSearchParams(window.location.search).get("language");
  let [allSheetsCount, setAllSheetsCount] = useState(0);
  let [activeAnswerSheet, setActiveAnswerSheet] = useState({});
  let [allAnswerSheets, setAllAnswersSheets] = useState([]);
  let [module, setModule] = useState(false);
  let [isTimerStartsEver, setIsTimerStartsEver] = useState(false)

  let [device, setDevice] = useState([]);
  let [stream, setStream] = useState([]);

  const video1 = useRef({ srcObject: null });
  const video2 = useRef({ srcObject: null });
  const screen = useRef({ srcObject: null });

  let [page, setPage] = useState(1);
  let per_page_data = 20;

  const navigate = useNavigate();
  const zoomPluginInstance = zoomPlugin();
  let [allSheetsDataOpen, setAllSheetsDataOpen] = useState(true);
  let [activeIndex, setActiveIndex] = useState(0);

  let { ZoomIn, ZoomOut, ZoomPopover } = zoomPluginInstance;
  let [paperData, setPaperData] = useState({});
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const fullScreenPluginInstance = fullScreenPlugin();
  const [organizationName, setOrganizationName] = useState()
  const { EnterFullScreen } = fullScreenPluginInstance
  let { GoToNextPage } = pageNavigationPluginInstance

  const evaluatorStart = async () => {
    loader.start();
    await evaluationStart(userId(), {
      startTime: new Date(),
      paperName: evalutionPaper,
      organization_name: organizationName?.orgName ? organizationName?.orgName : "",
      paper_name: PaperNameData
      // orgAdminName:userInfo().name,
      // orgAdminNameId:userId(),
    })
      .then((res) => {
        console.log(res);
        loader.stop();
        console.log(activeAnswerSheet?.evaluator_status?.status)
        if (activeAnswerSheet?.evaluator_status?.status !== "submitted") {
          startTimer()
        }



        startEvaluatorNotification()
        setModule(false);
      })
      .catch((err) => {
        console.log(err);
        loader.stop();
      });
  };

  let startTime = new Date().toISOString();

  let [evaluationData, setEvaluationData] = useState({
    marks: "",
    remarks: "",
  });

  let [bottomBarOpen, setBottomBarOpen] = useState(true);
  const [peers, setPeers] = useState([]);
  let questionId = useRef("");
  const socketRef = io(baseUrl, {
    auth: {
      id: userId(),
      role: userRole(),
    },
  });
  const userVideo = useRef({ srcObject: "" });
  const peersRef = useRef([]);
  const roomID = "UqwhbsvaybxjzkU12132Y";

  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [screenRecorder, setScreenRecorder] = useState(null);
  let videoAndScreenBlobData = useRef({
    videoBlob: null,
    screenBlob: null,
  });

  const endEvalution = async () => {
    loader.start();
    await evaluationEnd(userId(), {
      endTime: new Date(),
      organization_name: organizationName?.orgName ? organizationName?.orgName : "",
      paper_name: PaperNameData,
      exam_name: evalutionPaper,
    })
      .then((res) => {
        // console.log(res)
        stopEvaluatingNotification()
        loader.stop();
      })
      .catch((err) => {
        console.log(err);
        loader.stop();
      });
  };
  const startVideo = async (num) => {
    try {
      let mediaRecorderInstance;
      let videoRecordedChunks = [];
      navigator.mediaDevices

        .getUserMedia({
          video: { deviceId: device[num].deviceId },
          audio: true,
        })
        .then((stream) => {
          console.log("getUserMedia success:", stream);

          if (num === 0) {
            video1.current.srcObject = stream;
          } else {
            video2.current.srcObject = stream;
          }

          let room = {
            roomID,
            name: userInfo().name,
            role: "evaluator",
            orgId: userOrgId(),
            paperId: paperId,
            type: device[num].label.includes("Integrated") ? "Front" : "Back",
          };
          socketRef.emit("join room", room);

          socketRef.on("user joined", (payload) => {
            console.log("User joined:", payload);
            const peer = addPeer(payload.signal, payload.callerID, stream);
            peersRef.current.push({
              peerID: payload.callerID,
              peer,
            });
          });

          mediaRecorderInstance = new MediaRecorder(stream);
          setMediaRecorder(mediaRecorderInstance);
          mediaRecorderInstance.ondataavailable = (e) => {
            if (e.data.size > 0) {
              videoRecordedChunks.push(e.data);
            }
          };
          mediaRecorderInstance.onstop = () => {
            videoAndScreenBlobData.current.videoBlob = new File(
              [new Blob(videoRecordedChunks, { type: "video/webm" })],
              `${Math.random() + Math.random()}`,
              { type: "video/webm", lastModified: Date.now() }
            );

            submitRecording();
          };
          mediaRecorderInstance.start();
        })
        .catch((error) => {
          console.error("getUserMedia error:", error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const deviceData = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter(
      (device) => device.kind === "videoinput"
    );
    console.log(videoDevices);
    setDevice(videoDevices);
    setModule(true);
  };
  // useEffect(() => {
  //   ipcRenderer.send("start-screen-share");
  //   ipcRenderer.on("screen-share-stream", async (event, sources) => {
  //     const streams = await navigator.mediaDevices.getUserMedia({
  //       audio: false,
  //       video: {
  //         mandatory: {
  //           chromeMediaSource: "desktop",
  //           chromeMediaSourceId: sources[0].id,
  //         },
  //       },
  //     });
  //     console.log(streams);
  //     setStream(streams);
  //   });
  //   deviceData();

  //   startScreenRecording()
  // }, []);
  const processButtonDisable = () => {
    if (
      video1?.current?.srcObject &&
      video2?.current?.srcObject &&
      screen?.current?.srcObject
    ) {
      return false;
    } else {
      return true;
    }
  };

  let screenBlobData = useRef([]);
  let screenMediaRecorder = useRef(null)

  async function startScreenRecording() {
    let screenRecordedChunks = [];
    try {
      const recordStream = stream;

      let room = {
        roomID,
        name: userInfo().name,
        role: "evaluator",
        orgId: userOrgId(),
        paperId: paperId,
        type: "Screen",
      };

      socketRef.emit("join room", room);

      socketRef.on("user joined", (payload) => {
        console.log("User joined:", payload);
        const peer = addPeer(payload.signal, payload.callerID, recordStream);
        peersRef.current.push({
          peerID: payload.callerID,
          peer,
        });
      });
      if (screen.current) {
        screen.current.srcObject = recordStream;
      }

      screenMediaRecorder.current = new MediaRecorder(recordStream);

      setScreenRecorder(screenMediaRecorder.current);

      screenMediaRecorder.current.ondataavailable = (e) => {
        if (e.data.size > 0) {
          screenBlobData.current.push(e.data)
          screenRecordedChunks.push(e.data);
        }
      };

      screenMediaRecorder.current.onstop = () => {

        let rr = new File(
          [new Blob(screenBlobData.current, { type: "video/webm" })],
          `${Math.random() + Math.random()}`,
          { type: "video/webm", lastModified: Date.now() }
        );
        console.log(rr)
        // submitRecording();
        recordStream.getTracks().forEach((track) => track.stop());
      };
      screenMediaRecorder.current.start();

    } catch (err) {
      console.log(err);
    }
  }

  async function stopScreen() {
    if (screenRecorder && screenRecorder.state === "recording") {
      screenRecorder.stop();
    }

  }
  function startScreen() {
    startScreenRecording()
  }


  async function submitRecording() {
    try {
      if (Object.values(videoAndScreenBlobData.current).every((e) => e)) {
        loader.start();
        let res = await uploadMultipleFiles([
          videoAndScreenBlobData.current.screenBlob,
          videoAndScreenBlobData.current.videoBlob,
        ]);

        let screenData = res.data.data[0];
        let videoData = res.data.data[1];
        let duration = moment.duration(moment().diff(startTime));
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();
        console.log(seconds);

        let postData = {
          evaluatorId: userId(),
          question_paper_id: paperId,
          start_time: startTime,
          end_time: new Date().toISOString(),
          duration: `${hours}hr:${minutes}min:${seconds}sec`,
          media_data: {
            screenFileName: screenData.fileName,
            screenFileUrl: screenData.fileUrl,
            cameraFileName: videoData.fileName,
            cameraFileUrl: videoData.fileUrl,
          },
        };
        let res2 = await addEvaluationRecording(postData);
        loader.stop();
        navigate(
          `/assignedQuestions/assignedQuestionsView/${questionId.current}?paperId=${paperId}&language=${language}`
        );
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function stopEvaluation() {
    socketRef.emit("roomOff", { roomID, orgId: userOrgId(), userId: userId() });
    endEvalution();

    // if (video1.current && video1.current.srcObject) {
    //   const tracks = await video1.current.srcObject.getTracks();
    //   tracks.forEach((track) => track.stop());
    //   video1.current.srcObject = null;
    // }
    // if (video2.current && video2.current.srcObject) {
    //   const tracks = await video2.current.srcObject.getTracks();
    //   tracks.forEach((track) => track.stop());
    //   video2.current.srcObject = null;
    // }

    // if (screen.current && screen.current.srcObject) {
    //   const tracks = await screen.current.srcObject.getTracks();
    //   tracks.forEach((track) => track.stop());
    //   screen.current.srcObject = null;
    // }

    // if (mediaRecorder && mediaRecorder.state === "recording") {
    //   mediaRecorder.stop();
    // }
    // if (screenRecorder && screenRecorder.state === "recording") {
    //   screenRecorder.stop();
    // }

    // navigate(
    //   `/assignedQuestions/assignedQuestionsView/${questionId.current}?paperId=${paperId}&language=${language}`
    // );

  }

  function addPeer(incomingSignal, callerID, stream) {
    try {
      const peer = new Peer({
        initiator: false,
        trickle: false,
        stream,
        config: {
          iceServers: [
            {
                url: "turn:192.158.29.39:3478?transport=udp",
                credential: "JZEOEt2V3Qb0y27GRntt2u2PAYA=",
                username: "28224511:1379330808",
              },
              {
                url: "turn:192.158.29.39:3478?transport=tcp",
                credential: "JZEOEt2V3Qb0y27GRntt2u2PAYA=",
                username: "28224511:1379330808",
              }
          ],
        },
      });

      peer.on("signal", (signal) => {
        console.log(signal, "signal");

        socketRef.emit("returning signal", { signal, callerID });
      });

      peer.signal(incomingSignal);

      return peer;
    } catch (error) {
      console.error("Error add peer:", error);
      // Handle error (e.g., fallback mechanism, user notification)
    }
  }

  let timer = useRef(0);

  const getOrgnizationNameById = async (id, status) => {
    await getOrrganizationById(id).then((res) => {
      console.log(res.data.data, "pppppppppppppppppppppp;;;;;;;;;;;;;;;;;;;;;;;;")
      timer.current = res.data.data.timer;
      // if (status == "notSubmitted") {
      //   startTimer()
      // } else {
      //   setIsTimeCompleted(true);
      // }


      setOrganizationName(res.data.data)
    }).catch((err) => {
      console.log(err)
    })
  }




  async function getAnswerSheet() {
    try {
      let evaluator_id = userId();
      let body = {
        evaluator_id,
        language,
        check: "all",
        current_page: page,
        page_size: per_page_data,
      };
      let res = await getEvaluatorAllSheetsData(paperId, body);
      console.log(res.data, "plplplplplplplplplplplplplplpl");
      getOrgnizationNameById(res.data.data[0].orgId, res?.data?.data?.[0]?.evaluator_status?.status)
      setAllSheetsCount(res.data.totalCount?.[0].count || 1);

      setActiveAnswerSheet(res.data.data[0]);

      if (res.data.data[0].evaluator_status.status == "notSubmitted" && isTimerStartsEver) {
        startTimer()
      }

      // else {
      //   setIsTimeCompleted(true);
      // }


      if (res?.data?.data[0]?.evaluator_status?.status) {
        setEvaluationData({
          marks: res?.data?.data[0].evaluator_status.marks,
          remarks: res?.data?.data[0].evaluator_status.remarks,
        });
      }
      setAllAnswersSheets(res.data.data);
      // setAnswerSheet(res.data.data)
    } catch (err) {
      console.log(err);
    }
  }

  async function getPaperDataById() {
    try {
      let userID = userId();
      let body = {
        evaluator_id: userID,
      };
      let res = await getQuestionByEvaluatorId(paperId, body);
      // let res = await getPaperById(paperId)
      console.log(res.data.data);
      questionId.current = res.data.data[0]._id;
      console.log(res.data.data, "][][][][][][][][][][][]");
      setPaperData(res.data.data[0]);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    setAllAnswersSheets([]);
    setActiveIndex(0);
    getAnswerSheet();
  }, [page]);

  useEffect(() => {
    getPaperDataById();
  }, []);

  function sheetClick(e) {
    setEvaluationData({
      marks: "",
      remarks: "",
    });
    setActiveIndex(e);
    setActiveAnswerSheet(allAnswerSheets[e]);

    if (allAnswerSheets[e].evaluator_status.status !== "submitted") {
      startTimer()
    }
    if (allAnswerSheets[e].evaluator_status.status == "submitted") {
    }

    if (
      allAnswerSheets[e]?.evaluator_status?.marks &&
      allAnswerSheets[e]?.evaluator_status?.remarks
    ) {
      setEvaluationData({
        marks: allAnswerSheets[e].evaluator_status.marks,
        remarks: allAnswerSheets[e].evaluator_status.remarks,
      });
    }
  }

  async function submitAnswerSheet(status) {
    console.log(activeAnswerSheet);

    let evaluator_id = userId();
    let answerSheetId = activeAnswerSheet._id;
    let evaluator_number = 1;

    if (paperData?.assignEvaluator1 === evaluator_id) {
      evaluator_number = 1;
    }
    if (paperData?.assignEvaluator2 === evaluator_id) {
      evaluator_number = 2;
    }

    let body = {
      status,
      evaluator_id: evaluator_id,
      question_id: paperData._id,
      marks: parseInt(evaluationData.marks),
      remarks: evaluationData.remarks,
      evaluator_number,
      question_number: paperData.quesNo,
    };
    // console.log(status)
    if (activeAnswerSheet.evaluator_status.status === "notSubmitted") {
      if (activeAnswerSheet.evaluator_status.evaluated_id) {
        body.dataId =
          activeAnswerSheet.evaluator_status.evaluated_id ||
          activeAnswerSheet.evaluator_status._id;
        updateSheetAgain(answerSheetId, body);
      } else {
        submitEvaluationSheet(answerSheetId, body);
      }
    } else {
      body.dataId =
        activeAnswerSheet.evaluator_status.evaluated_id ||
        activeAnswerSheet.evaluator_status._id;
      updateSheetAgain(answerSheetId, body);
    }
  }

  async function submitEvaluationSheet(id, bodyData) {
    try {
      loader.start();
      let res = await submitAnswerSheetResult(id, bodyData);
      console.log(res.data.data);
      setAllAnswersSheets((pre) => {
        pre[activeIndex].evaluator_status = res.data.data;
        return [...pre];
      });
      openNextAnswerSheet();
      loader.stop();
    } catch (err) {
      loader.stop();
      console.log(err);
    }
  }

  function openNextAnswerSheet() {
    if (allAnswerSheets.length - 1 == activeIndex) {
      setPage(1);
      return;
    }
    if (activeIndex == per_page_data - 1) {
      setPage((pre) => pre + 1);
    } else {
      setEvaluationData({
        marks: allAnswerSheets[activeIndex + 1].evaluator_status.marks,
        remarks: allAnswerSheets[activeIndex + 1].evaluator_status.remarks,
      });
      setActiveAnswerSheet(allAnswerSheets[activeIndex + 1]);

      if (allAnswerSheets[activeIndex + 1].evaluator_status.status !== "submitted") {
        resetTimer()
        setTimeout(() => {
          startTimer()
        }, 100);
      }

      setActiveIndex((ind) => ind + 1);
    }
  }

  async function updateSheetAgain(id, body) {
    try {
      loader.start();
      let res = await updateAnswerSheetDraftResult(id, body);
      let obj = {
        evaluated_id: res.data.data.evaluator_id,
        marks: res.data.data.marks,
        remarks: res.data.data.remarks,
        status: res.data.data.status,
      };
      openNextAnswerSheet();
      setAllAnswersSheets((pre) => {
        pre[activeIndex].evaluator_status = {
          ...pre[activeIndex].evaluator_status,
          ...obj,
        };
        return [...pre];
      });
      loader.stop();
    } catch (err) {
      loader.stop();
      console.log(err);
    }
  };

  const op = useRef(null);
  const questionOpRef = useRef(null);


  let [isTimerStart, setIsTimerStart] = useState(false);
  let [isTimePause, setIsTimePause] = useState(false);
  let [isResetTimer, setIsResetTimer] = useState(false);

  function startTimer() {
    setIsTimerStart(true);
    setIsTimePause(false);
    setIsResetTimer(false);
  }

  function pauseTimer() {
    setIsTimePause(true);
    setIsTimerStart(false)
  }

  function resetTimer() {
    setIsResetTimer(true);
    setIsTimerStart(false)
    setIsTimePause(false);
  }

  function TimerComplete() {
    setIsTimerStart(false)
    setIsTimePause(false)
    setIsResetTimer(false)
    console.log("commmmmmmmmmmmmmcommmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm")
    // resetTimer()
  }

  return (
    <>
      {/* <Dialog
        visible={module}
        modal
        style={{ width: "80rem", height: "auto" }}
        onHide={() => setModule(true)}
        closable={false}
        draggable={false}
      >
        <div className="d-flex ms-5">
          <Infomation
            type="warning"
            text="Permission Required: To proceed with the evaluation process, kindly grant access to the front camera, back camera, and screen sharing."
          />
        </div>
        <div className=" d-flex justify-content-around mt-3">
          <div>
            <video
              className="border"
              style={{}}
              width="320"
              height="240"
              playsInline
              autoPlay
              muted
              ref={video1}
            />
            <br />
            <button
              onClick={() => startVideo(0)}
              className="btn btn-secondary mt-3"
            >
              {device[0]?.label.includes("Integrated")
                ? "Front Camera"
                : "Back Camera"}
            </button>
          </div>
          <div className="">
            <video
              className="border"
              width="320"
              height="240"
              playsInline
              autoPlay
              muted
              ref={video2}
            />
            <br />
            <button
              onClick={() => startVideo(1)}
              className="btn btn-secondary mt-3"
            >
              {device[1]?.label.includes("Integrated")
                ? "Front Camera"
                : "Back Camera"}
            </button>
          </div>

          <div>
            <video
              className="border"
              width="320"
              height="240"
              playsInline
              autoPlay
              muted
              ref={screen}
            />
            <br />
            <button
              className="btn btn-secondary mt-3"
              onClick={() => startScreenRecording()}
            >
              Start Screen
            </button>
          </div>
        </div>
        <div className="d-flex mt-5 ms-4 ps-3">
          <button
            className="btn btn-primary"
            disabled={processButtonDisable() && activeAnswerSheet?.evaluator_status?.status}
            onClick={() => {
              // setModule(false);
              resetTimer()
              setIsTimerStartsEver(true)
              evaluatorStart();
            }}
          >
            Proceed to Evaluation
          </button>
        </div>
      </Dialog> */}


      <div className={`${style.sheetContainer}`}>

        <div>
          <div className={style.top_bar_main_container}>
            <div className="d-flex w-100 align-items-center justify-content-between px-4">
              <div>
                <div>E-V</div>
              </div>
              <div className="d-flex gap-3 align-items-center">


                <button className={`${style.evaluation_ans_btn}`} onClick={(e) => questionOpRef.current.toggle(e)}>
                  <div className="d-flex gap-2 align-items-center">
                    <div className="fs-2">
                      <MdEditDocument />
                    </div>
                    <div>
                      View Question
                    </div>
                  </div>
                </button>

                <OverlayPanel ref={questionOpRef} style={{ width: "80%", margin: "auto", maxHeight: "80vh", overflow: "auto" }}>
                  <div className="text-end"><i className="pi pi-times pointer" onClick={() => questionOpRef.current.hide()} /></div>
                  <div style={{ width: "80%" }} className={style.question_container}>
                    {paperData?.question ? (
                      <div className={style.question}>
                        <div
                          dangerouslySetInnerHTML={{ __html: paperData?.question }}
                        />
                      </div>
                    ) : null}
                  </div>
                </OverlayPanel>

                <ViewPdfDialog
                  title="Question Paper"
                  pdfUrl={paperData?.paperData?.[0]?.question_paper_link}>
                  <button className={`${style.evaluation_ans_btn}`}>
                    <div className="d-flex gap-2 align-items-center">
                      <div className="fs-2">
                        <MdEditDocument />
                      </div>
                      <div>
                        Question Paper
                      </div>
                    </div>
                  </button>
                </ViewPdfDialog>

                <ViewPdfDialog
                  title="Answer Key"
                  pdfUrl={paperData?.paperData?.[0]?.answerKey_link}
                >
                  <button className={`${style.evaluation_ans_btn}`}>
                    <div className="d-flex gap-2 align-items-center">
                      <div className="fs-2">
                        <MdEditDocument />
                      </div>
                      <div>
                        Answer Key
                      </div>
                    </div>
                  </button>
                </ViewPdfDialog>

              </div>
              <div className="d-flex align-items-center gap-3">
                <Select className={style.reportIssue_dropdwn} placeholder="Report issue" />


                {/* <button
                  className={`${style.evaluation_action_btn}`}
                  onClick={() => {
                    stopEvaluation();
                  }}
                >
                  Stop Evaluation
                </button> */}

                <GetUserMediaDetails
                  resetTimer={resetTimer}
                  setIsTimerStartsEver={setIsTimerStartsEver}
                  evaluatorStart={evaluatorStart}
                  paperData={paperData}
                  questionId={questionId.current}
                  endEvalution={endEvalution}


                />



              </div>
            </div>
          </div>


          {/* <div className="row">
              <div className="col-8">
                <div className="d-flex justify-content-between">
                 
                  <div className="subHeading">
                    Question {paperData?.quesNo || "--"}
                  </div>
                  <div>Maximum Marks - 05</div>
                </div>
                {paperData?.question ? (
                  <div className={style.question}>
                    <div
                      dangerouslySetInnerHTML={{ __html: paperData?.question }}
                    />
                  </div>
                ) : null}
              </div>
              <div className="col-4 d-flex gap-3">
                <div>
                  <div className="text-center">Timer</div>

                  {timer.current !== 0 && <Timer isTimeStart={isTimeStart} time={timer.current} />}
                  <button onClick={()=>setIsTimeStart(true)}>Start</button>
                </div>

                <div className="d-flex flex-column w-100">
                  <div className="h-100 d-flex justify-content-center">
                    <div className={`${style.info}`}>
                      <div className="text-center">Sheet Status</div>

                      <div className="d-flex  justify-content-center">
                        <div>
                          <div className="d-flex mt-2">
                            <div
                              className={`${style.green} ${style.ebox}`}
                            ></div>
                            <div className="ms-2">Evaluated</div>
                          </div>
                          <div className="d-flex mt-2">
                            <div
                              className={`${style.yellow} ${style.ebox}`}
                            ></div>
                            <div className="ms-2">Draft</div>
                          </div>
                          <div className="d-flex mt-2">
                            <div
                              className={`${style.blue} ${style.ebox}`}
                            ></div>
                            <div className="ms-2">Not Evaluated</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`text-center ${style.sheets_record_arrow_con}`}
                  >
                    <div
                      onClick={() => setAllSheetsDataOpen(!allSheetsDataOpen)}
                    >
                      <i className="pi pi-angle-down fs-3 pointer" />
                    </div>

                    <div
                      className={`${allSheetsDataOpen
                        ? style.popover_hidden
                        : style.popover_show
                        } ${style.allSheets_num_con}`}
                    >
                      <div className={`p-3`}>
                        <div className="text-end">
                          <i
                            onClick={() => setAllSheetsDataOpen(true)}
                            className="pointer pi pi-times-circle"
                          />
                        </div>
                        <div className={`${style.allSheets_grid_con}`}>
                          {allAnswerSheets?.map((res, ind) => {
                            return (
                              <div
                                onClick={() => sheetClick(ind)}
                                className={`${style.num_item}
                               ${res?.evaluator_status?.status === "submitted"
                                    ? style.green
                                    : ""
                                  }
                               ${res?.evaluator_status?.status === "draft"
                                    ? style.yellow
                                    : ""
                                  }
                               ${res?.evaluator_status?.status ===
                                    "notSubmitted"
                                    ? style.blue
                                    : ""
                                  }
                                pointer ${activeIndex === ind ? style.active_sheet : ""
                                  }`}
                              >
                                {(page - 1) * per_page_data + ind + 1}
                              </div>
                            );
                          })}
                        </div>
                        <div>
                          <br />
                          <CustomPagination
                            pageChange={setPage}
                            page={page}
                            perPageData={per_page_data}
                            totalRecords={allSheetsCount}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

          <div className={style.main_body_container}>
            <div className={style.left_container}>

              <div className="subHeading py-2">
                Answer Sheet ({activeAnswerSheet?.original_name})
              </div>
              <div className={style.pdf_container}>

                {activeAnswerSheet?.pdf_url ? (
                  <iframe
                    className="w-100 h-100"
                    src={`${activeAnswerSheet?.pdf_url}#toolbar=0`}
                  ></iframe>
                ) : null}
              </div>
            </div>

            <div className={`bg-white p-4 ${style.right_container}`}>

              <div className="text-center">Timer</div>

              <div className="d-flex justify-content-center mt-2">
                {/* {timer.current && <Timer onTimerStop={stopTimer} isTimeStart={isTimeStart} time={timer.current} />} */}
                {timer.current && <Timer
                  pauseTimer={isTimePause}
                  resetTimer={isResetTimer}
                  time={timer.current}
                  startTimer={isTimerStart}
                  onTimerComplete={TimerComplete}
                />}
              </div>

              <br />
              <div className={style.sheet_tracker_con}>
                <div className="text-center">Sheet Tracker</div>

                <div className="d-flex  justify-content-center">
                  <div>
                    <div className="d-flex mt-2">
                      <div className={`${style.blue} ${style.ebox}`}></div>
                      <div className="ms-2">Not Evaluated</div>
                    </div>
                    <div className="d-flex mt-2">
                      <div className={`${style.green} ${style.ebox}`}></div>
                      <div className="ms-2">Evaluated</div>
                    </div>
                    <div className="d-flex mt-2">
                      <div className={`${style.yellow} ${style.ebox}`}></div>
                      <div className="ms-2">Draft</div>
                    </div>

                    <div onClick={(e) => op.current.toggle(e)} className="text-center pointer text-primary mt-2">View more</div>
                    <OverlayPanel ref={op}>
                      <div className="text-end"><i onClick={() => op.current.hide()} className="pi pi-times pointer pt-1 pe-1" /></div>
                      <div className={`${style.allSheets_grid_con}`}>
                        {allAnswerSheets?.map((res, ind) => {
                          return (
                            <div
                              onClick={() => {

                                if (ind !== activeIndex && !isTimerStart) {
                                  sheetClick(ind)
                                }
                              }}
                              className={`
                               ${style.num_item}
                               ${res?.evaluator_status?.status === "submitted" ? style.green : ""}
                               ${res?.evaluator_status?.status === "draft" ? style.yellow : ""}
                               ${res?.evaluator_status?.status === "notSubmitted" ? style.blue : ""}
                               ${activeIndex === ind ? style.active_sheet : ""}
                               pointer
                               `}
                            >
                              {(page - 1) * per_page_data + ind + 1}
                            </div>
                          );
                        })}
                      </div>
                      <div>
                        <br />
                        <CustomPagination
                          pageChange={(e) => {
                            if (!isTimerStart) {
                              setPage(e)
                            }
                          }}
                          page={page}
                          perPageData={per_page_data}
                          totalRecords={allSheetsCount}
                        />
                      </div>
                    </OverlayPanel>
                  </div>
                </div>
              </div>

              <br />
              <label>Marks Given</label>
              <div className={style.marks_con}>
                <div className="d-flex gap-1 justify-content-center">
                  <input
                    placeholder="--"
                    type="number"
                    min={0}
                    max={paperData?.maxMarks}
                    value={evaluationData.marks || ""}
                    disabled={
                      activeAnswerSheet?.evaluator_status?.status ==
                      "submitted" || !paperData?.maxMarks
                    }
                    onChange={(e) => {
                      
                      const newValue = parseFloat(e.target.value);
                      console.log(newValue)
                      if ((!isNaN(newValue) && newValue <= paperData?.maxMarks) || e.target.value == "" || newValue === 0) {
                        console.log(newValue, "inside inside inside inside inside")
                        setEvaluationData((pre) => ({
                          ...pre,
                          marks: newValue,
                        }));

                      }


                    }}
                  />
                  <div>/</div>
                  <div>{paperData?.maxMarks}</div>
                </div>
              </div>

              <br />
              <label>Remarks</label>
              <textarea
                className="form-control"
                rows={6}
                placeholder="Enter remarks herer..."
                disabled={
                  activeAnswerSheet?.evaluator_status?.status ==
                  "submitted"
                }
                onChange={(e) => {
                  setEvaluationData((pre) => ({
                    ...pre,
                    remarks: e.target.value,
                  }));
                }}
                value={evaluationData.remarks || ""}
              ></textarea>
              <br />

              <div>
                <button
                  className="btn border w-100"
                  disabled={
                    activeAnswerSheet?.evaluator_status?.status ==
                    "submitted" || !evaluationData.marks ||
                    !evaluationData.remarks
                  }
                  onClick={() => submitAnswerSheet("draft")}
                >Save as Draft</button>
              </div>

              <div className="mt-3">
                <button
                  className={`w-100 btn btn-Primary`}
                  onClick={() => submitAnswerSheet("submitted")}
                  disabled={
                    activeAnswerSheet?.evaluator_status?.status ==
                    "submitted" ||
                    !evaluationData.marks ||
                    !evaluationData.remarks ||
                    isTimerStart

                  }
                >Submit</button>
              </div>

            </div>
          </div>

        </div>
      </div>
    </>
  );
}
