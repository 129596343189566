import React, { useEffect, useState } from "react";
import { TopBar } from "../../Components/Sidebar/Sidebar";
import { Label } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { adminBygetId, adminUpdateById } from "../../Services/Admin/Api";
import Loader from "../../Components/Assets/Loader";
import Infomation from "../../Components/Assets/Infomation";

export default function EditAdminstration() {
  const [editAdmin, setEditAdmin] = useState();
  const [contactedit, setContactEdit] = useState();
  const [emailEdit, setEmailEdit] = useState();
  const [getAdminId, setGetAdminId] = useState();
  const navigate = useNavigate();
  const param = useParams();
  const [loading, setLoading] = useState(false);
  const pattern = new RegExp(/^\d{10}$/);
  const [isError, setIsError] = useState(false);
  const [emailExixt, setEmailExit] = useState(false);
  const [contactExist,setContactExist] = useState(false)

  const getAdminbyId = async () => {
    setLoading(true);
    adminBygetId(param.id)
      .then((res) => {
        console.log(res);
        setGetAdminId(res.data.data);
        setEditAdmin(res.data.data.name);
        setContactEdit(res.data.data.contectNo);
        setEmailEdit(res.data.data.email);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const updatedAdminById = async (e) => {
    e.preventDefault();
    setLoading(true);
    let adminPayload = {
      name: editAdmin.trim(),
      email: emailEdit,
      contectNo: contactedit,
    };
    await adminUpdateById(param.id,adminPayload)
      .then((res) => {
        console.log(res);
        setLoading(false);
        navigate("/adminstrations");
      })
      .catch((err) => {
        console.log(err);
        if (err?.response.data?.errormessage?.includes("email")) {
          setEmailExit(true);
          setLoading(false);
        } else {
          setEmailExit(false);
          setLoading(false);
        }
        if(err?.response.data?.errormessage?.includes("contectNo_1 dup key")){
          setContactExist(true)
          setLoading(false)
        }else{
          setContactExist(false)
          setLoading(false)
        }
      });
  };
  useEffect(() => {
    getAdminbyId();
  }, []);
  return (
    <>
      <TopBar></TopBar>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          updatedAdminById(e);
        }}
      >
        <div className="mainContainer">
          <div className="card p-md-4 p-sm-3 p-2">
            <h1>Edit Organizational Admin</h1>
            <div>
              {emailExixt ? (
                <Infomation text="Email is already exit" type="warning" />
              ) : (
                ""
              )}
              {
                contactExist ? (
                  <Infomation text="Contact is already exists" type="warning" />
                ):(
                  ""
                )
              }
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <Label className="HelveticaMedium">Name</Label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={getAdminId?.name}
                  onChange={(e) => {
                    if (e.target.value === " ") {
                      e.target.value = "";
                    } else {
                      setEditAdmin(e.target.value);
                    }
                  }}
                  required
                />
              </div>
              <div className="col-md-6">
                <Label className="HelveticaMedium">Admin Code</Label>
                <input
                  className="form-control"
                  defaultValue={getAdminId?.uid}
                  // value={adminUId.current}
                  disabled
                  required
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-6">
                <Label className="HelveticaMedium">Contact Number</Label>
                <input
                  className="form-control"
                  defaultValue={getAdminId?.contectNo}
                  onChange={(e) => {
                    if (e.target.value.length === 10) {
                      setContactEdit(e.target.value);
                      setIsError(false);
                    } else {
                      setIsError(true);
                    }
                  }}
                  required
                  type="number"
                />
              </div>
              <div className="col-md-6">
                <Label className="HelveticaMedium">Email Address</Label>
                <input
                  className="form-control"
                  defaultValue={getAdminId?.email}
                  onChange={(e) => {
                    setEmailEdit(e.target.value);
                  }}
                  disabled
                  required
                />
              </div>
            </div>
            {isError ? (
              <div className="row">
                {" "}
                <div className="col-md-6">
                  <Infomation
                    text="Please Enter 10 digit contact number"
                    type="warning"
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="d-flex justify-content-end mt-5">
              <div className="d-flex">
                <button
                  className="btn btn-Secondary me-3"
                  onClick={() => navigate("/adminstrations")}
                  type="button"
                >
                  Cancel
                </button>
                <button className="btn btn-primary" type="submit">
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Loader open={loading} />
    </>
  );
}
