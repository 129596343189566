import React, { useEffect, useRef, useState } from "react";
import { TopBar } from "../../Components/Sidebar/Sidebar";
import "./style.css";
import { useNavigate } from "react-router";
import TableContainer from "../../Components/Assets/TableContainer";
import { Popover, PopoverBody, Table } from "reactstrap";
import { OverlayPanel } from "primereact/overlaypanel";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import {
  getAllOrganization,
  organizeDeleteById,
} from "../../Services/Admin/Api";
import Loader from "../../Components/Assets/Loader";
import { findAllByTestId } from "@testing-library/react";
import { Dialog } from "primereact/dialog";
import Infomation from "../../Components/Assets/Infomation";

export default function Organization() {
  const navigate = useNavigate();
  const [allOrganization, setAllOrganization] = useState([]);
  const [show, setShow] = useState(false);
  const [orgId, setOrgId] = useState();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [orgDeleteid, setOrgDeleteId] = useState();
  const [blank, setBlank] = useState(true);
  const [nameid, setName] = useState();
  const [noData, setNodata] = useState(false);
  const op = useRef(null);
  const [searchData, setSearchData] = useState([]);

  const showHide = () => {
    setShow(!show);
  };

  const handleSearchClick = (e) => {
    let data = [...searchData];
    console.log(data);
    let val = e.trim().toLowerCase();
    let searchName = data.filter((res) =>
      res?.orgName?.toLowerCase().includes(val)
    );
    // console.log(searchName);
    setAllOrganization(searchName);
  };
  const getAllOrganizations = async () => {
    setLoading(true);
    await getAllOrganization()
      .then((res) => {
        console.log(res);
        let dataReverse = [...res.data.data];
        let reverData = dataReverse.reverse();
        setAllOrganization(reverData);
        setSearchData(reverData);
        if (res.data.data.length == 0) {
          setNodata(true);
        } else {
          setNodata(false);
        }
        setLoading(false);
        setBlank(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const deleteOrganizedById = async (id) => {
    setLoading(true);
    await organizeDeleteById(id)
      .then((res) => {
        console.log(res);
        setLoading(false);
        getAllOrganizations();
        setVisible(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllOrganizations();
  }, []);
  return (
    <>
      <Dialog
        visible={visible}
        modal
        style={{ width: "40rem" }}
        onHide={() => setVisible(false)}
        closable={false}
        draggable={false}
      >
        <h1>Delete Organization</h1>
        <p className="mt-4">
          Are you sure you want to delete the organization named &nbsp;"{nameid}
          " ?
        </p>
        <Infomation
          type="warning"
          text="Warning: Deleting this organization will permanently erase all associated users, papers, recordings, and data. This action is irreversible, and the organization cannot be recovered once deleted."
        ></Infomation>
        <div className="d-flex justify-content-end mt-4">
          <div className="d-flex">
            <button
              className="btn btn-Secondary me-3"
              onClick={() => setVisible(false)}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                deleteOrganizedById(orgId);
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </Dialog>
      <TopBar>
        {allOrganization.length !== 0 ? (
          <>
            <div className="d-flex justify-content-end"></div>
          </>
        ) : (
          <></>
        )}
      </TopBar>
      {!blank && (
        <>
          {!noData ? (
            <>
              <div className="mainContainer">
                <div className="card p-md-4 p-sm-3 p-2">
                  <div className="d-flex justify-content-between ">
                    <h1>Organizations ({allOrganization.length}) </h1>
                    <div className="d-flex ">
                      <div className="searchInput w-50 me-3">
                        <input
                          className="form-control ps-5"
                          placeholder="Search"
                          onChange={(e) => {
                            handleSearchClick(e.target.value);
                          }}
                        ></input>
                        <i className="pi pi-search searchIcon"></i>
                      </div>
                      <button
                        type=""
                        class="btn btn-Primary w-50"
                        onClick={() => {
                          navigate("/organization/addOrganization");
                        }}
                      >
                        <i className="pi pi-plus me-2"></i>Add Organization
                      </button>
                    </div>
                  </div>
                  <div className="py-4">
                    <TableContainer>
                      <table className="">
                        <thead>
                          <th>Name</th>
                          <th>Organization Code</th>
                          <th>No. of Exams</th>
                          <th>Administrators</th>
                          <th>HODs</th>
                          <th>Secretary</th>
                          <th>Evaluators</th>
                          <th>Command Center</th>
                          <th></th>
                        </thead>
                        <tbody>
                          {allOrganization.map((res, index) => {
                            return (
                              <>
                                <tr className="pointer">
                                  <td>{res?.orgName}</td>
                                  <td>{res?.orgId}</td>
                                  <td>{res?.examIds.length}</td>
                                  <td>{res?.adminIds.length}</td>
                                  <td>{res?.hodIds.length}</td>
                                  <td>{res?.secretaryIds.length}</td>
                                  <td>{res?.evaluatorIds.length}</td>
                                  <td>{res?.command_centerIds.length}</td>
                                  <td>
                                    <i
                                      className="pi pi-ellipsis-v  pointer "
                                      id="Popover1"
                                      onClick={(e) => {
                                        setOrgId(res._id);
                                        setName(res.orgName);
                                        op.current.toggle(e);
                                      }}
                                    ></i>
                                    <OverlayPanel
                                      isOpen={show}
                                      flip
                                      placement="bottom"
                                      // target="Popover1"
                                      // toggle={showHide}
                                      ref={op}
                                    >
                                      {/* <PopoverBody> */}
                                      <div
                                        className="popover_item pointer"
                                        onClick={() => {
                                          navigate(
                                            `/organization/editOrganization/${orgId}`
                                          );
                                        }}
                                      >
                                        <i class="pi pi-pencil"></i>
                                        <span className="ms-2">
                                          Edit Organization
                                        </span>
                                      </div>
                                      <div
                                        className="popover_item pointer mt-2 "
                                        onClick={() => {
                                          setVisible(true);
                                          // setOrgDeleteId(res._id);
                                        }}
                                      >
                                        <i class="pi pi-trash"></i>
                                        <span className="ms-2">
                                          Delete Organization
                                        </span>
                                      </div>
                                      {/* </PopoverBody> */}
                                    </OverlayPanel>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      </table>
                    </TableContainer>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="mainContainer align-items-center text-align-center py-1 ">
                <div className="d-flex justify-content-center">
                  <img src="/images/oImage.png" />
                </div>
                <p className="d-flex justify-content-center textColor">
                  It seems like you have not added any Organization yet.
                </p>
                <p className="d-flex justify-content-center textColor">
                  Please add to proceed further.
                </p>
                <div className="d-flex justify-content-center">
                  <button
                    type=""
                    class="btn btn-Primary"
                    onClick={() => {
                      navigate("/organization/addOrganization");
                    }}
                  >
                    <i className="pi pi-plus me-2"></i>Add Organization
                  </button>
                </div>
              </div>
            </>
          )}
        </>
      )}

      <Loader open={loading} />
    </>
  );
}
