import React, { useEffect, useRef, useState } from "react";
import { TopBar } from "../../Components/Sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import { Input } from "reactstrap";
import {
  userProfileListGetById,
  getAllOrganization,
  userProfileDeleteById,
  activeUserById,
  getOrrganizationById
} from "../../Services/Admin/Api";
import Loader from "../../Components/Assets/Loader";
import { OverlayPanel } from "primereact/overlaypanel";
import { Dialog } from "primereact/dialog";
import moment from "moment";
import { baseUrl } from "../../Services/EndPoint";
import { io } from "socket.io-client";
import TableContainer from "../../Components/Assets/TableContainer";
import { Select } from "../../Components/Inputs/Select";
import { userId, userInfo, userOrgId, userRole } from "../../Components/Assets/userData";
import { deleteUserNotification, enabledUserNotification } from "../../Components/Assets/socket";
const socket = io(baseUrl);
// import { getOrrganizationById } from "../../Services/Admin/Api";
// let socket;

export default function UserListing() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userListId, setUserListById] = useState([]);
  const [organizationList, setOrganizationList] = useState([]);
  const [selectOrganization, setSelectOrganization] = useState(
    userRole === "admin" ? null:userOrgId()
  );
  const [blank, setBlank] = useState(true);
  const [orgId, setOrgId] = useState();
  const op = useRef(null);
  const [visible, setVisible] = useState(false);
  const [nameid, setName] = useState();
  const [roleName, setRoleName] = useState();
  const [noData, setNodata] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [enableDisable,setEnableDisable] = useState([{name:"Enabled",value:true},
  {name:"Disabled",value:false}])
  const [enableValue, setEnableValue] = useState("")
  const [searchtext, setsearchtext] = useState("");
  const [organizationName,setOrganizationName] = useState()
  const[enableData,setEnableData] = useState([])



  socket?.on("getStatusUser", (data) => {
    if (data.role !== "admin") {
      let result = userListId.map((e) => {
        if (e._id === data.userId) {
          e.status = data.status;
          return e;
        } else {
          return e;
        }
      });
      setUserListById(result);
    }
  });
  const enableAdnDisableData = (e, data1) => {
    let data;
    if (data1) {
      data = [...data1];
    } else {
      data = [...searchData];
    }
    // console.log(data);
    let activeDatatable = data.filter((res) => res?.isActive === JSON.parse(e));
    setEnableValue(e)
    setEnableData(activeDatatable)
    setUserListById(activeDatatable);
    // console.log(activeDatatable);
  };

  const handleSearchClick = (e) => {
    let data = [...enableData];
    // console.log(data);
    let val = e.trim().toLowerCase();
    if(val.length !== 0){

      let searchName = data.filter((res) =>
        res?.name?.toLowerCase().includes(val)
      );
      // console.log(searchName);
      setUserListById(searchName);

    }else{
      setUserListById(enableData);

    }
  };
  const getOrgnizationNameById = async (id)=>{
    await getOrrganizationById(id).then((res)=>{
      console.log(res)
      setOrganizationName(res.data.data)
    }).catch((err)=>{
      console.log(err)
    })
  }

  const userListingById = async (id) => {
    setLoading(true);
    let idds = sessionStorage.getItem("org");
    await userProfileListGetById(idds ? idds : id)
      .then((res) => {
        console.log(res.data.data);
        let dataReverse = [...res.data.data];
        let reverData = dataReverse.reverse();
        setUserListById(reverData);
        setSearchData(reverData);
        enableAdnDisableData(true, reverData);

        setLoading(false);
        setBlank(false);
        if (res.data.data.length === 0) {
          setNodata(true);
        } else {
          setNodata(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setUserListById([]);
        setLoading(false);
      });
  };

  const getAllOrganizationList = async () => {
    await getAllOrganization()
      .then((res) => {
        // console.log(res);
        setOrganizationList(res.data.data);
      if(sessionStorage.getItem("org")){
        setSelectOrganization(sessionStorage.getItem("org"));

      }else{

        setSelectOrganization(res.data.data[0]._id);
      }
        // setSelectOrganization(res.data.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const UserDeleteProfile = async (id) => {
    setLoading(true);
     let payload ={
      organization_name:organizationName.orgName,
      orgAdminName:userInfo().name,
      orgAdminNameId:userId()
     }
    await userProfileDeleteById(id,payload)
      .then((res) => {
        // console.log(res);
        deleteUserNotification()
        userListingById( userRole() === "admin" ? selectOrganization:userOrgId());
        setLoading(false);
        setVisible(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const activeUserProfile = async (status) => {
    setLoading(true);
    await activeUserById(orgId, {
      isActive: status,
      organization_name:organizationName.orgName,
      orgAdminName:userInfo().name,
      orgAdminNameId:userId()
    })
      .then(async (res) => {
        // console.log(res);
        await userListingById(   userRole() === "admin" ? selectOrganization:userOrgId());
        enabledUserNotification()
        // await getAllOrganizationList()
        // enableAdnDisableData(true)
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  useEffect(() => {
    getAllOrganizationList();

  }, []);
  useEffect(() => {
    if (selectOrganization) {
      userListingById( userRole() === "admin" ? selectOrganization:userOrgId());
    }
  }, [selectOrganization]);

  return (
    <>
      <Dialog
        visible={visible}
        modal
        style={{ width: "40rem" }}
        onHide={() => setVisible(false)}
        closable={false}
        draggable={false}
      >
        <h1>Delete User</h1>
        <p>
          Are you sure you want to delete the user named&nbsp; "{nameid}"&nbsp;"
          {roleName}" ? This action is irreversible, and the user cannot be
          recovered once deleted.
        </p>
        <p>
          To hide the user temporarily, use the Disable option for that user.
        </p>
        <div className="d-flex justify-content-end mt-4">
          <div className="d-flex">
            <button
              className="btn btn-Secondary me-3"
              onClick={() => setVisible(false)}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                UserDeleteProfile(orgId);
              }}
            >
              Delete User
            </button>
          </div>
        </div>
      </Dialog>
      <TopBar>
        <div className="d-flex justify-content-end">
          {/* <Input
            type="select"
            className="form-control w-25"
            value={sessionStorage.getItem("org")}
            onChange={(e) => {
              setSelectOrganization(e.target.value);
              sessionStorage.setItem("org", e.target.value);
            }}
          >
            <option value="" disabled>
              Select Organization
            </option>
            {organizationList.map((res, index) => {
              return (
                <option key={index} value={res._id}>
                  {res.orgName}
                </option>
              );
            })}
          </Input> */}
          {userRole() === "admin" &&(
            <>
            <Select
            style={{ width: "200px" }}
            options={organizationList}
            optionLabel="orgName"
            placeholder="Select organization"
            value={organizationList.find(
              (s) => s._id == (sessionStorage.getItem("org") ? sessionStorage.getItem("org") : selectOrganization)
            )}
            onChange={(e) => {
              // setSelectRole(e.target.value);
              // sessionStorage.setItem("org", e.target.value);
              console.log("okokokokok");

              console.log(e.value);
              setSelectOrganization(e.value._id);
              sessionStorage.setItem("org", e.value._id);
              setsearchtext("")

            }}
          />
            </>
          )}
          
        </div>
      </TopBar>

      {!blank && (
        <>
          {!noData ? (
            <>
              <div className="mainContainer">
                <div className="card p-md-4 p-sm-3 p-2">
                  <div className="d-flex justify-content-between">
                    <h1>User Directory ({userListId.length})</h1>
                    <button
                      type=""
                      class="btn btn-Primary"
                      onClick={() => {
                        navigate("/users/addUser");
                      }}
                    >
                      <i className="pi pi-plus me-2"></i>Add User
                    </button>
                  </div>
                  <div className="d-flex mt-3 w-50">
                    <div className="searchInput w-50 me-3">
                      <input
                        className="form-control ps-5 "
                        placeholder="Search"
                        value={searchtext}
                        onChange={(e) => {handleSearchClick(e.target.value); setsearchtext(e.target.value)}}
                      ></input>
                      <i className="pi pi-search searchIcon"></i>
                    </div>
                    {/* <Input
                      type="select"
                      className="form-control w-25"
                      onChange={(e) => {
                        enableAdnDisableData(e.target.value);
                      }}
                    >
                      <option value={true}>Enabled</option>
                      <option value={false}>Disabled</option>
                    </Input> */}
                    <Select options={enableDisable} value={enableValue} optionLabel="name"  onChange={(e) => {
                        enableAdnDisableData(e.target.value);
                        setsearchtext("")
                      }}/>
                  </div>
                  <div className="py-4">
                    <TableContainer>
                      <table className="">
                        <thead>
                          <th>Name</th>
                          <th>User Id</th>
                          <th>User Role</th>
                          <th>Email Address</th>
                          <th>Status</th>
                          <th>Last Active on</th>
                          <th></th>
                        </thead>
                        <tbody>
                          {userListId &&
                            userListId.map((res, index) => {
                              const formattedLogoutTime = new Date(res.logoutTime).toLocaleString();
                              return (
                                <>
                                  <tr key={index} >
                                    <td
                                      onClick={() => {
                                        navigate(`/users/viewUser/${res._id}`);
                                      }}
                                      className="pointer"
                                    >
                                      {res?.name}
                                    </td>
                                    <td>{res?.uid}</td>
                                    <td>{res?.role}</td>
                                    <td>{res?.email}</td>
                                    <td>
                                      {res.status === false ? (
                                        <span className="oflineColor">
                                          Offline
                                        </span>
                                      ) : (
                                        <span className="onlineColor">
                                          Online
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {/* {console.log(res.logoutTime)} */}
                                      {res.logoutTime?moment(res.logoutTime).format(
                                        "DD/MM/YYYY, h:mm:ss a"
                                      ):"N/A"}
                                      {/* {formattedLogoutTime} */}
                                    </td>
                                    <td>
                                      <i
                                        className="pi pi-ellipsis-v  pointer "
                                        onClick={(e) => {
                                          setOrgId(res._id);
                                          getOrgnizationNameById(res.orgId)
                                          setName(res.name);
                                          setRoleName(res.role);
                                          op.current.toggle(e);
                                        }}
                                      ></i>
                                      <OverlayPanel ref={op}>
                                        <div
                                          className="popover_item pointer"
                                          onClick={() => {
                                            navigate(
                                              `/users/editUser/${orgId}`
                                            );
                                          }}
                                        >
                                          <i class="pi pi-pencil"></i>
                                          <span className="ms-2">
                                            Edit Profile
                                          </span>
                                        </div>
                                        {res.isActive === true ? (
                                          <div
                                            className="popover_item pointer mt-2"
                                            onClick={() => {
                                              activeUserProfile(false);
                                              setsearchtext("")

                                            }}
                                          >
                                            <i className="pi pi-ban"></i>
                                            <span className="ms-2">
                                              Disable Profile
                                            </span>
                                          </div>
                                        ) : (
                                          <div
                                            className="popover_item pointer mt-2"
                                            onClick={() => {
                                              activeUserProfile(true);
                                              setsearchtext("")

                                            }}
                                          >
                                            <i className="pi pi-ban"></i>
                                            <span className="ms-2">
                                              Enable Profile
                                            </span>
                                          </div>
                                        )}

                                        <div
                                          className="popover_item pointer mt-2"
                                          onClick={() => {
                                            setVisible(true);
                                          }}
                                        >
                                          <i class="pi pi-trash"></i>
                                          <span className="ms-2">
                                            Delete Profile
                                          </span>
                                        </div>
                                      </OverlayPanel>
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                        </tbody>
                      </table>
                    </TableContainer>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="mainContainer align-items-center text-align-center py-1 ">
                <div className="d-flex justify-content-center">
                  <img src="/images/oImage.png" />
                </div>
                <p className="d-flex justify-content-center textColor">
                  It seems like you have not added any Users yet.
                </p>
                <p className="d-flex justify-content-center textColor">
                  Please add to proceed further.
                </p>
                <div className="d-flex justify-content-center">
                  <button
                    type=""
                    class="btn btn-Primary"
                    onClick={() => {
                      navigate("/users/addUser");
                    }}
                  >
                    <i className="pi pi-plus me-2"></i>Add Users
                  </button>
                </div>
              </div>
            </>
          )}
        </>
      )}

      <Loader open={loading} />
    </>
  );
}
