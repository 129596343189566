export const formValidation = (formData, fields) => {
    let error = [];
    let fieldsObj = {}
    fields.forEach((res) => {
        if (!formData[res]?.trim() || formData?.length === 0) {
            error.push(res)
            fieldsObj[res] = true
        }
        else {
            fieldsObj[res] = false
        }
    })
    return ({ keys: fieldsObj, errorFields: error, isFormValid: error.length == 0 ? true : false })
}