import React, { useRef } from 'react'
import "./style.css"
import { TopBar } from '../../Components/Sidebar/Sidebar'

import { QuestionPaperForm } from './QuestionPaperForm';
import { addPaper } from '../../Services/Admin/Api';
import { loader } from '../../utils/loader';
import { useNavigate } from 'react-router-dom';
import { userId, userInfo } from '../../Components/Assets/userData';
import { addPaperNotification } from '../../Components/Assets/socket';

export const CreateQuestionPaper = () => {
let navigate=useNavigate()

    let questionData = useRef({
        paperName: "",
        paperCode: "",
        orgId: '',
        paperDate: null,
        examId: null,
        organization_name:"",
        orgAdminName:userInfo().name,
        orgAdminNameId:userId(),
    }
    )

    async function createPaper(data) {
        loader.start()
        try {
            console.log(data)
            await addPaper(data)
            addPaperNotification()
            loader.stop()
            navigate("/question")
        }
        catch (err) {
            console.log(err)
            loader.stop()
        }
    }

    return (
        <>
            <TopBar />
            <QuestionPaperForm data={questionData.current} mode={"Add"} onSubmit={createPaper} />
        </>
    )
}
