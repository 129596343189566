import { Dialog } from 'primereact/dialog'
import { Slider } from 'primereact/slider'
import React, { useState } from 'react'
import { updateOrgTimer } from '../../Services/Admin/Api'
import { loader } from '../../utils/loader'

export const EditTimer = ({ data ,getDataAgain}) => {

    let [visible, setVisible] = useState(false);

    let [time, setTime] = useState(data.timer);

    async function updateTimer() {
        loader.start()
        try {
            let res = await updateOrgTimer({ timer: time }, data._id);
            console.log(res);
            setVisible(false);
            loader.stop()
            getDataAgain();
        }
        catch (err) {
            console.log(err);
        }
    };

    return (
        <div>
            <i className='pi pi-pencil pointer' onClick={() => setVisible(true)} />
            <Dialog draggable={false} closable={false} header="Edit Timer" visible={visible}>
                <div>
                    <div>
                        {time} Mins
                        <br />
                        <br />
                        <Slider value={time} onChange={(e) => setTime(e.value)} className="w-14rem" />
                    </div>
                    <br />
                    <div className="d-flex justify-content-end">
                        <button onClick={() => setVisible(false)} className='btn border'>Cancel</button>&nbsp;&nbsp;
                        <button className='btn btn-primary' onClick={updateTimer}>Submit</button>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}
