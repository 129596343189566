import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { Select } from "../../../Components/Inputs/Select";
import {
  assignThirdEvaluator,
  getEvaluatorsForThirdEvaluation,
} from "../../../Services/Admin/Api";
import { userInfo, userOrgId } from "../../../Components/Assets/userData";
import { getOrrganizationById } from "../../../Services/Admin/Api";
import { addthirdEvaluatorNotification } from "../../../Components/Assets/socket";

export const AssignThirdEvaluator = ({ answerSheetData, answerSheetId,paperData ,test}) => {
  let [dialog, setDialog] = useState(false);
  let paperId = new URLSearchParams(window.location.search).get("paperId");
  let [allEvaluators, setAllEvaluators] = useState([]);
  let [selectedEvaluator, setSelectedEvaluator] = useState(null);
  const [organizationName, setOrganizationName] = useState();
  console.log(paperData);
  console.log(answerSheetId);
console.log(answerSheetData)
  
  const getOrgnizationNameById = async (id)=>{
    await getOrrganizationById(id).then((res)=>{
      console.log(res)
      setOrganizationName(res.data.data)
    }).catch((err)=>{
      console.log(err)
    })
  }

  useEffect(()=>{
    getOrgnizationNameById(userInfo().orgId)
  },[])

  async function getAllEvaluators() {
    try {
      let orgId = userOrgId();
      let body = {
        organization_id: orgId,
      };
      let res = await getEvaluatorsForThirdEvaluation(paperId, body);
      console.log(res.data.data);
      setAllEvaluators(res.data.data);
    } catch (err) {
      console.log(err);
    }
  }

  async function assignEvaluator() {
    try {
        console.log(selectedEvaluator)
      let body = {
        evaluatorId: selectedEvaluator._id,
        assignDate: new Date().toISOString(),
        answerSheet_id: answerSheetId,
        questionId: answerSheetData.questionId,
        type: "evaluator",
        question_number: answerSheetData.question_number,
        evaluator_number: 3,
        organization_name:organizationName.orgName,
        evaluator_name:selectedEvaluator.name,
        orgId:userOrgId(),
        paper_name:paperData.paperName,
        exam_name:paperData.examName,
        hod_name:userInfo().name,
       
      };
      console.log()
      console.log(body);

      let res = await assignThirdEvaluator(answerSheetData.questionId, body);
      console.log(res);
      test("res")
      addthirdEvaluatorNotification()
      setDialog(false);
      setSelectedEvaluator([]);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <div
        className="pointer text-primary"
        onClick={() => {
          setDialog(true);
          getAllEvaluators();
        }}
      >
        -- Select --
      </div>
      <Dialog
        closable={false}
        draggable={false}
        visible={dialog}
        header={"Assign Third Evaluator"}
        style={{ width: "40rem" }}
      >
        <div className="p-2">
          <label className="HelveticaMedium mb-1">Evaluators</label>
          <Select
            options={allEvaluators}
            optionLabel="name"
            value={
              selectedEvaluator
                ? allEvaluators.find((e) => e._id == selectedEvaluator._id)
                : null
            }
            placeholder="Select Evaluator"
            onChange={(e) => {
              setSelectedEvaluator(e.target.value);

            }}
          />
          <br />
          <div className="d-flex justify-content-end gap-3">
            <button
              className="btn btn-Secondary"
              onClick={() => {
                setDialog(false);
                setSelectedEvaluator([]);
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-Primary"
              disabled={!selectedEvaluator}
              onClick={assignEvaluator}
            >
              Assign
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};
