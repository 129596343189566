import { createSlice } from "@reduxjs/toolkit";


export const LoaderSlice = createSlice({
    name: "loader",
    initialState: false,
    reducers: {
        updateLoader: ((state, action) => {
            console.log(action)
            return action.payload
        })
    }

})

export let { updateLoader } = LoaderSlice.actions