import React, { useEffect, useState } from "react";
import "./style.css";
import { Calendar } from "primereact/calendar";
import { TopBar } from "../../Components/Sidebar/Sidebar";
import {
  getnotification,
  getNotificationItAdminData,
  readOrgAdminNotification,
} from "../../Services/Admin/Api";
import { userId, userOrgId, userRole } from "../../Components/Assets/userData";
import { io } from "socket.io-client";
import { baseUrl } from "../../Services/EndPoint";
import { deleteUserNotification } from "../../Components/Assets/socket";
import Loader from "../../Components/Assets/Loader";
import moment from "moment";
import { useNavigate } from "react-router-dom";
const socket = io(baseUrl);

export default function Notification() {
  let [notificationData, setNotificationData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [notifyMessage, setNotifyMessage] = useState([]);

  socket.on("check", () => {
    getNotificationData();
  });

  socket.on("getNotificationForAssign3QuestionsAndAssign", () => {
    getNotificationData();
  });

  // socket.on("getNotificationForDeleteUser", () => {
  //   getNotificationData()
  // });
  // socket.on("startEvaluationNotification", () => {
  //   getNotificationData()
  // });

  const [notificationData2, setNotificationData2] = useState([]);
  const [yesterdata, setYesterdata] = useState([]);

  const getNotificationData = async () => {
    await getNotificationItAdminData(userId())
      .then(async (res) => {
        let dataReverse = [...res.data.data.result];
        setLoader(false);
        setNotificationData(dataReverse);
        let ok = dataReverse.map((e) => e.data).flat(Infinity);
        console.log(ok);

        let ids = ok
          .filter((e) => !e.view_by.includes(userId()))
          .map((e) => e._id);
        console.log(ids);
        if(window.location.pathname.includes("notification")){

          await readDataNotificationOrg(ids);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };
  const readDataNotificationOrg = async (d) => {
    let payload = {
      notificationData: d,
    };
    await readOrgAdminNotification(userId(), payload)
      .then((res) => {
        console.log(res.data.data);
        // setNotificationData("")
        sessionStorage.setItem("notifyNo", 0);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function getDatainTime(date) {
    let today = moment().format("YYYY-MM-DD");
    let Yesterday = new Date(today);
    Yesterday.setDate(Yesterday.getDate() - 1);
    let current = new Date(date);
    if (date === "Today") {
      return date;
    }
    if (+Yesterday === +current) {
      return "Yesterday";
    } else {
      return moment(current).format("DD MMMM, YYYY");
    }
  }
  let navigate = useNavigate();

  useEffect(() => {
    getNotificationData();
  }, []);

  function navigateToNotification(data) {
    console.log(data);

    if (data?.routes) {
      let routeData = data.routes.find((e) => e.role === userRole());
      console.log(routeData);
      if (routeData?.route) {
        navigate(routeData.route);
      }
    }
  }

  return (
    <>
      <TopBar></TopBar>
      <div className="mainContainer">
        <div className="card p-md-4 p-sm-3 p-2">
          <div className="d-flex align-items-center justify-content-between ">
            <div className="d-flex align-items-center">
              <h1>Notifications</h1>
              {/* <span className="notification-number ms-4">3</span>
              <i className="pi pi-refresh ms-4 refreshColor"></i> */}
            </div>
            {/* <div className="d-flex align-items-center ">
              <span className="markAll w-75">Mark all as Read</span>
              <Calendar placeholder="Select Date" className=" ms-4" />
            </div> */}
          </div>
          {/* <span className="text-main-style mt-5">Today</span>  */}
          {/* {notificationData.map((res) => {
            return (
              <div className="notificationList mt-3 p-3">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex  align-items-center">
                   
                    <div className="">
                      <span className="textStyleMain">{res?.itAdmin_message?.message_one}</span>
                      <p className="textStyle">{res?.itAdmin_message?.message_two} </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <span className="inActive me-2"></span>
                    
                  </div>
                </div>
              </div>
            )
          })} */}
          {Array.isArray(notificationData) &&
            notificationData.map((res) => {
              return (
                <>
                  <h5 className="mt-3">{getDatainTime(res._id)}</h5>
                  {res?.data?.map((res2) => {
                    return (
                      <>
                        <div
                          className="notificationList mt-3 p-3"
                          onClick={() => navigateToNotification(res2)}
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex  align-items-center">
                              {/* <div>
                      <img
                        src="/images/eProfile.png"
                        alt=""
                        style={{ width: "60px" }}
                      />
                    </div> */}
                              <div className="">
                                <span className="textStyleMain">
                                  {res2?.itAdmin_message?.message_one}
                                </span>
                                <p className="textStyle">
                                  {res2?.itAdmin_message?.message_two}{" "}
                                </p>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <span className=" me-2"></span>
                              <span>{moment(res2.created_at).fromNow()}</span>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </>
              );
            })}
          {/* <span className="text-main-style mt-5">Yesterday</span> */}
        </div>
      </div>
      <Loader open={loader} />
    </>
  );
}
