import React, { useEffect, useRef, useState } from "react";
import { TopBar } from "../../Components/Sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import PopOver from "../../Components/Assets/PopOver";
import { PopoverItem } from "../../Components/Assets/PopoverItem";
import { OverlayPanel } from "primereact/overlaypanel";
import TableContainer from "../../Components/Assets/TableContainer";
import { getDeclaredExamDataListAdmin } from "../../Services/Admin/Api";
import moment from "moment";
import { loader } from "../../utils/loader";

 

export default function ResultDeclare() {
  const navigate = useNavigate();
  const op = useRef(null);
  const [declaredData,setDeclareData] = useState([])
  const [searchData, setSearchData] = useState([]);


  const handleSearchClick = (e) => {
    let data = [...searchData];
    let val = e.trim().toLowerCase();
    let searchName = data.filter((res) =>
      res?.examName?.toLowerCase().includes(val)
    );
    setDeclareData(searchName);
  };

  const getDeclaredData = async()=>{
    loader.start()
      await getDeclaredExamDataListAdmin().then((res)=>{
        console.log(res)
        loader.stop()
        setDeclareData(res.data.data)
        setSearchData(res.data.data)
      }).catch((err)=>{
        console.log(err)
        loader.stop()
      })
  }

  useEffect(()=>{
    getDeclaredData()
  },[])
  return (
    <>
  
          
  <div className="d-flex justify-content-between align-items-center pt-4 pb-3">
            <h1>Exam Results</h1>
            <div className="searchInput w-25 me-3">
              <input
                className="form-control ps-5"
                placeholder="Search"
                onChange={(e) => {
                    handleSearchClick(e.target.value);
                }}
              ></input>
              <i className="pi pi-search searchIcon"></i>
            </div>
          </div>
          <div className="py-3">
            <TableContainer>
            <table className="left_align_table">
              <thead>
                <th>Exam Name</th>
                <th>No. of Paper</th>
                <th>No. of Student</th>
                <th>Exam Date</th>
                <th>Result Date</th>
                {/* <th>&nbsp;</th> */}
              </thead>
              <tbody>
                {declaredData.map((res, index) => {
                  return (
                    <tr key={index}>
                      <td  className="pointer"
                        onClick={() =>
                          navigate(`/resultmaintab/resultDeclare/viewresult/${res._id}`,{state:res.examName})
                        }>{res.examName}</td>
                      <td
                       
                      >
                        {res.questionIds.length}
                      </td>
                      <td>{res.no_of_student}</td>
                      <td>{moment(res.examYear).format("YYYY")}</td>
                      <td>{moment(res.declaredDate).format("DD/MM/YYYY")}</td>
                      {/* <td>
                        <img
                          src="/images/import.png"
                          onClick={(e) => {
                            op.current.toggle(e);
                          }}
                          className="pointer"
                        ></img>
                        <OverlayPanel ref={op}>
                          <div className="popover_item pointer">PDF</div>
                          <div className="popover_item pointer">Excel</div>
                        </OverlayPanel>
                      </td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            </TableContainer>
          </div>
       
    </>
  );
}
