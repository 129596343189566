import React from "react";
import { Link } from "react-router-dom";
import "./style.css";


export default function () {
  return (
    <>
      <div className="main-container">
        <div className="left-contain">
          <div className="logoFixed">
            <span className="eLogo">E-Valuators App</span>
          </div>
        </div>
        <div className="right-contain">
          <div className="right-content-div ">
            <div className="d-flex justify-content-center ">
              <span className="logIn">Forgot Password</span>
            </div>
            <div className="d-flex justify-content-center">
              <span className="HelveticaMedium mt-3">
                Use your Biometric Credentials
              </span>
            </div>
            <div className="d-flex justify-content-center">
              <span className="resetPasswordFirst mt-3">
                Place your registered finger on the external fingerprint sensor
                in order to authenticate yourself
              </span>
            </div>
            <Link to="/forgotResetPassword">
              <div className="d-flex justify-content-center mt-4">
                <img src="/images/biometric1.png" alt="" />
              </div>
            </Link>
            <div className="d-flex justify-content-center mt-4">
              <Link to="/forgetPassword" className="textDecoration">
                <span className="backTologin">Cancel</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
