import React, { useEffect, useState } from "react";
import { TopBar } from "../../Components/Sidebar/Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import { adminBygetId } from "../../Services/Admin/Api";
import { Label } from "reactstrap";
import Loader from "../../Components/Assets/Loader";
import { BackButton } from "../../Components/Assets/BackButton";
import { FaArrowLeft } from "react-icons/fa6";
import "./style.css";
import { io } from "socket.io-client";
import { baseUrl } from "../../Services/EndPoint";
const socket = io(baseUrl);

export default function ViewDetailAdmin() {
  const [getAdminId, setGetAdminId] = useState({});
  const param = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  socket?.on("getStatusUser", (data) => {
    if (data.role !== "admin") {
      let datas = getAdminId;
      if (param.id === data.userId) {
        datas.status = data.status;
      }

      setGetAdminId(datas);
    }
  });
  const getAdminbyId = async () => {
    setLoading(true);
    await adminBygetId(param.id)
      .then((res) => {
        // console.log(res);
        setGetAdminId(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  useEffect(() => {
    getAdminbyId();
  }, []);

  return (
    <>
      <TopBar></TopBar>
      {getAdminId.name && (
        <div className="mainContainer">
          <div className="card p-md-4 p-sm-3 p-2">
            <div
              className="d-flex align-items-center gap-1  back_btn_con"
              onClick={() => navigate("/adminstrations")}
            >
              <FaArrowLeft /> Back
            </div>

            {/* <BackButton link={"/adminstarions"}/> */}

            <div className="d-flex  mt-3">
              {/* <div>
              <img src="/images/Avatar.png" />
            </div> */}
              <div className=" w-100 align-self-center text-start">
                <h1>{getAdminId?.name}</h1>
                <p>{getAdminId?.uid}</p>
                <p>
                  {getAdminId?.status === false ? (
                    <span className="oflineColor">Offline</span>
                  ) : (
                    <span className="onlineColor">Online</span>
                  )}
                </p>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-6">
                <Label className="HelveticaMedium">Contact Number</Label>
                <input
                  className="form-control"
                  defaultValue={getAdminId?.contectNo}
                  disabled
                />
              </div>
              <div className="col-md-6">
                <Label className="HelveticaMedium">Email Address</Label>
                <input
                  className="form-control"
                  defaultValue={getAdminId?.email}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <Loader open={loading} />
    </>
  );
}
