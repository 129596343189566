import React, { useEffect, useRef, useState } from "react";
import { TopBar } from "../../Components/Sidebar/Sidebar";
import Infomation from "../../Components/Assets/Infomation";
import { Label } from "reactstrap";
import "./style.css";
import {
  addOrganizationItAdmin,
  addAdminOrganization,
  addPaper,
  addExam,
  emailVerified,
} from "../../Services/Admin/Api";
import uniqid from "uniqid";
import { useNavigate } from "react-router-dom";
import Loader from "../../Components/Assets/Loader";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Calendar } from "primereact/calendar";
import { userId, userInfo } from "../../Components/Assets/userData";
import { addExamNotification,addPaperNotification } from "../../Components/Assets/socket";
import { numberVerified } from "../../Services/Admin/Api";
export default function AddOrganization() {
  const [orgAbbr, setOrgAbbr] = useState("");
  const [organizeFullName, setOrganizeFullName] = useState("");
  const [error, setError] = useState(false);
  const [next, setNext] = useState(1);
  const [indexs, setindexs] = useState(0);
  const [emailindexs, setEmailindexs] = useState(0);
  const [emailError,setEmailError] = useState(false)
  const [indexError,setindexError] = useState([{erorvalue:false}])
  const [indexEmail,setIndexEmail] = useState([{erroemail:false}])
  const [uniqUserID, setUniqUserID] = useState(uniqid());
  

  let adminUId = "adm-" + uniqid();

  let demo = {
    AdminName: "",
    AdUid: orgAbbr + "-" + uniqid(),
    orgId: "",
    emailAdmin: "",
    contact: "",
  };
  let exams = {
    ExamName: "",
    ExamYear: "",
    EUid: "",
    paper: [
      {
        paperFullName: "",
        papeDate: "",
        PUid: "",
      },
    ],
  };
  const [examList, setExamList] = useState([
    {
      ExamName: "",
      ExamYear: "",
      EUid: "",
      paper: [
        {
          paperFullName: "",
          papeDate: "",
          PUid: "",
        },
      ],
    },
  ]);
  const newExam = () => {
    let examArr = [...examList];
    examArr.push(exams);
    setExamList(examArr);
  };
  // let papers = {
  //   paperFullName: "",
  //   papeDate: "",
  //   PUid: "",
  // };
  // const [paperList, setPaperList] = useState([papers]);
  const Newpapers = (index) => {
    let arr2 = [...examList];

    arr2[index].paper.push({
      paperFullName: "",
      papeDate: "",
      PUid: "",
    });

    setExamList(arr2);
  };
  // console.log(papers.PUid);
  const [adminList, setAdminList] = useState([demo]);
  const [adminLen, setAdminLen] = useState();
  const navigate = useNavigate();
  let uId = useRef("org-" + uniqid());
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [emailExixt, setEmailExit] = useState(false);
  const [num, setnum] = useState(0);
  const [indexValue, setIndexValue] = useState("");
  const [contactExist,setContactExist] = useState(false)
  const [checkErrordata,setCheckErrorData] = useState(0)


  const newAdmin = () => {
    setindexError(pre=>([...pre,{ errorvalue: false }]))
    setIndexEmail(pre1=>([...pre1,{erroemail:false}]))
    setAdminList([...adminList, ...[demo]]);
    console.log("newAdmin");
  };
  console.log(demo.AdUid);
  const handleSubmit1 = (e) => {
    e.preventDefault();
    if (num === 0) {
      setAdminList([demo]);
      setnum(1);
    }
    setNext(2);
  };

  const handleSumitOrganize = async (e) => {
    e.preventDefault();
    setLoading(true);
    let emailcheck = false;
    let numberCheck = false;
    for (let a = 0; a < adminList.length; a++) {
      let emailss = await emailVerified({
        email: adminList[a].emailAdmin,
      }).catch((err) => {
        return err;
        // emailcheck=false;
        // setLoading(false);
        // setEmailExit(false);
      });


      console.log(emailss?.data);
      if (emailss?.data?.data?.message === "Email is Available") {
        emailcheck = true;
        setLoading(false);
        setEmailExit(true);
        setCheckErrorData(1)
        break;
      } else {
        // emailcheck = false;
        setLoading(true);
        setEmailExit(false);
      }
      let numbersssss = await numberVerified({
        contectNo:adminList[a].contact,
      }).catch((err)=>{
        return err
      })
      console.log(numbersssss)
      if(numbersssss?.data?.data?.message === "Number is Available"){
        numberCheck = true
        setLoading(false);
        setContactExist(true);
        setCheckErrorData(2)
        break;
      } else {
        // numberCheck = false
        setLoading(true);
        setContactExist(false);

      }
    }

    // return;
    if (emailcheck === false && numberCheck === false) {
      let payload = {
        orgName: orgAbbr.trim(),
        orgFullName: organizeFullName.trim(),
        orgId: orgAbbr + "-" + uniqUserID,
      };
      // console.log(payload);
      // return;
      await addOrganizationItAdmin(payload)
        .then(async (res) => {
          console.log(res);
          // setError(true);
          for (let i = 0; i < examList.length; i++) {
            console.log(i, "list1");
            let examPayload = {
              examName: examList[i].ExamName.trim(),
              examCode: examList[i].EUid.trim(),
              orgId: res.data.data._id,
              examYear: examList[i].ExamYear.trim(),
              // organization_name:orgAbbr.trim(),
              // orgAdminName:userInfo().name,
              // orgAdminNameId:userId(),
            };
            let res2 = await addExam(examPayload)
              .then(async (res3) => {
                console.log(res3);
                // addExamNotification()
                for (let j = 0; j < examList[i].paper.length; j++) {
                  console.log(j, "list2");
                  let paperPayload = {
                    paperName: examList[i].paper[j].paperFullName.trim(),
                    paperCode: examList[i].paper[j].PUid.trim(),
                    orgId: res.data.data._id,
                    paperDate: examList[i].paper[j].papeDate,
                    examId: res3.data.data._id,
                    // organization_name:orgAbbr.trim(),
                    // orgAdminName:userInfo().name,
                    // orgAdminNameId:userId(),
                  };
                  let res5 = await addPaper(paperPayload)
                    .then((res4) => {
                      console.log(res4);
                      // addPaperNotification()
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                  console.log(res5);
                }
              })
              .catch((err) => {
                console.log(err);
              });
            console.log(res2);
          }

          for (let i = 0; i < adminList.length; i++) {
            let adminPayload = {
              email: adminList[i].emailAdmin.trim(),
              name: adminList[i].AdminName.trim(),
              contectNo: adminList[i].contact.trim(),
              role: "orgAdmin",
              uid: adminList[i].AdUid.trim(),
              orgId: res.data.data._id,
            };
            let res1 = await addAdminOrganization(adminPayload)
              .then((res) => {
                console.log(res);
                navigate("/organization");
              })
              .catch((err) => {
                console.log(
                  err?.response?.data?.errormessage?.includes("email")
                );
                if (err?.response?.data?.errormessage?.includes("email")) {
                  setEmailExit(true);
                  setLoading(false);
                } else {
                  setEmailExit(false);
                  setLoading(false);
                }
                if(err?.response.data?.errormessage?.includes("contectNo_1 dup key")){
                  setContactExist(true)
                  setLoading(false)
                }else{
                  setContactExist(false)
                  setLoading(false)
                }
              });
            console.log(res1);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };
  useEffect(() => {}, []);

  function addd(val, ind) {
    let arr = [...examList];
    arr[ind].ExamName = val;
    setExamList(arr);
  }

  function addYear(val, ind) {
    let arr = [...examList];
    arr[ind].ExamYear = val;
    setExamList(arr);
  }
  function addEcode(val, ind) {
    let arr = [...examList];
    arr[ind].EUid = val;
    setExamList(arr);
  }

  function adminNameFun(val, ind) {
    let arr = [...adminList];
    arr[ind].AdminName = val;
    setAdminList(arr);
  }

  function contactAdmin(val, ind) {
    let arr = [...adminList];
    arr[ind].contact = val;
    setAdminList(arr);
  }
  function setEmailAdmin(val, ind) {
    let arr = [...adminList];
    arr[ind].emailAdmin = val;
    setAdminList(arr);
  }

  const disableFunction = () => {
    console.log(examList[0]);
    if (
      examList[0]?.ExamName?.length === 0 ||
      examList[0]?.ExamYear?.length === 0 ||
      examList[0]?.EUid?.length === 0 ||
      examList[0]?.paper[0]?.paperFullName?.length === 0 ||
      examList[0]?.paper[0]?.papeDate?.length === 0 ||
      examList[0]?.paper[0]?.PUid?.length === 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const disablAddfunction = () => {
    console.log(adminList[0]);
    if (
      adminList[0]?.AdminName.length === 0 ||
      adminList[0]?.emailAdmin?.length === 0 ||
      adminList[0]?.contact?.length === 0
    ) {
      return true;
    } else {
      if (adminList[0]?.contact?.length !== 10) {
        return true;
      } else {
        if (!/\S+@\S+\.\S+/.test(adminList[0]?.emailAdmin)) {
          return true;
        } else {
          return false;
        }
      }
    }
  };
  return (
    <>
      <TopBar></TopBar>
      {next === 1 ? (
        <>
          <form onSubmit={(e) => {e.preventDefault();handleSubmit1(e)}}>
            <div className="mainContainer">
              <div className="card p-md-4 p-sm-3 p-2">
                <h1>Add Organization</h1>

                <div className="organizeStep mt-2">
                  Organization Details (Step 1 of 2)
                </div>
                <div className="row mt-5">
                  <div className="col-md-12">
                    <Label className="HelveticaMedium">
                      Organization’s Full Name
                    </Label>
                    <input
                      className="form-control"
                      placeholder="Enter the Organization’s Name"
                      value={organizeFullName}
                      onChange={(e) => {
                        if (e.target.value === " ") {
                          e.target.value = "";
                        } else {
                          setOrganizeFullName(e.target.value);
                        }
                      }}
                      type="text"
                      id="organizationNameId"
                      required
                    />
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-md-6">
                    <Label className="HelveticaMedium">
                      Organization’s Abbreviation
                    </Label>
                    <input
                      className="form-control"
                      placeholder="Enter the Organization’s Abbreviation"
                      value={orgAbbr}
                      onChange={(e) => {
                        if (e.target.value === " ") {
                          e.target.value = "";
                        } else {
                          setOrgAbbr(e.target.value);
                        }
                      }}
                      type="text"
                      id="OrganizationAbbriviationId"
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <Label className="HelveticaMedium">Organization Code</Label>
                    <input
                      className="form-control"
                      placeholder="EVA-101-OID-240101"
                      value={orgAbbr + "-" + uniqUserID}
                      required
                      disabled
                    />
                  </div>
                </div>
                {examList.map((res, index2) => {
                  return (
                    <>
                      <div className="hr mt-4 my-3"></div>
                      <div className="mt-2">
                        <Accordion
                          className=""
                          onClick={() => {
                            console.log(examList);
                          }}
                          activeIndex={0}
                        >
                          <AccordionTab
                            className=""
                            down
                            arrow={false}
                            header={
                              <div className="d-flex justify-content-between align-items-center text-align-center ">
                                <div className="HeadingStyle">
                                  Exam {index2 + 1}
                                </div>
                                <span className=" align-items-center">
                                  {examList.length !== 1 && (
                                    <>
                                      <span
                                        className="deletStyle "
                                        onClick={(e) => {
                                          console.log("delete button lick");
                                          let arr = examList.filter(
                                            (item, index1) => index1 !== index2
                                          );
                                          console.log(arr);
                                          setExamList(arr);
                                        }}
                                      >
                                        <i className="pi pi-trash me-2" />{" "}
                                        Delete
                                      </span>
                                    </>
                                  )}
                                </span>
                              </div>
                            }
                            headerStyle={null}
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <Label className="HelveticaMedium">
                                  Exam’s Full Name
                                </Label>
                                <input
                                  className="form-control"
                                  placeholder="Enter the Exam’s Full Name"
                                  value={res.ExamName}
                                  onChange={(e) => {
                                    if (e.target.value === " ") {
                                      e.target.value = "";
                                    } else {
                                      addd(e.target.value, index2);
                                    }
                                  }}
                                  required
                                />
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-md-6">
                                <Label className="HelveticaMedium">
                                  Exam’s Year
                                </Label>
                                <input
                                  className="form-control"
                                  placeholder="Enter the Exam’s Year"
                                  value={res.ExamYear}
                                  onChange={(e) => {
                                    if (e.target.value === " ") {
                                      e.target.value = "";
                                    } else {
                                      addYear(e.target.value, index2);
                                    }
                                  }}
                                  type="number"
                                  required
                                />
                              </div>
                              <div className="col-md-6">
                                <Label className="HelveticaMedium">
                                  Exam Code
                                </Label>
                                <input
                                  className="form-control"
                                  placeholder="Enter Exam Code"
                                  value={res.EUid}
                                  onChange={(e) => {
                                    if (e.target.value === " ") {
                                      e.target.value = "";
                                    } else {
                                      addEcode(e.target.value, index2);
                                    }
                                  }}
                                  required
                                />
                              </div>
                            </div>
                            {res?.paper?.map((res1, index1) => {
                              return (
                                <>
                                  {console.log(res?.paper)}
                                  <div className="mt-4">
                                    <div className="d-flex justify-content-between">
                                      <span className="subHeading">
                                        Paper {index1 + 1}
                                      </span>
                                      {res?.paper?.length !== 1 && (
                                        <>
                                          <span
                                            onClick={(e) => {
                                              let arr = res?.paper?.filter(
                                                (item, index3) =>
                                                  index3 !== index1
                                              );
                                              console.log(arr);
                                              let parrr = [...examList];
                                              parrr[index2].paper = arr;
                                              setExamList(parrr);
                                            }}
                                            className="deletStyle"
                                          >
                                            <i className="pi pi-trash me-2" />
                                            Delete
                                          </span>
                                        </>
                                      )}
                                    </div>
                                    <div className="row mt-4">
                                      <div className="col-md-6">
                                        <Label className="HelveticaMedium">
                                          Paper’s Full Name
                                        </Label>
                                        <input
                                          className="form-control"
                                          placeholder="Enter the Paper’s Name"
                                          value={res1.paperFullName}
                                          onChange={(e) => {
                                            if (e.target.value === " ") {
                                              e.target.value = "";
                                            } else {
                                              setExamList((pre) => {
                                                pre[index2].paper[
                                                  index1
                                                ].paperFullName =
                                                  e.target.value;
                                                console.log(pre);
                                                return [...pre];
                                              });
                                            }
                                          }}
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div className="row mt-3">
                                      <div className="col-md-6">
                                        <Label className="HelveticaMedium">
                                          Paper Date
                                        </Label>
                                        <Calendar
                                          placeholder="Enter the Paper’s Date"
                                          value={res1.papeDate}
                                          onChange={(e) => {
                                            setExamList((pre) => {
                                              pre[index2].paper[
                                                index1
                                              ].papeDate = e.target.value;
                                              return [...pre];
                                            });
                                          }}
                                          required
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <Label className="HelveticaMedium">
                                          Paper Code
                                        </Label>
                                        <input
                                          className="form-control"
                                          placeholder="Enter Paper Code"
                                          value={res1.PUid}
                                          onChange={(e) => {
                                            if (e.target.value === " ") {
                                              e.target.value = "";
                                            } else {
                                              setExamList((pre) => {
                                                pre[index2].paper[index1].PUid =
                                                  e.target.value;
                                                return [...pre];
                                              });
                                            }
                                          }}
                                          required
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                            <div className="d-flex  mt-5">
                              <span
                                className="addOrganize "
                                onClick={() => Newpapers(index2)}
                              >
                                <i className="pi pi-plus me-2" />
                                Add More Paper
                              </span>
                            </div>
                          </AccordionTab>
                        </Accordion>
                      </div>
                    </>
                  );
                })}
                <div className="d-flex  mt-5">
                  <span
                    className="addOrganize "
                    onClick={(e) => {
                      newExam(e);
                    }}
                  >
                    <i className="pi pi-plus me-2" />
                    Add More Exam
                  </span>
                </div>
                <div className="d-flex justify-content-end mt-5">
                  <div className="d-flex">
                    <button
                      className="btn btn-Secondary me-3"
                      onClick={() => navigate("/organization")}
                      id="organizationcancelButton"
                      type="button"
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-primary"
                      type="submit"
                      id="organizationNextbutton"
                      disabled={disableFunction()}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </>
      ) : (
        <>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSumitOrganize(e);
            }}
          >
            <div className="mainContainer">
              <div className="card p-md-4 p-sm-3 p-2">
             
                <h1>Add Organization</h1>

                <div className="organizeStep mt-2">
                  Add Organization Admin (Step 2 of 2)
                </div>
                <div>
                  <Infomation text="Upon clicking the “Add” button, the Organization will be added to the system and an invitation email will be automatically dispatched to the specified email address(s). This email will contain self-generated credentials, enabling the user to seamlessly log in to the system."></Infomation>
                </div>
                <div>
                  {error ? (
                    <>
                      <Infomation
                        type="warning"
                        text="Please add at least one admin to the organization during the setup process. Additional admins can be added later to accommodate evolving organizational needs."
                      ></Infomation>
                    </>
                  ) : (
                    ""
                  )}
                </div>
               

                {adminLen !== 0 ? (
                  <>
                  {
                      checkErrordata === 2 ?   <div>
                      {
                      contactExist ? (
                        <Infomation text="Contact is already exists" type="warning" />
                      ):(
                        ""
                      )
                    }
                      </div>:""
                    }
                  {checkErrordata === 1 ?
                  <>
                  {emailExixt ? (
                      <Infomation
                        text="Email is already exists"
                        type="warning"
                      />
                    ) : (
                      ""
                    )}
                  </>:""}

                    
                  
                    {adminList.map((item, index) => (
                      <>
                        <div className="d-flex justify-content-between mt-4">
                          <div className="subheadind ">
                            Organizational Admin {index + 1}
                          </div>
                          {adminList.length !== 1 && (
                            <>
                              <span
                                className="deletStyle pointer"
                                onClick={(e) => {
                                  let arr = adminList.filter(
                                    (item, index1) => index1 !== index
                                  );
                                  setindexError(indexError.filter((item, index1) => index1 !== index))
                                  setIndexEmail(indexEmail.filter((item,index1)=>index1 !==index))
                                  setAdminList(arr);
                                }}
                              >
                                <i className="pi pi-trash me-2" /> Delete
                              </span>
                            </>
                          )}
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-6">
                            <Label className="HelveticaMedium">Name</Label>
                            <input
                              className="form-control"
                              placeholder="Enter the Organizational Admin’s Name"
                              type="text"
                              value={item.AdminName}
                              onChange={(e) => {
                                if (e.target.value === " ") {
                                  e.target.value = "";
                                } else {
                                  adminNameFun(e.target.value, index);
                                }
                              }}
                              id="orgadminNameId"
                              required
                            ></input>
                          </div>
                          <div className="col-md-6">
                            <Label className="HelveticaMedium">
                              Admin Code
                            </Label>
                            <input
                              className="form-control"
                              placeholder="EVA-101-OA-240103"
                              value={adminList[index].AdUid}
                              onChange={adminList[index].AdUid}
                              id="orgadminId"
                              disabled
                            ></input>
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-md-6">
                            <Label className="HelveticaMedium">
                              Contact Number
                            </Label>
                            <input
                              className="form-control"
                              placeholder="Enter the Organizational Admin’s Contact Number"
                              value={item.contact}
                              onChange={(e) => {
                                contactAdmin(e.target.value, index);
                                setindexs(index);
                                setCheckErrorData(0)
                                if (e.target.value.length === 10) {
                                  // adminList[index].contact = e.target.value;
                                  setIsError(false);
                                  setindexError(e=>{
                                    e[index].erorvalue=false
                                    return [...e]
                                  })
                                } else {
                                  
                                  setIsError(true);
                                  console.log(indexError)
                                  setindexError(e=>{
                                    e[index].erorvalue=true
                                    return [...e]
                                  })
                                }
                              }}
                              type="number"
                              id="orgadminNumber"
                              required
                            ></input>
                            {indexError?.[index]?.erorvalue ? (
                          
                              <Infomation
                                text="Please Enter 10 digit contact number"
                                type="warning"
                              />
        
                        ) : (
                          ""
                        )}
                          </div>
                          <div className="col-md-6">
                            <Label className="HelveticaMedium">
                              Email Address
                            </Label>
                            <input
                              className="form-control "
                              placeholder="Enter the Organizational Admin’s Email Address"
                              // type="email"
                              value={item.emailAdmin}
                              onChange={(e) => {
                                setCheckErrorData(0)
                                if (e.target.value === " ") {
                                  e.target.value = "";
                                } else {
                                  setEmailAdmin(e.target.value, index);
                                  setEmailindexs(index)
                                }
                                if(!/\S+@\S+\.\S+/.test(e.target.value)){
                                  setEmailError(true)
                                  setIndexEmail(e=>{
                                    e[index].erroemail=true
                                    return [...e]})
                                }else{
                                  setEmailError(false)
                                  setIndexEmail(e=>{
                                    e[index].erroemail=false
                                    return [...e]})
                                }
                              }}
                              id="orgadmnemail"
                              required
                            ></input>
                             {indexEmail?.[index]?.erroemail ?(       
                <Infomation   text="Invalid email format "
                    type="warning"/>
    
            ):(<></>)}
                          </div>
                        </div>

                        
                        <div></div>
                      </>
                    ))}
                  </>
                ) : (
                  <></>
                )}

                <div className="d-flex  mt-5">
                  <button
                    className="addOrganize1 "
                    onClick={(e) => {
                      newAdmin(e);
                    }}
                    type="button"
                    // disabled={disablAddfunction()}
                  >
                    <i className="pi pi-plus me-2" />
                    More Organizational Admin
                  </button>
                </div>
                <div className="d-flex justify-content-end mt-5">
                  <div className="d-flex">
                    <button
                      className="btn btn-Secondary me-3"
                      onClick={() => navigate("/organization")}
                      id="organizationAdminCancel"
                      type="button"
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-Secondary me-3"
                      onClick={(e) => {
                        e.preventDefault()
                        setNext(1)
                      }}
                      id="organizationAdminBack"
                      type="button"
                    >
                      Back
                    </button>
                    <button
                      className="btn btn-primary"
                      type="submit"
                      id="organizationAdminAdd"
                      disabled={disablAddfunction()}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </>
      )}
      <Loader open={loading} />
    </>
  );
}
