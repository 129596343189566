import React, { useEffect, useRef, useState } from 'react'
import { userId, userInfo, userOrgId, userRole } from '../../../Components/Assets/userData';
import { baseUrl } from '../../../Services/EndPoint';
import { io } from 'socket.io-client';
import Peer from "simple-peer";
import { addRecording, addRecordingParts, getLastSession, uploadMultipleFiles } from '../../../Services/Admin/Api';
import { Dialog } from 'primereact/dialog';
import Infomation from '../../../Components/Assets/Infomation';
import moment from 'moment';
import style from "./style.module.css"
import { useNavigate } from 'react-router-dom';
import { loader } from '../../../utils/loader';

// const { desktopCapturer, ipcRenderer } = window.require("electron");

export const GetUserMediaDetails = ({ resetTimer, setIsTimerStartsEver, evaluatorStart, paperData, questionId, endEvalution }) => {
  let paperId = new URLSearchParams(window.location.search).get("paperId");
  let language = new URLSearchParams(window.location.search).get("language");
  let frontCameraStreamRef = useRef(null)
  let backCameraStreamRef = useRef(null)
  let frontCameraRecordingRef = useRef(null)
  let backCameraRecordingRef = useRef(null)
  let screenRecordStreamRef = useRef(null);
  let screenRecordingRef = useRef(null);
  let partStartTime = useRef(null);
  let navigate = useNavigate();
  let isFinalStopped = useRef(false)

  let [isAllCamerasStarted, setIsAllCamerasStarted] = useState(false);
  let currentPart = useRef(1)
  let currentSession = useRef(1)
  let sessionDocumentId = useRef(null)

  let [module, setModule] = useState(true);

  const peersRef = useRef([]);
  let allRecordings = useRef({
    frontCameraFile: null,
    backCameraFile: null,
    screenFile: null
  })

  const roomID = "UqwhbsvaybxjzkU12132Y";
  const socketRef = io(baseUrl, {
    auth: {
      id: userId(),
      role: userRole(),
    },
  });

  let [devices, setDevices] = useState([]);

  const deviceData = async () => {
    try {
      let dd = navigator
      console.log(dd);
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );
      setDevices(videoDevices);
    }
    catch (err) {
      console.log(err)
    }
  };

  useEffect(() => {
    deviceData();
  }, []);

  let frontCameraVideoRef = useRef(null)
  let backCameraVideoRef = useRef(null)
  let screenVideoRef = useRef(null)

  async function startBackCameraRef(deviceId) {
    backCameraStreamRef.current = await navigator.mediaDevices.getUserMedia({
      video: { deviceId: devices[0].deviceId },
      audio: true
    }
    );
    backCameraVideoRef.current.srcObject = backCameraStreamRef.current;

    let room = {
      roomID,
      name: userInfo().name,
      role: "evaluator",
      orgId: userOrgId(),
      paperId: paperId,
      type: "Back",
    };
    socketRef.emit("join room", room);
    socketRef.on("user joined", (payload) => {
      console.log("User joined:", payload);
      const peer = addPeer(payload.signal, payload.callerID, backCameraStreamRef.current);
      peersRef.current.push({
        peerID: payload.callerID,
        peer,
      });
    });

  }

  function addPeer(incomingSignal, callerID, stream) {
    try {
      const peer = new Peer({
        initiator: false,
        trickle: false,
        stream,
        config: {
          iceServers: [
            {
              url: "turn:192.158.29.39:3478?transport=udp",
              credential: "JZEOEt2V3Qb0y27GRntt2u2PAYA=",
              username: "28224511:1379330808",
            },
            {
              url: "turn:192.158.29.39:3478?transport=tcp",
              credential: "JZEOEt2V3Qb0y27GRntt2u2PAYA=",
              username: "28224511:1379330808",
            }
            // Add TURN servers here if needed
          ],
        },
      });

      peer.on("signal", (signal) => {
        console.log(signal, "signal");
        socketRef.emit("returning signal", { signal, callerID });
      });

      peer.signal(incomingSignal);

      return peer;
    } catch (error) {
      console.error("Error add peer:", error);
      // Handle error (e.g., fallback mechanism, user notification)
    }
  }

  async function startFrontCameraRef(deviceId) {
    frontCameraStreamRef.current = await navigator.mediaDevices.getUserMedia({
      video: { deviceId: devices[1].deviceId },
      audio: true
    }
    );
    frontCameraVideoRef.current.srcObject = frontCameraStreamRef.current;


    let room = {
      roomID,
      name: userInfo().name,
      role: "evaluator",
      orgId: userOrgId(),
      paperId: paperId,
      type: "Front"
    };
    socketRef.emit("join room", room);

    socketRef.on("user joined", (payload) => {
      console.log("User joined:", payload);
      const peer = addPeer(payload.signal, payload.callerID, frontCameraStreamRef.current);
      peersRef.current.push({
        peerID: payload.callerID,
        peer,
      });
    });
  }

  async function startScreenRecordRef() {
    screenRecordStreamRef.current = await navigator.mediaDevices.getUserMedia(
      {
        audio: false,
        video: {
          mandatory: {
            chromeMediaSource: 'desktop',
          }
        }
      }
    );
    screenVideoRef.current.srcObject = screenRecordStreamRef.current;
    let room2 = {
      roomID,
      name: userInfo().name,
      role: "evaluator",
      orgId: userOrgId(),
      paperId: paperId,
      type: "Screen",
    };
    socketRef.emit("join room", room2);

    socketRef.on("user joined", (payload) => {
      console.log("User joined:", payload);
      const peer = addPeer(payload.signal, payload.callerID, screenRecordStreamRef.current);
      peersRef.current.push({
        peerID: payload.callerID,
        peer,
      });
    });
  }


  async function startFrontCameraVideo() {
    let frontChunks = [];
    frontCameraRecordingRef.current = new MediaRecorder(frontCameraStreamRef.current);
    frontCameraRecordingRef.current.ondataavailable = event => {
      if (event.data.size > 0) {
        frontChunks.push(event.data);
      }
    };
    frontCameraRecordingRef.current.onstop = async () => {
      let file = new File(
        [new Blob(frontChunks, { type: "video/webm" })],
        `${Math.random() + Math.random()}`,
        { type: "video/webm", lastModified: Date.now() }
      );

      frontChunks = [];
      allRecordings.current.frontCameraFile = file

    };
    frontCameraRecordingRef.current.start()
  }

  async function startBackCameraVideo() {
    let backChunks = [];
    console.log(backCameraStreamRef.current)
    backCameraRecordingRef.current = new MediaRecorder(backCameraStreamRef.current);
    backCameraRecordingRef.current.ondataavailable = event => {
      if (event.data.size > 0) {
        backChunks.push(event.data);
      }
    };
    backCameraRecordingRef.current.onstop = async () => {

      let file = new File(
        [new Blob(backChunks, { type: "video/webm" })],
        `${Math.random() + Math.random()}`,
        { type: "video/webm", lastModified: Date.now() }
      );

      backChunks = [];
      allRecordings.current.backCameraFile = file

    };
    backCameraRecordingRef.current.start()
  }
  async function startScreenRecordVideo() {
    let screenChunks = [];

    screenRecordingRef.current = new MediaRecorder(screenRecordStreamRef.current);
    screenRecordingRef.current.ondataavailable = event => {
      if (event.data.size > 0) {
        screenChunks.push(event.data);
      }
    };
    screenRecordingRef.current.onstop = async () => {
      // const blob = new Blob(screenChunks, { type: 'video/webm' });
      // const url = URL.createObjectURL(blob);
      // const a = document.createElement('a');
      // a.href = url;
      // a.download = 'screen_capture.webm';
      // document.body.appendChild(a);
      // a.click();
      // window.URL.revokeObjectURL(url);
      let file = new File(
        [new Blob(screenChunks, { type: "video/webm" })],
        `${Math.random() + Math.random()}`,
        { type: "video/webm", lastModified: Date.now() }
      );

      allRecordings.current.screenFile = file
      uploadRecordings(allRecordings.current)
      screenChunks = [];
    };
    screenRecordingRef.current.start()
  }

  function startRecording() {
    partStartTime.current = new Date()
    startBackCameraVideo()
    startFrontCameraVideo()
    startScreenRecordVideo()
  }

  function stopRecording() {
    frontCameraRecordingRef.current.stop()
    backCameraRecordingRef.current.stop()
    screenRecordingRef.current.stop()
  }

  let recordingIntervalRef = useRef()

  function finalStart() {
    startRecording()
    recordingIntervalRef.current = setInterval(() => {
      stopRecording();
      startRecording();
    }, 900000);
  }

  function finalStopRecording() {
    clearInterval(recordingIntervalRef.current)
    stopRecording()
    if (frontCameraStreamRef.current) {
      const tracks = frontCameraStreamRef.current.getTracks();
      tracks.forEach(track => track.stop());
      frontCameraStreamRef.current = null;
    }
    if (backCameraStreamRef.current) {
      const tracks = backCameraStreamRef.current.getTracks();
      tracks.forEach(track => track.stop());
      backCameraStreamRef.current = null;
    }
    if (screenRecordStreamRef.current) {
      const tracks = screenRecordStreamRef.current.getTracks();
      tracks.forEach(track => track.stop());
      screenRecordStreamRef.current = null;
    }
  }

  async function uploadRecordings(files) {
    console.log(files, ":::all file alll files alll files")
    let allFiles = Object.keys(files).map(e => files[e])
    let part_start_time = new Date(partStartTime.current).toISOString()
    let user_id = userId()
    let session = currentSession.current
    let part = currentPart.current

    currentPart.current += 1

    try {
      // console.log(paperData)
      let res = await uploadMultipleFiles(allFiles, user_id, session, part)
      // console.log(res.data.data, "fiels")

      let [
        { fileUrl: frontCameraFileUrl, fileName: frontCameraFileName },
        { fileUrl: backCameraFileUrl, fileName: backCameraFileName },
        { fileUrl: screenFileUrl, fileName: screenFileName }
      ] = res.data.data

      let body = {
        screenFileName,
        screenFileUrl,
        frontCameraFileName,
        frontCameraFileUrl,
        backCameraFileName,
        backCameraFileUrl,
        part: new Date(part).toISOString(),
        part_end_time: new Date().toISOString(),
        part_start_time,
        part_number: part,
        duration: moment.duration(moment(new Date()).diff(moment(part_start_time))).asSeconds()
      }
      console.log(files)

      if (!sessionDocumentId.current) {
        let body2 = {
          evaluatorId: userId(),
          question_paper_id: paperData?.paperData[0]?._id,
          start_time: new Date(),
          end_time: "2024-02-26T19:45:57.475+00:00",
          duration: "33:00",
          session: currentSession.current,
          orgId: userOrgId(),
          session_parts: [body]
        }

        let res2 = await addRecording(body2)
        console.log(res2);
        sessionDocumentId.current = res2.data.data._id
      }

      else {
        let updatedData = await addRecordingParts(sessionDocumentId.current, body)
        console.log  (updatedData, "updateed updated u pdae")
      }

      if (isFinalStopped.current) {
        stopEvaluation()
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  const processButtonDisable = () => {
    if (
      frontCameraVideoRef?.current?.srcObject &&
      backCameraVideoRef?.current?.srcObject &&
      screenVideoRef?.current?.srcObject
    ) {
      return false;
    } else {
      return true;
    }
  };


  async function getCurrentSession() {
    try {
      let res = await getLastSession(userId())
      console.log(res.data.data)
      currentSession.current = res.data.data.session
    }
    catch (err) {
      console.log(err);
    }
  }


  useEffect(() => {
    getCurrentSession()
  }, [])

  function closeDialog() {
    navigate(
      `/assignedQuestions/assignedQuestionsView/${questionId}?paperId=${paperId}&language=${language}`
    );
  };

  function stopEvaluation() {
    socketRef.emit("roomOff", { roomID, orgId: userOrgId(), userId: userId() });
    endEvalution()
    loader.stop()
    navigate(
      `/assignedQuestions/assignedQuestionsView/${questionId}?paperId=${paperId}&language=${language}`
    );
  };


  return (
    <>
      <button
        className={`${style.evaluation_action_btn}`}
        onClick={() => {
          finalStopRecording()
          isFinalStopped.current = true
          loader.start()
        }}
      >
        Stop Evaluation
      </button>
      <Dialog
        visible={module}
        modal
        style={{ width: "80rem", height: "auto" }}
        onHide={closeDialog}
        draggable={false}
      >
        <div className="d-flex ms-5">
          <Infomation
            type="warning"
            text="Permission Required: To proceed with the evaluation process, kindly grant access to the front camera, back camera, and screen sharing."
          />
        </div>
        <div className=" d-flex justify-content-around mt-3">
          <div>
            <video
              className="border"
              style={{}}
              width="320"
              height="240"
              playsInline
              autoPlay
              muted
              ref={frontCameraVideoRef}
            />
            <br />
            <button
              onClick={startFrontCameraRef}
              className="btn btn-secondary mt-3"
            >
              Front Camera
            </button>
          </div>
          <div className="">
            <video
              className="border"
              width="320"
              height="240"
              playsInline
              autoPlay
              muted
              ref={backCameraVideoRef}
            />
            <br />
            <button
              onClick={startBackCameraRef}
              className="btn btn-secondary mt-3"
            >
              Back Camera
            </button>
          </div>

          <div>
            <video
              className="border"
              width="320"
              height="240"
              playsInline
              autoPlay
              muted
              ref={screenVideoRef}
            />
            <br />
            <button
              className="btn btn-secondary mt-3"
              onClick={startScreenRecordRef}
            >
              Start Screen
            </button>
          </div>
        </div>
        <div className="d-flex mt-5 ms-4 ps-3">
          <button
            className="btn btn-primary"
            // disabled={processButtonDisable()
            //   // && activeAnswerSheet?.evaluator_status?.status
            // }
            onClick={() => {
              if (!processButtonDisable()) {
                setModule(false);
                finalStart()
                resetTimer()
                setIsTimerStartsEver(true)
                evaluatorStart();
              }
              else {
                alert("Please run all cameras")
              }

            }}
          >
            Proceed to Evaluation
          </button>
        </div>
      </Dialog>


    </>
  )
}
