

module.exports = {
    baseUrl: "https://evaluator-backend-eeb5dcd4eb02.herokuapp.com",
    // baseUrl: "http://localhost:3002",
    // baseUrl: "http://192.168.1.29:3002",
    auth: {
        authLogin: "/userAuth/login",
        authResetPassword: "/userAuth/resetPasword",
        authemailVerification: "/userAuth/emailCheck",
        authDisable: "/user/disableFirstTimeLogin",
        authnumberCheck: "/userAuth/phoneCheck",
    },
    organize: {
        addOrganized: "/organization/addOrganization",
        organizedById: "/organization/getOrgById/",
        getAllorganizationList: "/organization/orgListing",
        organizationUpdateById: "/organization/updateOrgById/",
        organizationDeleteById: "/organization/deleteOrgById/",
        updateOrgTimer: "/organization/updateOrgTimer/"
    },
    orgAdmin: {
        addAdmin: "/admin/addAdmin",
        adminListing: "/admin/allAdminListing",
        adminListingById: "/admin/adminListing/",
        adminGetById: "/admin/getAdminById/",
        adminDeleteById: "/admin/deleteAdminById/",
        adminUpdatedById: "/admin/updateAdminById/",
        adminDisableEnable: "/admin/isActiveData/",
    },
    userProfile: {
        addUser: "/user/addUser",
        usersListingById: "/user/getUserByOrgId/",
        getUserById: "/user/getUserById/",
        updateuserById: "/user/updateUserById/",
        userDeleteById: "/user/deleteUserById/",
        userActiveById: "/user/isActiveData/"
    },
    questionPaper: {
        addpaper: "/paper/addPaper",
        getPaperById: "/paper/getPaperById/",
        updatePaperById: "/paper/updatePaperById/",
        deletePaperById: "/paper/deletePaperById/",
        updatePaperView: "/paper/updatePaperView/",
        deletePaper: "/paper/deletePaperById/",
        paperConfirm:"/paper/confirmEvaluator/"

    },
    exam: {
        addexam: "/exam/addExam",
        getExamByOrgId: "/exam/getExamByOrgId/",
        getExamWithPaperList: "/exam/getExamwithPaperList",
        getExamById: "/exam/getExamById/",
        examUpdateById: "/exam/updateExamById/",
        examandpaper: "/exam/getExamwithPaperList/",
        deleteExam: "/exam/deleteExamById/",
        getPaperByExamId: "/exam/getQuestionPaperOfExam/",
        examDataDeclared:"/exam/examDeclared/",
        examDeclaredataList:"/exam/examDeclaredDataList",
        getExamDeclaredDateOfOrgAdmin:"/exam/examDeclaredDataListOrgAdmin/"


    },
    file: {
        upload: "/upload/doc",
        uploadMultiple: "/upload/uploadMultipleDocsData?",
        delete: "/upload/deleteDoc"
    },

    question: {
        addQuestion: "/question/addQuestion",
        deleteQuestion: "/question/deleteQuestionById/",
        updateQuestion: "/question/updateQuestionById/",
        questionApproved: "/paper/guildlineStatus/",
        getQuestionByEvaluatorId: "/question/getQuestionbyEvaluatorAndPaper/",
        assignThirdEvaluator: "/question/assignEvaluator3/",
        overViewEvaluatorById: "/question/getAssignedPapersByEvaluator/"

    },
    answerSheet: {
        getFolderName: "/s3/gettingData",
        copyAnswerSheet: "/s3/singleDataBucketFunctionReload",
        getOurS3Data: "/s3/gettingBucketProgramFunctions",
        getFolderData: "/s3/getDataInsideFolder",
        deleteFolder: "/s3/deleteData",
        getAnswerSheetByPaperId: "/answerSheet/getAnswerSheetByPaperId/",
        getAnswerSheetForEvaluatorNotSubmitted: "/answerSheet/getAnswerSheetForEvaluatorNotSubmitted/",
        getAnswerSheetForEvaluator: "/answerSheet/getAnswerSheetForEvaluator/",
        submitAnswerSheetResult: "/answerSheet/submitResult/",
        updateAnswerSheetDraftResult: "/answerSheet/updateDraftedData/",
        hodAnswerSheetlist: "/answerSheet/getAnswerSheetFoldersByOrgId",
        hodAnswerSheetDetailList: "/answerSheet/getAnswerSheetByOrgId/",
        checkAnswerSheetTotalMarks: "/answerSheet/checkAnswerSheetTotalMarks/",
        paperWithDifferenceOverViewOrg:"/answerSheet/answerSheetDataByOrgId/",
        answerSheetPaperDataForDeclare:"/answerSheet/paperDataForDeclare/",
        answerSheetPaperDataForOrg:"/answerSheet/paperDataForDeclareOrgAdmin"
    },
    recording: {
        getAllRecording: "/recording/getRecordings",
        getEvaluatorList: "/recording/getEvluatorRecordingList",
        getRecordingOfEvaluatorById: "/recording/getRecordingsOfEvaluator/",
        deleteRecording: "/recording/deleteRecording/",
        addEvaluationRecording: "/recording/addRecording",
        getRecordingById: "/recording/getRecordingById/",
        getPartData: "/recording/getPartData/",
        addRecording: "/recording/addRecording",
        addRecordingParts: "/recording/addRecordingPartsForSession/",
        getLastSession: "/recording/getLastSession/",
        getRecordingSessionJson:"/recording/getRecordingSessionJson/"
    },
    evaluator: {
        getAssignQuestion: '/question/assignQuestionlistByEvalutorId/',
        getFullAssignQuestionListing: '/question/fullQuestionDataByid/',
        evaluatorListing: "/user/evaluatorListing/",
        activeEvaluatorList: "/user/activeEvaluators/",
        startEvaluating: "/user/startEvaluation/",
        endEvaluating: "/user/endEvaluation/",
        getEvaluatorsForThirdEvaluation: "/user/getAllEvaluatorForParticularPaperNotAssigned/"
    },
    report: {
        addReportCommandcenter: "/report/addReport",
        getReportListingByOrgId: "/report/getReportList/",
        getReportListById: "/report/getReportByid/",
    },
    notification: {
        addNotification: "/notification/addNotification",
        getorgNotification: "/notification/getNotificationForOrgAdmin/",
        getEvalNotifiaction: "/notification/getNotificationForEvaluator/",
        getcommandcenterNotification: "/notification/getNotificationForCommandCenter/",
        getSecreteryNotification: "/notification/getNotificationForSecretary/",
        getdatanotificationItAdmin: "/notification/getNotificationForItAdmin/",
        readOrgNotification: "/notification/readAllNotificationData/",
        getHodNotification:"/notification/getNotificationForHod/"
    },
    overview:{
        orgAdminOverviewLatest:"/recording/latestRecording/overview/"
    },
    results:{
        examDataForResultItAdmin:"/paper/examDataforResult",
        examResultOfOrgAdmn:"/paper/examDataforResultOrgAdmin/",
        ViewDeclareDataItAdmin:"/result/getDeclareResult/",
        viewDeclaredDataOrgAdmin:"/result/showResult"
    }
}