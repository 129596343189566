import React, { useEffect, useState } from "react";
import { TopBar } from "../../Components/Sidebar/Sidebar";
import { BackButton } from "../../Components/Assets/BackButton";
import TableContainer from "../../Components/Assets/TableContainer";
import {
  getrecordingOfEvaluatorById,
  userProfileGetById,
  deleteRecording,
} from "../../Services/Admin/Api";
import { useParams } from "react-router";
import Loader from "../../Components/Assets/Loader";
import moment from "moment";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router-dom";

export default function ViewRecording() {
  const [evalutorlistById, setEvaluatorListById] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [evaluatorId, setEvaluatorId] = useState();
  const [loading, setLoading] = useState(false);
  const [deleteid, setDeleteId] = useState();
  const [dateId, setDataId] = useState();
  const [paperNameId, setpaperNameId] = useState();
  const [visible, setVisible] = useState(false);
  const param = useParams();
  const [fileOpen, setFileOpen] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const handleSearchClick = (e) => {
    let data = [...searchData];
    let val = e.trim().toLowerCase();
    let searchName = data.filter((res) =>
      res?.paperName?.toLowerCase().includes(val)
    );
    setEvaluatorListById(searchName);
  };

  const getuserByid = async () => {
    setLoading(true);
    await userProfileGetById(param.id)
      .then((res) => {
        console.log(res);
        setEvaluatorId(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const getEvaluatorsById = async () => {
    setLoading(true);
    await getrecordingOfEvaluatorById(param.id)
      .then((res) => {
        console.log(res);
        let dataReverse = [...res.data.data];
        let reverData = dataReverse.reverse();
        setEvaluatorListById(reverData);

        setSearchData(reverData);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const recordingDelete = async (id) => {
    setLoading(true);
    await deleteRecording(id)
      .then((res) => {
        console.log(res);
        setLoading(false);
        setVisible(false);
        getEvaluatorsById();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  useEffect(() => {
    getEvaluatorsById();
    getuserByid();
  }, []);

let navigate=useNavigate()
  return (
    <>
      <TopBar />
      <Dialog
        visible={visible}
        modal
        style={{ width: "40rem" }}
        onHide={() => setVisible(false)}
        closable={false}
        draggable={false}
      >
        <h1>Delete Recording</h1>
        <p className="mt-3">
          Are you sure you want to delete the session recording for the date of
          &nbsp;{moment(dateId).format("DD/MMM/YYYY")} for the paper of{" "}
          {paperNameId}? This action is irreversible, and the recording cannot
          be recovered once deleted.
        </p>
        <div className="d-flex justify-content-end mt-4">
          <div className="d-flex">
            <button
              className="btn btn-Secondary me-3"
              onClick={() => {
                setVisible(false);
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                recordingDelete(deleteid);
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog
        visible={openModal}
        modal
        style={{ width: "60rem" }}
        onHide={() => setOpenModal(false)}
        closable={false}
        draggable={false}
        headerClassName="d-none"
      >
        <div className="pt-4">

          <div className="d-flex align-items-center">
            <video width="50%" controls>
              <source src={fileOpen?.screenFile} type="video/mp4" />
            </video>
            <video width="50%" controls>
              <source src={fileOpen?.cameraFile} type="video/mp4" />
            </video>
          </div>

          <div className="d-flex justify-content-end mt-2">
            <button
              className="btn btn-Secondary"
              onClick={() => {
                setOpenModal(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </Dialog>
      <div className="mainContainer">
        <div className="card p-md-4 p-sm-3 p-2">
          <BackButton link={"/recording"} />
          <div className="d-flex mt-3">
            {/* <div>
              <img src="/images/Avatar.png" />
            </div> */}
            <div className=" w-100 align-self-center text-start">
              <h1>{evaluatorId?.name}</h1>
              <p>{evaluatorId?.uid}</p>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center pt-4 pb-3">
            <h1>Session Logs</h1>
            <div className="searchInput w-25 me-3">
              <input
                className="form-control ps-5"
                placeholder="Search"
                onChange={(e) => {
                  handleSearchClick(e.target.value);
                }}
              ></input>
              <i className="pi pi-search searchIcon"></i>
            </div>
          </div>
          <div className="py-3">
            <TableContainer>
              <table className="left_align_table">
                <thead>
                  <th>S.No.</th>
                  <th>Date</th>
                  <th>Paper Name</th>
                  <th>Start Time</th>
                  <th>Session Duration</th>
                  <th>Session</th>
                  <th>End Time</th>
                  <th>&nbsp;</th>
                </thead>

                <tbody>
                  {evalutorlistById.sort((a, b) => a.session - b.session).map((res, index) => {
                    return (
                      <tr
                        key={index}
                        className="pointer"

                      >
                        <td>{index + 1}</td>
                        <td>{moment(res?.createAt).format("DD/MM/YYYY")}</td>
                        <td
                          onClick={() => {
                            // setOpenModal(true);
                            // setFileOpen({ screenFile: res.media_data.screenFileUrl, cameraFile: res.media_data.cameraFileUrl });
                            // console.log(res);
                            navigate(`/recording/viewsession/${res._id}`)
                          }}
                        >{res?.paperName}</td>
                        <td>{moment(res?.start_time).format("h:mm:ss a")}</td>
                        <td>{res?.duration}</td>
                        <td>{res?.session}</td>
                        <td>{moment(res?.end_time).format("h:mm:ss a")}</td>
                        <td>
                          <i
                            className="pi pi-trash pointer"
                            onClick={() => {
                              setVisible(true);
                              setDeleteId(res._id);
                              setpaperNameId(res.paperName);
                              setDataId(res.createAt);
                            }}
                          ></i>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </TableContainer>
          </div>
        </div>
      </div>
      <Loader open={loading} />
    </>
  );
}
