import React, { useEffect, useRef, useState } from 'react'
import { TopBar } from '../../../Components/Sidebar/Sidebar';
import { BackButton } from '../../../Components/Assets/BackButton';
import TableContainer from '../../../Components/Assets/TableContainer';
import PopOver from '../../../Components/Assets/PopOver';
import { PopoverItem } from '../../../Components/Assets/PopoverItem';
import { getViewDataListOfOrgAdmn } from '../../../Services/Admin/Api';
import { useLocation, useParams } from 'react-router-dom';
import { userOrgId } from '../../../Components/Assets/userData';
import { loader } from '../../../utils/loader';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useReactToPrint } from 'react-to-print';
import Paginator from '../../../Components/Assets/Paginator';


export default function OrgViewResultDeclare() {
  

      const [viewResultDeclare,setViewResultDeclare] = useState([])
      const [headerData,setHeaderData] = useState([])
  const [flagData, setFlagData] = useState([]);
  const [paginationData,setPaginationData] = useState([])

  const componentPDF = useRef()

  const [searchData, setSearchData] = useState([]);
  const op = useRef(null);

  let paramId = useParams();
  const location = useLocation();
  let examName = location?.state;


  const generatePDF = useReactToPrint({
    content:()=>componentPDF.current,
    documentTitle:"userData",
    // onAfterPrint:()=>alert("Data saved in PDF")
  })

      const getOrgViewData =async () =>{
        let payloadData = {
          examId:paramId,
          orgId:userOrgId()
        }
        loader.start();
        await getViewDataListOfOrgAdmn(payloadData).then((res)=>{
          console.log(res)
          loader.stop();
        let resData = res.data.data.map((e) => {
          let obj = e;
          obj["finalScore"] = e.paperResult.reduce((total, data) => {
            return total + data.paperMarks;
          }, 0);
          return obj;
        });
        let sortData1 = resData.sort((a, b) => a.finalScore - b.finalScore);
        let sortData = sortData1.reverse();
        setViewResultDeclare(sortData);
        setHeaderData(sortData[0].paperResult);
        setSearchData(res.data.data)
        setFlagData(res.data.data);

        }).catch((err)=>{
          console.log(err)
          loader.stop();
        })
      }

      const handleSearchClick = (e) => {
        let data = [...searchData];
        let val = e.trim().toLowerCase();
        let searchName = data.filter((res) =>
          res?.sheetCode?.toLowerCase().includes(val)
        );
        setViewResultDeclare(searchName);
      };
      useEffect(()=>{
        getOrgViewData()
      },[])
  return (
    <>
 <TopBar />
      <div className="mainContainer">
        <div className="card p-md-4 p-sm-3 p-2">
          <BackButton link={"/orgAdminMainTab"} />
          <div className="d-flex justify-content-between align-items-center pt-4 pb-3">
            <h5>{examName}</h5>
            <div className="d-flex">
              
            <div className="searchInput w-75 me-3">
              <input
                className="form-control ps-5"
                placeholder="Search"
                onChange={(e) => {
                  handleSearchClick(e.target.value);
                }}
              ></input>
              <i className="pi pi-search searchIcon"></i>
            </div>
            <div className="ms-2">

            {/* <img
              src="/images/import.png"
              onClick={(e) => {
                op.current.toggle(e);
              }}
              className="pointer"
            ></img> */}
            <button className="btn btn-primary"   onClick={(e) => {
                op.current.toggle(e);
              }}>Export</button>
            <OverlayPanel ref={op}>
              <div className="popover_item pointer" onClick={generatePDF}>PDF</div>
              {/* <div className="popover_item pointer">Excel</div> */}
            </OverlayPanel>
            </div>
            </div>
          </div>
          <div className="py-3">
          <TableContainer>
          <div ref={componentPDF} style={{width:"100%"}}>
          <table className="left_align_table">
              <thead>
                <th>Rank</th>
                <th>Sheet Code</th>
                <th>Final Score</th>
                {headerData.map((resdata) => {
                    return (
                      <>
                        <th>{resdata?.paperName}</th>
                      </>
                    );
                  })}
              </thead>
              <tbody>
                {paginationData.map((res, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                        <td>{res.sheetCode}</td>
                        <td>{res.finalScore}</td>
                        {res.paperResult.map((res2) => {
                          return (
                            <>
                              <td>{res2.paperMarks}</td>
                            </>
                          );
                        })}
                      
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          
            </TableContainer>
            <div className="mt-4">
              <Paginator itemsPerPage={10} items={viewResultDeclare} setShowData={setPaginationData} hideButtons={false} flagg={flagData}/>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
