import React, { useEffect, useState } from 'react'
import { TopBar } from '../../../Components/Sidebar/Sidebar'
import TableContainer from '../../../Components/Assets/TableContainer'
import { useNavigate } from 'react-router-dom'
import { userId } from '../../../Components/Assets/userData'
import { getAssignPaperByEvaluatorId } from '../../../Services/Admin/EvaluatorApi'
import moment from 'moment'
import { loader } from '../../../utils/loader'

export default function AssignedPapersListing() {
    const navigate = useNavigate()
    const [tableData, setTableData] = useState([])
    const [tableDataBackUp, setTableDataBackUp] = useState([])

    async function getListingData() {
        let id = await userId()
        try {
            loader.start()
            let allData = await getAssignPaperByEvaluatorId(id)
            setTableData(allData.data.data)
            loader.stop()
            setTableDataBackUp(allData.data.data)
        } catch (err) {
            loader.stop()
            console.log(err)
        }
    }

    function handleSearch(e) {
        let arr = [...tableDataBackUp]
        let newArr = arr.filter(res => res?.paperData[0]?.paperName.toLocaleLowerCase().includes(e.toLocaleLowerCase()))
        setTableData(newArr)
    }

    useEffect(() => {
        getListingData()
    }, [])


    return (
        <>
            <TopBar />
            <div className="mainContainer">
                <div className="card p-md-4 p-sm-3 p-2">
                    <div className='d-flex justify-content-between align-items-center pt-4 pb-3'>
                        <h1>Assigned Questions ({tableDataBackUp.length})</h1>
                        <div className="searchInput w-25 me-3">
                            <input
                                className="form-control ps-5"
                                placeholder="Search"
                                onChange={(e) => {
                                    if (e.target.value == ' ') {
                                        e.target.value = ''
                                    } else {
                                        handleSearch(e.target.value);
                                    }
                                }}
                            ></input>
                            <i className="pi pi-search searchIcon"></i>
                        </div>
                    </div>
                    <div className='py-3'>
                        <TableContainer>
                            <table className="left_align_table">
                                <thead>
                                    <th>Paper Code</th>
                                    <th>Paper Name</th>
                                    <th>Exam Name</th>
                                    <th>Language</th>
                                    <th>Ques. No.</th>
                                    {/* <th>Pending Sheets</th>
                                    <th>Assigned Sheets</th> */}
                                    <th>Exam Date</th>
                                </thead>
                                <tbody>
                                    {tableData?.map((res, index) => {
                                        return (
                                            <tr className='pointer'
                                                key={index}
                                                onClick={() => navigate(`/assignedQuestions/assignedQuestionsView/${res._id}?paperId=${res.paperId}&language=${res.language}`)}
                                            >
                                                <td ><img width='36px' height='36px' src={`/images/questionPaper/questionTypeIcons${index < 6 ? index + 1 : 1}.svg`} className='me-2' /> {res?.paperData[0]?.paperCode}</td>
                                                <td>{res?.paperData[0]?.paperName}</td>
                                                <td>{res?.examData[0]?.examName}</td>
                                                <td>{res?.language}</td>
                                                <td>{res?.quesNo}</td>
                                                {/* <td>1200</td>
                                                <td>1200</td> */}
                                                <td>{moment(res?.paperData[0]?.paperDate).format("DD MMM, YYYY")}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </TableContainer>
                    </div>
                </div>
            </div>
        </>
    )
}
