import React, { useEffect, useState } from "react";
import { TopBar } from "../../../Components/Sidebar/Sidebar";
import { BackButton } from "../../../Components/Assets/BackButton";
import { Label } from "reactstrap";
import { Select } from "../../../Components/Inputs/Select";
import TableContainer from "../../../Components/Assets/TableContainer";
import { PAPER_LANGUAGES } from "../../../constant";
import { useParams } from "react-router-dom";
import { getPaperById } from "../../../Services/Admin/Api";
import moment from "moment";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router-dom";

export default function HodPaperData() {
  const [paperData, setPaperData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectLanguage, setSelectLanguage] = useState();
  const [modalOpen1, setModalOPen1] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [ModalOPen3, setModalOpen3] = useState(false);
  const [fileOpen, setFileOpen] = useState("");
  const [fileOpen1, seFileOpen1] = useState("");
  const [fileOpen2, setFileOpen2] = useState("");
  let params = useParams();

  const getPaperData = async () => {
    setLoading(true);
    await getPaperById(params.id)
      .then((res) => {
        let paperData = res.data.data;
        setPaperData(paperData);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  useEffect(() => {
    getPaperData();
  }, []);
  return (
    <>
      <TopBar />
      <Dialog
        style={{ width: "60rem" }}
        visible={modalOpen1}
        modal
        onHide={() => {
          setModalOPen1(false);
        }}
        closable={false}
        draggable={false}
      >
        {console.log(fileOpen)}
        <iframe
          src={fileOpen}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          style={{ width: "100%", height: "700px" }}
        ></iframe>
        <div>
          <button
            className="btn btn-Secondary"
            onClick={() => {
              setModalOPen1(false);
            }}
          >
            Cancel
          </button>
        </div>
      </Dialog>
      <Dialog
        style={{ width: "60rem" }}
        visible={modalOpen2}
        modal
        onHide={() => {
          setModalOpen2(false);
        }}
        closable={false}
        draggable={false}
      >
        <iframe
          src={fileOpen1}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          style={{ width: "100%", height: "700px" }}
        ></iframe>
        <div>
          <button
            className="btn btn-Secondary"
            onClick={() => {
              setModalOpen2(false);
            }}
          >
            Cancel
          </button>
        </div>
      </Dialog>
      <Dialog
        style={{ width: "60rem", height: "60rem" }}
        visible={ModalOPen3}
        modal
        onHide={() => {
          setModalOpen3(false);
        }}
        closable={false}
        draggable={false}
      >
        <iframe
          src={fileOpen2}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          style={{ width: "100%", height: "700px" }}
        ></iframe>
        <div>
          <button
            className="btn btn-Secondary"
            onClick={() => {
              setModalOpen3(false);
            }}
          >
            Cancel
          </button>
        </div>
      </Dialog>
      <div className="mainContainer">
        <div className="card p-md-4 p-sm-3 p-2">
          <div className="d-flex justify-content-between">
            <BackButton link={"/hodquestiontab"} />
          </div>
          <br />
          <section className="d-flex justify-content-between">
            <div className="d-flex align-items-center gap-3">
              <div>
                <img src="/images/questionPaper/questionTypeIcons4.svg" />
              </div>
              <div>
                <h1>{paperData?.paperCode}</h1>
                <div>{paperData?.paperName}</div>
              </div>
            </div>
            <div className="d-flex gap-3 align-items-center">
              <div>
                <div>{moment(paperData.paperDate).format("MMM/DD/YYYY")}</div>
                <div>Paper Date</div>
              </div>
            </div>
          </section>

          <div className="row mt-4">
            <div className="col-md-6">
              <Label className="HelveticaMedium">Question Paper Format</Label>
              {paperData?.question_skeleton_fileName ? (
                <div
                  className="border rounded-2 p-2 pointer"
                  onClick={() => {
                    setModalOPen1(true);
                    setFileOpen(paperData?.question_skeleton_Link);
                    console.log(paperData);
                  }}
                >
                  {paperData?.question_skeleton_fileName}
                </div>
              ) : (
                <input className="form-control" placeholder="-Na-" disabled />
              )}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-12">
              <Label className="HelveticaMedium">Evaluation Guidelines</Label>
              {paperData.guildline ? (
                <div
                  dangerouslySetInnerHTML={{ __html: paperData?.guildline }}
                  className="border rounded-2 p-2 pointer"
                ></div>
              ) : (
                <input className="form-control" placeholder="-Na-" disabled />
              )}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-6 col-12">
              <Label className="HelveticaMedium">Question Paper</Label>
              {paperData?.question_paper_fileName ? (
                <div
                  className="border rounded-2 p-2 pointer"
                  onClick={() => {
                    setModalOpen2(true);
                    seFileOpen1(paperData.question_paper_link);
                  }}
                >
                  {paperData?.question_paper_fileName}
                </div>
              ) : (
                <input className="form-control" placeholder="-Na-" disabled />
              )}
            </div>
            <div className="col-lg-6 col-12">
              <Label className="HelveticaMedium">Answer Key</Label>
              {paperData?.answerKey_fileName ? (
                <div
                  className="border rounded-2 p-2 pointer"
                  onClick={() => {
                    setModalOpen3(true);
                    setFileOpen2(paperData.answerKey_link);
                  }}
                >
                  {paperData?.answerKey_fileName}
                </div>
              ) : (
                <input className="form-control" placeholder="-Na-" disabled />
              )}
            </div>
          </div>
          <div className="d-flex justify-content-between mt-5">
            <h1>Question {paperData?.questions?.length}</h1>
            <Select
              placeholder="Select Language"
              options={PAPER_LANGUAGES}
              value={selectLanguage}
              onChange={(e) => {
                setSelectLanguage(e.target.value);
              }}
            />
          </div>
          <div className="py-3">
            <TableContainer>
              <table className="left_align_table">
                <thead>
                  <th>Ques No.</th>
                  <th>Language</th>
                  <th>Question</th>
                  <th>Evaluator 1</th>
                  <th>Evaluator 2</th>
                </thead>
                <tbody>
                  {paperData?.questions?.map((res, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{res?.language}</td>
                        <td>
                          <div
                            dangerouslySetInnerHTML={{ __html: res?.question }}
                          ></div>
                        </td>
                        <td>{res?.evaluator1Name}</td>
                        <td>{res?.evaluator2Name}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </TableContainer>
          </div>
        </div>
      </div>
    </>
  );
}
