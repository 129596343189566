import React from 'react'
import { BsPlusCircle } from 'react-icons/bs'
import PopOver from '../../Components/Assets/PopOver'
import { AddUpdateQuestion } from './AddUpdateQuestion'
import { PopoverItem } from '../../Components/Assets/PopoverItem'
import { ConfirmationModal } from '../../Components/Assets/ConfirmationModal'
import { loader } from '../../utils/loader'
import { deleteQuestion } from '../../Services/Admin/Api'

export const AssignEvaluator = ({ paperData, onAddUpdate }) => {


    async function questionDelete(id) {
        try {
            loader.start()
            await deleteQuestion(id)
            onAddUpdate()
            loader.stop()
        }
        catch (err) {
            console.log(err)
            loader.stop()
        }
    }

    
    return (
        <div>
            <label className='HelveticaMedium'>Questions ({paperData?.questions?.length})</label>

            <table className="mt-5">
                <thead>
                    <th>Ques No.</th>
                    <th>Section</th>
                    <th>Language</th>
                    <th>Question</th>
                    <th>Evaluator 1</th>
                    <th>Evaluator 2</th>
                    <th></th>
                </thead>
                <tbody>

                    {paperData?.questions.map((res) => {
                        return (
                            <tr key={res._id}>
                                <td>{res.quesNo}</td>
                                <td>{res.section}</td>
                                <td>{res.language}</td>
                                <td>
                                    <div dangerouslySetInnerHTML={{ __html: res.question }} />
                                </td>
                                <td>{res.evaluator1Name}</td>
                                <td>{res.evaluator2Name}</td>
                                <td>
                                    <PopOver>
                                        <AddUpdateQuestion onSubmit={onAddUpdate} mode={"Update"} paperData={paperData} data={res} />
                                        <ConfirmationModal data={res._id} onSubmit={questionDelete} header={"Delete"} text={"Are you sure you want to delete question ?"}>
                                            <PopoverItem text={"Delete"} icon={<i className='pi pi-trash' />} />
                                        </ConfirmationModal>
                                    </PopOver>
                                </td>
                            </tr>
                        )
                    })}

                </tbody>
            </table>
            <br />
            <AddUpdateQuestion onSubmit={onAddUpdate} paperData={paperData} mode="Create" />
        </div>
    )
}
