import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../Pages/Authentication/Login";
import { useNavigate } from "react-router-dom";
import { LogingKeeper } from "../Auth/LogingKeeper";
import ResetPassword from "../Pages/Authentication/ResetPassword";
import EmailVarification from "../Pages/Authentication/EmailVarification";
import Biometric from "../Pages/Authentication/Biometric";
import ForgetBiometric from "../Pages/Authentication/ForgetBiometric";
import ForgotResetPassword from "../Pages/Authentication/ForgotResetPassword";
import Overview from "../Pages/Overview/Overview";
import Organization from "../Pages/Organization/Organization";
import Notification from "../Pages/Notification/Notification";
import AddOverview from "../Pages/Overview/AddOverview";
import AddOrganization from "../Pages/Organization/AddOrganization";
import ForgetPasswordChange from "../Pages/Authentication/ForgetPasswordChange";
import BiometricSecond from "../Pages/Authentication/BiometricSecond";
import EditOrganization from "../Pages/Organization/EditOrganization";
import AddAdminstration from "../Pages/Adminstration/AddAdminstration";
import AdminstartionList from "../Pages/Adminstration/AdminstartionList";
import EditAdminstration from "../Pages/Adminstration/EditAdminstration";
import { Questions } from "../Pages/QuestionsPaper/Questions";
import { CreateQuestionPaper } from "../Pages/QuestionsPaper/CreateQuestionPaper";
import { ViewQuestionPaper } from "../Pages/QuestionsPaper/ViewQuestionPaper";
import UserListing from "../Pages/UserDirectory/UserListing";
import AddUsers from "../Pages/UserDirectory/AddUsers";
import ViewDetailAdmin from "../Pages/Adminstration/ViewDetailAdmin";
import EditUser from "../Pages/UserDirectory/EditUser";
import { EditQuestionForm } from "../Pages/QuestionsPaper/EditQuestionPaper";
import ViewDetailUser from "../Pages/UserDirectory/ViewDetailUser";
import EvaluatOverview from "../Pages/Evaluator/EvalutorOverview/EvaluatOverview";
import EvaluateNotification from "../Pages/Evaluator/Notification/EvaluateNotification";
import ScreenTimeTab from "../Pages/Evaluator/ScreenTime/ScreenTimeTab";
import { AnswerSheet } from "../Pages/AnswerSheet/AnswerSheet";
import AssignedPapersListing from "../Pages/Evaluator/AssignedPapers/AssignedPapersListing";
import AssignPaperView from "../Pages/Evaluator/AssignedPapers/AssignPaperView";
import { ViewFolder } from "../Pages/AnswerSheet/ViewFolder";
import ResultDeclare from "../Pages/Result/ResultDeclare";
import ViewResultDeclare from "../Pages/Result/ViewResultDeclare";
import Recording from "../Pages/Recordings/Recording";
import ViewRecording from "../Pages/Recordings/ViewRecording";
import CommandOverview from "../Pages/CommandCenter/CommandOverview";
import { LiveFeed } from "../Pages/CommandCenter/LiveFeed/LiveFeed";
import { ViewLiveFeed } from "../Pages/CommandCenter/LiveFeed/ViewLiveFeed";
import CommandNotification from "../Pages/CommandCenter/Notification/CommandNotification";
import EvaluationSheet from "../Pages/Evaluator/AssignedPapers/EvaluationSheet";
import SecretaryOverView from "../Pages/Secretary/SecretaryOverview/SecretaryOverView";
import SecretaryNotification from "../Pages/Secretary/SecretaryNotification/SecretaryNotification";
import QuestionPaperTab from "../Pages/Secretary/SecretaryQuestionPaper/QuestionPaperTab";
import { ViewSheet } from "../Pages/AnswerSheet/ViewSheet";
import ParticularPaperNoData from "../Pages/Secretary/SecretaryQuestionPaper/ParticularPaperNoData";
import HodNotification from "../Pages/Hod/Notification/HodNotification";
import HodOverview from "../Pages/Hod/HodOverView/HodOverview";
import HodQuestion from "../Pages/Hod/HodQuestion/HodQuestion";
import HodPaperData from "../Pages/Hod/HodQuestion/HodPaperData";
import AnswerSheetTab from "../Pages/Hod/AnswerSheet/AnswerSheetTab";
import AnswerSheetList from "../Pages/Hod/AnswerSheet/AnswerSheetList";
import DetailViewParticularAnswer from "../Pages/Hod/AnswerSheet/DetailViewParticularAnswer";
import Reports from "../Pages/Hod/ReportsHod/Reports";
import DetailReport from "../Pages/Hod/ReportsHod/DetailReport";
// import RecordingSession from "../Pages/Recordings/RecordingSession";
import OverviewOrganization from "../Pages/OrganizationAdmin/Overview/OverviewOrganization";
import NotificationOrganizationAdmin from "../Pages/OrganizationAdmin/Notification/NotificationOrganizationAdmin";
import OrgAdminAnswerSheetList from "../Pages/OrganizationAdmin/OrgAdminAnswerSheet/OrgAdminAnswerSheetList";
import OrgAdminAnswerSheetfile from "../Pages/OrganizationAdmin/OrgAdminAnswerSheet/OrgAdminAnswerSheetfile";
import OrgAdminViewSheet from "../Pages/OrganizationAdmin/OrgAdminAnswerSheet/OrgAdminViewSheet";
import OrgresultDeclare from "../Pages/OrganizationAdmin/OrgAdminResult/OrgresultDeclare";
import OrgRecording from "../Pages/OrganizationAdmin/OrgAdminRecording/OrgRecording";
import { QuestionsTimer } from "../Pages/QuestionsTimer/QuestionsTimer";
import { EditTimer } from "../Pages/QuestionsTimer/EditTimer";
import { ViewSession } from "../Pages/Recordings/ViewSession";
import { ViewSessionPart } from "../Pages/Recordings/ViewSessionPart";
import ResultMainTab from "../Pages/Result/ResultMainTab";
import DetailedScreenParticularExam from "../Pages/Result/DetailedScreenParticularExam";
import AwaitedResultTab from "../Pages/Result/AwaitedResultTab";
import OrgAdminMainResultTab from "../Pages/OrganizationAdmin/OrgAdminResult/OrgAdminMainResultTab";
import OrgViewResultDeclare from "../Pages/OrganizationAdmin/OrgAdminResult/OrgViewResultDeclare";
import OrgAwaitedResult from "../Pages/OrganizationAdmin/OrgAdminResult/OrgAwaitedResult";
import OrgDetailScreenParticular from "../Pages/OrganizationAdmin/OrgAdminResult/OrgDetailScreenParticular";
export default function Router() {
  const navigate = useNavigate();

  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/forgetPassword" element={<EmailVarification />} />
        <Route path="/bioAuthentication" element={<ForgetBiometric />} />
        <Route path="/forgotResetPassword" element={<ForgotResetPassword />} />
        <Route path="/passwordChange" element={<ForgetPasswordChange />} />
        <Route path="/loginBioAuthentication" element={<BiometricSecond />} />

        {/* <Route path="/" element={<LogingKeeper />}> */}
        <Route path="biometric" element={<Biometric />} />
        <Route path="resetPassword" element={<ResetPassword />} />
        {/* </Route> */}
        {/* </Routes>

     
        <Routes> */}
        <Route element={<LogingKeeper />}>
          <Route path="/overview" element={<Overview />} />
          <Route path="/addOverview" element={<AddOverview />} />
          <Route path="/organization" element={<Organization />} />
          <Route path="/organization/addOrganization" element={<AddOrganization />} />
          <Route path="/organization/editOrganization/:id" element={<EditOrganization />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/adminstrations" element={<AdminstartionList />} />
          <Route path="/adminstrations/addAdminstration" element={<AddAdminstration />}></Route>
          <Route path="/adminstrations/editAdminstration/:id" element={<EditAdminstration />}></Route>
          <Route path="/adminstrations/viewAdminDetail/:id" element={<ViewDetailAdmin />} />
          <Route path="/users" element={<UserListing />} />
          <Route path="/users/addUser" element={<AddUsers />} />
          <Route path="/users/editUser/:id" element={<EditUser />} />
          <Route path="/users/viewUser/:id" element={<ViewDetailUser />} />
          <Route path="/question" element={<Questions />} />
          <Route path="/question/create" element={<CreateQuestionPaper />} />
          <Route path="/question/edit/:id" element={<EditQuestionForm />} />
          <Route path="/question/view/:id" element={<ViewQuestionPaper />} />
          <Route path="/evaluatorOverview" element={<EvaluatOverview />} />
          <Route path="/evaluatorNotification" element={<EvaluateNotification />} />
          <Route path="/screentime" element={<ScreenTimeTab />} />
          <Route path="/answerSheet" element={<AnswerSheet />} />
          <Route path="/assignedQuestions" element={<AssignedPapersListing />} />
          <Route path="/assignedQuestions/assignedQuestionsView/:id" element={<AssignPaperView />} />
          <Route path="/answerSheet/viewFolder/:id" element={<ViewFolder />} />
          <Route path="/resultDeclare" element={<ResultDeclare />} />
          <Route path="/resultmaintab" element={<ResultMainTab/>}/>
          <Route path="/resultmaintab/resultDeclare/viewresult/:id" element={<ViewResultDeclare />} />
          <Route path="/recording" element={<Recording />} />
          <Route path="/recording/viewrecording/:id" element={<ViewRecording />} />
          <Route path="/recording/viewsession/:id" element={<ViewSession />} />
          <Route path="/recording/viewsession/part/:partId/:sessionId" element={<ViewSessionPart />} />
          {/* <Route path="/recording/recordsession" element={<RecordingSession/>} /> */}
          <Route path="/answerSheet/viewSheet/:id" element={<ViewSheet />} />
          <Route path="/commandoverview" element={<CommandOverview />} />
          <Route path="/commandCenter/LiveFeed" element={<LiveFeed />} />
          <Route path="/commandCenter/LiveFeed/view" element={<ViewLiveFeed />} />
          <Route path="/commandnotification" element={<CommandNotification />} />
          <Route path="/secretaryOverview" element={<SecretaryOverView />} />
          <Route path="/secretaryNotification" element={<SecretaryNotification />} />
          <Route path="/questionPaperTab" element={<QuestionPaperTab />} />
          <Route path="/questionPaperTab/particularPaperData/:id" element={<ParticularPaperNoData />} />
          <Route path="/hodoverview" element={<HodOverview />} />
          <Route path="/hodnotification" element={<HodNotification />} />
          <Route path="/hodquestiontab" element={<HodQuestion />} />
          <Route path="/hodquestiontab/paperdata/:id" element={<HodPaperData />} />
          <Route path="/answersheetTab" element={<AnswerSheetTab />} />
          <Route path="/answersheetTab/detailViewFolder/:id" element={<AnswerSheetList />} />
          <Route path="/answersheetTab/detailViewFolder/detailviewAnswer/:id" element={<DetailViewParticularAnswer />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/reports/detailReport/:id" element={<DetailReport />} />
          <Route path="/overViewOrgAdmin" element={<OverviewOrganization />} />
          <Route path="/notificationOrgAdmin" element={<NotificationOrganizationAdmin />} />
          <Route path="/orgAdminAnswerSheet" element={<OrgAdminAnswerSheetList />} />
          <Route path="/orgAdminAnswerSheet/orgAdminAnswerfile/:id" element={<OrgAdminAnswerSheetfile />} />
          <Route path="/orgAdminAnswerSheet/orgAdminAnswerfile/orgAdminViewSheet/:id" element={<OrgAdminViewSheet />} />
          <Route path="/orgresultdeclare" element={<OrgresultDeclare />} />
          <Route path="/orgadminrecordin" element={<OrgRecording />} />
          <Route path="/questionsTimer" element={<QuestionsTimer />}>
            <Route path="edit/:id" element={<EditTimer />} />
          </Route>
          <Route path="/awaitedresulttab" element={<AwaitedResultTab/>}/>
          <Route path="/resultmaintab/awaitedresulttab/detailedscreenparticular/:id" element={<DetailedScreenParticularExam/>}/>
          <Route path="/orgAdminMainTab" element={<OrgAdminMainResultTab/>}/>
          <Route path="/orgAdminMainTab/orgresultdeclare/orgadminViewDeclare/:id" element={<OrgViewResultDeclare/>}/>
          <Route path="/orgAwaitedResult" element={<OrgAwaitedResult/>}/>
          <Route path="/orgAdminMainTab/orgAwaitedResult/orgdetailScreen/:id" element={<OrgDetailScreenParticular/>}/>


        </Route>
        <Route path="/evaluation" element={<EvaluationSheet />} />
      </Routes>
      {/* </Sidebar> */}
    </>
  );
}
