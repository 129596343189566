import React, { useEffect, useState } from 'react'
import "./livefeed.css";
import { activeListEvaluator } from "../../../Services/Admin/Api";
import { userOrgId } from "../../../Components/Assets/userData";
import moment from "moment";
import PopOver from "../../../Components/Assets/PopOver";




export default function ActiveEvaluatorList() {
const [activeEvaluator,setActiveEvaluator] = useState([])
const getAllActiveEvaluator = async() =>{
    await activeListEvaluator(userOrgId()).then((res)=>{
    //   console.log(res)
      setActiveEvaluator(res.data.data)
    }).catch((err)=>{
      console.log(err)
    })
    }
    const date = moment()

    useEffect(()=>{
        getAllActiveEvaluator()
        const intervalId = setInterval(() => {
          getAllActiveEvaluator();
        }, 1000); // Update every second
      
        // Clear the interval when the component is unmounted
        return () => clearInterval(intervalId);
      },[])

  return (
    <>
     <div className="table_con">
              <table>
                <thead>
                  <tr>
                    <th>Evaluator Full Name</th>
                    <th>Evaluator Code</th>
                    <th>Paper Name</th>
                    <th>Start Time</th>
                    <th>Session Duration</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {activeEvaluator.map((res,index)=>(

                  <tr >
                    <td>{res.name}</td>
                    <td>{res.uid}</td>
                    <td>{res?.activeEvaluation?.paperName}</td>
                    <td>{moment(res?.activeEvaluation?.startTime).format(
                                      "h:mm:ss a"
                                    )}</td>
                    <td>
                      { moment.utc().startOf('day').add(date.diff(res?.activeEvaluation?.startTime,"second"), 'second').format('HH:mm:ss ')}
                    </td>
                    <td>
                      <PopOver />
                    </td>
                  </tr>
                  ))}
                </tbody>
              </table>
            </div>
    </>
  )
}
