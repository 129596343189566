import React from 'react'
import "./style.css";
import { useNavigate } from 'react-router';

export default function ForgetPasswordChange() {
    const navigation = useNavigate()
  return (
    <>
        <div className='main-container'>
            <div className='left-contain'>
                <div className='logoFixed'>
                    <span className='eLogo'>E-Valuators App</span>
                </div>
            </div>
            <div className='right-contain'>
                <div className='right-content-div'>
                    <div className='d-flex justify-content-center '>
                        <span className='logIn'>Reset Password</span>
                    </div>
                    <div className='mt-4 password-container'>
                        <p className='text-main-style'>Your password has been changed successfully! </p>
                        <p className='text-main-style'>Log in using your new password.</p>
                    </div>
                    <div className='mt-4'>
                        <button  className=" btn btn-primary w-100" id='subitPasswordChange' onClick={()=>{navigation("/")}}>Back to Log In page </button>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
