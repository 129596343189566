import React, { useEffect, useState } from "react";
import { TopBar } from "../../../Components/Sidebar/Sidebar";
import Infomation from "../../../Components/Assets/Infomation";
import TableContainer from "../../../Components/Assets/TableContainer";
import './style.css'
import { AssignThirdEvaluator } from "./AssignThirdEvaluator";
import { useNavigate, useParams } from "react-router-dom";
import { checkAnswerSheetTotalMarks } from "../../../Services/Admin/Api";
import { findDiffereceBetweenTwoNumber, findDifferencePercentageEvaluatorsMarks } from "../../../utils/commonUtils";
import { FaArrowLeft } from "react-icons/fa6";
import { loader } from "../../../utils/loader";

export default function DetailViewParticularAnswer() {

  let { id } = useParams();
  let [answerData, setAnswerSheet] = useState([]);
  let paperId = new URLSearchParams(window.location.search).get("paperId");
  let folderName = new URLSearchParams(window.location.search).get("folderName");
  let [isNodata, setIsNodata] = useState(false)
const[refreshCount,setRefreshCount] = useState(0)
  let [paperData, setPaperData] = useState({
    examName: "",
    papeName: "",
    paperId: null
  })
  let [totalMarks, setTotalMarks] = useState(0);

  const finalMarksFunction = (result) => {
    console.log(result,"adsaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa")
    if(result.length === 3){
      console.log("rrrrrrrrrrrrrrrrrrrrrrrrrrrrrr")
      if(result[2].status === "submitted"){
          let marks = result.map(e=>{return  e.marks})
          let marksSorting = marks.sort().reverse()
          let average  = (marksSorting[0] + marksSorting[1])/2
          console.log(average)
        //  totalMarks =totalMarks + average
        setTotalMarks(totalMarks + average)
          return average

      }else{
        return "pending"
      }
    }else{
      let average  = (result[0].marks + result[1].marks)/2
      console.log(average,"asdasdasdasdasdadadasdasdasdas")
      // totalMarks = totalMarks + average
      setTotalMarks(totalMarks + average)
      
      return average
    }

  }

  useEffect(() => {
    getAnswerSheetData();
  }, []);

  async function getAnswerSheetData() {
    try {
      loader.start()
      setIsNodata(false)
      let body = {
        paper_id: paperId
      };
      let res = await checkAnswerSheetTotalMarks(id, body);
      console.log(res.data.data);
      if (res.data.data.result.length == 0) {
        setIsNodata(true)
      }
      let data = res.data.data.result.sort(
        (e, b) => e?.evaluator_number - b?.evaluator_number
      )
      let resultData = data.map((e)=>{
        let avg = finalMarksFunction(e.evaluators);
        return{
          ...e,
          ...{finalMark:avg}
        }
      }) 
      setAnswerSheet(
        resultData 
      );
      setPaperData({
        paperName: res.data.data.paper[0].paperName,
        examName: res.data.data.paper[0].examName,
        paperId: res.data.data.paper[0]._id
      });
      loader.stop()
    }
    catch (err) {
      loader.stop()
      console.log(err);
    }
  };

  let navigate = useNavigate()

  function backUrl() {
    paperData.paperId && navigate(`/answersheetTab/detailViewFolder/${paperData.paperId}?folderName=${encodeURIComponent(folderName)}`)
  }

  const test =(res)=>{
    console.log(res)
  }

  return (
    <>
      <TopBar />
      <div className="mainContainer">
        <div className="card p-md-4 p-sm-3 p-2">
          {/* <BackButton link={backUrl()} /> */}

          <div className="back_btn_con" onClick={backUrl}><FaArrowLeft />&nbsp;Back</div>
          <br />
          <div>
            <h1>{folderName}</h1>
            <p>{paperData?.paperName} - {paperData?.examName}</p>
          </div>
          <div className="mt-3">


            {answerData.some(e => findDifferencePercentageEvaluatorsMarks(parseInt(e.evaluators[0].marks), parseInt(e.evaluators[1].marks)) > 16 && !e.evaluators?.[2]?.status) ?

              <Infomation
                type="warning"
                text={
                  <ul>
                    <li>Attention Required: Discrepancy in evaluation</li>
                    <li>
                      There exists some discrepancy in the evaluation of a few
                      questions by the two assigned evaluators. Kindly assign a
                      third evaluator to those questions to proceed with the
                      evaluation for the questions.{" "}
                    </li>
                  </ul>
                }
              ></Infomation> : null}
          </div>
          <div className="d-flex justify-content-between mt-4">
            <h1>01. AHE285JSL037A</h1>
            <p>Total Marks:{totalMarks}</p>
          </div>

          <div className="py-5">
            {isNodata ? <div>No Data Available</div> :
              <TableContainer>
                <table className="left_align_table">
                  <thead>
                    <th>Ques:</th>
                    <th>Evaluator 1</th>
                    <th>Marks</th>
                    <th>Evaluator 2</th>
                    <th>Marks</th>
                    <th>Avg. Marks</th>
                    <th>Difference</th>
                    <th>Evaluator 3</th>
                    <th>Marks</th>
                    <th className="align-items-center ">Final Marks<i className="pi pi-info-circle ms-2"></i></th>
                  </thead>
                  <tbody>
                    {answerData?.map((res, index) => (
                      <tr key={index}>
                        <td>{res?.question_number}</td>
                        <td>{res?.evaluators?.[0]?.name}</td>
                        <td>{res?.evaluators?.[0]?.marks}</td>
                        <td>{res?.evaluators?.[1]?.name}</td>
                        <td>{res?.evaluators?.[1]?.marks}</td>
                        <td>{res.evaluators?.[0]?.marks && findDifferencePercentageEvaluatorsMarks(parseInt(res.evaluators[0].marks), parseInt(res.evaluators[1].marks))} %</td>
                        <td>{findDiffereceBetweenTwoNumber(res.evaluators[1].marks, res.evaluators[0].marks)}</td>
                        <td>
                          {findDifferencePercentageEvaluatorsMarks(parseInt(res.evaluators[0].marks), parseInt(res.evaluators[1].marks)) > 12.5 ?
                            <>
                            {console.log(res?.evaluators?.[2]?.name)}
                              {res?.evaluators?.[2]?.name ||
                                <AssignThirdEvaluator answerSheetId={id} answerSheetData={res} paperData={paperData} test={getAnswerSheetData}/>}
                            </>
                            : <span>-</span>}
                        </td>
                        <td>{res?.evaluators[2]?.status === "submitted" && res?.evaluators?.[2]?.marks || "--"}</td>
                        <td>{res.finalMark}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </TableContainer>
            }

          </div>
        </div>
      </div>
    </>
  );
};







