import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { List } from "reactstrap";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { OverlayPanel } from "primereact/overlaypanel";
import { toggleByClick, toggleByValue } from "../../Store/Slices/CommonSlice";
import { socketLogin } from "../Assets/socket";
import { userId, userOrgId, userRole } from "../Assets/userData";
import { logout } from "../../Services/Admin/Api";
import PopOver from "../Assets/PopOver";
import {
  getnotification,
  readOrgAdminNotification,
  getNotificationItAdminData,
  getsecretaryNotification,
  getEvaluationNotification,
  getCommandCenterNotification,
  getNotificationHodData,
} from "../../Services/Admin/Api";
import { baseUrl } from "../../Services/EndPoint";
import { io } from "socket.io-client";
const socket = io(baseUrl);

export default function Sidebar({ children }) {
  const drawerWidth = "272px";
  const [open, setopen] = useState(true);
  const [transition, setTransition] = useState(true);
  const [showNotification, setShowNotification] = useState(false);

  const location = useLocation();
  const { pathname } = useLocation();
  const [count, setCount] = useState(0);
  let [notificationData, setNotificationData] = useState(0);
  const [notifyMessage, setNotifyMessage] = useState([]);
  let [notificationDataIt, setNotificationDataIt] = useState(0);
  let [notificationDataSecre, setNotificationDataSecre] = useState(0);
  let [notificationDataEva, setNotificationDataEva] = useState(0);
  let [notificationDataCod, setNotificationDataCod] = useState(0);
  let [notificationDataHod, setNotificationDataHod] = useState(0);
  let [nofity, setNofity] = useState(false);

  const [tab, setTab] = useState("");

  const [loader, setLoader] = useState(false);

   

  const openToggle = useSelector((state) => state.ToggleButtonState);
  const dispatch = useDispatch();
  function toggleOpen() {
    dispatch(toggleByClick());
  }

  function togglefalse(val) {
    dispatch(toggleByValue(val));
  }
  let navigate = useNavigate();

  socket.on("check", () => {
    // getNotificationData();
    // setNotifyMessage(notifyMessage+1)
    if (roleData === "orgAdmin") {
      getNotificationData();
      setNofity(true);
    }
    if (roleData === "admin") {
      getNotificationDataIt();
      setNofity(true);
    }
    if (pathname?.includes("notification")) {
      setNofity(false);
    }
  });

  useEffect(() => {
    if (pathname?.includes("notification")) {
      setShowNotification(false);
    } else {
      setShowNotification(true);
    }
  }, [pathname]);
  useEffect(() => {
  
    if (!nofity){
      setNotificationDataIt(0)
    }
    if(!nofity){
      setNotificationData(0)
    }
    if(!nofity){
      setNotificationDataSecre(0)
    }
    if(!nofity){
      setNotificationDataEva(0)
    }
    if(!nofity){
      setNotificationDataCod(0)
    }
    if(!nofity){
      setNotificationDataHod(0)
    }
  }, [nofity]);

  const getNotificationData = async () => {
    // setLoader(true)

    if (window.location.pathname !== "/notificationOrgAdmin") {
      console.log("org admin not notyuyyyyyyyyyyyyyyyyy");
      await getnotification(userId(), { organization_id: userOrgId() })
        .then((res) => {
          console.log(res.data.data.result.data);
          let dataReverse = res.data.data.result[0].data;
          setNotificationData(res.data.data.data);

          let id = [];

         
        })
        .catch((err) => {
          console.log(err);
          // setLoader(false)
        });
        
    }else {
      setNofity(false);
    }
  };

  // const readDataNotificationOrg = async () => {
  //   let payload = {
  //     notificationData: notifyMessage,
  //   };
  //   await readOrgAdminNotification(userId(), payload)
  //     .then((res) => {
  //       console.log(res.data.data);
  //       setNotificationData(0);
  //       setNotificationDataIt(0);
  //       setNotificationDataSecre("");
  //       setNotificationDataEva("");
  //       setNotificationDataCod("");
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
      
  // };

  // socket.on("check", () => {
  //   getNotificationDataIt();
  // });

  const getNotificationDataIt = async () => {
    console.log(window.location.pathname)
    if (window.location.pathname !== ('/notification')) {
      console.log(
        location.pathname,
        "rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr"
      );

      await getNotificationItAdminData(userId(), {
        organization_id: userOrgId(),
      })
        .then((res) => {
          // console.log(res.data.data);
          let dataReverse1 = res.data.data.result[0].data;
          console.log(location.pathname !== "/notification");
          console.log(location.pathname);

          setNotificationDataIt(res.data.data.data);
          // setNofity(true);
          let id = [];
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setNofity(false);
    }
  };
  socket.on("getNotificationForHOD", () => {
    getNotificationDataOfHod();
    if (pathname?.includes("notification")) {
      setNofity(false);
    }
  });

  const getNotificationDataOfHod = async() =>{
    if(window.location.pathname !== ('/hodnotification')){
      let payloadData = {
        user_id: userId(),
      };
      await getNotificationHodData(userOrgId(),payloadData).then((res)=>{
        console.log(res)
        setNotificationDataHod(res.data.data.data)
      }).catch((err)=>{
        console.log(err)
      })
    }else{
      setNofity(false);

    }
  }

  socket.on("getNotificationForguidelinesadded", () => {
    getNotificationDataSecr();
    setNofity(true);

    if (pathname?.includes("notification")) {
      setNofity(false);
    }
  });
  const getNotificationDataSecr = async () => {
    let payloadData = { user_id: userId() };

    if (window.location.pathname !== "/secretarynotification") {
      await getsecretaryNotification(userOrgId(), payloadData)
        .then((res) => {
          console.log(res.data.data);
          let dataReverse2 = res.data.data.result[0].data;
          // let reverData = dataReverse.reverse();
          setNotificationDataSecre(res.data.data.data);
          let id = [];

         
        })
        .catch((err) => {
          console.log(err, "asdsadasdasdasd");
        });
    }else {
      setNofity(false);
    }
  };
  socket.on("getNotificationForAssign3QuestionsAndAssign", () => {
    getNotificationDataEvalu();
    setNofity(true);

    if (pathname?.includes("notification")) {
      setNofity(false);
    }
  });

  socket.on("getNotificationForAddNewQuestionAndAssign", () => {
    getNotificationDataEvalu();
    setNofity(true);

    if (pathname?.includes("notification")) {
      setNofity(false);
    }
  });
  const getNotificationDataEvalu = async () => {
    if (window.location.pathname !== "/evaluatornotification") {
      console.log("evlauteval not notyuyyyyyyyyyyyyyyyyy");
      await getEvaluationNotification(userId(), {
        organization_id: userOrgId(),
      })
        .then((res) => {
          console.log(res.data.data);
          let dataReverse3 = res.data.data.result[0].data;

          setNotificationDataEva(res.data.data.data);
          let id = [];

         
        })
        .catch((err) => {
          console.log(err);
        });
    }else {
      setNofity(false);
    }
  };

  socket.on("startEvaluationNotification", () => {
    getNotificationDataCod();
    setNofity(true);

    if (pathname?.includes("notification")) {
      setNofity(false);
    }
  });

  const getNotificationDataCod = async () => {
    let payloadDataSec = { user_id: userId() };
    if (window.location.pathname !== "/commandnotification") {
      console.log("cmnd amnd cmnd cmnd not notyuyyyyyyyyyyyyyyyyy");

      await getCommandCenterNotification(userOrgId(), payloadDataSec)
        .then((res) => {
          console.log(res.data.data);
          let dataReverse4 = res.data.data.result[0].data;
          // let reverData = dataReverse.reverse();
          setNotificationDataCod(res.data.data.data);
          let id = [];

         
        })
        .catch((err) => {
          console.log(err);
        });
    }else {
      setNofity(false);
    }
  };

  console.log(userRole());
  function logoutUser() {
    logout();
    navigate("/");
  }

  let roleData = userRole();
  const [xs, setXs] = useState(false);
  $(document).ready(function () {
    $(window).on("resize", function () {
      if ($(window).width() <= 800) {
        setXs(true);
        togglefalse(false);
        setopen(false);
      } else if ($(window).width() > 800) {
        setXs(false);
        togglefalse(true);
      }
    });
  });
  useEffect(() => {
    if (roleData === "orgAdmin") {
      getNotificationData();
    }
    if (roleData === "admin") {
      getNotificationDataIt();
    }
    if (roleData === "secretary") {
      getNotificationDataSecr();
    }

    if (roleData === "evaluator") {
      getNotificationDataEvalu();
    }
    if (roleData === "command center") {
      getNotificationDataCod();
    }
    if (roleData === "hod"){
      getNotificationDataOfHod()
    }
  }, []);

  useEffect(() => {
    if ($(window).width() <= 800) {
      setXs(true);
      togglefalse(false);
      if (count == 0) {
        setopen(false);
        setCount(1);
      }
    } else if ($(window).width() > 800) {
      setXs(false);
      setCount(0);
      togglefalse(true);
    }
    socketLogin();
  }, []);

  const drawerTheamOpen = {
    width: drawerWidth,
    padding: "25px 16px 0 16px",
  };
  const drawerTheamOpenMobile = {
    width: drawerWidth,
    padding: "25px 16px 0 16px",
  };
  const drawerTheamClose = {
    width: "84px",
    padding: "25px 20px 0 15px",
  };
  const drawerTheamCloseMobile = {
    width: "0",
    padding: "25px 0 0 0",
  };

  return (
    <>
      <div className="w-100 d-flex">
        <div
          id="drawer"
          className={` ${xs ? "mobile-effect drawer2" : "drawer"}`}
          style={
            xs
              ? openToggle
                ? drawerTheamOpenMobile
                : drawerTheamCloseMobile
              : openToggle
              ? drawerTheamOpen
              : ""
              ? drawerTheamCloseMobile
              : ""
              ? drawerTheamCloseMobile
              : drawerTheamClose
          }
        >
          {<MainNavBar />}
        </div>
        <div
          className={` ${openToggle ? "content" : "content2"} `}
          style={{
            width: location.pathname.includes("")
              ? openToggle
                ? xs
                  ? ""
                  : "calc(100% - 272px)"
                : "100%"
              : xs
              ? "100%"
              : ""
              ? openToggle
                ? "calc(100% - 272px)"
                : "100%"
              : openToggle
              ? "calc(100% - 272px)"
              : "calc(100% - 88px)",
          }}
        >
          {xs ? (
            openToggle ? (
              <div className="bg-tranparen-dark" onClick={toggleOpen}></div>
            ) : (
              ""
            )
          ) : (
            ""
          )}
          <div className="childrenContent">{children}</div>
        </div>
      </div>
    </>
  );

  function MainNavBar() {
    const pop1 = useRef(null);
    const pop = useRef(null);
    return (
      <>
        <div className="testt">
          <div className="main-nav-container">
            {xs ? (
              <div className="pb-2 pt-1 d-flex justify-content-end sticky-top">
                <img
                  src="/images/toggle-left.png"
                  alt=""
                  onClick={toggleOpen}
                />
              </div>
            ) : (
              ""
            )}
            <div className="logo">
              {xs ? (
                ""
              ) : openToggle ? (
                <img src="/images/fullLogo.png" className="w-100" />
              ) : (
                // <span className="EvaluteLogo">E-Valuators App</span>
                <img src="/images/shortlogo.png" className="w-100" />
                // <span className="EvaluteLogo">E-V</span>
              )}
            </div>
            <br />
            {xs ? "" : <br />}
            <List type="unstyled">
              {console.log(roleData)}
              {roleData === "orgAdmin" && (
                <>
                  <li>
                    <NavLink
                      to="/overViewOrgAdmin"
                      className={`primary_popover_item1 pointer decoration-none d-flex align-items-center nav-link ${
                        transition ? "" : "justify-content-center"
                      }`}
                      onClick={() => {}}
                    >
                      <i className="pi pi-home fs-5 ms-1"></i>
                      {transition ? (
                        <span className="ms-4 ">Overview</span>
                      ) : (
                        ""
                      )}
                    </NavLink>
                  </li>
                  <li>
                  
                    <NavLink
                      to="/notificationOrgAdmin"
                      className={`primary_popover_item1 pointer decoration-none d-flex align-items-center nav-link ${
                        nofity ? "notificationColorHilight" : ""
                      } ${
                        transition ? "" : "justify-content-center"
                      }`}
                      onClick={() => {
                        if (xs) {
                          togglefalse(false);
                        }
                        // readDataNotificationOrg();

                        setNotificationData(0);
                        setNofity(false)
                      }}
                    >
                      <i className="pi pi-bell fs-5 ms-1"></i>
                      {transition ? (
                        <span className="ms-4 min-w2">
                          Notifications{" "}
                          {notificationData !== 0 && nofity
                            ? notificationData
                            : ""}
                        </span>
                      ) : (
                        ""
                      )}
                    </NavLink>
                  </li>
                  <div className="hr my-3"></div>
                  <li>
                    <NavLink
                      to="/users"
                      className={`primary_popover_item1 pointer decoration-none d-flex align-items-center nav-link ${
                        transition ? "" : "justify-content-center"
                      }`}
                      onClick={() => {
                        if (xs) {
                          togglefalse(false);
                        }
                      }}
                    >
                      <i className="pi pi-book fs-5 ms-1"></i>
                      {transition ? (
                        <span className="ms-4 min-w2">User Directory</span>
                      ) : (
                        ""
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/question"
                      className={`primary_popover_item1 pointer decoration-none d-flex align-items-center nav-link ${
                        transition ? "" : "justify-content-center"
                      }`}
                      onClick={() => {
                        if (xs) {
                          togglefalse(false);
                        }
                      }}
                    >
                      <i className="pi pi-book fs-5 ms-1"></i>
                      {transition ? (
                        <span className="ms-4 min-w2">Question Paper</span>
                      ) : (
                        ""
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/orgAdminAnswerSheet"
                      className={`primary_popover_item1 pointer decoration-none d-flex align-items-center nav-link ${
                        transition ? "" : "justify-content-center"
                      }`}
                      onClick={() => {
                        if (xs) {
                          togglefalse(false);
                        }
                      }}
                    >
                      <i className="pi pi-book fs-5 ms-1"></i>
                      {transition ? (
                        <span className="ms-4 min-w2">Answer Sheet</span>
                      ) : (
                        ""
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/orgAdminMainTab"
                      className={`primary_popover_item1 pointer decoration-none d-flex align-items-center nav-link ${
                        transition ? "" : "justify-content-center"
                      }`}
                      onClick={() => (xs ? togglefalse(false) : {})}
                    >
                      <i className="pi pi-book fs-5 ms-1"></i>
                      {transition ? (
                        <span className="ms-4 min-w2">Result</span>
                      ) : (
                        ""
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/orgadminrecordin"
                      className={`primary_popover_item1 pointer decoration-none d-flex align-items-center nav-link ${
                        transition ? "" : "justify-content-center"
                      }`}
                      onClick={() => (xs ? togglefalse(false) : {})}
                    >
                      <i className="pi pi-comment fs-5 ms-1"></i>
                      {transition ? (
                        <span className="ms-4 min-w2">Recordings</span>
                      ) : (
                        ""
                      )}
                    </NavLink>
                  </li>
                </>
              )}
              {roleData === "admin" && (
                <>
                  <li>
                    <NavLink
                      to="/overview"
                      className={`primary_popover_item1 pointer decoration-none d-flex align-items-center nav-link ${
                        transition ? "" : "justify-content-center"
                      }`}
                    >
                      <i className="pi pi-home fs-5 ms-1"></i>
                      {transition ? (
                        <span className="ms-4 ">Overview</span>
                      ) : (
                        ""
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/organization"
                      className={`primary_popover_item1 pointer decoration-none d-flex align-items-center nav-link ${
                        transition ? "" : "justify-content-center"
                      }`}
                      onClick={() => (xs ? togglefalse(false) : {})}
                    >
                      <i className="pi pi-cog fs-5 ms-1"></i>
                      {transition ? (
                        <span className="ms-4">Organizations</span>
                      ) : (
                        ""
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/notification"
                      className={`primary_popover_item1 pointer decoration-none d-flex align-items-center nav-link ${
                        nofity ? "notificationColorHilight" : ""
                      }  ${transition ? "" : "justify-content-center"}`}
                      onClick={() => {
                        if (xs) {
                          togglefalse(false);
                        }
                        setNotificationDataIt("");
                        // readDataNotificationOrg();
                        setNofity(false);
                      }}
                    >
                      <i className="pi pi-bell fs-5 ms-1"></i>
                      {transition ? (
                        <span className="ms-4 min-w2">
                          Notifications{" "}
                          {notificationDataIt !== 0 && nofity
                            ? notificationDataIt
                            : ""}
                        </span>
                      ) : (
                        ""
                      )}
                    </NavLink>
                  </li>

                  <div className="hr my-3"></div>
                  <li>
                    <NavLink
                      to="/adminstrations"
                      className={`primary_popover_item1 pointer decoration-none d-flex align-items-center nav-link ${
                        transition ? "" : "justify-content-center"
                      }`}
                      onClick={() => (xs ? togglefalse(false) : {})}
                    >
                      <i className="pi pi-user fs-5 ms-1"></i>
                      {transition ? (
                        <span className="ms-4 min-w2">Administrators</span>
                      ) : (
                        ""
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/users"
                      className={`primary_popover_item1 pointer decoration-none d-flex align-items-center nav-link ${
                        transition ? "" : "justify-content-center"
                      }`}
                      onClick={() => (xs ? togglefalse(false) : {})}
                    >
                      <i className="pi pi-book fs-5 ms-1"></i>
                      {transition ? (
                        <span className="ms-4 min-w2">User Directory</span>
                      ) : (
                        ""
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/question"
                      className={`primary_popover_item1 pointer decoration-none d-flex align-items-center nav-link ${
                        transition ? "" : "justify-content-center"
                      }`}
                      onClick={() => (xs ? togglefalse(false) : {})}
                    >
                      <i className="pi pi-book fs-5 ms-1"></i>
                      {transition ? (
                        <span className="ms-4 min-w2">Question Paper</span>
                      ) : (
                        ""
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/answerSheet"
                      className={`primary_popover_item1 pointer decoration-none d-flex align-items-center nav-link ${
                        transition ? "" : "justify-content-center"
                      }`}
                      onClick={() => (xs ? togglefalse(false) : {})}
                    >
                      <i className="pi pi-book fs-5 ms-1"></i>
                      {transition ? (
                        <span className="ms-4 min-w2">Answer Sheet</span>
                      ) : (
                        ""
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/resultmaintab"
                      className={`primary_popover_item1 pointer decoration-none d-flex align-items-center nav-link ${
                        transition ? "" : "justify-content-center"
                      }`}
                      onClick={() => (xs ? togglefalse(false) : {})}
                    >
                      <i className="pi pi-book fs-5 ms-1"></i>
                      {transition ? (
                        <span className="ms-4 min-w2">Result</span>
                      ) : (
                        ""
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/recording"
                      className={`primary_popover_item1 pointer decoration-none d-flex align-items-center nav-link ${
                        transition ? "" : "justify-content-center"
                      }`}
                      onClick={() => (xs ? togglefalse(false) : {})}
                    >
                      <i className="pi pi-comment fs-5 ms-1"></i>
                      {transition ? (
                        <span className="ms-4 min-w2">Recordings</span>
                      ) : (
                        ""
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/questionsTimer"
                      className={`primary_popover_item1 pointer decoration-none d-flex align-items-center nav-link ${
                        transition ? "" : "justify-content-center"
                      }`}
                      onClick={() => (xs ? togglefalse(false) : {})}
                    >
                      <i className="pi pi-comment fs-5 ms-1"></i>
                      {transition ? (
                        <span className="ms-4 min-w2">Timer</span>
                      ) : (
                        ""
                      )}
                    </NavLink>
                  </li>
                </>
              )}
              {roleData === "evaluator" && (
                <>
                  <li>
                    <NavLink
                      to="/evaluatorOverview"
                      className={`primary_popover_item1 pointer decoration-none d-flex align-items-center nav-link ${
                        transition ? "" : "justify-content-center"
                      }`}
                      onClick={() => (xs ? togglefalse(false) : {})}
                    >
                      <i className="pi pi-home fs-5 ms-1"></i>
                      {transition ? (
                        <span className="ms-4 min-w2">Overview </span>
                      ) : (
                        ""
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/evaluatornotification"
                      className={`primary_popover_item1 pointer decoration-none d-flex align-items-center nav-link ${
                        nofity ? "notificationColorHilight" : ""
                      }  ${
                        transition ? "" : "justify-content-center"
                      }`}
                      onClick={() => {
                        if (xs) {
                          togglefalse(false);
                        }
                        // readDataNotificationOrg();
                        setNotificationDataEva("");
                        setNofity(false);

                      }}
                    >
                      <i className="pi pi-bell fs-5 ms-1"></i>
                      {transition ? (
                        <span className="ms-4 min-w2">
                          Notification{" "}
                          {notificationDataEva && nofity !== 0
                            ? notificationDataEva
                            : ""}
                        </span>
                      ) : (
                        ""
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/assignedQuestions"
                      className={`primary_popover_item1 pointer decoration-none d-flex align-items-center nav-link ${
                        transition ? "" : "justify-content-center"
                      }`}
                      onClick={() => (xs ? togglefalse(false) : {})}
                    >
                      <i className="pi pi-book fs-5 ms-1"></i>
                      {transition ? (
                        <span className="ms-4 min-w2">Assigned Questions</span>
                      ) : (
                        ""
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/screentime"
                      className={`primary_popover_item1 pointer decoration-none d-flex align-items-center nav-link ${
                        transition ? "" : "justify-content-center"
                      }`}
                      onClick={() => (xs ? togglefalse(false) : {})}
                    >
                      <i className="pi pi-book fs-5 ms-1"></i>
                      {transition ? (
                        <span className="ms-4 min-w2">Screen Time</span>
                      ) : (
                        ""
                      )}
                    </NavLink>
                  </li>
                  {/* <li>
                  <NavLink to="" className={`decoration-none d-flex align-items-center nav-link ${transition ? "" : "justify-content-center"
                    }`} onClick={() => (xs ? togglefalse(false) : {})}>
                    <i className="pi pi-cog fs-5 ms-1"></i>
                    {transition ? (
                      <span className="ms-4 min-w2">Audio Video Setting</span>
                    ) : (
                      ""
                    )}
                  </NavLink>
                </li> */}
                </>
              )}
              {roleData === "command center" && (
                <>
                  <li>
                    <NavLink
                      to="/commandoverview"
                      className={`primary_popover_item1 pointer decoration-none d-flex align-items-center nav-link ${
                        transition ? "" : "justify-content-center"
                      }`}
                      onClick={() => (xs ? togglefalse(false) : {})}
                    >
                      <i className="pi pi-home fs-5 ms-1"></i>
                      {transition ? (
                        <span className="ms-4 min-w2">Overview</span>
                      ) : (
                        ""
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/commandnotification"
                      className={`primary_popover_item1 pointer decoration-none d-flex align-items-center nav-link ${
                        nofity ? "notificationColorHilight" : ""
                      } ${
                        transition ? "" : "justify-content-center"
                      }`}
                      onClick={() => {
                        if (xs) {
                          togglefalse(false);
                        }
                        // readDataNotificationOrg();
                        setNotificationDataCod("");
                        setNofity(false);

                      }}
                    >
                      <i className="pi pi-bell fs-5 ms-1"></i>
                      {transition ? (
                        <span className="ms-4 min-w2">
                          Notification{" "}
                          {notificationDataCod && nofity !== 0
                            ? notificationDataCod
                            : ""}
                        </span>
                      ) : (
                        ""
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="commandCenter/liveFeed"
                      className={`primary_popover_item1 pointer decoration-none d-flex align-items-center nav-link ${
                        transition ? "" : "justify-content-center"
                      }`}
                      onClick={() => (xs ? togglefalse(false) : {})}
                    >
                      <i className="pi pi-stop-circle fs-5 ms-1"></i>
                      {transition ? (
                        <span className="ms-4 min-w2">Live Feed</span>
                      ) : (
                        ""
                      )}
                    </NavLink>
                  </li>
                </>
              )}
              {roleData === "secretary" && (
                <>
                  <li>
                    <NavLink
                      to="/secretaryOverview"
                      className={`primary_popover_item1 pointer decoration-none d-flex align-items-center nav-link ${
                        transition ? "" : "justify-content-center"
                      }`}
                      onClick={() => (xs ? togglefalse(false) : {})}
                    >
                      <i className="pi pi-home fs-5 ms-1"></i>
                      {transition ? (
                        <span className="ms-4 min-w2">Overview</span>
                      ) : (
                        ""
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/secretarynotification"
                      className={`primary_popover_item1 pointer decoration-none d-flex align-items-center nav-link ${
                        nofity ? "notificationColorHilight" : ""
                      } ${
                        transition ? "" : "justify-content-center"
                      }`}
                      onClick={() => {
                        if (xs) {
                          togglefalse(false);
                        }
                        // readDataNotificationOrg();
                        setNotificationDataSecre("");
                        setNofity(false);

                      }}
                    >
                      <i className="pi pi-bell fs-5 ms-1"></i>
                      {transition ? (
                        <span className="ms-4 min-w2">
                          Notification{" "}
                          {notificationDataSecre && nofity !== 0
                            ? notificationDataSecre
                            : ""}
                        </span>
                      ) : (
                        ""
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/questionPaperTab"
                      className={`primary_popover_item1 pointer decoration-none d-flex align-items-center nav-link ${
                        transition ? "" : "justify-content-center"
                      }`}
                      onClick={() => (xs ? togglefalse(false) : {})}
                    >
                      <i className="pi pi-book fs-5 ms-1"></i>
                      {transition ? (
                        <span className="ms-4 min-w2">Question Paper</span>
                      ) : (
                        ""
                      )}
                    </NavLink>
                  </li>
                </>
              )}

              {roleData === "hod" && (
                <>
                  <li>
                    <NavLink
                      to="/hodoverview"
                      className={`primary_popover_item1 pointer decoration-none d-flex align-items-center nav-link ${
                        transition ? "" : "justify-content-center"
                      }`}
                      onClick={() => (xs ? togglefalse(false) : {})}
                    >
                      <i className="pi pi-home fs-5 ms-1"></i>
                      {transition ? (
                        <span className="ms-4 min-w2">Overview</span>
                      ) : (
                        ""
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/hodnotification"
                      className={`primary_popover_item1 pointer decoration-none d-flex align-items-center nav-link ${
                        nofity ? "notificationColorHilight" : ""
                      } ${
                        transition ? "" : "justify-content-center"
                      }`}
                      onClick={() => {
                        if (xs) {
                          togglefalse(false);
                        }
                        setNotificationDataHod("");
                        setNofity(false);

                      }}
                    >
                      <i className="pi pi-bell fs-5 ms-1"></i>
                      {transition ? (
                        <span className="ms-4 min-w2">Notification {notificationDataHod && nofity !== 0
                          ? notificationDataHod
                          : ""}</span>
                      ) : (
                        ""
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/hodquestiontab"
                      className={`primary_popover_item1 pointer decoration-none d-flex align-items-center nav-link ${
                        transition ? "" : "justify-content-center"
                      }`}
                      onClick={() => (xs ? togglefalse(false) : {})}
                    >
                      <i className="pi pi-book fs-5 ms-1"></i>
                      {transition ? (
                        <span className="ms-4 min-w2">Question Paper</span>
                      ) : (
                        ""
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/answersheetTab"
                      className={`primary_popover_item1 pointer decoration-none d-flex align-items-center nav-link ${
                        transition ? "" : "justify-content-center"
                      }`}
                      onClick={() => (xs ? togglefalse(false) : {})}
                    >
                      <i className="pi pi-book fs-5 ms-1"></i>
                      {transition ? (
                        <span className="ms-4 min-w2">Answer Sheets</span>
                      ) : (
                        ""
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/reports"
                      className={`primary_popover_item1 pointer decoration-none d-flex align-items-center nav-link ${
                        transition ? "" : "justify-content-center"
                      }`}
                      onClick={() => (xs ? togglefalse(false) : {})}
                    >
                      <i className="pi pi-globe fs-5 ms-1"></i>
                      {transition ? (
                        <span className="ms-4 min-w2">Reports</span>
                      ) : (
                        ""
                      )}
                    </NavLink>
                  </li>
                </>
              )}
            </List>
          </div>
          {/* bottom setting button start */}

          {/* <div className={`setting-bottom`}>
           

               
          </div> */}
          <div className="hr mb-1"></div>
          <PopOver
            item={
              <div className="d-flex align-items-center mx-2 gap-4">
                <div>
                  <img src="/images/Profile.png" alt="" />
                </div>

                <div>Settings</div>
              </div>
            }
          >
            <div className="p-1">
              <div className="primary_popover_item pointer mb-2">
                Profile Setting
              </div>

              <hr className="p-0 m-0" />
              <div
                onClick={logoutUser}
                className="primary_popover_item pointer mt-2"
              >
                Logout
              </div>
            </div>
          </PopOver>
        </div>
        {/* bottom setting button end */}
      </>
    );
  }
}
export function TopBar({ children }) {
  const data = useSelector((state) => state.ToggleButtonState);
  const location = useLocation();
  // console.log(data);
  const dispatch = useDispatch();

  useEffect(() => {}, []);

  function ok() {
    dispatch(toggleByClick());
  }
  return (
    <>
      <div
        className={`app-bar ${
          true ? (data ? "" : "") : ""
        } topbar-p sticky-top align-items-center d-flex`}
      >
        <div className="d-flex align-items-center  w-100">
          <img
            src={data ? "/images/toggle-left.png" : "/images/toggle-right.png"}
            className={`pointer`}
            width="24px"
            height="18.25px"
            alt=""
            onClick={() => ok()}
          />
          <div className="w-100">
            {children ? children : <div className="p-3">&nbsp;</div>}
          </div>
        </div>
      </div>
    </>
  );
}
