import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Sidebar from "../Components/Sidebar/Sidebar";

export const LogingKeeper = () => {
  console.log("this isk keeper");

  function jwtoken() {
    let flag = false;
    sessionStorage.getItem("token") ? (flag = true) : (flag = false);
    return flag;
  }

  return <>{jwtoken() ? <Sidebar><Outlet></Outlet></Sidebar> : <Navigate to={"/"} />}</>;
};
