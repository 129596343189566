import React, { useEffect, useState } from 'react'
import { TopBar } from '../../Components/Sidebar/Sidebar'
import { getAllOrganization } from '../../Services/Admin/Api'
import TableContainer from '../../Components/Assets/TableContainer';
import { Outlet, useNavigate } from "react-router-dom";
import { EditTimer } from './EditTimer';
import { ProgressSpinner } from 'primereact/progressspinner';

export const QuestionsTimer = () => {

    let [allOrganization, setAllOrganization] = useState([]);
    let navigate = useNavigate()
    useEffect(() => {
        getTimerData();
    }, []);

    async function getTimerData() {
        try {
            let res = await getAllOrganization();
            console.log(res.data.data);
            setAllOrganization(res.data.data);
        }
        catch (err) {
            console.log(err);
        };
    };

    return (
        <>
            <div>
                <TopBar />
                <div className='mainContainer'>

                    <div className="card p-md-4 p-sm-3 p-2">
                        <TableContainer>
                            <table className='text-start'>
                                <thead>
                                    <th>Organization Name</th>
                                    <th>Timer</th>
                                    <th>Action</th>
                                </thead>
                                <tbody>
                                    {allOrganization.map((res) => {
                                        return (
                                            <tr>
                                                <td>{res.orgName}</td>
                                                <td>{res.timer}</td>
                                                <td>
                                                    {/* <i onClick={() => navigate(`edit/${res._id}`)} className='pi pointer pi-pencil' /> */}
                                                    <EditTimer getDataAgain={getTimerData} data={res} />
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </TableContainer>
                    </div>
                </div>
            </div>
            <Outlet />
        </>
    );
};



