import React, { useEffect, useState } from "react";
import { TopBar } from "../../../Components/Sidebar/Sidebar";
import { TfiLayoutGrid3 } from "react-icons/tfi";
import { CiBoxList } from "react-icons/ci";
import "./livefeed.css";
import PopOver from "../../../Components/Assets/PopOver";
import { GridView } from "./GridView";
import { useNavigate } from "react-router-dom";
import { activeListEvaluator } from "../../../Services/Admin/Api";
import { useParams } from "react-router-dom";
import moment from "moment";
import { userOrgId } from "../../../Components/Assets/userData";
import ActiveEvaluatorList from "./ActiveEvaluatorList";  

export const LiveFeed = () => {
  let [isGridView, setIsGridView] = useState(
    sessionStorage.getItem("grid") === "true" ? true : false
  );
  let navigate = useNavigate();
function grid(value){
    sessionStorage.removeItem("grid")
    setIsGridView(value)
}
const [activeEvaluator,setActiveEvaluator] = useState([])
const [searchData, setSearchData] = useState([]);
const [searchtext, setsearchtext] = useState("");

const handleSearchClick = (e) => {
  let data = [...searchData];
  let val = e.trim().toLowerCase();

  let searchName = data.filter((res) =>
    res?.name?.toLowerCase().includes(val)
  );
  console.log(searchName);
  setActiveEvaluator(searchName);
};
const getAllActiveEvaluator = async() =>{
await activeListEvaluator(userOrgId()).then((res)=>{
  // console.log(res)
  let dataReverse = [...res.data.data];
  let reverData = dataReverse.reverse();
  setActiveEvaluator(reverData)
  setSearchData(reverData)
}).catch((err)=>{
  console.log(err)
})
}
useEffect(()=>{
  getAllActiveEvaluator()
  // const intervalId = setInterval(() => {
  //   getAllActiveEvaluator();
  // }, 1000); // Update every second

  // // Clear the interval when the component is unmounted
  // return () => clearInterval(intervalId);
},[])



const date = moment()
  return (
    <>
      <TopBar />
      <div className="mainContainer live_feed_main_container">
        <div className="card p-md-4 p-sm-3 p-2">
          <div className="row">
            <div className="col-6">
              <h1>Online Evaluators ({activeEvaluator.length})</h1>
            </div>
            <div className="col-6 p-0 d-flex gap-3">
              <div className="searchInput w-100">
                <input
                  className="form-control ps-5"
                  placeholder="Search"
                  value={searchtext}
                  onChange={(e) => {
                    handleSearchClick(e.target.value);

                  setsearchtext(e.target.value)
                  }}
                ></input>
                <i className="pi pi-search searchIcon"></i>
              </div>
              <div className="d-flex align-items-center">
                <button
                  onClick={() => grid(false)}
                  className={`icon_btn left_btn ${
                    !isGridView ? "active_btn" : ""
                  }`}
                >
                  <CiBoxList />
                </button>
                <button
                  onClick={() => grid(true)}
                  className={`icon_btn right_btn ${
                    isGridView ? "active_btn" : ""
                  }`}
                >
                  <TfiLayoutGrid3 />
                </button>
              </div>
            </div>
          </div>
          <br />
          <br />
          {isGridView ? (
            <GridView />
          ) : (
            // <div className="table_con">
            //   <table>
            //     <thead>
            //       <tr>
            //         <th>Evaluator Full Name</th>
            //         <th>Evaluator Code</th>
            //         <th>Paper Name</th>
            //         <th>Start Time</th>
            //         <th>Session Duration</th>
            //         <th></th>
            //       </tr>
            //     </thead>
            //     <tbody>
            //       {activeEvaluator.map((res,index)=>(

            //       <tr >
            //         <td>{res.name}</td>
            //         <td>{res.uid}</td>
            //         <td>{res?.activeEvaluation?.paperName}</td>
            //         <td>{moment(res?.activeEvaluation?.startTime).format(
            //                           "h:mm:ss a"
            //                         )}</td>
            //         <td>
            //           { moment.utc().startOf('day').add(date.diff(res?.activeEvaluation?.startTime,"seconds"), 'seconds').format('HH:mm:ss ')}
            //         </td>
            //         <td>
            //           <PopOver />
            //         </td>
            //       </tr>
            //       ))}
            //     </tbody>
            //   </table>
            // </div>
            <ActiveEvaluatorList/>
          )}
        </div>
      </div>
    </>
  );
};
