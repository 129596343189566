import React, { useEffect, useRef, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export const Timer = ({ time, startTimer, pauseTimer, resetTimer, onTimerComplete }) => {

    let timer = time * 60
    console.log(time, "tttttttttttttttttttttt")
    let timerRef = useRef(null);

    let [timerValue, setTimerValue] = useState(0)

    useEffect(() => {
        console.log(startTimer)
        if (startTimer) {
            setTimerValue(timer)
            timerRef.current = setInterval(() => {
                setTimerValue(pre => {
                    if (pre == 0) {
                        onTimerComplete();
                        pausetimer();
                        return 0
                    }
                    else {
                        return pre - 1
                    }
                })
            }, 1000);
        }
    }, [startTimer])

    // useEffect(() => {
    //     if (pauseTimer) {
    //         pausetimer()
    //     }
    // }, [pauseTimer])

    useEffect(() => {
        if (resetTimer) {
            resettimer()
        }
    }, [resetTimer])

    function pausetimer() {
        clearInterval(timerRef.current)
    }

    function resettimer() {
        pausetimer()
        setTimerValue(0)
    }


    return (
        <>
            <div style={{ width: 150, height: 150, fontSize: "10px" }}>
                <CircularProgressbar
                    styles={buildStyles({
                        textSize: '13px',
                        pathTransitionDuration: 0,
                        pathColor: `#027BFF`,
                        textColor: '#0474EE',
                        trailColor: '#E6F2FF',
                    })}

                    value={timerValue * 100 / timer} text={`${timerValue} sec`} />
            </div>

        </>
    );
};
