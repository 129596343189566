import { io } from "socket.io-client";
import { baseUrl } from "../../Services/EndPoint";
import { jwtDecodes } from "../../Services/Admin/Api";
import { userId, userRole, fetchIpAddress } from "./userData";
import moment from "moment";
var socket;

export const socketLogin = async () => {
  let ips = await fetchIpAddress();
  let body = { id: userId(), role: userRole(), ip: ips, loginTime: moment() };
  socket.emit("login", body);
};

export const socketLogOut = async () => {
  let ips = await fetchIpAddress();

  let body = {
    id: userId(),
    role: userRole(),
    ip: ips,
    logoutTime: moment(),
  };
  socket.emit("logout", body);
};

export const sockets = socket;

export const runSocket = (res) => {
  if (res) {
    socket = io(baseUrl, {
      auth: {
        id: res._id,
        role: res.role,
      },
    });
  }
};

export const addNotification=()=>{
  socket?.emit("AddUserNotification", {});
}

export const deleteUserNotification = ()=>{
  socket?.emit("DeleteuserNotification",{});
}

export const addExamNotification = () =>{
  socket?.emit("AddExamNotification",{})
}

export const addPaperNotification = () =>{
  socket?.emit("AddPaperNotification",{})
}

export const enabledUserNotification = () =>{
  socket?.emit("BlockUserNotification",{})
}

export const startEvaluatorNotification = () =>{
  socket?.emit("startEvaluationNotification",{})
}

export const stopEvaluatingNotification = () =>{
  socket?.emit("newRecordingDataNotification",{})
}

export const approvedQuestionNotification =()=>{
  socket?.emit("approvedNotification",{})
}

export const addQuestionNotification =()=>{
  socket?.emit("Assign3QuestionsAndAssign",{})
}

export const addthirdEvaluatorNotification = ()=>{
  socket?.emit("AddNewQuestionsAndAssign",{})
}

export const addQuestionGuidelineNotification =()=>{
  socket?.emit("guidelinesadded",{})
}

export const addReportCommandCenterToHod = () =>{
  socket?.emit("getNotificationForHOD",{})
}