import { configureStore } from '@reduxjs/toolkit'
import { counterSlice, sliderToggle } from './Slices/CommonSlice'
import { LoaderSlice } from './Slices/LoaderSlice'

export const store = configureStore({
  reducer: {
    TestState:counterSlice.reducer,
    ToggleButtonState:sliderToggle.reducer,
    Loader:LoaderSlice.reducer
  },
})