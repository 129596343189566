import React, { useEffect, useState } from "react";
import { TopBar } from "../../Components/Sidebar/Sidebar";
import { Label } from "reactstrap";
import {
  userProfileGetById,
  userProfileUdateById,
} from "../../Services/Admin/Api";
import Loader from "../../Components/Assets/Loader";
import { useNavigate, useParams } from "react-router-dom";
import Infomation from "../../Components/Assets/Infomation";

export default function EditUser() {
  const [userGet, setUserGet] = useState();
  const [loading, setLoading] = useState(false);
  const [editName, setEditName] = useState();
  const [editEmail, setEditEmail] = useState();
  const [edintNumber, setEditNumber] = useState();
  const param = useParams();
  const navigate = useNavigate();
  const pattern = new RegExp(/^\d{10}$/);
  const [isError, setIsError] = useState(false);
  const [emailExixt, setEmailExit] = useState(false);
  const [contactExist,setContactExist] = useState(false)


  const getUserData = async () => {
    setLoading(true);
    await userProfileGetById(param.id)
      .then((res) => {
        console.log(res);
        setUserGet(res.data.data);
        setEditName(res.data.data.name);
        setEditNumber(res.data.data.contectNo);
        setEditEmail(res.data.data.email);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const updatedUserById = async (e) => {
    e.preventDefault();
    setLoading(true);
    let updatePayload = {
      name: editName.trim(),
      email: editEmail,
      contectNo: edintNumber,
    };
    await userProfileUdateById(param.id,updatePayload)
      .then((res) => {
        console.log(res);
        setLoading(false);
        navigate("/users");
      })
      .catch((err) => {
        console.log(err?.response?.data?.errormessage?.includes("email"));
        if (err?.response?.data?.errormessage?.includes("email")) {
          setEmailExit(true);
          setLoading(false);
        } else {
          setEmailExit(false);
          setLoading(false);
        }
        // if(err?.response.data?.errormessage?.includes("contectNo_1 dup key")){
        //   setContactExist(true)
        //   setLoading(false)
        // }else{
        //   setContactExist(false)
        //   setLoading(false)
        // }
      });
  };

  useEffect(() => {
    getUserData();
  }, []);
  return (
    <>
      <TopBar></TopBar>
     
        <div className="mainContainer">
          <div className="card p-md-4 p-sm-3 p-2">
          <form
        onSubmit={(e) => {
          e.preventDefault()
          updatedUserById(e);
        }}
      >
            <h1>Edit User</h1>
            <div>
              {emailExixt ? (
                <Infomation text="Contact is already exists" type="warning" />
              ) : (
                ""
              )}
              {/* {
                contactExist ? (
                  <Infomation text="Contact is already exists" type="warning" />
                ):(
                  ""
                )
              } */}
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <Label className="HelveticaMedium">Name</Label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={userGet?.name}
                  onChange={(e) => {
                    if (e.target.value === " ") {
                      e.target.value = "";
                    } else {
                      setEditName(e.target.value);
                    }
                  }}
                  required
                />
              </div>
              <div className="col-md-6">
                <Label className="HelveticaMedium">User Code</Label>
                <input
                  className="form-control"
                  defaultValue={userGet?.uid}
                  disabled
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-6">
                <Label className="HelveticaMedium">Conatct Number</Label>
                <input
                  className="form-control"
                  type="number"
                  defaultValue={userGet?.contectNo}
                  onChange={(e) => {
                    if (e.target.value.length === 10) {
                      setEditNumber(e.target.value);
                      setIsError(false);
                    } else {
                      setIsError(true);
                    }
                  }}
                  required
                />
              </div>
              <div className="col-md-6">
                <Label className="HelveticaMedium">Email Address</Label>
                <input
                  className="form-control"
                  type="email"
                  defaultValue={userGet?.email}
                  onChange={(e) => {
                    setEditEmail(e.target.value);
                  }}
                  disabled
                />
              </div>
            </div>
            {isError ? (
              <div className="row">
                {" "}
                <div className="col-md-6">
                  <Infomation
                    text="Please Enter 10 digit contact number"
                    type="warning"
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="d-flex justify-content-end mt-4">
              <div className="d-flex">
                <button
                  className="btn btn-Secondary me-3"
                  onClick={() => {
                    navigate("/users");
                  }}
                  type="button"

                >
                  Cancel
                </button>
                <button className="btn btn-primary">Update User</button>
              </div>
            </div>
      </form>
          </div>
        </div>
      <Loader open={loading} />
    </>
  );
}
