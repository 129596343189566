import React, { useEffect } from "react";
import "./style.css";
import { Link, useLocation } from "react-router-dom";
import { storeUserInfo } from "../../Components/Assets/userData";
import { userRole } from "../../Components/Assets/userData";

export default function BiometricSecond() {
  const location = useLocation()
  let userDataRole = location?.state
  // console.log(location?.state);
  useEffect(() => {
    storeUserInfo();
  }, []);
  return (
    <>
      <div className="main-container">
        <div className="left-contain">
          <div className="logoFixed">
            <span className="eLogo">E-Valuators App</span>
          </div>
        </div>
        <div className="right-contain">
          <div className="right-content-div ">
            <div className="d-flex justify-content-center ">
              <span className="logIn">Log In</span>
            </div>
            <div className="d-flex justify-content-center">
              <span className="HelveticaMedium mt-3">
                Use your Biometric Credentials
              </span>
            </div>
            <div className="d-flex justify-content-center">
              <span className="resetPasswordFirst mt-3">
                In order to login, touch the external fingerprint sensor with
                your finger
              </span>
            </div>
            <div className="d-flex justify-content-center mt-4">
              {/* <Link to="/overview">
                <img src="/images/biometric.png" alt="" />
              </Link> */}
              {userDataRole === "orgAdmin" &&(<>
                <Link to="/overViewOrgAdmin">
                <img src="/images/biometric.png" alt="" />
              </Link>
              </>)}
              {userDataRole === "admin" &&(<>
              <Link to="/overview">
                <img src="/images/biometric.png" alt="" />
              </Link>
              </>)}
              {userDataRole === "evaluator" &&(
                 <Link to="/evaluatorOverview">
                 <img src="/images/biometric.png" alt="" />
               </Link>
              )}
              {userDataRole === "command center" &&(
                <Link to="/commandoverview">
                <img src="/images/biometric.png" alt="" />
              </Link>
              )}
              {userDataRole === "secretary" &&(
                <Link to="/secretaryOverview">
                <img src="/images/biometric.png" alt="" />
              </Link>
              )}
              {userDataRole === "hod" &&(
                 <Link to="/hodoverview">
                 <img src="/images/biometric.png" alt="" />
               </Link>
              )}
            </div>
            <div className="d-flex justify-content-center mt-3">
              <Link to="/" className="textDecoration">
                <span className="backTologin">Cancel</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
