import React, { useEffect, useState } from 'react'
import { TopBar } from '../../Components/Sidebar/Sidebar'
import { QuestionPaperForm } from './QuestionPaperForm'
import { useNavigate, useParams } from 'react-router-dom'
import { getPaperById, updatePaperById } from '../../Services/Admin/Api'
import { loader } from '../../utils/loader'

export const EditQuestionForm = () => {

  let params = useParams()
  let [questionPaperData, setQuestionPaperDate] = useState({})
  let [updatedFields, setUpdatedFields] = useState({})
  let navigate = useNavigate()

  useEffect(() => {
    const getpaperbyid = async () => {
      try {
        loader.start()
        let res = await getPaperById(params.id)
        console.log(res,"{}{}{}{}{}{}{}{}{}{}")
        let { paperName, paperCode, orgId, paperDate, examId } = res.data.data
        setQuestionPaperDate({ paperName, paperCode, orgId, paperDate, examId })
        loader.stop()
      }
      catch (err) {
        console.log(err)
        loader.stop()
      }
    }
    getpaperbyid()
  }, [])

  async function formSubmit(data) {
    try {
      console.log(updatedFields)
      loader.start()
      let res = await updatePaperById(params.id, updatedFields)
      loader.stop()
      navigate("/question")
      console.log(res)
    }
    catch (err) {
      loader.stop()
      console.log(err)
    }
  }

  return (
    <>
      <TopBar />
      {questionPaperData.paperName ?
        <QuestionPaperForm mode={"Update"} setUpdatedFields={setUpdatedFields} onSubmit={formSubmit} data={questionPaperData} /> : null}
    </>
  )
}