import React, { useEffect, useState } from "react";
import { TopBar } from "../../../Components/Sidebar/Sidebar";
import TableContainer from "../../../Components/Assets/TableContainer";
import { BackButton } from "../../../Components/Assets/BackButton";
import { useParams } from "react-router-dom";
import { getReportsById } from "../../../Services/Admin/Api";
import moment from "moment";
import { loader } from "../../../utils/loader";

export default function DetailReport() {
  const param = useParams();
  const [getreportId, setGetReportId] = useState();

  const getReportDataById = async () => {
    loader.start()
    await getReportsById(param.id)
      .then((res) => {
        console.log(res);
        loader.stop()
        setGetReportId(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        loader.stop()
      });
  };
  const date = moment();

  useEffect(() => {
    getReportDataById();
  }, []);
  return (
    <>
      <TopBar />
      <div className="mainContainer">
        <div className="card p-md-4 p-sm-3 p-2">
          <BackButton link={"/reports"} />
          <div className="mt-3">
            <h1>{getreportId?.userName}</h1>
          </div>
          <div className="py-2">
            <TableContainer>
              <table className="left_align_table d-flex justify-content-between">
                <div className="w-50 border">
                  <tr className="w-100 d-flex justify-content-between p-4">
                    <th>Reported by</th>
                    <td>{getreportId?.commandCentreName}</td>
                  </tr>
                  <tr className="w-100 d-flex justify-content-between p-4">
                    <th>Reported on</th>
                    <td>
                      {moment(getreportId?.reportTime).format("DD  MMMM  YYYY")}
                    </td>
                  </tr>
                  <tr className="w-100 d-flex justify-content-between p-4">
                    <th>Reported at</th>
                    <td>
                      {moment(getreportId?.reportTime).format("h:mm:ss a")}
                    </td>
                  </tr>
                  <tr className="w-100 d-flex justify-content-between p-4">
                    <th>Reporting Reason</th>
                    <td>{getreportId?.messages}</td>
                  </tr>
                </div>
                <div className="w-50 border">
                  <tr className="w-100 d-flex justify-content-between p-4">
                    <th>Paper Name</th>
                    <td>{getreportId?.paperName}</td>
                  </tr>
                  <tr className="w-100 d-flex justify-content-between p-4">
                    <th>Login Time</th>
                    <td>
                      {moment(getreportId?.startEvalution).format("h:mm:ss a")}
                    </td>
                  </tr>
                  <tr className="w-100 d-flex justify-content-between p-4">
                    <th>Logout Time</th>
                    <td>
                      {getreportId?.endEvalution ? (
                        getreportId?.endEvalution
                      ) : (
                        <span className="onlineColor">Online</span>
                      )}
                    </td>
                  </tr>
                  <tr className="w-100 d-flex justify-content-between p-4">
                    <th>Total Screen Time</th>
                    <td>
                      {moment
                        .utc()
                        .startOf("day")
                        .add(
                          date.diff(getreportId?.startEvalution, "second"),
                          "second"
                        )
                        .format("HH:mm:ss ")}
                    </td>
                  </tr>
                </div>
              </table>
            </TableContainer>
          </div>
        </div>
      </div>
    </>
  );
}
