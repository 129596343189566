import React from 'react'
import { FaArrowLeft } from 'react-icons/fa6'
import "./style.css"
import { Link, useNavigate } from 'react-router-dom'

export const BackButton = ({ link }) => {
    const navigate = useNavigate();

    return (
        <Link to={link} className='back_btn_con'>
            <div className='d-flex align-items-center gap-1  back_btn_con'><FaArrowLeft /> Back</div>
        </Link>
    )
}
