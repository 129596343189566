import React, { useState } from "react";
import "./style.css";
import { Label } from "reactstrap";
import Infomation from "../../Components/Assets/Infomation";
import { login } from "../../Services/Admin/Api";
import CryptoJS from "crypto-js";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ProgressSpinner } from 'primereact/progressspinner';
import Loader from "../../Components/Assets/Loader";
export default function Login() {
  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();
  const [error, setEror] = useState(null);
  const [infoError, setInfoError] = useState(false);
  const [eye, setEye] = useState(true);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [userError,setUserError] = useState(false)

  const validate = (password) => {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{12,}$/.test(
      password
    );
  };
  const handleChangePass = (e) => {
    setPassword(e.target.value);
    setInfoError(false);
  };
  function isValidEmail(userName) {
    return /\S+@\S+\.\S+/.test(userName);
  }

  const handleChange = (e) => {
    setUserName(e.target.value);
    setInfoError(false);
  };
  const handleEncrypt = (message) => {
    const cipherText = CryptoJS.AES.encrypt(message, "<w9|2+-zV-8f(oJ");
    return cipherText.toString();
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setEror(null);
    setLoading(true)
    if (isValidEmail(userName)) {
     
      // if (validate(password)) {
        await login({
          email: userName.toLowerCase(),
          password: password,
        })
          .then((res) => {
            console.log(res);
            setInfoError(false);
            setLoading(false)
            if (res?.response?.data?.message) {
              setInfoError(true);
              setLoading(false)
            } else {
              console.log(res.data.data);
              const criptToken = handleEncrypt(res.data?.data?.token);
              sessionStorage.setItem("token", criptToken);
              setInfoError(false);
              setLoading(false)
              if(res.data.data.firstTimeLogin === true){

                navigate("/biometric");
              }else{
                console.log(res?.data?.data?.role)
                navigate("/loginBioAuthentication",{state:res?.data?.data?.role})
              }
            }
          })
          .catch((error) => {
            console.log(error);
            if(error?.response?.data?.errormessage?.message ==="Logout from previous device"){
              setUserError(true)
              setInfoError(false);
              setLoading(false)
            }else{
                setUserError(false)
                setLoading(false)
                setInfoError(true)
            }
          });
      // } else {
      //   setLoading(false)
      //   setInfoError(true);
      // }
    }else{
      setLoading(false)
      setInfoError(true);
    }
  };
  return (
    <>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="main-container">
          <div className="left-contain">
            <div className="logoFixed">
              <span className="eLogo">E-Valuators App</span>
            </div>
          </div>
          <div className="right-contain">
            <div className="right-content-div ">
              <div className="d-flex justify-content-center ">
                <span className="logIn">Log In</span>
              </div>
              <div className="mt-4">
                <Label className="HelveticaMedium">Email Address</Label>
                <input
                  placeholder="Enter Email Address"
                  id="loginEmail"
                  className="form-control"
                  type="email"
                  value={userName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-password mt-4">
                <Label className="HelveticaMedium">Password</Label>
                <input
                  placeholder="Enter Password"
                  className="form-control"
                  id="loginPassword"
                  value={password}
                  onChange={handleChangePass}
                  type={eye ? "password" : "text"}
                  required
                />
                {eye ? (
                  <i
                    className="pi pi-eye-slash password-eye pointer"
                    style={{ color: "#94989E" }}
                    onClick={() => setEye(!eye)}
                  ></i>
                ) : (
                  <i
                    className="pi pi-eye password-eye pointer"
                    style={{ color: "#94989E" }}
                    onClick={() => setEye(!eye)}
                  ></i>
                )}
              </div>

              {infoError ? (
                <>
                  <Infomation text="Incorrect credentials" type="warning" />
                </>
              ) : (
                ""
              )}
              {userError ? (
                <>
                <Infomation type="warning" text="It seems you're currently logged in with the same credentials on another device. To proceed with logging in here, please ensure you log out from the other device."/>
                </>
              ):(
                <></>
              )}
              <div className="d-flex justify-content-end mt-4">
                <Link to="/forgetPassword" className="textDecoration">
                  <span className="forgot-password">Forgot Password?</span>
                </Link>
              </div>
              <div className="mt-4">
                <button
                  className=" btn btn-primary w-100"
                  type="submit"
                  id="loginsubmit"
                >
                  Continue 
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Loader open={loading}/>
    </>
  );
}
