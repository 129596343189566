import React from "react";

export default function Infomation({ text, type }) {
  return (
    <>
      <div
        className="d-flex align-items-center info-box mt-2"
        style={{ background: type == "warning" ? "#FCDAD7" : "" }}
      >
        <i
          className="pi pi-info-circle "
          style={{
            fontSize: "19px",
            color: type == "warning" ? "#F04438" : "#101828",
          }}
        ></i>
        <span className=" ms-3 text-main-color text_justify w-100" style={{fontSize:"14px"}}>
          {text}
        </span>
      </div>
    </>
  );
}
