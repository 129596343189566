import React, { useState } from "react";
import "./style.css";
import Infomation from "../../Components/Assets/Infomation";
import { Label } from "reactstrap";
import { resetPassword,userFirstTimeDisable } from "../../Services/Admin/Api";
import Loader from "../../Components/Assets/Loader";
import { useNavigate } from "react-router";
import { userEmail, userRole } from "../../Components/Assets/userData";

export default function ResetPassword() {
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [eye, setEye] = useState(true);
  const [confirmEye, setConfirmEye] = useState(true);
  const [infoError, setInfoError] = useState(false);
  const [error, setError] = useState(false);
  const [check, setCheck] = useState(false);
  const [loading, setLoading] = useState(false)
  const  navigate = useNavigate();
  const validate = (password) => {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{12,}$/.test(
      password
    );
  };
  const handleChangePass = (e) => {
    setPassword(e.target.value);
    setError(false);
    setCheck(false);
  };
  const handleconfirmPass = (e) => {
    setConfirmPassword(e.target.value);
    setError(false);
    setCheck(false);
    setInfoError(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
   
    if (!validate(password)) {
      setError(true);
    } else {
      if (password === confirmPassword) {
        setLoading(true)
        await resetPassword({
          email: userEmail(),
          password: password,
          role: userRole()
        })
          .then(async(res) => {
            console.log(res);
            setLoading(false)
            let res1 = await userFirstTimeDisable({
              email:userEmail(),
              role:userRole(),
            })
            .catch((err)=>{
              console.log(err)
            setLoading(false)

            })
            console.log(res1)
            // sessionStorage.clear()
            navigate("/")

          })
          
          .catch((err) => {
            console.log(err);
            setLoading(false)

          });

      } else {
        setCheck(true);
        console.log("inCorrect password match");
        setInfoError(true);
      }
    }
  };
  return (
    <>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="main-container">
          <div className="left-contain">
            <div className="logoFixed">
              <span className="eLogo">E-Valuators App</span>
            </div>
          </div>
          <div className="right-contain">
            <div className="right-content-div">
              <div className="d-flex justify-content-center">
                <span className="logIn">Login</span>
              </div>
              <div className="d-flex justify-content-center">
                <span className="HelveticaMedium mt-3">Reset Password</span>
              </div>
              <div className="d-flex justify-content-center">
                <span className="resetPasswordFirst mt-3">
                  As a first-time user, please reset your password as a
                  precautionary measure to safeguard your account and prevent
                  any potential misuse.
                </span>
              </div>
              <div className="">
                {error ? (
                  <Infomation
                    text={
                      <ul className="py-0">
                        <li className="py-0">Password must have at least 12 characters</li>
                        <li className="py-0">
                          A mixture of both uppercase and lowercase letters
                        </li>
                        <li className="py-0">
                          Inclusion of at least one special character, e.g., ! @
                          # ?{" "}
                        </li>
                      </ul>
                    }
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="form-password mt-4">
                <Label className="HelveticaMedium">New Password</Label>
                <input
                  className="form-control"
                  placeholder="Enter New Password"
                  type={eye ? "password" : "text"}
                  required
                  onChange={handleChangePass}
                  id="resetPassword"
                />
                {eye ? (
                  <i
                    className="pi pi-eye-slash password-eye pointer"
                    style={{ color: "#94989E" }}
                    onClick={() => setEye(!eye)}
                  ></i>
                ) : (
                  <i
                    className="pi pi-eye password-eye pointer"
                    style={{ color: "#94989E" }}
                    onClick={() => setEye(!eye)}
                  ></i>
                )}
              </div>
              <div className="form-password mt-4">
                <Label className="HelveticaMedium">Confirm New Password</Label>
                <input
                  className="form-control"
                  placeholder="Confirm New Password"
                  type={confirmEye ? "password" : "text"}
                  required
                  onChange={handleconfirmPass}
                  id="resetConfirmPassword"
                />
                {confirmEye ? (
                  <i
                    className="pi pi-eye-slash password-eye pointer"
                    style={{ color: "#94989E" }}
                    onClick={() => setConfirmEye(!confirmEye)}
                  ></i>
                ) : (
                  <i
                    className="pi pi-eye password-eye pointer"
                    style={{ color: "#94989E" }}
                    onClick={() => setConfirmEye(!confirmEye)}
                  ></i>
                )}
              </div>
              {infoError ? (
                <Infomation
                  text="Passwords are not same"
                  type="warning"
                ></Infomation>
              ) : (
                ""
              )}
              <div className="mt-4">
                <button
                  className=" btn btn-primary w-100"
                  type="submit"
                  id="resetSubmit"
                >
                  Login <img src="/images/Vector.png" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Loader open={loading}/>
    </>
  );
}
