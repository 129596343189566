import { Dialog } from "primereact/dialog";
import React, { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import Peer from "simple-peer";
import styled from "styled-components";
import { baseUrl } from "../../../Services/EndPoint";
import {
  userId,
  userInfo,
  userOrgId,
  userRole,
} from "../../../Components/Assets/userData";
import { useLocation } from "react-router-dom";
import { activeListEvaluator } from "../../../Services/Admin/Api";
import {
  userProfileGetById,
  commandCenterReport,
} from "../../../Services/Admin/Api";
import moment from "moment";
import { Select } from "../../../Components/Inputs/Select";
import Loader from "../../../Components/Assets/Loader";
import { addReportCommandCenterToHod } from "../../../Components/Assets/socket";

const Container = styled.div`
  padding: 20px;
  display: flex;
  height: 100vh;
  width: 90%;
  margin: auto;
  flex-wrap: wrap;
`;

const StyledVideo = styled.video`
  height: 100%;
  width: 100%;
`;
const StyledVideo1 = styled.video`
  height: 100%;
  width: 100%;
`;
const Video = ({ peer }) => {
  const ref = useRef();

  useEffect(() => {
    if (peer) {
      peer.on("stream", (stream) => {
        ref.current.srcObject = stream;
      });
    }
  }, [peer]);

  return <StyledVideo playsInline autoPlay ref={ref} muted />;
};

const videoConstraints = {
  height: window.innerHeight / 2,
  width: window.innerWidth / 2,
};
const VideoOne = (props) => {
  const refs = useRef();
  useEffect(() => {
    refs.current.srcObject = props.stream;
  }, [props.stream]);
  if (!props.stream) {
    return null;
  }

  return <StyledVideo1 playsInline autoPlay ref={refs} muted={props.mute} />; // Render video element
};
export const GridView = () => {
  const [peers, setPeers] = useState([]);
  const [loader, setLoader] = useState(0);
  const location = useLocation();
  let socketRef;
  const userVideo = useRef({ srcObject: "" });
  const peersRef = useRef([]);
  const roomID = "UqwhbsvaybxjzkU12132Y";
  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    socketRef = io(baseUrl, {
      auth: {
        id: userId(),
        role: userRole(),
      },
    });
    navigator.mediaDevices
      .getUserMedia({ video: videoConstraints, audio: true })
      .then((stream) => {
        userVideo.current.srcObject = stream;
        let room = {
          roomID,
          name: userInfo().name,
          role: "command",
          orgId: userOrgId(),
        };
        console.log(room);
        socketRef.emit("join room", room);

        socketRef.on("all users", (users) => {
          console.log(users, "users");
          const peers = [];
          if (users === null) users = [];

          users.forEach((userID) => {
            const peer = createPeer(userID.id, socketRef.id, stream);
            peersRef.current.push({
              peerID: userID.id,
              peer,
              userName: userID.name,
            });
            peers.push({
              peer,
              userName: userID.name,
              userId: userID.userId,
              paperId: userID.paperId,

              type: userID.type,
              organization_name: userID.organization_name,
              paper_name: userID.paper_name,
              exam_name: userID.exam_name,
            });
          });
          const output = peers.reduce(
            (
              acc,
              {
                peer,
                type,
                userId,
                userName,
                paperId,
                organization_name,
                paper_name,
                exam_name,
              }
            ) => {
              const existingUser = acc.find((item) => item.userId === userId);
              if (!existingUser) {
                acc.push({
                  userId: userId,
                  userName: userName,
                  paperId: paperId,
                  [`peer${type}`]: peer,
                  organization_name: organization_name,
                  paper_name: paper_name,
                  exam_name: exam_name,
                });
              } else {
                existingUser[`peer${type}`] = peer;
              }
              return acc;
            },
            []
          );
          setPeers(output);
        });
        socketRef.on("user joined", (payload) => {
          const peer = addPeer(payload.signal, payload.callerID, stream);
          peersRef.current.push({
            peerID: payload.callerID,
            peer,
          });
          setPeers((users) => [
            ...users,
            {
              peer,
            },
          ]);
        });

        socketRef.on("receiving returned signal", (payload) => {
          const item = peersRef.current.find((p) => p.peerID === payload.id);
          item.peer.signal(payload.signal);
        });

        stream.getTracks().forEach((track) => {
          track.stop();
        });
      });
    socketRef.on("setData", (orgId) => {
      if (location.pathname === "/commandCenter/liveFeed") {
        if (orgId === userOrgId()) {
          sessionStorage.setItem("grid", true);
          window.location.reload();
        }
      }
      // setLoader(loader + 1);
    });
  }, [loader]);

  function createPeer(userToSignal, callerID, stream) {
    try {
      const peer = new Peer({
        initiator: true,
        trickle: false,
        stream,
        config: {
          iceServers: [
            // {
            //   urls: "turns:numb.viagenie.ca:19403",
            //   username: "webrtc@live.com",
            //   credential: "muazkh",
            // },

            {
              url: "turn:192.158.29.39:3478?transport=udp",
              credential: "JZEOEt2V3Qb0y27GRntt2u2PAYA=",
              username: "28224511:1379330808",
            },
            {
              url: "turn:192.158.29.39:3478?transport=tcp",
              credential: "JZEOEt2V3Qb0y27GRntt2u2PAYA=",
              username: "28224511:1379330808",
            },

            // Add more TURN or STUN servers here if needed
          ],
        },
      });

      peer.on("signal", (signal) => {
        socketRef.emit("sending signal", {
          userToSignal,
          callerID,
          signal,
        });
      });

      return peer;
    } catch (error) {
      console.error("Error creating peer:", error);
      // Handle error (e.g., fallback mechanism, user notification)
    }
  }

  function addPeer(incomingSignal, callerID, stream) {
    try {
      const peer = new Peer({
        initiator: false,
        trickle: false,
        stream,
        config: {
          iceServers: [
            // {
            //   urls: "turns:numb.viagenie.ca:19403",
            //   username: "webrtc@live.com",
            //   credential: "muazkh",
            // },

            {
              url: "turn:192.158.29.39:3478?transport=udp",
              credential: "JZEOEt2V3Qb0y27GRntt2u2PAYA=",
              username: "28224511:1379330808",
            },
            {
              url: "turn:192.158.29.39:3478?transport=tcp",
              credential: "JZEOEt2V3Qb0y27GRntt2u2PAYA=",
              username: "28224511:1379330808",
            },

            // Add more TURN or STUN servers here if needed
          ],
        },
      });

      peer.on("signal", (signal) => {
        socketRef.emit("returning signal", { signal, callerID });
      });

      peer.signal(incomingSignal);

      return peer;
    } catch (error) {
      console.error("Error adding peer:", error);
      // Handle error (e.g., fallback mechanism, user notification)
    }
  }

  function GridCard({
    peer,
    name,
    index,
    userID,
    paperId,
    organization_name,
    paper_name,
    exam_name,
  }) {
    const [dialog, setDialog] = useState(false);
    const [videoRef, setVideoRef] = useState(null);
    const [videoFront, setVideoFront] = useState(null);
    const [videoBack, setVideoBack] = useState(null);

    const [mute, setMute] = useState(true);
    // const videoRef = useRef({ srcObject: "" });
    const [evaluatorTime, setEvaluatorTime] = useState([]);

    const [opndata, setOpenData] = useState(false);

    const [EvaluateData, setEvaluateData] = useState("");
    const reportData = [
      "Using phone/other digital device while evaluating",
      "Trying switching tabs while evaluating",
      "Talking to somebody else in the room while evaluating",
      "Left the system unattended while the evaluation was on",
      "Evaluator not clearly visible on screen",
      "Custom enter the reason...",
    ];
    const date = moment();

    const [message, setMessage] = useState("");

    const sumitReport = async () => {
      // setLoading(true)
      // loader.start()
      await commandCenterReport({
        messages: EvaluateData,
        paperId: paperId,
        userId: userID,
        startEvalution: evaluatorTime?.activeEvaluation?.startTime,
        commandCentreId: userId(),
        reportTime: new Date(),
        orgId: userOrgId(),
        evaluator_name: name,
        paper_name: paper_name,
        organization_name: organization_name,
        exam_name: exam_name,
      })
        .then((res) => {
          console.log(res);
          // loader.stop()
          // setLoading(false)
          setDialog(false);
          addReportCommandCenterToHod();
        })
        .catch((err) => {
          console.log(err);
          // loader.stop()
          // setLoading(false)
        });
    };

    const evaluatorData = async () => {
      await userProfileGetById(userID)
        .then((res) => {
          console.log(res);
          setEvaluatorTime(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    useEffect(() => {
      evaluatorData();
      const intervalId = setInterval(() => {
        evaluatorData();
      }, 1000);

      // Clear the interval when the component is unmounted
      return () => clearInterval(intervalId);
    }, []);
    function start() {
      peer.on("stream", (stream) => {
        console.log(stream, name);
        // videoRef.current.srcObject = stream;

        setVideoRef(stream);
      });
    }
    useEffect(() => {
      start();
    }, [peer]);

    return (
      <>
        <div className="grid_card_con" onClick={() => setDialog(true)}>
          <Video key={index} peer={peer} />
        </div>
        <Dialog
          visible={dialog}
          style={{ width: "800px" }}
          draggable={false}
          closable={false}
        >
          <div className="p-2">
            <div className="d-flex justify-content-between">
              <h1>{name}</h1>
              <div>
                <button
                  className="btn btn-Secondary"
                  onClick={() => {
                    setDialog(false);
                    setMute(!mute);
                    setOpenData(false);
                    setEvaluateData("");
                  }}
                >
                  Close
                </button>
              </div>
            </div>
            <br />
            <div>
              <VideoOne
                key={index}
                stream={videoRef}
                className="w-100"
                style={{ height: "170px" }}
                mute={mute}
              />
            </div>
            <div className="d-flex justify-content-between mt-3">
              <div>
                <span>
                  Login Time:
                  {moment(evaluatorTime?.activeEvaluation?.startTime).format(
                    "h:mm:ss a"
                  )}
                </span>
              </div>
              <div>
                <span>
                  Total Screen Time:
                  {moment
                    .utc()
                    .startOf("day")
                    .add(
                      date.diff(
                        evaluatorTime?.activeEvaluation?.startTime,
                        "second"
                      ),
                      "second"
                    )
                    .format("HH:mm:ss ")}
                </span>
              </div>
            </div>
            <div className="d-flex justify-content-between mt-3">
              {opndata === true ? (
                <div className="col-md-6">
                  <input
                    className="form-control"
                    placeholder="Please Enter The Reason"
                    onChange={(e) => {
                      setEvaluateData(e.target.value);
                    }}
                  />
                </div>
              ) : (
                <>
                  {" "}
                  <Select
                    placeholder="Choose Reporting Reason"
                    options={reportData}
                    value={EvaluateData}
                    onChange={(e) => {
                      setEvaluateData(e.target.value);
                      if (e.target.value === "Custom enter the reason...") {
                        setOpenData(true);
                      } else {
                        setOpenData(false);
                      }
                    }}
                    className="w-75"
                  />
                </>
              )}

              <div>
                <button
                  className="btn btn-secondary"
                  onClick={() => {
                    sumitReport();
                  }}
                  disabled={EvaluateData.length !== 0 ? false : true}
                >
                  Report Hod
                </button>
              </div>
            </div>
            <div className="mt-3">
              <button
                className="btn btn-Primary"
                onClick={() => setMute(!mute)}
              >
                {mute ? "UnMute" : "Mute"}
              </button>
            </div>
          </div>
        </Dialog>
      </>
    );
  }

  return (
    <>
      <div className="row p-3 rounded border">
        {console.log("asdasdasdasdasdasd")}
        {peers.map((d, idn) => (
          <>
            {console.log(peers)}
            {d.peerFront && d.peerBack && d.peerScreen && (
              <>
                <div>{d.userName}</div>
                <div className="d-flex justify-content-around ">
                  <div className="col-4 p-3" key={idn}>
                    <GridCard
                      peer={d.peerFront}
                      name={d.userName}
                      index={idn}
                      userID={d.userId}
                      paperId={d.paperId}
                      organization_name={d.organization_name}
                      paper_name={d.paper_name}
                      exam_name={d.exam_name}
                    />
                    <div>Front Camera</div>
                  </div>
                  <div className="col-4 p-3" key={idn}>
                    <GridCard
                      peer={d.peerBack}
                      name={d.userName}
                      index={idn}
                      userID={d.userId}
                      paperId={d.paperId}
                      organization_name={d.organization_name}
                      paper_name={d.paper_name}
                      exam_name={d.exam_name}
                    />
                    <div>Back Camera</div>
                  </div>
                  <div className="col-4 p-3" key={idn}>
                    <GridCard
                      peer={d.peerScreen}
                      name={d.userName}
                      index={idn}
                      userID={d.userId}
                      paperId={d.paperId}
                      organization_name={d.organization_name}
                      paper_name={d.paper_name}
                      exam_name={d.exam_name}
                    />
                    <div>Screen Share</div>
                  </div>
                </div>
                <br />
              </>
            )}
          </>
        ))}
      </div>
      <Loader open={Loading} />
    </>
  );
};
