import React, { useEffect, useState } from "react";
import { TopBar } from "../../Components/Sidebar/Sidebar";
import PopOver from "../../Components/Assets/PopOver";
import { PopoverItem } from "../../Components/Assets/PopoverItem";
import { useNavigate } from "react-router";
import TableContainer from "../../Components/Assets/TableContainer";
import {
  getAllRecordings,
  getAllEvaluatorList,
  getAllOrganization,
} from "../../Services/Admin/Api";
import Loader from "../../Components/Assets/Loader";
import moment from "moment";
import { Select } from "../../Components/Inputs/Select";
import { Input } from "reactstrap";

export default function Recording() {
  const navigate = useNavigate();
  const [allEvaluators, setAllEvaluators] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [organizationList, setOrganizationList] = useState([]);
  const [selectOrganization, setSelectOrganization] = useState();
  const [filterOrganization, setFilterOrganization] = useState([])
  const [searchtext, setsearchtext] = useState("");

  let [allData, setAllData] = useState([])

  const allEvaluatorGet = async (id) => {
    setLoading(true);
    await getAllEvaluatorList()
      .then((res) => {
        console.log(res.data.data);
        setAllData(res.data.data)
        // let dataReverse = [...res.data.data];
        // let reverData = dataReverse.reverse();
        // setAllEvaluators(reverData);
        // filterByOrg(id,reverData);

        // setSearchData(reverData);
        // setFilterOrganization(reverData)
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  function filterByOrg(id, data) {
    console.log(id)
    console.log(data)
    let filteredData = data.filter((e) => e.orgId === (sessionStorage.getItem("org") ? sessionStorage.getItem("org") : id))
    setSearchData(filteredData);
    console.log(filteredData)
    setFilterOrganization(filteredData)
  }

  const getAllOrganizationList = async () => {
    await getAllOrganization()
      .then((res) => {
        // console.log(res);
        setOrganizationList(res.data.data);
        // setSelectOrganization(res.data.data)
        allEvaluatorGet(res.data.data[0]._id)
        if (sessionStorage.getItem("org")) {
          setSelectOrganization(sessionStorage.getItem("org"));

        } else {

          setSelectOrganization(res.data.data[0]._id);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getAllOrganizationList();
    // allEvaluatorGet()

  }, [])

  // useEffect(() => {
  //     filterByOrg(sessionStorage.getItem("org"),allEvaluators);
  // },[selectOrganization] );
  return (
    <>
      <TopBar>
        <div className="d-flex justify-content-end">
          {/* <Input
            type="select"
            className="form-control w-25"
            value={sessionStorage.getItem("org")}
            onChange={(e) => {
              setSelectOrganization(e.target.value);
              sessionStorage.setItem("org", e.target.value);
            }}
          >
            <option value="" disabled>
              Select Organization
            </option>
            {organizationList.map((res, index) => {
              return (
                <option key={index} value={res._id}>
                  {res.orgName}
                </option>
              );
            })}
          </Input> */}
          {/* <Select
            style={{ width: "200px" }}
            options={organizationList}
            optionLabel="orgName"
            placeholder="Select organization"
            value={organizationList.find(
              (s) => s._id == (sessionStorage.getItem("org") ? sessionStorage.getItem("org") : selectOrganization)
            )}
            onChange={(e) => {
              // setSelectRole(e.target.value);
              // sessionStorage.setItem("org", e.target.value);
              console.log("okokokokok");

              console.log(e.value);
              setSelectOrganization(e.value._id);
              sessionStorage.setItem("org", e.value._id);
              setsearchtext("")
            }}
          /> */}
        </div>
      </TopBar>
      <div className="mainContainer">
        <div className="card p-md-4 p-sm-3 p-2">
          <div className="d-flex justify-content-between align-items-center pt-4 pb-3">
            <h1>Recordings</h1>
            <div className="searchInput w-25 me-3">
              <input
                className="form-control ps-5"
                placeholder="Search"
                value={searchtext}
                onChange={(e) => {
                  // handleSearchClick(e.target.value);
                  // setsearchtext(e.target.value)
                }}
              ></input>
              <i className="pi pi-search searchIcon"></i>
            </div>
          </div>
          <div className="py-3">
            <TableContainer>
              <table className="left_align_table">
                <thead>
                  <th>Evaluator Full Name</th>
                  <th>Evaluator Code</th>
                  <th>Email Address</th>
                  <th>Last Active on</th>
                </thead>
                <tbody>
                  {allData.map((res, index) => {
                    return (
                      <tr key={index}>
                        <td
                          className="pointer"
                          onClick={() =>
                            navigate(`/recording/viewrecording/${res._id}`)
                          }
                        >
                          {/* <img
                            width="36px"
                            height="36px"
                            src="/images/Profile.png"
                            className="me-2"
                          /> */}
                          {res.name}
                        </td>
                        <td>{res.uid}</td>
                        <td>{res.email}</td>
                        <td>
                          {moment(res.lastOnline).format(
                            "DD/MM/YYYY, h:mm:ss a"
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </TableContainer>
          </div>
        </div>
      </div>
      <Loader open={loading} />
    </>
  );
}
