import React, { useEffect, useState } from "react";
import { TopBar } from "../../Components/Sidebar/Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Label } from "reactstrap";
import Loader from "../../Components/Assets/Loader";
import { BackButton } from "../../Components/Assets/BackButton";
import { userProfileGetById } from "../../Services/Admin/Api";
import { baseUrl } from "../../Services/EndPoint";
import { io } from "socket.io-client";
import { loader } from "../../utils/loader";
const socket = io(baseUrl);
console.log(socket)

export default function ViewDetailUser() {
  const param = useParams();
  const [getUserId, setGetUserId] = useState({});
  const [loading, setLoading] = useState(false);


  socket?.on("getStatusUser", (data) => {
    if (data.role !== "admin") {
  let datas =getUserId
  console.log(datas)
        if (param.id === data.userId) {
         datas.status = data.status;
         
        }
   
      setGetUserId(datas);
    }
  });

  const getUserById = async () => {
    setLoading(true);
    await userProfileGetById(param.id)
      .then((res) => {
        // console.log(res);
        setGetUserId(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      })
  };

  useEffect(() => {
    
      getUserById();
  }, []);

  return (
    <>
      <TopBar></TopBar>
              {getUserId.name &&
      <div className="mainContainer">
        <div className="card p-md-4 p-sm-3 p-2">
          <BackButton link={"/users"} />
          <div className="d-flex  mt-3">
            {/* <div>
              <img src="/images/Avatar.png" />
            </div> */}
            <div className="ms-3 w-100 align-self-center text-start">
              <h1>{getUserId?.name}</h1>
              <p>{getUserId?.uid}</p>
              
              <p>
                {getUserId?.status === false ? (
                  <span className="oflineColor">Offline</span>
                  ) : (
                  <span className="onlineColor">Online</span>
                )}
              </p>

            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-6">
              <Label className="HelveticaMedium">Contact Number</Label>
              <input
                className="form-control"
                defaultValue={getUserId?.contectNo}
                disabled
              />
            </div>
            <div className="col-md-6">
              <Label className="HelveticaMedium">Email Address</Label>
              <input
                className="form-control"
                defaultValue={getUserId?.email}
                disabled
              />
            </div>
          </div>
        </div>
      </div>}
      <Loader open={loading} />

    </>
  );
}
