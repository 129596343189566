import React, { useEffect, useState } from "react";
import { TopBar } from "../../../Components/Sidebar/Sidebar";
import { BackButton } from "../../../Components/Assets/BackButton";
import { Input, Label } from "reactstrap";
import { Select } from "../../../Components/Inputs/Select";
import TableContainer from "../../../Components/Assets/TableContainer";
import { PAPER_LANGUAGES } from "../../../constant";
import {
  getOrrganizationById,
  getPaperById,
} from "../../../Services/Admin/Api";
import { useLocation, useParams } from "react-router-dom";
import Loader from "../../../Components/Assets/Loader";
import { InputTextarea } from "primereact/inputtextarea";
import moment from "moment";
import { Dialog } from "primereact/dialog";
import { approvedQuestion } from "../../../Services/Admin/Api";
import { useNavigate } from "react-router-dom";
import { userInfo } from "../../../Components/Assets/userData";
import { approvedQuestionNotification } from "../../../Components/Assets/socket";

export default function ParticularPaperNoData() {
  const [paperData, setPaperData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [splitData, setSplitData] = useState("");
  const [languageList, setLanguageList] = useState([
    "English",
    "Urdu",
    "Telgu",
  ]);
  const [selectLanguage, setSelectLanguage] = useState();
  const [modalOpen1, setModalOPen1] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [ModalOPen3, setModalOpen3] = useState(false);
  const [fileOpen, setFileOpen] = useState("");
  const [fileOpen1, seFileOpen1] = useState("");
  const [fileOpen2, setFileOpen2] = useState("");
  const [approveButton, setApprovedButton] = useState(false);
  const [statusState, setStatusState] = useState("");
  const [unApprovedBtn, setUnApproveBtn] = useState(false);
  const [organizationName, setOrganizationName] = useState();
  const [paperQuestion, setPaperQuestion] = useState([]);

  const navigate = useNavigate();
  let params = useParams();
  const location = useLocation();
  let examName = location?.state;
  console.log(examName);

  const getOrgnizationNameById = async (id) => {
    await getOrrganizationById(id)
      .then((res) => {
        console.log(res);
        setOrganizationName(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOrgnizationNameById(userInfo().orgId);
  }, []);
  const getPaperData = async () => {
    setLoading(true);
    await getPaperById(params.id)
      .then((res) => {
        let paperData = res.data.data;
        setPaperData(paperData);
        setPaperQuestion(paperData.questions);
        setApprovedButton(paperData.guildline_verify);
        if (paperData?.guildline_unaprove) {
          setUnApproveBtn(true);
        } else {
          setUnApproveBtn(false);
        }
        console.log(res);

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const approvedPaper = async (status, state) => {
    setLoading(true);
    await approvedQuestion(params.id, {
      guildlineStatus: status,
      guildline_unaprove: unApprovedBtn,
      organization_name: organizationName.orgName,
      Secretary_name: userInfo().name,
      orgId: paperData.orgId,
      exam_name: examName,
      status: state,
    })
      .then((res) => {
        setLoading(false);
        approvedQuestionNotification();
        // navigate("/questionPaperTab")
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const seletctLanguageData = (lang) => {
    let data = paperData?.questions?.filter((item) => {
      return item.language == lang;
    });
    setPaperQuestion(data);
  };
  useEffect(() => {
    getPaperData();
  }, []);
  return (
    <>
      <TopBar></TopBar>
      <Dialog
        style={{ width: "60rem" }}
        visible={modalOpen1}
        modal
        onHide={() => {
          setModalOPen1(false);
        }}
        closable={false}
        draggable={false}
      >
        {console.log(fileOpen)}
        <iframe
          src={fileOpen}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          style={{ width: "100%", height: "700px" }}
        ></iframe>
        <div>
          <button
            className="btn btn-Secondary"
            onClick={() => {
              setModalOPen1(false);
            }}
          >
            Cancel
          </button>
        </div>
      </Dialog>
      <Dialog
        style={{ width: "60rem" }}
        visible={modalOpen2}
        modal
        onHide={() => {
          setModalOpen2(false);
        }}
        closable={false}
        draggable={false}
      >
        <iframe
          src={fileOpen1}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          style={{ width: "100%", height: "700px" }}
        ></iframe>
        <div>
          <button
            className="btn btn-Secondary"
            onClick={() => {
              setModalOpen2(false);
            }}
          >
            Cancel
          </button>
        </div>
      </Dialog>
      <Dialog
        style={{ width: "60rem", height: "60rem" }}
        visible={ModalOPen3}
        modal
        onHide={() => {
          setModalOpen3(false);
        }}
        closable={false}
        draggable={false}
      >
        <iframe
          src={fileOpen2}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          style={{ width: "100%", height: "700px" }}
        ></iframe>
        <div>
          <button
            className="btn btn-Secondary"
            onClick={() => {
              setModalOpen3(false);
            }}
          >
            Cancel
          </button>
        </div>
      </Dialog>
      <div className="mainContainer">
        <div className="card p-md-4 p-sm-3 p-2">
          <div className="d-flex justify-content-between">
            <BackButton link={"/questionPaperTab"} />
          </div>
          <br />
          <section className="d-flex justify-content-between">
            <div className="d-flex align-items-center gap-3">
              <div>
                <img src="/images/questionPaper/questionTypeIcons4.svg" />
              </div>
              <div>
                <h1>{paperData?.paperCode}</h1>
                <div>{paperData?.paperName}</div>
              </div>
            </div>
            <div className="d-flex gap-3 align-items-center">
              <div>
                <div>{moment(paperData.paperDate).format("MMM/DD/YYYY")}</div>
                <div>Paper Date</div>
              </div>
            </div>
          </section>

          <div className="row mt-4">
            <div className="col-md-6">
              <Label className="HelveticaMedium">Question Paper Format</Label>
              {paperData?.question_skeleton_fileName ? (
                <div
                  className="border rounded-2 p-2 pointer"
                  onClick={() => {
                    setModalOPen1(true);
                    setFileOpen(paperData?.question_skeleton_Link);
                    console.log(paperData);
                  }}
                >
                  {paperData?.question_skeleton_fileName}
                </div>
              ) : (
                <input className="form-control" placeholder="-Na-" disabled />
              )}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-12">
              <Label className="HelveticaMedium">Evaluation Guidelines</Label>
              {paperData.guildline ? (
                <div
                  dangerouslySetInnerHTML={{ __html: paperData?.guildline }}
                  className="border rounded-2 p-2 pointer"
                ></div>
              ) : (
                <input className="form-control" placeholder="-Na-" disabled />
              )}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-6 col-12">
              <Label className="HelveticaMedium">Question Paper</Label>
              {paperData?.question_paper_fileName ? (
                <div
                  className="border rounded-2 p-2 pointer"
                  onClick={() => {
                    setModalOpen2(true);
                    seFileOpen1(paperData.question_paper_link);
                  }}
                >
                  {paperData?.question_paper_fileName}
                </div>
              ) : (
                <input className="form-control" placeholder="-Na-" disabled />
              )}
            </div>
            <div className="col-lg-6 col-12">
              <Label className="HelveticaMedium">Answer Key</Label>
              {paperData?.answerKey_fileName ? (
                <div
                  className="border rounded-2 p-2 pointer"
                  onClick={() => {
                    setModalOpen3(true);
                    setFileOpen2(paperData.answerKey_link);
                  }}
                >
                  {paperData?.answerKey_fileName}
                </div>
              ) : (
                <input className="form-control" placeholder="-Na-" disabled />
              )}
            </div>
          </div>
          <div className="d-flex justify-content-between mt-5">
            <h1>Question {paperData?.questions?.length}</h1>
            <Select
              placeholder="Select Language"
              options={PAPER_LANGUAGES}
              value={selectLanguage}
              onChange={(e) => {
                setSelectLanguage(e.target.value);
                seletctLanguageData(e.target.value)
              }}
            />
          </div>
          <div className="py-3">
            <TableContainer>
              <table className="left_align_table">
                <thead>
                  <th>Ques No.</th>
                  <th>Language</th>
                  <th>Question</th>
                  <th>Evaluator 1</th>
                  <th>Evaluator 2</th>
                </thead>
                <tbody>
                  {paperQuestion?.map((res, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{res?.language}</td>
                        <td>
                          <div
                            dangerouslySetInnerHTML={{ __html: res?.question }}
                          ></div>
                        </td>
                        <td>{res?.evaluator1Name}</td>
                        <td>{res?.evaluator2Name}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </TableContainer>
          </div>

          <div className="d-flex justify-content-end mt-5">
            {paperData?.question_skeleton_Link && paperData.guildline ? (
              <div className="d-flex">
                {unApprovedBtn === false ? (
                  <button
                    className="btn btn-Secondary me-3"
                    onClick={(e) => {
                      approvedPaper(false, "Unapproved");
                      setUnApproveBtn(true);
                      setApprovedButton(false);
                      setStatusState("Unapproved");
                    }}
                    disabled={approveButton}
                  >
                    Mark as Unapproved
                  </button>
                ) : (
                  <button className="btn unapproved_btn me-3">
                    Unapproved
                  </button>
                )}
                {approveButton === false ? (
                  <button
                    className="btn btn-primary"
                    onClick={(e) => {
                      approvedPaper(true, "Approved");
                      setApprovedButton(true);
                      setUnApproveBtn(false);
                      setStatusState("Approved");
                    }}
                  >
                    Approve
                  </button>
                ) : (
                  <button className="btn approved_btn">
                    <i className="pi pi-check"></i>Approved!
                  </button>
                )}
              </div>
            ) : (
              <div className="d-flex">
                <button className="btn btn-markUnapproved me-3" disabled>
                  Mark as Unapproved
                </button>
                <button className="btn btn-primary" disabled>
                  Approve
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Loader open={loading} />
    </>
  );
}
