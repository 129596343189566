import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import style from './style.module.css'


export default function Paginator({ itemsPerPage, items, setShowData, hideButtons,flagg }) {
    const [itemOffset, setItemOffset] = useState(0);
    const [pageCount, setPageCount] = useState()
    const [flag, setFlag] = useState(0)



    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % items.length;
        setItemOffset(newOffset);
        setFlag(flag + 1)
    };

    function pageNoClick(){
        const endOffset = itemOffset + itemsPerPage;
        const currentItems = items.slice(itemOffset, endOffset);
        setShowData(currentItems)
        setPageCount(Math.ceil(items.length / itemsPerPage))
    }
    useEffect(() => {
        pageNoClick()
    }, [flag,flagg])

    return (
        <>
            <div className={style.paginationContainer}>
                <div className={style.customPagination}>
                    {hideButtons ? '' : <button className={`btn btn-Secondary d-flex align-items-center bg-white  border ${style.btn} `} onClick={() => {
                        document.getElementById('prev').click()
                        setFlag(flag + 1)
                    }} ><i className={`pi pi-arrow-left me-1 ${style.icon}`} />Previous</button>}
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel={<button className='btn p-0' id='next'></button>}
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={2}
                        pageCount={pageCount}
                        previousLabel={<button className='btn p-0' id='prev'></button>}
                        renderOnZeroPageCount={null}
                        activeClassName={'activePagination'}
                    />
                    {hideButtons ? '' : <button className={`btn btn-Secondary d-flex align-items-center  bg-white border ${style.btn} `} onClick={() => {
                        document.getElementById('next').click()
                        setFlag(flag + 1)
                    }}>Next<i className={`pi pi-arrow-right ms-1 ${style.icon}`} /></button>}
                </div>
                {hideButtons ? '' :
                    <div className='between'>
                        <button className={`btn btn-Secondary d-flex align-items-center bg-white  border ${style.btn2}`} onClick={() => {
                            document.getElementById('prev').click()
                            setFlag(flag + 1)
                        }} ><i className={`pi pi-arrow-left me-1 ${style.icon}`} />Previous</button>

                        <button className={`btn btn-Secondary d-flex align-items-center bg-white  border ${style.btn2}`} onClick={() => {
                            document.getElementById('next').click()
                            setFlag(flag + 1)
                        }}>Next<i className={`pi pi-arrow-right ms-1 ${style.icon}`} /></button>
                    </div>
                }
            </div>
        </>
    );
}