import React, { useState } from "react";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import { Label } from "reactstrap";
import Infomation from "../../Components/Assets/Infomation";
import { emailVerified } from "../../Services/Admin/Api";
import Loader from "../../Components/Assets/Loader";
import CryptoJS from "crypto-js";

export default function EmailVarification() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [loading,setLoading] = useState(false)
  const navigate = useNavigate();
  const [userError,setUserError] = useState(false)

  function isValidEmail(userName) {
    return /\S+@\S+\.\S+/.test(userName);
  }
  const handleChange = (e) => {
    setEmail(e.target.value);
    setError(null);
  };
  const handleEncrypt = (message) => {
    const cipherText = CryptoJS.AES.encrypt(message, "<w9|2+-zV-8f(oJ");
    return cipherText.toString();
  };
  const handleSubmit = async(e) => {
    e.preventDefault();
    setError(null);
    setLoading(true)
    if (isValidEmail(email)) {
       await emailVerified({ email: email.toLowerCase() }).then((res)=>{
        const criptToken = handleEncrypt(res.data?.data?.token);
        sessionStorage.setItem("token", criptToken);
        sessionStorage.setItem("email",email.toLowerCase())
        navigate("/bioAuthentication");
        setLoading(false)
       }).catch((error)=>{
        console.log(error)
      
        if(error?.response?.data?.errormessage?.message ==="First reset your password"){
          setUserError(true)
          setError(false)
          setLoading(false)
        }else{
          setError(true)
          setLoading(false)
          setUserError(false)
        }
       })
     
    } else {
      setError(true);
      setLoading(false)
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="main-container">
          <div className="left-contain">
          <div className="logoFixed">
              <span className="eLogo">E-Valuators App</span>
            </div>
          </div>
          <div className="right-contain">
            <div className="right-content-div ">
              <div className="d-flex justify-content-center ">
                <span className="logIn">Forgot Password</span>
              </div>
              <div className="mt-4">
                <Label className="HelveticaMedium">Email Address</Label>
                <input
                  placeholder="Enter Email Address"
                  className={`form-control `}
                  type="email"
                  onChange={handleChange}
                  id="emailVerification"
                  required
                />
              </div>
              {error ? (
                <Infomation
                  text="Email Id does not exist"
                  type="warning"
                ></Infomation>
              ) : (
                ""
              )}
              {userError ? (
                <>
                <Infomation type="warning" text="First Time Login Alert: Please use the provided credentials to log in. Set up biometrics and reset password as required."/>
                </>
              ):(
                <></>
              )}
              <div>
                <button className=" btn btn-primary w-100 mt-4" type="subit" id="emailSubmit">
                  Continue
                </button>
              </div>
              <div className="d-flex justify-content-center mt-3">
                <Link to="/" className="textDecoration"> 
                <span className="backTologin ">Back to Log In page</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </form>
    <Loader open={loading}/>
    </>
  );
}
