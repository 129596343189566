import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import { TopBar } from "../../Components/Sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import Loader from "../../Components/Assets/Loader";
import Infomation from "../../Components/Assets/Infomation";
import {
  allAdminListing,
  deleteAdminById,
  disableEnableAdmin,
  getAllOrganization,
  adminListById,
} from "../../Services/Admin/Api";
import { Input } from "reactstrap";
import { OverlayPanel } from "primereact/overlaypanel";
import moment from "moment";
import { baseUrl } from "../../Services/EndPoint";
import { io } from "socket.io-client";
import TableContainer from "../../Components/Assets/TableContainer";
import { Select } from "../../Components/Inputs/Select";
import { Dropdown } from "primereact/dropdown";
import { CustomPagination } from "../../Components/Assets/CustomPagination";
const socket = io(baseUrl);
// let socket;

export default function AdminstartionList() {
  const navigate = useNavigate();
  const [adminsLists, setAdminLists] = useState([]);
  const [blank, setBlank] = useState(true);
  const [show, setShow] = useState(false);
  const [orgId, setOrgId] = useState();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [nameid, setName] = useState();
  const [roleListing, setRoleListing] = useState([]);
  const [selectRole, setSelectRole] = useState();
  const [adminListBy, setAdminListBy] = useState([]);
  const [searchAdmin, setSearchAdmin] = useState("");
  const [noData, setNodata] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [searchtext, setsearchtext] = useState("");
  const[enableData,setEnableData] = useState([])
  let [allSheetsCount, setAllSheetsCount] = useState(0);

  let [page, setPage] = useState(1);
  let per_page_data = 2;

  const [enableDisable, setEnableDisable] = useState([
    { name: "Enabled", value: true },
    { name: "Disabled", value: false },
  ]);
  const [enableValue, setEnableValue] = useState("");
  const op = useRef(null);

  const showHide = () => {
    setShow(!show);
  };

  const enableAdnDisableData = (e, data1) => {
    console.log(e);
    let data;
    if (data1) {
      data = [...data1];
    } else {
      data = [...searchData];
    }
    console.log(typeof e);
    console.log(data);
    let activeDatatable = data.filter((res) => res.isActive === JSON.parse(e));
    setEnableValue(e);
    setEnableData(activeDatatable)
    setAdminLists(activeDatatable);
  };
  const handleSearchClick = (e) => {
    let data = [...enableData];
    let val = e.trim().toLowerCase();

    if (val.length !== 0) {
      let searchName = data.filter((res) =>
        res?.name?.toLowerCase().includes(val)
      );
      console.log(searchName);
      setAdminLists(searchName);
    } else {
      setAdminLists(enableData);
    }
  };
  const adminsList = async () => {
    setLoading(true);
    await allAdminListing()
      .then((res) => {
        console.log(res);
        let dataReverse = [...res.data.data];
        let reverData = dataReverse.reverse();
        setAdminLists(reverData);
        setSearchData(reverData);
        enableAdnDisableData(true, reverData);
        setLoading(false);
        setBlank(false);
        if (res.data.data === 0) {
          setNodata(true);
        } else {
          setNodata(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  socket?.on("getStatusUser", (data) => {
    if (data.role === "admin") {
      let result = adminsLists.map((e) => {
        if (e._id === data.userId) {
          e.status = data.status;
          return e;
        } else {
          return e;
        }
      });
      setAdminLists(result);
    }
  });
  const listAdminById = async (id) => {
    setLoading(true);
    let idds = sessionStorage.getItem("org");
    await adminListById(idds ? idds : id)
      .then((res) => {
        console.log(res);
        let dataReverse = [...res.data.data];
        let reverData = dataReverse.reverse();

        setAdminLists(reverData);
        setSearchData(reverData);
        enableAdnDisableData(true, reverData);
        setLoading(false);
        setBlank(false);

        if (reverData.length === 0) {
          setNodata(true);
        } else {
          setNodata(false);
        }
      })
      .catch((err) => {
        setAdminLists([]);
        console.log(err);
        setLoading(false);
      });
  };

  const deleteOrganizationalAdmin = async (id) => {
    setLoading(true);
    await deleteAdminById(id)
      .then((res) => {
        listAdminById(selectRole);
        setLoading(false);
        setVisible(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const enableDisableProfil = async (status) => {
    setLoading(true);
    await disableEnableAdmin(orgId, {
      isActive: status,
    })
      .then(async (res) => {
        await listAdminById(selectRole);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const getSelectRole = async () => {
    await getAllOrganization().then((res) => {
      setRoleListing(res.data.data);
      if (sessionStorage.getItem("org")) {
        setSelectRole(sessionStorage.getItem("org"));
      } else {
        setSelectRole(res?.data?.data[0]?._id);
      }
      // setSelectRole(res.data.data)
    });
  };
  useEffect(() => {
    getSelectRole();
  }, []);
  useEffect(() => {
    if (selectRole) {
      listAdminById(selectRole);
    }
  }, [selectRole]);

  return (
    <>
      <Dialog
        visible={visible}
        modal
        style={{ width: "40rem" }}
        onHide={() => setVisible(false)}
        closable={false}
        draggable={false}
      >
        {" "}
        <h1>Delete Organizational Admin</h1>
        <p className="mt-4">
          Are you sure you want to delete the organizational admin named &nbsp;
          "{nameid}" ? This action is irreversible, and the organizational admin
          cannot be recovered once deleted.
        </p>
        <p className="mt-3">
          To hide the Admin temporarily, use the Disable option for the user.
        </p>
        <div className="d-flex justify-content-end mt-4">
          <div className="d-flex">
            <button
              className="btn btn-Secondary me-3"
              onClick={() => setVisible(false)}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                deleteOrganizationalAdmin(orgId);
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </Dialog>
      <TopBar>
        <div className="d-flex justify-content-end">
          {/* <Input
            className="form-control w-25"
            type="select"
            value={sessionStorage.getItem("org")}
            onChange={(e) => {
              setSelectRole(e.target.value);
              sessionStorage.setItem("org", e.target.value);
            }}
          >
            <option value="" disabled>
              Select Organization
            </option>
            {roleListing.map((res, index) => {
              return (
                <option key={index} value={res._id}>
                  {res.orgName}
                </option>
              );
            })}
          </Input> */}
          <Select
            style={{ width: "200px" }}
            options={roleListing}
            optionLabel="orgName"
            placeholder="Select organization"
            value={roleListing.find(
              (s) =>
                s._id ==
                (sessionStorage.getItem("org")
                  ? sessionStorage.getItem("org")
                  : selectRole)
            )}
            onChange={(e) => {
              console.log(e.value);
              setSelectRole(e.value._id);
              sessionStorage.setItem("org", e.value._id);
              setsearchtext("");
            }}
          />
        </div>
      </TopBar>

      {!blank && (
        <>
          {!noData ? (
            <>
              <div className="mainContainer">
                <div className="card p-md-4 p-sm-3 p-2">
                  <div className="d-flex justify-content-between">
                    <h1>Organizational Admin ({adminsLists.length})</h1>
                    <button
                      type=""
                      class="btn btn-Primary"
                      onClick={() => {
                        navigate("/adminstrations/addAdminstration");
                      }}
                    >
                      <i className="pi pi-plus me-2"></i>Add Organizational
                      Admin
                    </button>
                  </div>
                  <div className="d-flex mt-3 w-50">
                    <div className="searchInput w-50 me-3">
                      <input
                        className="form-control ps-5 "
                        placeholder="Search"
                        value={searchtext}
                        onChange={(e) => {
                          handleSearchClick(e.target.value);

                          setsearchtext(e.target.value);
                        }}
                      ></input>
                      <i className="pi pi-search searchIcon"></i>
                    </div>
                    {/* <Input
                      type="select"
                      className="form-control w-25"
                      onChange={(e) => enableAdnDisableData(e.target.value)}
                    >
                      <option value={true}>Enabled</option>
                      <option value={false}>Disabled</option>
                    </Input> */}
                    <Select
                      options={enableDisable}
                      value={enableValue}
                      optionLabel="name"
                      onChange={(e) => {enableAdnDisableData(e.target.value);setsearchtext("")}}
                    />
                  </div>
                  <div className="py-4">
                    <TableContainer>
                      <table className="">
                        <thead>
                          <th>Name</th>
                          <th>Admin Id</th>
                          <th>Email Address</th>
                          <th>Status</th>
                          <th>Last Active On</th>
                          <th></th>
                        </thead>
                        <tbody>
                          {adminsLists.map((res, index) => {
                            // const formattedLogoutTime = new Date(res.logoutTime).toLocaleString();
                            return (
                              <>
                                <tr key={index} className="pointer">
                                  <td
                                    onClick={() => {
                                      navigate(
                                        `/adminstrations/viewAdminDetail/${res._id}`
                                      );
                                    }}
                                    className="pointer"
                                  >
                                    {res?.name}
                                  </td>
                                  <td>{res?.uid}</td>
                                  <td>{res?.email}</td>
                                  <td>
                                    {res.status === false ? (
                                      <span className="oflineColor">
                                        Offline
                                      </span>
                                    ) : (
                                      <span className="onlineColor">
                                        Online
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    {console.log(res.logoutTime)}
                                    {res.logoutTime ?moment(res.logoutTime).format(
                                      "DD/MM/YYYY, h:mm:ss a"
                                    ):"N/A"}
                                    {/* {formattedLogoutTime} */}
                                  </td>
                                  <td>
                                    <i
                                      className="pi pi-ellipsis-v  pointer"
                                      id="Popover1"
                                      onClick={(e) => {
                                        setOrgId(res._id);
                                        setName(res.name);
                                        op.current.toggle(e);
                                      }}
                                    ></i>
                                    <OverlayPanel
                                      isOpen={show}
                                      flip
                                      placement="bottom"
                                      className="pointer"
                                      ref={op}
                                    >
                                      <div
                                        className="popover_item pointer mt-2"
                                        onClick={() => {
                                          navigate(
                                            `/adminstrations/editAdminstration/${orgId}`
                                          );
                                        }}
                                      >
                                        <i class="pi pi-pencil"></i>
                                        <span className="ms-2">
                                          Edit Profile
                                        </span>
                                      </div>

                                      {res.isActive === true ? (
                                        <div
                                          className="popover_item mt-2"
                                          onClick={() => {
                                            enableDisableProfil(false);
                                            setsearchtext("")
                                          }}
                                        >
                                          <i className="pi pi-ban"></i>
                                          <span className="ms-2">
                                            Disable Profile
                                          </span>
                                        </div>
                                      ) : (
                                        <div
                                          className="popover_item mt-2"
                                          onClick={() => {
                                            enableDisableProfil(true);
                                            setsearchtext("")

                                          }}
                                        >
                                          <i className="pi pi-ban"></i>
                                          <span className="ms-2">
                                            Enable Profile
                                          </span>
                                        </div>
                                      )}
                                      {/* {console.log(res)} */}

                                      <div
                                        className="popover_item pointer mt-2"
                                        onClick={() => {
                                          setVisible(true);
                                        }}
                                      >
                                        <i class="pi pi-trash"></i>
                                        <span className="ms-2">
                                          Delete Profile
                                        </span>
                                      </div>
                                      {/* </PopoverBody> */}
                                    </OverlayPanel>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      </table>
                    </TableContainer>
                    {/* <CustomPagination perPageData={per_page_data} page={page} totalRecords={adminsLists.length} pageChange={setPage}/> */}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="mainContainer align-items-center text-align-center py-1 ">
                <div className="d-flex justify-content-center">
                  <img src="/images/oImage.png" />
                </div>
                <p className="d-flex justify-content-center textColor">
                  It seems like you have not added any Organizational Admin yet.
                </p>
                <p className="d-flex justify-content-center textColor">
                  Please add to proceed further.
                </p>
                <div className="d-flex justify-content-center">
                  <button
                    type=""
                    class="btn btn-Primary"
                    onClick={() => {
                      navigate("/adminstrations/addAdminstration");
                    }}
                  >
                    <i className="pi pi-plus me-2"></i>Add Organizational Admin
                  </button>
                </div>
              </div>
            </>
          )}
        </>
      )}

      <Loader open={loading} />
    </>
  );
}
