import React, { Fragment, useEffect, useState } from "react";
import { TopBar } from "../../../Components/Sidebar/Sidebar";
import "./style.css";
import {getAllOrganization, getExamWithPaperList,getExamAndPaperId } from "../../../Services/Admin/Api";
import Loader from "../../../Components/Assets/Loader";
import moment from "moment";
import { Select } from "../../../Components/Inputs/Select";
import { useNavigate } from "react-router-dom";
import { userOrgId } from "../../../Components/Assets/userData";
export default function QuestionPaperTab() {
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  let [orgData, setOrgData] = useState([]);
  const [searchData,setSearchData] = useState([])
  const [orgList,setOrgList] = useState([])
  const [selectOrg,setSelectOrg] = useState()
  const navigate = useNavigate();
  const [filterOrganization,setFilterOrganization] = useState([])
  
  const handleSearchClick = (e) =>{
    let data = [...searchData]
    let val = e.trim().toLowerCase();
    
    let searchName = data.filter((res) =>
      res?.examName?.toLowerCase().includes(val)
    );
    setOrgData(searchName);
  }
  // const getAllData = async () => {
  //   setLoading(true);
  //   await getExamWithPaperList()
  //     .then((res) => {
  //       console.log(res);
  //       let dataReverse = [...res.data.data];
  //       let reverData = dataReverse.reverse();
  //       setAllData(reverData);
  //       setOrgData(reverData)
  //       setSearchData(reverData)
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setLoading(false);
  //     });
  // };
  
const getAllDataById = async () =>{
  setLoading(true)
  await getExamAndPaperId(userOrgId())
  .then((res)=>{
    console.log(res)
    let dataReverse = [...res.data.data];
        let reverData = dataReverse.reverse();
        setAllData(reverData);
        setSearchData(reverData)
        setOrgData(reverData)
        setLoading(false);
  }).catch((err)=>{
    console.log(err)
    setLoading(false);
  })
}
  const getSelectOrganization = async() =>{
    await getAllOrganization()
    .then((res)=>{
        setOrgList(res.data.data)
    }).catch((err)=>{
        console.log(err)
    })
  }
  useEffect(()=>{

    getSelectOrganization()
  },[])
  useEffect(() => {
    // getAllData();
    getAllDataById();
  }, []);
  return (
    <>
      <TopBar  >
      <div className="d-flex justify-content-end">
        {/* <Select  style={{ width: "200px" }}
        options={orgList}
        optionLabel="orgName"
        placeholder="Select organization"
        value={orgList.find(
            (s) => s._id == sessionStorage.getItem("org")
          )}
          onChange = {(e)=>{
            setSelectOrg(e.value._id);
            sessionStorage.setItem("org", e.value._id);
          }}
        /> */}
      </div>
      </TopBar>
      <div className="mainContainer">
        <div className="card p-md-4 p-sm-3 p-2">
          <div className="d-flex justify-content-end">
            <div className="searchInput w-25 me-3">
              <input
                className="form-control ps-5"
                placeholder="Search"
                onChange={(e) => {
                    handleSearchClick(e.target.value);
                }}
              ></input>
              <i className="pi pi-search searchIcon"></i>
            </div>
          </div>
          {orgData.map((res1) => {
            return (
              <Fragment>
                <div className="mt-5">
                  <h1>{res1.examName}</h1>
                </div>
                <div className="d-flex gap-5  my-5 flex-wrap">
                  {res1.QuestionData.map((res2,ind) => {
                    // {console.log()}
                    return (
                      <>
                        <div className="question_paper_card_con_secre1 pointer" onClick={()=>{navigate(`/questionPaperTab/particularPaperData/${res2._id}`,{state:res1.examName})}}>
                          <div className="row">
                            <div className="col-10 d-flex gap-3">
                              <div>
                                <img
                                  src={`/images/questionPaper/questionTypeIcons${
                                    ind < 6 ? ind + 1 : 1
                                  }.svg`}
                                />
                              </div>
                              <div>
                                <div className="questionPaperCard_heading">
                                  {res2.paperCode}
                                </div>
                                <div className="questionPaperCard_text mt-2">
                                  {res2.paperName}
                                </div>
                              </div>
                            </div>
                            <div className="col-2">{res2.guildline?res2.guildline_verify=== true ?<i className="pi pi-info-circle fs-5 colorchange"></i>:<img src="/images/info.png" />:""}</div>
                          </div>
                          <div className="questionPaperCard_text mt-3">
                          Paper Date: {moment(res2.paperDate).format(
                            "DD/MM/YYYY"
                          )}
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </Fragment>
            );
          })}
        </div>
      </div>
      <Loader open={loading} />
    </>
  );
}
