import React, { useState } from "react";
import { Dialog } from "primereact/dialog";

export const ConfirmationModal = ({
  children,
  data,
  header,
  text,
  onSubmit,
}) => {
  let [visible, setVisible] = useState(false);
  return (
    <>
      <div onClick={() => setVisible(true)}>{children}</div>

      <Dialog
        draggable={false}
        header={header}
        closable={false}
        visible={visible}
        style={{ width: "50vw" }}
        onHide={() => setVisible(false)}
      >
        <div>{text}</div>
        <div className="d-flex justify-content-end mt-3">
          <button
            className="btn btn-Secondary me-2"
            onClick={() => setVisible(false)}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              onSubmit(data);
              setVisible(false);
            }}
          >
            Delete
          </button>
        </div>
      </Dialog>
    </>
  );
};
