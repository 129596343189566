import React from 'react'
import style from "./style.module.css"

export default function ({ children, rounded }) {
  return (
    <div className={style.tableContainer}>
        <div className={`${style.Table_container} ${rounded ? style.roundBorder : ''}`}>
            <div className={style.TableContent}>
            {children}
            </div>
        </div>
    </div>
  )
}
