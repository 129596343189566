import React from 'react'
import { TopBar } from '../../../Components/Sidebar/Sidebar'

export default function () {
  return (
    <>
    <TopBar/>
    </>
  )
}
