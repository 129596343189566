import React, { useEffect, useState } from "react";
import TableContainer from "../../../Components/Assets/TableContainer";
import { getPaperDataResultForOrgAdmin } from "../../../Services/Admin/Api";
import { loader } from "../../../utils/loader";
import { userOrgId } from "../../../Components/Assets/userData";
import moment from "moment";
import { useNavigate } from "react-router-dom";

export default function OrgAwaitedResult() {
    const [awitedData,setAwaitedData] = useState([])

    const getOrgAwaitedResult = async() => {
        loader.start()
        await getPaperDataResultForOrgAdmin(userOrgId()).then((res)=>{
            console.log(res)
            setAwaitedData(res.data.data)
            loader.stop()
        }).catch((err)=>{
            console.log(err)
            loader.stop()
        })
    }

    useEffect(()=>{
        getOrgAwaitedResult()
    },[])
    let navigate = useNavigate()

  return (
    <>
      <div className="py-3">
        <TableContainer>
          <table className="left_align_table">
            <thead>
              <th>Exam Name</th>
              <th>No. of Paper</th>
              <th>Evaluated Papers</th>
              <th>No. of Students</th>
              <th>Exam Year</th>
              <th>Declare Result</th>
            </thead>
            <tbody>
                {awitedData.map((res,index)=>{
                    return (
                        <>
                        <tr key={index}>
                            <td className="pointer" onClick={()=>{navigate(`/orgAdminMainTab/orgAwaitedResult/orgdetailScreen/${res.examData._id}`,{state:res.examData.examName})}}>{res.examData.examName}</td>
                            <td>{res?.examData?.questionIds?.length}</td>
                            <td>{res.evaluatedSheet}</td>
                            <td>{res.number_of_students}</td>
                            <td>{moment(res.examData.examYear).format("YYYY")}</td>
                            <td>{<button  className="btn btn-primary" disabled={res.examData.declared === "undeclared"}>Declare</button>}</td>
                        </tr>
                        </>
                    )
                })}
              </tbody>
          </table>
        </TableContainer>
      </div>
    </>
  );
}
