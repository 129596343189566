import React, { useEffect, useState } from "react";
import { TopBar } from "../../../Components/Sidebar/Sidebar";
import TableContainer from "../../../Components/Assets/TableContainer";
import { useNavigate } from "react-router-dom";
import { hodAnswerSheetListById } from "../../../Services/Admin/Api";
import { userOrgId } from "../../../Components/Assets/userData";
import { loader } from "../../../utils/loader";
export default function OrgAdminAnswerSheetList() {
  const [hodFolder, setHodFolder] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const navigate = useNavigate();


  const handleSearchClick = (e) => {
    let data = [...searchData];
    let val = e.trim().toLowerCase();
    let searchName = data.filter((res) =>
      res?.original_name?.toLowerCase().includes(val)
    );
    setHodFolder(searchName);
  };

  const getAllListById = async () => {
    loader.start();
    await hodAnswerSheetListById({ id: userOrgId() })
      .then((res) => {
        console.log(res.data.data, "{}{}{}{}{}{");
        let dataReverse = [...res.data.data];
        let reverData = dataReverse.reverse();
        loader.stop();
        setHodFolder(reverData);
        setSearchData(reverData);
      })
      .catch((err) => {
        console.log(err);
        loader.stop();
      });
  };

  useEffect(() => {
    getAllListById();
  }, []);
  return (
    <>
      <TopBar />
      <div className="mainContainer">
        <div className="card p-md-4 p-sm-3 p-2">
          <div className="d-flex justify-content-between">
            <h1>Added Folders ({hodFolder.length})</h1>
            <div className="searchInput w-25 me-3">
              <input
                className="form-control ps-5"
                placeholder="Search"
                onChange={(e) => {
                  handleSearchClick(e.target.value);
                }}
              ></input>
              <i className="pi pi-search searchIcon"></i>
            </div>
          </div>
          <div className="py-5">
            <TableContainer>
              <table className="left_align_table">
                <thead>
                  <th>S.No</th>
                  <th>Folder Name</th>
                </thead>
                <tbody>
                  {hodFolder.map((res, index) => {
                    return (
                      <tr
                        onClick={() => {
                          navigate(
                            `/orgAdminAnswerSheet/orgAdminAnswerfile/${
                              res.question_paper_id
                            }?folderName=${encodeURIComponent(
                              res.original_name
                            )}`
                          );
                        }}
                        className="pointer"
                        key={index}
                      >
                        <td>{index + 1}</td>
                        <td>{res.original_name}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </TableContainer>
          </div>
        </div>
      </div>
    </>
  );
}
