import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import style from './style.module.css'

export default function Paginator1( { itemsPerPage1, items1, setShowData1, hideButtons1,flagg1 }) {
    const [itemOffset1, setItemOffset1] = useState(0);
    const [pageCount1, setPageCount1] = useState()
    const [flag1, setFlag1] = useState(0)



    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage1) % items1.length;
        setItemOffset1(newOffset);
        setFlag1(flag1 + 1)
    };

    function pageNoClick(){
        const endOffset = itemOffset1 + itemsPerPage1;
        const currentItems = items1.slice(itemOffset1, endOffset);
        setShowData1(currentItems)
        setPageCount1(Math.ceil(items1.length / itemsPerPage1))
    }
    useEffect(() => {
        pageNoClick()
    }, [flag1,flagg1])
  return (
    <>
    <div className={style.paginationContainer}>
        <div className={style.customPagination}>
            {hideButtons1 ? '' : <button className={`btn btn-Secondary d-flex align-items-center bg-white  border ${style.btn} `} onClick={() => {
                document.getElementById('prev1').click()
                setFlag1(flag1 + 1)
            }} ><i className={`pi pi-arrow-left me-1 ${style.icon}`} />Previous</button>}
            <ReactPaginate
                breakLabel="..."
                nextLabel={<button className='btn p-0' id='next1'></button>}
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                pageCount={pageCount1}
                previousLabel={<button className='btn p-0' id='prev1'></button>}
                renderOnZeroPageCount={null}
                activeClassName={'activePagination'}
            />
            {hideButtons1 ? '' : <button className={`btn btn-Secondary d-flex align-items-center  bg-white border ${style.btn} `} onClick={() => {
                document.getElementById('next1').click()
                setFlag1(flag1 + 1)
            }}>Next<i className={`pi pi-arrow-right ms-1 ${style.icon}`} /></button>}
        </div>
        {hideButtons1 ? '' :
            <div className='between'>
                <button className={`btn btn-Secondary d-flex align-items-center bg-white  border ${style.btn2}`} onClick={() => {
                    document.getElementById('prev1').click()
                    setFlag1(flag1 + 1)
                }} ><i className={`pi pi-arrow-left me-1 ${style.icon}`} />Previous</button>

                <button className={`btn btn-Secondary d-flex align-items-center bg-white  border ${style.btn2}`} onClick={() => {
                    document.getElementById('next1').click()
                    setFlag1(flag1 + 1)
                }}>Next<i className={`pi pi-arrow-right ms-1 ${style.icon}`} /></button>
            </div>
        }
    </div>
</>
  )
}
