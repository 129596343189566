import React, { useEffect, useState } from "react";
import { TopBar } from "../../../Components/Sidebar/Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import { loader } from "../../../utils/loader";
import { hodAnswerSheetViewDetail } from "../../../Services/Admin/Api";
import { BackButton } from "../../../Components/Assets/BackButton";
import TableContainer from "../../../Components/Assets/TableContainer";
import { userOrgId } from "../../../Components/Assets/userData";

export default function OrgAdminAnswerSheetfile() {
    let params = useParams();
    // let ddd=useSearchParams()
    let folderName = new URLSearchParams(window.location.search).get("folderName")
    // console.log(ddd) 
    console.log(folderName)
    const [answerSheetData, setAnswerSheetData] = useState([])
    const [searchData, setSearchData] = useState([])
    let [paperData,setPaperData]=useState({
      examName:"",
      paperName:""
    })
    const handleSearchClick = (e) => {
      let data = [...searchData]
      let val = e.trim().toLowerCase();
      let searchName = data.filter((res) =>
        res?.pdf_name?.toLowerCase().includes(val)
      );
      setAnswerSheetData(searchName);
    }
  
    const getAllAnswerSheetData = async () => {
      loader.start()
      await hodAnswerSheetViewDetail(userOrgId(), { folder_name: folderName,paper_id:params.id }).then((res) => {
        console.log(res)
        loader.stop()
        setPaperData({
          examName:res.data.data.paper.examName,
          paperName:res.data.data.paper.paperName
        })
        let dataReverse = [...res.data.data.answerSheet];
        let reverData = dataReverse.reverse();
        setAnswerSheetData(reverData)
        setSearchData(reverData)
      }).catch((err) => {
        console.log(err)
        loader.stop()
      })
    }
    useEffect(() => {
      getAllAnswerSheetData()
    }, [])
    const navigate = useNavigate();
  
  return (
    <>
      <TopBar />
      <div className="mainContainer">
        <div className="card p-md-4 p-sm-3 p-2">
          <BackButton link={"/orgAdminAnswerSheet"} />
          <div className="mt-4">
            <h1>{folderName}</h1>
            <p>{paperData?.examName} - {paperData.paperName}</p>
          </div>
          <div className="d-flex justify-content-between mt-5">
            <h1>Answer Sheets ({answerSheetData.length})</h1>
            <div className="searchInput w-25 me-3">
              <input
                className="form-control ps-5"
                placeholder="Search"
                onChange={(e) => {
                  handleSearchClick(e.target.value);
                }}
              ></input>
              <i className="pi pi-search searchIcon"></i>
            </div>
          </div>
          <div className="py-5">
            <TableContainer>
              <table className="left_align_table">
                <thead>
                  <th>S.NO</th>
                  <th>Sheet Code</th>
                  {/* <th>Status</th> */}
                </thead>
                <tbody>{answerSheetData.map((res, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td onClick={() => {
                      navigate(`/orgAdminAnswerSheet/orgAdminAnswerfile/orgAdminViewSheet/${res._id}?paperId=${res.question_paper_id}&folderName=${encodeURIComponent(folderName)}`)
                    }} className="pointer">{res.pdf_name}</td>
                    {/* <td>{res.status ==="Verified" ?(
                            <span className="onlineColor">{res.status}</span>
                        ):(
                            <span className="oflineColor">{res.status}</span>
                        )}</td> */}
                  </tr>
                ))}</tbody>
              </table>
            </TableContainer>
          </div>
        </div>
      </div>
    </>
  );
}
