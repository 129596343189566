import React, { useEffect, useState } from "react";
import "./style.css";
import { TopBar } from "../../../Components/Sidebar/Sidebar";
import ReactApexChart from "react-apexcharts";
import { getOverviewEvaluatorData } from "../../../Services/Admin/Api";
import { userId } from "../../../Components/Assets/userData";
import Loader from "../../../Components/Assets/Loader";
import moment from "moment";

export default function EvaluatOverview() {
  const [overViewData, setOverViewData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getEvaluatorOverviewData = async () => {
    await getOverviewEvaluatorData(userId())
      .then((res) => {
        console.log(res);
        let overview = res.data.data;
        let datas = overview.map((e) => {
          return {
            data: [
              {
                name: "Evaluated",
                data: e.submittedCount,
              },
              {
                name: "Draft",
                data: e.draftCount,
              },
              {
                name: "Not Evaluated",
                data: e.notSubmittedCount,
              },
            ],
            paperName: e.paperName,
            paperDate: e.paperDate,
            language: e.language,
            examName: e.examName,
            orgId: e.orgId,
            question_number: e.question_number,
          };
        });
        setOverViewData(datas);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        loading(false);
      });
  };
  useEffect(() => {
    getEvaluatorOverviewData();
  }, []);
  const donutData = overViewData[0]
    ? [
        {
          name: "Evaluated",
          data: overViewData[0].submittedCount,
        },
        {
          name: "Draft",
          data: overViewData[0].draftCount,
        },
        {
          name: "Not Evaluated",
          data: overViewData[0].notSubmittedCount,
        },
      ]
    : [];
  const options = {
    chart: {
      type: "donut",
    },
    dataLabels: {
      enabled: false,
    },
    labels: ["Evaluated", "Draft", "Not Evaluated"],
    colors: ["#FFCE54", "#48CFAD", "#4FC1E9"],
    data: {
      enabled: true,
      formatter: function (val, opts) {
        console.log(opts.w.config.series);
        return opts.w.config?.series;
      },
    },
  };
  return (
    <>
      <TopBar></TopBar>
      <div className="mainContainer">
        <div className="card p-md-4 p-sm-3 p-2">
          <h1>Assigned Papers Summary ({overViewData.length})</h1>
          <div className="d-flex  gap-10 flex-wrap">
            {overViewData.map((res, ind) => {
              return (
                <>
                  <div className="cardStyle gap-10 me-3 mt-3">
                    <div className="d-flex">
                      <img
                        src={`/images/questionPaper/questionTypeIcons${
                          ind < 6 ? ind + 1 : 1
                        }.svg`}
                      />
                      <div className="ms-3">
                        <h5>{res.orgId}</h5>
                        {/* <br /> */}
                        <h6>{res.paperName}</h6>
                      </div>
                    </div>


                    <ReactApexChart
                      options={options}
                      type="donut"
                      height={350}
                      width={350}
                      series={res?.data?.map((item) => item.data)}
                    />
                    <div className="d-flex ">
                      <div className="">Exam Name</div> &nbsp;&nbsp; :
                      &nbsp;&nbsp;<div>{res?.examName}</div>
                    </div>
                    <div className="d-flex mt-2 ">
                      <div>Language</div> &nbsp;&nbsp; : &nbsp;&nbsp;
                      <div>{res?.language}</div>
                    </div>
                    <div className="d-flex mt-2">
                      <div>Question Number</div> &nbsp;&nbsp; : &nbsp;&nbsp;
                      <div>{res?.question_number}</div>
                    </div>
                    <div className="d-flex mt-2">
                      <div>Exam Date</div> &nbsp;&nbsp; : &nbsp;&nbsp;
                      <div>{moment(res?.paperDate).format("DD/MM/YYYY")}</div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
      <Loader open={loading} />
    </>
  );
}
