import React, { useEffect, useState } from "react";
import moment from "moment";
import { TopBar } from "../../../Components/Sidebar/Sidebar";
import {
  getAnswerSheetPaperDataOrg,
  getDeclaredExam,
  putConfirmEvaluator,
  getExamById
} from "../../../Services/Admin/Api";
import { useLocation, useParams } from "react-router-dom";
import { userOrgId } from "../../../Components/Assets/userData";
import TableContainer from "../../../Components/Assets/TableContainer";
import { loader } from "../../../utils/loader";
import { BackButton } from "../../../Components/Assets/BackButton";

export default function OrgDetailScreenParticular() {
  const [paperData, setPaperData] = useState([]);
  const [declareData, setDeclaredData] = useState(0);
  const [examData,setExamData] = useState([])

  let paramId = useParams();
  const location = useLocation();
  let examName = location?.state;

  const getOrgDetailtScreenData = async () => {
    loader.start();
    let data = {
      examId: paramId,
      orgId: userOrgId(),
    };
    await getAnswerSheetPaperDataOrg(data)
      .then((res) => {
        console.log(res);
        setPaperData(res.data.data);
        let resDatas = res.data.data;
        let dattsaaa = 0;
        for (let i = 0; i < resDatas.length; i++) {
          if (resDatas[i].confirmEvaluation === "done") {
            dattsaaa = dattsaaa + 1;
          }
          if (i === resDatas.length - 1) {
            setDeclaredData(dattsaaa);
          }
        }
        loader.stop();
      })
      .catch((err) => {
        console.log(err);
        loader.stop();
      });
  };

  const getExamByIdData = async() =>{
    await getExamById(paramId.id).then((res)=>{
      console.log(res)
      setExamData(res.data.data)
    }).catch((err)=>{
      console.log(err)
    })
  }

  const confirmData = async (id,index) => {
    loader.start();

    await putConfirmEvaluator(id)
      .then((res) => {
        console.log(res);
        paperData[index].confirmEvaluation = "done"
        setPaperData(paperData)
        loader.stop();
        getOrgDetailtScreenData()
      })
      .catch((err) => {
        console.log(err);
        loader.stop();
      });
  };
  const declaredData = async () => {
    loader.start();
    await getDeclaredExam(paramId)
      .then((res) => {
        console.log(res);
        loader.stop();
        getExamByIdData()
      })
      .catch((err) => {
        console.log(err);
        loader.stop();
      });
  };

  useEffect(() => {
    getOrgDetailtScreenData();
    getExamByIdData()
  }, []);

  function declareButtondisable (){
    if(paperData.length !== 0 && examData.declared !== "done"){
      if(paperData.length === declareData){
return false
      }else{
        return true
      }
    }
    else{
      return true
    }
  }
  return (
    <div>
      <TopBar></TopBar>
      <div className="mainContainer">
        <div className="card p-md-4 p-sm-3 p-2">
          <BackButton link={`/orgAdminMainTab?activeTab=1`} />
          <div className="d-flex justify-content-between mt-3">
          <h5>{examName}</h5>
            <button className="btn btn-primary"  onClick={() => {
                declaredData();
              }}
              disabled={declareButtondisable()}>Declare</button>
          </div>
          <div className="py-3">
            <TableContainer>
              <table className="left_align_table">
                <thead>
                  <th>Paper Name</th>
                  <th>Paper Code</th>
                  <th>Paper Date</th>
                  <th>No. of Question</th>
                  <th>No. of Sheet</th>
                  <th>Evaluated Sheets</th>
                </thead>
                <tbody>
                  {paperData.map((res, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td>{res.paperName}</td>
                          <td>{res.paperCode}</td>
                          <td>{moment(res.paperDate).format("DD/MM/YYYY")}</td>
                          <td>{res.questionsId.length}</td>
                          <td>{res.no_of_sheet}</td>
                          <td>{res.evaluatedSheet}</td>
                          <td>
                          {res.confirmEvaluation === "pending" ?
                              <button
                                className="btn btn-Secondary"
                                disabled={
                                  res.no_of_sheet !== res.evaluatedSheet
                                }
                                onClick={() => {
                                  confirmData(res._id,index);
                                }}
                              >
                                Confirm Evaluation
                              </button>
                              : <button
                              className="btn btn-Secondary"
                             disabled
                             
                            >
                              Confirmed
                            </button>
                            }
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </TableContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
