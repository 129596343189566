import React from 'react'
import { useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'

export default function ({ open }) {
  return (
    <>
      {open ?
        <div className='loader_container'>
          <Spinner className='text-white'></Spinner>
        </div> : ""}
    </>
  )
}

export const MainLoader = () => {
  let loader = useSelector(e => e.Loader)
  return (
    <>
      {loader ?
        <div className='loader_container'>
          <Spinner className='text-white'></Spinner>
        </div> : null}
    </>
  )
}