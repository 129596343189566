import axios from "axios";
import CryptoJS from "crypto-js";
import { jwtDecode } from "jwt-decode";
import { baseUrl, evaluator,answerSheet,question ,recording} from "../EndPoint";

const handleDecrypt = () => {
  if (sessionStorage.getItem("token")) {
    const bytes = CryptoJS.AES.decrypt(
      sessionStorage.getItem("token"),
      "<w9|2+-zV-8f(oJ"
    );
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    //   const decoded = jwtDecode(originalText);

    return originalText;
  } else {
    return false;
  }
};

export async function getAssignPaperByEvaluatorId(id) {
  return await axios.get(`${baseUrl}${evaluator.getAssignQuestion}${id}`, {
    headers: { Authorization: `Bearer ${handleDecrypt()}` },
  });
}

export async function getAssignQuestionListByQuestionId(id) {
  return await axios.get(
    `${baseUrl}${evaluator.getFullAssignQuestionListing}${id}`,
    { headers: { Authorization: `Bearer ${handleDecrypt()}` } }
  );
}

export async function getEvaluatorAllSheetsData(id,body) {
  return await axios.post(
    `${baseUrl}${answerSheet.getAnswerSheetForEvaluatorNotSubmitted}${id}`,body,
    { headers: { Authorization: `Bearer ${handleDecrypt()}` } }
  );
}

export const getAnswerSheetForEvaluator=async(paperId,body)=>{
  return await  axios.post(
    `${baseUrl}${answerSheet.getAnswerSheetForEvaluator}${paperId}`,body,
    { headers: { Authorization: `Bearer ${handleDecrypt()}` } }
  );
}
export const submitAnswerSheetResult=async(anwerSheetId,body)=>{
  return await  axios.put(
    `${baseUrl}${answerSheet.submitAnswerSheetResult}${anwerSheetId}`,body,
    { headers: { Authorization: `Bearer ${handleDecrypt()}` } }
  );
}

export const updateAnswerSheetDraftResult=async(anwerSheetId,body)=>{
  return await  axios.put(
    `${baseUrl}${answerSheet.updateAnswerSheetDraftResult}${anwerSheetId}`,body,
    { headers: { Authorization: `Bearer ${handleDecrypt()}` } }
  );
}
export const getQuestionByEvaluatorId=async(paperId,body)=>{
  return await  axios.post(
    `${baseUrl}${question.getQuestionByEvaluatorId}${paperId}`,body,
    { headers: { Authorization: `Bearer ${handleDecrypt()}` } }
  );
}

export const addEvaluationRecording=async(body)=>{
  return await  axios.post(
    `${baseUrl}${recording.addEvaluationRecording}`,body,
    { headers: { Authorization: `Bearer ${handleDecrypt()}` } }
  );
}



